import {Injectable} from '@angular/core';
import * as AWS from 'aws-sdk';
import {environment} from "../../../../environments/environment";
import {EventSubscriptionService} from "./event-subscription.service";
import {NotificationEvent} from "../../models";
import {DebugConsole} from "../../helpers/debug-console";
import { Bool } from 'aws-sdk/clients/clouddirectory';

@Injectable()
export class AwsUploaderService {

	static readonly FILE_UPLOADED_EVENT = "FILE_UPLOADED_EVENT";


    publicBucketName = environment.awsBucketPublicName;
    secureBucketName = environment.awsBucketSecureName;
	cdnUrl = environment.cdnUrl;
	secureCdnUrl = environment.secureCdnUrl;
	envFolderPath = environment.awsAudioFolder;

	constructor(private notificationService:EventSubscriptionService) {
		AWS.config.region = 'us-east-1'; // Region
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: environment.awsCognitoIdentity,
		});
	}

	subscribeForNotification(scope, callback){
		this.notificationService.subscribeToNotificationEvent(AwsUploaderService.FILE_UPLOADED_EVENT, scope, callback);
	}

	unsubscribeNotification(scope){
		this.notificationService.unsubscribeToNotificationEvent(AwsUploaderService.FILE_UPLOADED_EVENT, scope);
	}

    getRandStr(): string{
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

	uploadFile(file, folderPath = null, isSecure: Bool = false, randFilename: Bool = false, meta = null): string {

	    let fileKey = '';

		if(!folderPath){
            folderPath = this.envFolderPath;
		}
		if(!randFilename) {
            fileKey = folderPath.concat(file.name);
        }
		else {
            let ext = file.name.split('.').pop();
            let randStr =  this.getRandStr();
            fileKey = folderPath.concat(`${randStr}.${ext}`);
        }

        DebugConsole.log("Upload File", fileKey, folderPath, isSecure);
		(AWS.config.credentials as AWS.Credentials).get( (err) => {
			if(!err){
				let s3 = new AWS.S3();
				let scope = this;
				let params = {
                    Bucket: (isSecure ? this.secureBucketName : this.publicBucketName),
                    Key: fileKey,
                    Body: file,
                    ACL: isSecure ? '' : 'public-read'
                };

				params = { ...params, ...meta};

				s3.upload(params, function(err, data) {
					if (err) {
						DebugConsole.log('There was an error uploading your file: ', err.message);
					}
					else{
						DebugConsole.log('Successfully uploaded file.', data);
						scope.notificationService.sendNotificationEvent(new NotificationEvent(AwsUploaderService.FILE_UPLOADED_EVENT, data));
					}
				});
			}
			else{
				DebugConsole.log("There was an error getting cognito credentials ", err.message);
			}
		});
		return (isSecure ? this.secureCdnUrl : this.cdnUrl) + fileKey;
	}
}
