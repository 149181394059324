import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
declare var fbq: Function;

@Injectable()
export class FacebookTrackingService {

    constructor(public router: Router) {
        this.router.events.subscribe(event => {
            try {
                if (typeof fbq === 'function') {
                    if (event instanceof NavigationEnd) {
                        // fbq('set', 'page', event.urlAfterRedirects);
                        fbq('track', 'PageView');
                        // console.error('%%% Google Analytics page view event %%%');
                    }
                }
            } catch (e) {
                console.error(e);
            }
        });

    }

    public appendFacebookPixel(){
        try {
            if(environment.FacebookPixelTracking_ID){
                const script = document.createElement('script');
                script.innerHTML = `
                    !function(f,b,e,v,n,t,s)
                         {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                         n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                         if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                         n.queue=[];t=b.createElement(e);t.async=!0;
                         t.src=v;s=b.getElementsByTagName(e)[0];
                         s.parentNode.insertBefore(t,s)}(window, document,'script',
                         'https://connect.facebook.net/en_US/fbevents.js');
                         fbq('init', ` + environment.FacebookPixelTracking_ID + `);
                  `;
                document.head.appendChild(script);
                // appending invisible pixel
                //document.append("<noscript><img height='1' width='1' style='display:none' src='https://www.facebook.com/tr?id="+ environment.FacebookPixelTracking_ID +"&ev=PageView&noscript=1'></noscript>" );

            }
            else{
                console.error("No Facebook Pixel ID");
            }

        } catch (ex) {
            console.error('Error appending Facebook Pixel Tracking');
            console.error(ex);
        }
    }



}
