import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../services/data/auth.service";
import { HttpHeaders } from "@angular/common/http";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  getHeaders(headers): HttpHeaders {
    const newHeaders = new HttpHeaders();

    Object.keys(headers).forEach(key=>{
      newHeaders.set(key, headers);
    });

    console.log('TokenInterceptor.intercept(): req', JSON.stringify(newHeaders, null, 2));

    return newHeaders;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authService: AuthService = this.injector.get(AuthService);
    const token = authService.getToken();
    if (token) {
      const changedReq = req.clone({
        headers: req.headers
          .set("Content-Type", "application/json")
          .set("Accept", "application/json")
          .set("Authorization", "Bearer " + token)
      });
      return next.handle(changedReq);
    } else {
      const changedReq = req.clone({
        headers: req.headers
          .set("Content-Type", "application/json")
          .set("Accept", "application/json")
      });
      return next.handle(changedReq);
    }
  }
}
