import {Component, OnInit, Input, ViewChild} from '@angular/core';
import {
    EventSubscriptionService,
    ContentService
} from '../../../classes/services';
import {NotificationEvent} from '../../../classes/models';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LearnModalComponent} from '../../../core/popup-manager/learn-modal/learn-modal.component';
import {CoachScheduleService} from '../../../classes/services/data/coach-schedule.service';
import {TextStoreService} from '../../../classes/services/data/text-store.service';
import {HelpPopupByKeyComponent} from '../../../core/popup-manager/help-popup-by-key/help-popup-by-key.component';
import {PopupNotificationService} from '../../../classes/services/core/popup-notification.service';

@Component({
    selector: 'app-coach-scheduler',
    templateUrl: './coach-scheduler.component.html',
    styleUrls: ['./coach-scheduler.component.scss']
})
export class CoachSchedulerComponent implements OnInit {
    constructor(
        private router: Router,
        private coachScheduleService: CoachScheduleService,
        private notificationService: EventSubscriptionService,
        // private modalService: NgbModal,
        private popupService: PopupNotificationService,
        private contentService: ContentService
    ) {
    }

    @ViewChild('helpModalTemplate') helpModalTemplate;

    page = 1;
    itemsPerPage = 4;

    isReadOnly = false;
    private _overrideUserId: number;

    @Input() set overrideUserId(value: number) {
        this._overrideUserId = value;
        this.isReadOnly = value !== 0 && value !== null;
    }

    get overrideUserId(): number {
        return this._overrideUserId;
    }

    schedule: any = [];
    helpPageKey = 'coach::schedule::help::';

    loadSchedule() {
        this.coachScheduleService.getSchedule(this.overrideUserId).subscribe(response => {
            if (response.success) {
                this.schedule = [];
                this.schedule.unshift.apply(this.schedule, response.response);
            }
        });
    }

    ngOnInit() {
        this.loadSchedule();

        this.notificationService.subscribeToNotificationEvent(
            'coach_schedule_modal',
            this,
            this.handleScheduleWS
        );
    }

    scheduleMore() {
        this.notificationService.sendNotificationEvent(
            new NotificationEvent('send_coach_message', 'Schedule upcoming sessions')
        );
    }

    removeSchedule(schedule: any) {
        this.coachScheduleService
            .removeSchedule(schedule)
            .subscribe(response => {
                if(response.success){
                    this.filterSchedule(schedule);
                }
            });
    }

    openHelpModal() {
        this.popupService.openHelpKeyPopup(this.helpPageKey);
    }

    // //================================================================================
    // // Web socket functions
    // //================================================================================
    private filterSchedule(schedule){
        this.schedule = this.schedule.filter(function (element, index, arr) {
            return element.id !== schedule.id;
        });
    }

    handleScheduleWS(scope, data: NotificationEvent) {
        switch (data.data.action) {
            case 'reload':
                // delay here to make sure the database has beem updated
                setTimeout(function () {
                    scope.loadSchedule();
                }, 1500);

                break;
            case 'add':
                scope.schedule.push(data.data.schedule);
                break;
            case 'remove':
                scope.filterSchedule(data.data.schedule);
                break;
            default:
                break;
        }
    }
}
