import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LearnCardData } from '../../../classes/models/learn-card-data';
import { PingsService } from '../../../classes/services';
import { PingsPageEnum, PingsActionEnum } from '../../../classes/enums/pings-page.enum';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {PopupSetPayload} from '../PopupSetPayload';

@Component({
  selector: 'app-learn-modal',
  templateUrl: './learn-modal.component.html',
  styleUrls: ['./learn-modal.component.scss']
})
export class LearnModalComponent implements OnInit, OnDestroy, PopupSetPayload {

  @Input() data: LearnCardData;

  constructor(
    private pingService: PingsService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sendPing();
  }

  sendPing() {
    this.pingService.sendPing(
      this.data.is_video ? PingsPageEnum.VideoTip : PingsPageEnum.Blog,
      PingsActionEnum.viewed,
      this.data.data.id
    );
  }

  setPayload(payload): void {
    this.data = payload;
  }

}
