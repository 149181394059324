<!--<div class=" {{ bot ? 'bg-gray-light text-right' : 'bg-white text-left' }} rounded-large p-4 flex flex-col">-->
    <app-base-chat-bubble [tailLocation]="bot ? 'left' :'right'" [colorClass]="bot ? 'bg-gray-light text-gray-darkest fill-gray-light' : 'bg-white text-gray-darkest fill-white'">
        <ng-container>
            <div class="flex flex-col">
                <div *ngIf="message.message" [innerHtml]="message.message"></div>
                <div *ngIf="message.options">
                    <div *ngFor="let option of message.options">
                        <app-coach-watch-card *ngIf="option.type == 'watch'"
                                              [id]="option.watch_id"></app-coach-watch-card>
                        <app-coach-read-card *ngIf="option.type == 'read'"
                                             [id]="option.read_id"></app-coach-read-card>
                        <app-coach-game-card *ngIf="option.type == 'game'"
                                             [id]="option.game_id"></app-coach-game-card>
                        <app-coach-present-card *ngIf="option.type == 'present'"></app-coach-present-card>
                        <app-coach-practice-card
                            *ngIf="option.type == 'practice'"></app-coach-practice-card>
                    </div>
                </div>
                <div>
                <span *ngIf="!message.hide_date_time || message.hide_date_time == false"
                      (click)="handleClickOfDateArea(message, true)"
                      class="text-xs"> {{message.created_at | dateToLocal | date: 'shortTime'}}
                    | {{message.created_at | dateToLocal | date}}</span>
                    <span *ngIf="message.hide_date_time == true"
                          (click)="handleClickOfDateArea(message, false)" class="text-xs">
                          ID: {{message.id}}
                </span>
                    <span *ngIf="bot" (click)="sendFeedback(message.id)" class="text-sm text-blue cursor-pointer ml-2">
                  Provide Feedback
                </span>
                </div>
            </div>

        </ng-container>
    </app-base-chat-bubble>



<!--</div>-->
