import { NgModule } from "@angular/core";
import {CoachBubbleComponent} from './coach-bubble/coach-bubble.component';
import {CoachPopupComponent} from './coach-popup/coach-popup.component';
import {CoachChatFeedbackPopupComponent} from './coach-popup/coach-chat/coach-chat-feedback-popup/coach-chat-feedback-popup.component';
import {CoachChatComponent} from './coach-popup/coach-chat/coach-chat.component';
import {CoachTasksComponent} from './coach-popup/coach-tasks/coach-tasks.component';
import {CoachPresentCardComponent} from './coach-popup/coach-chat/coach-present-card/coach-present-card.component';
import {CoachWatchCardComponent} from './coach-popup/coach-chat/coach-watch-card/coach-watch-card.component';
import {CoachReadCardComponent} from './coach-popup/coach-chat/coach-read-card/coach-read-card.component';
import {CoachGameCardComponent} from './coach-popup/coach-chat/coach-game-card/coach-game-card.component';
import {CoachSchedulerComponent} from './coach-popup/coach-scheduler/coach-scheduler.component';
import {PracticeCardComponent} from './coach-popup/coach-chat/practice-card/practice-card.component';
import {CoachChatService} from '../classes/services/data/coach-chat.service';
import {CoachTaskService} from '../classes/services/data/coach-task.service';
import {CoachScheduleService} from '../classes/services/data/coach-schedule.service';
import {CoachService} from '../classes/services/core/coach.service';
import {CommonModule} from '@angular/common';
import {CoreModule} from '../core/core.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from '@angular/forms';
import {LearnCardComponent} from '../presentr-app/learn/learn-card/learn-card.component';
import {CorePipesModule} from '../core/core-pipes.module';
import {UiCoreModule} from '../core/ui/ui-core.module';
import { CoachChatBubbleComponent } from './coach-popup/coach-chat/coach-chat-bubble/coach-chat-bubble.component';

@NgModule({
  imports: [
    NgbModule,
    FormsModule,
    CommonModule,
    CoreModule,
    CorePipesModule,
    UiCoreModule
  ],
    declarations: [
        CoachBubbleComponent,
        CoachPopupComponent,
        PracticeCardComponent,
        CoachChatFeedbackPopupComponent,
        CoachChatComponent,
        CoachTasksComponent,
        CoachPresentCardComponent,
        CoachWatchCardComponent,
        CoachReadCardComponent,
        CoachGameCardComponent,
        CoachSchedulerComponent,
        CoachChatBubbleComponent,
    ],
    exports: [
        CoachBubbleComponent
    ],
    providers:[
        CoachChatService,
        CoachTaskService,
        CoachScheduleService,
        CoachService,
    ]
})

export class CoachModule {}
