
<app-popup-base>
    <ng-container>
        <div class="modal-body container">
            <div class="p-4 text-center">
                <app-media-player class="p-4 min-w-1/2" *ngIf="recording" [recording]="recording"></app-media-player>
            </div>
        </div>


    </ng-container>
</app-popup-base>
