import { Component, OnInit, Input } from '@angular/core';
import { ContentService } from '../../../../classes/services';

@Component({
  selector: 'app-coach-watch-card',
  templateUrl: './coach-watch-card.component.html',
  styleUrls: ['./coach-watch-card.component.scss']
})
export class CoachWatchCardComponent implements OnInit {

  @Input() id: number;
  card: any;
  constructor(private contentService: ContentService) { }

  ngOnInit() {

    this.contentService.getVideoBlogsLearnCards(this.id).subscribe(resp => {
      if (resp.success) {
          this.card = resp.response[0];
      }
    });



  }

}
