<section class="flex flex-wrap" id="app-coach">
    <div id="top-bar" class="w-full">
        <app-top-bar></app-top-bar>
    </div>
    <!--Side-Menu-removed-->
    <!-- <div id="side-menu" class="flex justify-between w-full md:w-64">
        <app-side-menu></app-side-menu>
    </div> -->
    <div id="routero" class="container flex-1 p-2 ml-4 pb-8 {{('CanSeeCoach' | hasPermission) ? 'pb-24' : ''}}">
        <router-outlet></router-outlet>
    </div>

    <!-- <app-coach-bubble *ngIf="('CanSeeCoach' | hasPermission)"></app-coach-bubble> -->
    <app-loading></app-loading>
</section>
<br>
<br>
<br>
