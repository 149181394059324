import {Component, Input, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {AuthService, ContentService} from '../../classes/services';

@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss']
})
export class MediaPlayerComponent implements OnInit {

  @Input() recording: any = {};
  constructor(private contentService: ContentService) { }

  isFastForwarding: boolean;

  ngOnInit() {

  }

  securePath(): string {
    return this.contentService.getSignedContentPath('recording', this.recording.result_id);
  }

  forward() {
    this.isFastForwarding = true;
    document.querySelector("video").playbackRate = 16.0;
    document.querySelector("video").muted = true;
  }

  resume() {
    this.isFastForwarding = false;
    document.querySelector("video").playbackRate = 1.0;
    document.querySelector("video").muted = false;
}
}
