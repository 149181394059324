import { Component, OnInit, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { UserNotificationService } from '../../classes/services/core/user-notification.service';
import { PopupNotificationService } from '../../classes/services/core/popup-notification.service';
import { ConfirmOkOnlyPopupModalComponent } from '../../core/popup-manager/confirm-ok-only-popup-modal/confirm-ok-only-popup-modal.component';
import {AuthService} from '../../classes/services';
import {NotificationSourcePipe} from '../../classes/pipes/notification-source.pipe';
import { HasPermissionPipe } from "../../classes/pipes/has-permission.pipe";

@Component({
    selector: 'app-user-notifications',
    templateUrl: './user-notifications.component.html',
    styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent implements OnInit {

    customerNotifications = [];
    systemStaticNotifications = [];
    systemPopupNotification: any;

    userId: Number;
    customerId: Number;
    showSystemNotification: boolean = false;
    neverShowClicked: boolean = false;

    @ViewChild('userNotificationPopup') userNotificationElementRef: TemplateRef<any>;
    @ViewChild('systemNotificationPopup') systemNotificationElementRef: TemplateRef<any>;
    @Output() dismissed = new EventEmitter();
    @Input() whereShown: string;

    constructor(
        private notificationService: UserNotificationService,
        private popupService: PopupNotificationService,
        private authService: AuthService,
        private notificationSourcePipe: NotificationSourcePipe,
        private hasPermissionPipe: HasPermissionPipe
    ) { }

    ngOnInit() {


        this.userId = this.authService.getLoggedInUser().id;

        if(this.authService.getLicense()){

            this.customerId = this.authService.getLicense().customer_id;
        }
        else {
            this.customerId = 0;
        }

        if(this.whereShown == 'home') this.customerId = 0;

        this.notificationService.getAllNotificationsForUserId(this.customerId, this.userId).subscribe((resp: any) => {
            //filter the different types of notifications
            let systemPopupNotifications = this.notificationSourcePipe.transform(resp, 'system', this.whereShown)
                .filter(resp => resp.notificationType === 'popup');
            this.systemStaticNotifications = this.notificationSourcePipe.transform(resp, 'system', this.whereShown)
                .filter(resp => resp.notificationType != 'popup');  //not popups. static.
            this.customerNotifications = this.notificationSourcePipe.transform(resp, 'customer', this.whereShown);


            //Display system static notifications
            if(this.systemStaticNotifications) this.showSystemNotification = true;

            //Display system popup notifications
            if(this.customerNotifications && this.customerNotifications.length > 0) {
                this.displayUserNotifications();
            }

            if(systemPopupNotifications && systemPopupNotifications.length > 0) {
                //for popup notifications, we can only display one message so we'll pull the first one
                //only and display it. later, we might want to display multiple in the same popup or
                //display multiple popups.

                //limit to first:
                this.systemPopupNotification = systemPopupNotifications[0];

                //check permission of this notification and only display if permitted.
                var hasPermission = false;
                if(this.systemPopupNotification.permissions) {
                    this.systemPopupNotification.permissions.forEach(perm => {
                        if(this.hasPermissionPipe.transform(perm)) {
                            hasPermission = true;
                        }
                    });
                    
                }
                //check to see if the user has turned off this popup by looking in localstorage
                let popupAllowed = true;
                let popups = JSON.parse(localStorage.getItem("suppressPopups"));
                
                if(popups){
                    popups.map(item => {
                        if(item === this.systemPopupNotification.title) {
                            popupAllowed = false;
                        }
                    });
                }

                if(hasPermission && popupAllowed) this.displaySystemNotifications();
            }

        }, (error: any) => {
            console.log(error);
        });
    }

    removePopup (event, popupTitle) {
        let popups = [] || JSON.parse(localStorage.getItem("suppressPopups"));
        popups.push(popupTitle);
        localStorage.setItem("suppressPopups", JSON.stringify(popups));
        this.neverShowClicked = true;
    }

    displayUserNotifications() {
        this.popupService.openPopup(ConfirmOkOnlyPopupModalComponent, {
           // title: 'Notice',
            //content: message
            //message: message
            elementRef: this.userNotificationElementRef
        }, (result) => {
            if (result)
                this.dismissNotification();
        });
    }

    displaySystemNotifications() {
        this.popupService.openPopup(ConfirmOkOnlyPopupModalComponent, {
           // title: 'Notice',
            //content: message
            //message: message
            elementRef: this.systemNotificationElementRef
        }, (result) => {
            if (result)
                this.dismissNotification();
        });
    }

    dismissNotification() {
        this.dismissed.emit();
    }

}
