<app-base-static-card>
  <ng-container slot="body">
    <div class="flex w-full flex-col">
      <div class="flex w-full justify-center">
        <svg [attr.height]="size" [attr.width]="size">
          <circle [attr.class]="'circle-background'" id="_cir_P_x" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="outerRadius + (strokeWidthOut/ 2) - 1" stroke-width="0"></circle>

          <!--outter progress-->
          <circle [attr.class]="'circle-background progress-circle-bg'" id="_cir_P_v" [attr.cx]="middlePoint" [attr.cy]="middlePoint"
                  [attr.r]="outerRadius" [attr.stroke-width]="strokeWidthOut" [attr.stroke-dasharray]="perimeterOut+','+perimeterOut"></circle>
          <circle class="progress-circle" id="_cir_P_y" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="outerRadius" [attr.stroke-width]="strokeWidthOut"
                  [attr.stroke-dasharray]="progressOutNumber()+','+perimeterOut" stroke-dashoffset="" stroke="#FFFFFF" fill="none"></circle>

          <!--inner progress-->
          <circle [attr.class]="'circle-background progress-circle-bg'" id="_cir_P_v" [attr.cx]="middlePoint" [attr.cy]="middlePoint"
                  [attr.r]="innerRadius" [attr.stroke-width]="strokeWidth" [attr.stroke-dasharray]="perimeter+','+perimeter"></circle>
          <circle class="progress-circle" id="_cir_P_y" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="innerRadius" [attr.stroke-width]="strokeWidth"
                  [attr.stroke-dasharray]="progressNumber()+','+perimeter" stroke-dashoffset="" stroke="#6ABEFF" fill="none"></circle>

          <!--inner circle-->
          <circle [attr.class]="'circle-background'" id="_cir_P_z" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="bgInnerRadius" stroke-width="0"></circle>


          <text class="percentage-text" [attr.font-size]="textSize+'px'" x="50%" y="45%" text-anchor="middle" fill="#FFFFFF" stroke="none" stroke-width="1px" dy=".4em"
                id="_cir_Per">{{middleValue}}</text>
          <text class="percentage-text" [attr.font-size]="labelTextSize+'px'" x="50%" y="60%" text-anchor="middle" fill="#FFFFFF" stroke="none" stroke-width="1px" dy=".4em"
                id="_cir_Per">{{middleValueLabel}}</text>
        </svg>
      </div>

      <div class="flex w-full p-4 items-center">
        <h4 class="text-blue">{{ outValue }}</h4>
        <span class="ml-2 text-sm">{{outerDetailLabel}}</span>
      </div>

      <div class="flex w-full p-4 items-center">
        <h4 class="text-blue">{{ inValue }}</h4>
        <span class="ml-2 text-sm">{{innerDetailLabel}}</span>
      </div>
    </div>
  </ng-container>
</app-base-static-card>




<!--<div class="card" >-->
<!--  <div class="card mt-0" style="background-color: #0081e6">-->
<!--    <div class="card-body center">-->

<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--          <svg [attr.height]="size" [attr.width]="size">-->
<!--            <circle [attr.class]="'circle-background'" id="_cir_P_x" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="outerRadius + (strokeWidthOut/ 2)" stroke-width="0"></circle>-->

<!--            &lt;!&ndash;outter progress&ndash;&gt;-->
<!--            <circle [attr.class]="'circle-background progress-circle-bg'" id="_cir_P_v" [attr.cx]="middlePoint" [attr.cy]="middlePoint"-->
<!--                    [attr.r]="outerRadius" [attr.stroke-width]="strokeWidthOut" [attr.stroke-dasharray]="perimeterOut+','+perimeterOut"></circle>-->
<!--            <circle class="progress-circle" id="_cir_P_y" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="outerRadius" [attr.stroke-width]="strokeWidthOut"-->
<!--                    [attr.stroke-dasharray]="progressOutNumber()+','+perimeterOut" stroke-dashoffset="" stroke="#FFFFFF" fill="none"></circle>-->

<!--            &lt;!&ndash;inner progress&ndash;&gt;-->
<!--            <circle [attr.class]="'circle-background progress-circle-bg'" id="_cir_P_v" [attr.cx]="middlePoint" [attr.cy]="middlePoint"-->
<!--                    [attr.r]="innerRadius" [attr.stroke-width]="strokeWidth" [attr.stroke-dasharray]="perimeter+','+perimeter"></circle>-->
<!--            <circle class="progress-circle" id="_cir_P_y" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="innerRadius" [attr.stroke-width]="strokeWidth"-->
<!--                    [attr.stroke-dasharray]="progressNumber()+','+perimeter" stroke-dashoffset="" stroke="#6ABEFF" fill="none"></circle>-->

<!--            &lt;!&ndash;inner circle&ndash;&gt;-->
<!--            <circle [attr.class]="'circle-background'" id="_cir_P_z" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="bgInnerRadius" stroke-width="0"></circle>-->


<!--            <text class="percentage-text" [attr.font-size]="textSize+'px'" x="50%" y="45%" text-anchor="middle" fill="#FFFFFF" stroke="none" stroke-width="1px" dy=".4em"-->
<!--                  id="_cir_Per">{{middleValue}}</text>-->
<!--            <text class="percentage-text" [attr.font-size]="labelTextSize+'px'" x="50%" y="60%" text-anchor="middle" fill="#FFFFFF" stroke="none" stroke-width="1px" dy=".4em"-->
<!--                  id="_cir_Per">{{middleValueLabel}}</text>-->
<!--          </svg>-->
<!--        </div>-->
<!--      </div>-->


<!--    </div>-->

<!--    <div class="card-footer card-footer-inner">-->
<!--      <div class="row center">-->
<!--        <div class="col-6 ">-->
<!--          {{ outValue }}-->
<!--          <p class="card-footer-inner-label">{{outerDetailLabel}}</p>-->
<!--        </div>-->
<!--        <div class="col-6 inner">-->
<!--          {{ inValue }}-->
<!--          <p class="card-footer-inner-label">{{innerDetailLabel}}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
