
<div class="t flex flex-col  w-full justify-between {{bgColorClass}} {{rounded ? 'bs-rounded' : ''}} border-gray {{!showHover ? 'no-hover' : ''}} {{showOverlay ? 'hover:border-blue' : ''}} group" (click)="handleCardClicked()">
    <div class="flex w-full">
        <ng-content select="[slot='header']"></ng-content>
    </div>

    <div class="relative flex w-full h-full">
        <ng-content select="[slot='body']"></ng-content>
        <div *ngIf="showOverlay" class="absolute flex justify-center overlay bottom-0 left-0 w-full {{overlayHeight}} rounded-b max-h-0 group-hover:max-{{overlayHeight}} flex-invisible group-hover:visible">
            <ng-content select="[slot='overlay']" ></ng-content>
        </div>
    </div>
</div>
