import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

import * as socketIo from 'socket.io-client';
import { EventSubscriptionService } from './event-subscription.service';
import { AuthService } from '../data/auth.service';
import { NotificationEvent } from '../../models';

@Injectable()
export class SocketService {

    constructor(
        private notificationService: EventSubscriptionService,
        private authService: AuthService) {
    }

    wsUri = environment.webSocketUrl;
    private socket: socketIo;

    _isConnected: Boolean = false;
    set isConnected(isConnected: Boolean) {
        this._isConnected = isConnected;
        this.notificationService.sendNotificationEvent(new NotificationEvent('ws_connection_changed', isConnected));
      }

     get isConnected(): Boolean { return this._isConnected; }

    public initSocket(): void {
        const _scope = this;
        if (this.wsUri === '') {
            return;
        }
        console.log('init socket');
        this.socket = socketIo(this.wsUri, {
            transports: ['websocket'],
            secure: false,
            upgrade: false,
            reconnectionDelay: 1000,
            reconnectionDelayMax : 15000,
            reconnectionAttempts: 5,
            path: '/socket.io/socket.io.js'
        });

        this.socket.on('connect', function () {
            _scope.isConnected = true;
            console.log('socket connected');
            const user = _scope.authService.getLoggedInUser();
            if (user) {
                const token = user.access_token;

                _scope.socket.emit('token', token);
            }
        });

        this.socket.on('disconnect', function () {
            _scope.isConnected = false;
            console.log('socket disconnected');
        });

        this.socket.on('user::' + this.authService.getLoggedInUser().id, (data) => {
            const parsedData = JSON.parse(data);
            if(parsedData.parent == "coach" && parsedData.type == "chat") {
                this.notificationService.sendNotificationEvent(new NotificationEvent('coach_chat', parsedData));
            }
            else if(parsedData.parent == "coach" && parsedData.type == "task") {
                this.notificationService.sendNotificationEvent(new NotificationEvent('coach_task', parsedData));
            }
            else if(parsedData.parent == "coach" && parsedData.type == "schedule") {
                this.notificationService.sendNotificationEvent(new NotificationEvent('coach_schedule', parsedData));
            }
            else if(parsedData.parent == "coach" && parsedData.type == "chat_badge") {
                this.notificationService.sendNotificationEvent(new NotificationEvent('coach_chat_bubble_badge', parsedData.payload));
            }
            else if(parsedData.parent == "ketchup" && parsedData.type == "reload_customers") {
                this.notificationService.sendNotificationEvent(new NotificationEvent('ws_reload_customers', parsedData.payload));
            }
        });
    }

    public closeSocket(): void {
        if (this.socket != null) {
            this.socket.close();
            this.isConnected = false;
        }
    }

    public send(message: string): void {
        this.socket.emit('message', message);
    }

}
