import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ContentService} from '../../classes/services';
import {SettingsService} from '../../classes/services/core/settings.service';
import {PopupNotificationService} from '../../classes/services/core/popup-notification.service';
import {ConfirmationPopupModalComponent} from '../popup-manager/confirmation-popup-modal/confirmation-popup-modal.component';

@Component({
  selector: 'app-create-topic',
  templateUrl: './create-topic.component.html',
  styleUrls: ['./create-topic.component.scss']
})
export class CreateTopicComponent implements OnInit {

    @Input() customerId: Number;
    @Input() userId: Number;
    @Input() title: string = 'Custom Topics';
    @Input() createEditRoute: string;

    hidingDefaults:any = false;
    topics: any[];

    constructor(private contentService: ContentService,
                private settingsService: SettingsService,
                private ngCh: ChangeDetectorRef,
                private popupService: PopupNotificationService) { }

    ngOnInit() {
        if(this.userId){
            this.settingsService.asyncUserHasSetting(this.userId, 'custom-prompts-remove-defaults').subscribe(resp =>{
                this.hidingDefaults = resp;
                this.ngCh.detectChanges();
            });
        }
        if(this.customerId){
            this.settingsService.asyncCustomerHasSetting(this.customerId, 'custom-prompts-remove-defaults').subscribe(resp =>{
                this.hidingDefaults = resp;
                this.ngCh.detectChanges();
            });
        }
        this.fetchTopics();
    }

    fetchTopics(){
        this.contentService.getAllTopics(!!this.userId, !!this.customerId).subscribe(resp  => {
            let r: any = resp;
            if(r.success){
                this.topics = r.response;
            }
        });
    }

    deleteTopic(topic){
        this.popupService.openPopup(ConfirmationPopupModalComponent, {
            title: 'Confirmation',
            text: 'Are you sure you want to delete this topic?'
        }, (result) => {
            if (result)
                this.contentService.removeTopic(topic.id).subscribe(resp=>{
                    if(resp.success){
                        this.popupService.openSuccessPopup("Successfully removed Topic");
                        this.fetchTopics();
                    }
                    else{
                        this.popupService.openFailurePopup("Error removing Topic");
                    }
                });
        });
    }

    defaultsClicked(event){
        this.hidingDefaults = event;
        if(this.userId){
            if(event){ // hidden
                this.settingsService.setSettingForUserObserver(this.userId, 'custom-prompts-remove-defaults', true, 'true').subscribe(resp => this.fetchTopics());
            }else{
                this.settingsService.removeSettingForUserObserver(this.userId, 'custom-prompts-remove-defaults',true).subscribe(resp => this.fetchTopics());
            }
        }
        if(this.customerId){
            if(event){ // hidden
                this.settingsService.setSettingForCustomerObserver(this.customerId, 'custom-prompts-remove-defaults', 'true').subscribe(resp => this.fetchTopics());
            }else{
                this.settingsService.removeSettingForCustomerObserver(this.customerId, 'custom-prompts-remove-defaults').subscribe(resp => this.fetchTopics());
            }
        }
    }
}
