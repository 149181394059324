import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {EventSubscriptionService, LoadingService} from '../../classes/services';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
    public loading = false;

    constructor(protected notificationService: EventSubscriptionService, protected ngCh: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.notificationService.subscribeToNotificationEvent(LoadingService.START_LOADING, this, (scope, data) => {
            this.loading = true;
            this.ngCh.detectChanges();
        });

        this.notificationService.subscribeToNotificationEvent(LoadingService.FINISH_LOADING, this, (scope, data) => {
            this.loading = false;
            this.ngCh.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.notificationService.unsubscribeToNotificationEvent(LoadingService.START_LOADING, this);
        this.notificationService.unsubscribeToNotificationEvent(LoadingService.FINISH_LOADING, this);
    }

}
