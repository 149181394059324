import {Pipe} from '@angular/core';

@Pipe({
    name: 'secondsToTime'
})
export class SecondsToTimePipe {
    times = {
        hour: 3600,
        minute: 60,
        second: 1
    };

    transform(seconds) {

        seconds = Math.round(seconds);
        if(seconds == 0){
            return "0 seconds";
        }

        let time_string: string = '';
        let plural: string = '';
        for (let key in this.times) {
            if (Math.floor(seconds / this.times[key]) > 0) {
                if (Math.floor(seconds / this.times[key]) > 1) {
                    plural = 's';
                }
                else {
                    plural = '';
                }

                time_string += Math.floor(seconds / this.times[key]).toString() + ' ' + key.toString() + plural + ' ';
                seconds = seconds - this.times[key] * Math.floor(seconds / this.times[key]);

            }
        }
        return time_string;
    }
}