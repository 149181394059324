<div class="container flex flex-col">
    <app-base-dynamic-card class="flex w-full md:w-128 mx-auto text-center">
        <ng-container slot="header">
            <div class="flex flex-col mx-auto p-4">
                <h2 class="text-blue mx-auto my-6">{{textCached[pageKey + "title"]}}</h2>
            </div>
        </ng-container>
        <ng-container slot="body">
            <div class="flex flex-col w-full p-5 min-h-24">
                <p [innerHTML]="textCached[pageKey + 'description']"></p>
            </div>
        </ng-container>
        <ng-container slot="overlay">
            <div class="flex flex-grow flex-row">
                <button class="button-text flex-1" (click)="openDownload()">Download Google Chrome</button>
                <div class="w-px bg-white h-full"></div>
                <div class="flex flex-col my-auto">
                    <button class="button-text text-sm" (click)="continueToUrl()">Continue</button>
                    <div class="flex flex-row mx-auto p-2">
                        <input type="checkbox" [(ngModel)]="dontShowAgain" id="notShow" class="checkbox">
                        <label for="notShow" class="text-white text-xs ml-1">Don't show again</label>
                    </div>
                </div>
            </div>

        </ng-container>
    </app-base-dynamic-card>
</div>

