import {
    ChangeDetectorRef,
    Component,
    NgZone,
    OnInit
} from '@angular/core';
import {
    EventSubscriptionService,
    AuthService
} from '../../classes/services';
import {AppModeEnum} from '../../classes/enums/app-mode.enum';
import {NotificationEvent} from '../../classes/models';
import {ActivatedRoute} from '@angular/router';
@Component({
    selector: 'app-app-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss']
})
export class AppLayoutComponent implements OnInit {
    currentUser: any;
    coachHasConnection: Boolean = false;

    constructor(
        protected notificationService: EventSubscriptionService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.notificationService.sendNotificationEvent(
            new NotificationEvent('APP_MODE_CHANGED', AppModeEnum.WebApp)
        );

        this.notificationService.subscribeToNotificationEvent(
            'current_user_changed',
            this,
            this.handleUserChange
        );

        this.currentUser = this.authService.getLoggedInUser();
    }

    private handleUserChange(scope, data: NotificationEvent) {
        scope.currentUser = data.data;
    }

    private handleWSConnectionChanged(scope, data: NotificationEvent) {
        scope.coachHasConnection = data.data as Boolean;
    }
}
