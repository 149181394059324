import {Injectable} from '@angular/core';
import {Customer} from '../../models/customer';
import {ApiService} from './api.service';
import {AuthService} from './auth.service';
import { map } from 'rxjs/operators';

import { Observable, of, from, pipe } from 'rxjs';

@Injectable()
export class CustomerService {
    private appInfo;

    constructor(private apiService: ApiService,
                private authService: AuthService) {
    }

    getAppInfo() {
        if(this.appInfo) {
            return of(this.appInfo); //cached copy
        }
        else {
            //cache appInfo
            return this.apiService.get('/settings').pipe( map(response => {
                this.appInfo = JSON.parse(JSON.stringify(response));

                return this.appInfo
            })
            )
        }
    }

    calculateTrialDaysLeft(trialEndEpoch:number) {
        //takes the end date (in epoch time) and calculates # of days left in the trial period.
        var trialDate = new Date(trialEndEpoch * 1000);
        var nowDate = Date.now();
        var diff = trialDate.getTime() - nowDate;
        var trialDaysLeft = Math.round(diff / (1000 * 3600 * 24));
        trialDaysLeft = (trialDaysLeft <= 0) ? 0 : trialDaysLeft;

        return trialDaysLeft;
    }
      
    getCustomer() {
        return this.apiService.get('/customer').pipe (map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        })
        )
    }

    getAllInvoices() {
        return this.apiService.get('/customer/invoices').pipe( map(response => {
            const resp = JSON.parse(JSON.stringify(response));
            return resp;
        })
        )
    }

    payInvoice(data: any) {
        return this.apiService.post('/customer/invoices/pay', data).pipe( map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        })
        )
    }

    getAllSubscriptions() {
        return this.apiService.get('/customer/subscriptions').pipe( map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        })
        )
    }

    getAllCards() {
        return this.apiService.get('/customer/cards').pipe( map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        }) 
        )
    }

    create(data: any) {
        return this.apiService.post('/customer/create', data).pipe( map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        })
        )
    }

    upgradeSubscriptionToTeam(data: any) {
        return this.apiService.post('/customer/upgrade', data).pipe( map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        })
        )
    }

    cancelSubscription(data: any) {
        return this.apiService.post('/customer/subscriptions/cancel', data).pipe( map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        })
        )
    }

    update(customer: Customer) {
        return this.apiService.post('/customer/update', customer).pipe( map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        })
        )
    }

    addBillingCard(model: any) {
        return this.apiService
            .post('/customer/billing/card/add', model)
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    deleteBillingCardById(id) {
        return this.apiService
            .post('/customer/billing/card/delete', {card_id: id})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    addGroup(model: any) {
        return this.apiService.post('/customer/group/add', model).pipe( map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        })
        )
    }

    updateGroup(model: any) {
        return this.apiService
            .post('/customer/group/update', model)
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    removeTokenFromUser(user_id:number){
        return this.apiService
            .post('/customer/user/token/remove', {user_id:user_id})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )

    }

    assignTokenToUser(user_id:number){
        return this.apiService
            .post('/customer/user/token/add', {user_id:user_id})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
        )

    }

    removeUserFromGroup(userId: any, groupId: any) {
        return this.apiService
            .post('/customer/group/user/remove', {user_id: userId, group_id: groupId})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));
                return resp;
            })
            )

    }

    removeUserFromAllGroups(userId: any) {
        return this.apiService
            .post('/customer/group/user/removeAllGroup', {user_id: userId})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));
                return resp;
            })
            )

    }

    addUserToGroup(userId:number, groupId:number, roleId:number) {
        return this.apiService.post('/customer/group/user/add', {user_id: userId, group_id: groupId, role_id: roleId})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));
                return resp;
            })
            )
    }

    setUserGroupRole(userId:number, groupId:number, roleId:number) {
        return this.apiService
            .post("/customer/group/user/role/update", 
                {user_id: userId, group_id: groupId, role_id: roleId})
            .pipe(
             map(response => {
                const resp = JSON.parse(JSON.stringify(response));
                return resp;
        })
            )
    }

    removeRoleToUser(userId, roleId) {
        return this.apiService.post("/customer/user/role/remove", { user_id: userId, role_id: roleId }).pipe(map(response => {
            const resp = JSON.parse(JSON.stringify(response));
            return resp;
        })
        )
    }
    
    setRoleToUser(userId:number, roleId:number) {
        return this.apiService
            .post("/customer/user/role/add", 
                {user_id: userId, role_id: roleId})
            .pipe(
             map(response => {
                const resp = JSON.parse(JSON.stringify(response));
                return resp;
        })
            )
    }

    // removeUserFromGroup(userId, groupId) {
    //     return this.apiService.post("/customer/group/user/remove", { user_id: userId, group_id: groupId}).map(response => {
    //         const resp = JSON.parse(JSON.stringify(response));
    //         return resp;
    //     });
    // }

    sendPasswordResetEmail(user_id) {
        return this.apiService
            .post('/customer/user/reset-password', {user_id})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    resendInvite(user_id) {
        return this.apiService
            .post('/customer/user/resend-invite', {user_id})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    removeUsers(users) {
        return this.apiService
            .post('/customer/user/remove', {users})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    addUsers(users, model) {
        return this.apiService
            .post('/customer/user/add', {users, model})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    searchUsers(term: String) {
        return this.apiService
            .post('/customer/user/search', {search: term})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    addSubscription(model: any) {
        return this.apiService
            .post('/customer/subscriptions/add', model)
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    validateUsersUpload(users) {
        return this.apiService
            .post('/customer/user/validate', {users})
            .pipe(
            map(response => {
                const resp = JSON.parse(JSON.stringify(response));

                return resp;
            })
            )
    }

    validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    validateBatchData(data) {
        console.log('batchdata', data);
        let valid = false;
        for (let _i = 0; _i < data.length; _i++) {
            data[_i].valid = false;
            data[_i].validation_error = '';
            data[_i].validation_message = '';
        }

        return this.getCustomer().pipe( map(
            resp => {
                if (resp.success) {
                    const licenseUserList = resp.response.users;

                    let allValid = true;
                    // check the license list
                    for (let _i = 0; _i < data.length; _i++) {
                        data[_i].valid = true;
                        if (
                            !data[_i].email ||
                            data[_i].email == ''
                        ) {
                            data[_i].valid = false;
                            data[_i].validation_error = 'E';
                            data[_i].validation_message = 'EMPTY';
                        }
                        else if(
                            !this.validateEmail(data[_i].email)
                        ) {
                            data[_i].valid = false;
                            data[_i].validation_error = 'IE';
                            data[_i].validation_message = 'INVALID EMAIL';
                        }
                        else if (
                            licenseUserList.find(
                                ul => ul.email.toLowerCase() == data[_i].email.toLowerCase()
                            )
                        ) {
                            data[_i].valid = false;
                            data[_i].validation_error = 'AL';
                            data[_i].validation_message = 'ALREADY IN LICENSE';
                        }
                        else {
                            data[_i].valid = true;
                            data[_i].validation_error = '';
                            data[_i].validation_message = 'VALID';
                        }

                        allValid = allValid && data[_i].valid;
                    }

                    valid = allValid;
                } else {
                    valid = false;
                }
                return {valid, data};
            },
            error => {
                return false;
            }
        )
        )
    }

    getGroup(id:number) {
        // return {
        //     "id":1,
        //     "title":"Group A (Mocked)",
        //     "users": [
        //             {
        //                 "user": {
        //                 "first_name":"Gregory",
        //                 "last_name":"Alekel",
        //                 "email":"gregory@presentr.me",
        //                 "status":"valid",
        //                 "role":"Manager"
        //             }
        //         }
        //     ]
            
        // }
        return this.apiService.get('/customer/group/' + id).pipe(map(response => {
            //need to iterate through because ketchup returns
            //all roles. not just the role in this group.
            let resp = JSON.parse(JSON.stringify(response));

            // resp = resp.user_group.filter(u => {
                
            //     if(u.customer_user.user_role[x-y].group_id != null)
            // });

            return resp;
        })
        )
    }

    getUngroupedUsers(customer) {
        //returns a list of users who are not assigned to a group.
        let users = [];

        customer.users.forEach(u => {
            if(u.groups.length == 0) {
                users.push(u);
            }
        });

        return users;
    }

    getCurrentUser() {
        //gets the logged in user and all groups/roles
        let currentUser = this.authService.getLoggedInUser();

        return this.apiService.get('/customer/user/' + currentUser.id).pipe(map(response => {
            const resp = JSON.parse(JSON.stringify(response));

            return resp;
        })
        )
    }

    //ggg 9/10/21 - Not used, so I removed it
    // getUsersInGroup(id:number) {
    //     //returns a list of users in the group provided
    //     let currentLicense = this.authService.getLicense();
    //     let users = [];

    //     currentLicense.groups.forEach(g => {
    //         if(g.id === id) {
    //             users = g.users;
    //         }
    //     });

    //     return users;
    // }
}
