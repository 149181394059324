import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notificationSource'
})
export class NotificationSourcePipe implements PipeTransform {

  transform(value: any, sourceArg: any, whereArg: any): any {
    //console.log('value', value);
    //console.log('sourceArg', sourceArg);
    //console.log('whereArg', whereArg);
    
    return value.filter(item =>
      item.source==sourceArg &&
      item.location==whereArg &&
      (
        !item.expires ||
        item.expires && item.expires > Date.now())
      );

  }

}
