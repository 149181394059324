import { Injectable, ViewChild, Directive } from '@angular/core';

import { EventSubscriptionService } from "./event-subscription.service";
import { NotificationEvent } from "../../models";
import { Subscription } from "rxjs";
import { interval } from 'rxjs';
import { CoachChatService } from "../data/coach-chat.service";
import { HasPermissionPipe } from '../../pipes/has-permission.pipe';

@Directive()
@Injectable()
export class CoachService {
  badgeUpdater: Subscription;

  isCoachVisible: Boolean = true;
  @ViewChild('coachPopupComponent') coachElement;

  constructor(
    private notificationService: EventSubscriptionService,
    private coachChatService: CoachChatService,
    private hasPermissionPipe: HasPermissionPipe
  ) {}

  init() {

    if (!this.hasPermissionPipe.transform("CanSeeCoach")) {
      this.destroy();
      return;
    }

    this.getBadgeNumber();
    this.badgeUpdater = interval(2000 * 60).subscribe(x => {
      this.getBadgeNumber();
    });


    this.notificationService.subscribeToNotificationEvent(
      "coach_is_visible",
      this,
      this.handleCoachVisibility
    );

    this.notificationService.subscribeToNotificationEvent(
      "coach_chat",
      this,
      this.handleChatWS
    );

    this.notificationService.subscribeToNotificationEvent(
      "coach_task",
      this,
      this.handleTaskWS
    );

    this.notificationService.subscribeToNotificationEvent(
      "coach_schedule",
      this,
      this.handleScheduleWS
    );
  }

  destroy() {
    if (this.badgeUpdater) {
      this.badgeUpdater.unsubscribe();
    }
    this.notificationService.unsubscribeToNotificationEvent("coach_is_visible", this);
    this.notificationService.unsubscribeToNotificationEvent("coach_chat", this);
    this.notificationService.unsubscribeToNotificationEvent("coach_task", this);
  }

  getBadgeNumber() {
    this.coachChatService.getBadgeNumber().subscribe(response => {
      if (response.success) {
        this.notificationService.sendNotificationEvent(
          new NotificationEvent("coach_chat_bubble_badge", response.response)
        );
      }
    });
  }

  openCoach(){
    let element = document.getElementById('coach-component');
    if(element)
      element.focus();
  }

  handleCoachVisibility(scope, data: NotificationEvent) {
      scope.isCoachVisible = data.data;
  }

  handleTaskWS(scope, data: NotificationEvent) {
    switch (data.data.payload.action) {
      case "add":
      case "remove":
      case "reload":
        // if (scope.isCoachModalOpen()) {
          scope.notificationService.sendNotificationEvent(
            new NotificationEvent("coach_task_modal", data.data.payload)
          );
        // }
        break;
      default:
        break;
    }
  }

  handleScheduleWS(scope, data: NotificationEvent) {
    switch (data.data.payload.action) {
      case "add":
      case "remove":
      case "reload":
        // if (scope.isCoachModalOpen()) {
          scope.notificationService.sendNotificationEvent(
            new NotificationEvent("coach_schedule_modal", data.data.payload)
          );
        // }
        break;
      default:
        break;
    }
  }

  handleChatWS(scope, data: NotificationEvent) {
    switch (data.data.payload.type) {
      case "typing-indicator":
      case "buttons":
      case "reload":
        // if (scope.isCoachModalOpen()) {
          scope.notificationService.sendNotificationEvent(
            new NotificationEvent("coach_chat_ws", data.data.payload)
          );
        // }
        break;
      case "message":

        scope.notificationService.sendNotificationEvent(
          new NotificationEvent("coach_chat_ws", data.data.payload)
        );

        // if (scope.isCoachModalOpen()) {
        //   scope.notificationService.sendNotificationEvent(
        //     new NotificationEvent("coach_chat_modal", data.data.payload)
        //   );
        // } else {
        //   scope.notificationService.sendNotificationEvent(
        //     new NotificationEvent("coach_chat_bubble", data.data.payload)
        //   );
        // }
        break;
      default:
        break;
    }
  }
  //
  // private isCoachModalOpen(): Boolean {
  //   return document.querySelectorAll(".modal.show.coach-modal").length > 0;
  // }
}
