import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { DebugConsole } from "../../helpers/debug-console";
import { ResultType, ResultTypeHelper } from "../../models/result";
import { EnvironmentAPIs } from "../../enums/environment-apis";
import {map} from "rxjs/operators";

@Injectable()
export class WordManagementService {
  constructor(
    private apiService: ApiService
  ) {}

  wordCloud(user_id: number, resultTypes: ResultType[]) {
    let today = new Date();
    let from = today.getDate() - 14;
    let data = {
      from_date: from,
      to_date: today,
      id: user_id,
      filter_result_type: ResultTypeHelper.TransformForServerTypes(resultTypes)
    };
    return this.apiService
      .post("/user-analytics/word-cloud", data)
      .pipe(
      map(response => {
        return JSON.parse(JSON.stringify(response));
      })
    )
  }
  trackedWords() {
    return this.apiService.get("/content/tracked-words").pipe( map(response => {
      return JSON.parse(JSON.stringify(response));
    })
    )
  }
  storeTrackedWords(fillerWords, powerWords) {
    let data = {
      power_words: powerWords,
      filler_words: fillerWords
    };

    return this.apiService
      .post("/content/tracked-words", data)
      .pipe(
      map(response => {
        return JSON.parse(JSON.stringify(response));
      })
    )
  }

  teamWordCloud(license_id: number, resultTypes: ResultType[]) {
    let today = new Date();
    let from = today.getDate() - 14;
    let data = {
      from_date: from,
      to_date: today,
      filter_result_type: ResultTypeHelper.TransformForServerTypes(resultTypes)
    };
    return this.apiService
      .post(
        "/team-analytics/word-cloud",
        data,
        EnvironmentAPIs.Ketchup,
        // this.licenseService.getLicenseHeader(license_id)
      )
      .pipe(
      map(response => {
        return JSON.parse(JSON.stringify(response));
      })
    )
  }

  memberTrackedWords(user_id: number) {
    DebugConsole.log("memberTracked", user_id);
    return this.apiService
      .get("/content/tracked-words/" + user_id)
      .pipe(
       map(response => {
        DebugConsole.log("memberTracked", response);
        return JSON.parse(JSON.stringify(response));
      })
    )
  }

  addTrackedWord(word, isFiller) {
    DebugConsole.log(word);
    let data = {
      word: word,
      is_filler_word: isFiller
    };

    return this.apiService
      .post("/content/add-tracked-word", data)
      .pipe(
       map(response => {
        return JSON.parse(JSON.stringify(response));
      })
    )
  }
}
