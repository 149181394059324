import {
    AfterViewInit,
    Component,
    isDevMode,
    OnInit,
} from '@angular/core';

import * as DetectRTC from 'detectrtc';

var smartLookClient = require('smartlook-client');
import {EventSubscriptionService} from './classes/services/core/event-subscription.service';
import {AuthService} from './classes/services';
import {DebugConsole} from './classes/helpers/debug-console';
import {SocketService} from './classes/services/core/socket.service';

import {SettingsService} from './classes/services/core/settings.service';
import {ActivatedRoute} from '@angular/router';
import {GoogleAnalyticsService} from './classes/services/core/google-analytics.service';
import {AdrollService} from './classes/services/core/adroll.service';
import {HasPermissionPipe} from './classes/pipes/has-permission.pipe';
import {PopupNotificationService} from './classes/services/core/popup-notification.service';
import {FacebookTrackingService} from './classes/services/core/facebook-tracking.service';
//import {FirebaseMessagingService} from './classes/services/core/firebase-messaging.service';
import {environment} from '../environments/environment';
//import {FirebaseAnalyticsService} from './classes/services/core/firebase-analytics.service';

declare var AdobeDC: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    logoColor = isDevMode() ? 'RED' : 'BLUE';
    AdobeDC: any;

    static smartLookLoaded: boolean = false;
    constructor(
        private notificationService: EventSubscriptionService,
        private authService: AuthService,
        private settingService: SettingsService,
        private socketService: SocketService,
        private activatedRoute: ActivatedRoute,
        private googleAnalyticsService: GoogleAnalyticsService,
        private facebookTrackingService: FacebookTrackingService,
        private adrollService: AdrollService,
        private hasPermissionPipe: HasPermissionPipe,
        private popupService: PopupNotificationService,
        //private firebaseMessagingService: FirebaseMessagingService,
        //private firebaseAnalyticsService: FirebaseAnalyticsService
    ) {
        this.googleAnalyticsService.appendGoogleAnalyticsTrackingCode();
        this.facebookTrackingService.appendFacebookPixel();
        this.adrollService.appendAdrollTrackingCode();

        DetectRTC.load(() => {
        });

        this.activatedRoute.queryParams.subscribe(params => {
            const forceLoginUserId = params['forceLoginUserId'];
            if (forceLoginUserId && forceLoginUserId.length > 0) {
                if (this.hasPermissionPipe.transform('IsAdmin')) {
                    this.authService.forceLogin(forceLoginUserId);
                } else {
                    alert('Unable to force login. Please sign into an admin account.');
                }
            }
        });
    }

    StartWebSocket(user: any) {
        if (user.id != null) {
            this.socketService.initSocket();
        }
    }

    CloseWebSocket() {
        this.socketService.closeSocket();
    }

    // static StartSmartLookClient(user: any) {
    //     if (!isDevMode() && !AppComponent.smartLookLoaded && !user.is_admin) {
    //         AppComponent.smartLookLoaded = true;
    //         new Promise(resolve => {
    //             DebugConsole.log('StartedSmartLookClient');
    //             smartLookClient.init('3821d9ec2f0949ebd1c141de1fa6f6ca3d507406');
    //             smartLookClient.identify(user.id, {
    //                 name: user.first_name + ' ' + user.last_name,
    //                 email: user.email
    //             });
    //         });
    //     }
    // }

    ngAfterViewInit() {
        this.popupService.openStartupPopups();
    }

    updateUserTimezone() {
        this.settingService.setSettingForUser(
            this.authService.getLoggedInUser().id,
            'timezone',
            true,
            Intl.DateTimeFormat().resolvedOptions().timeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : 'UTC'
        );
    }

    ngOnInit() {
        let usr = this.authService.getLoggedInUser();
        DebugConsole.log(usr);
        if (usr) {
            // AppComponent.StartSmartLookClient(usr);
           // this.firebaseMessagingService.initializeFirebaseMessaging(usr.id);
            this.StartWebSocket(usr);
            this.updateUserTimezone();
        } else {
            this.CloseWebSocket();
        }

        // this.notificationService.subscribeToNotificationEvent(
        //     AppComponent.SHOW_WELCOME_MESSAGE,
        //     this,
        //     (scope, data) => {
        //         scope.showWelcomeMessage();
        //     }
        // );

        this.notificationService.subscribeToNotificationEvent(
            'current_user_changed',
            this,
            (scope, data) => {
                this.popupService.openStartupPopups();

                if (data.data) {
                    scope.firebaseMessagingService.initializeFirebaseMessaging(data.data.id);
                    // scope.firebaseMessagingService.requestPermission(data.data);
                    scope.StartWebSocket(data.data);
                    scope.updateUserTimezone();
                } else {
                    scope.CloseWebSocket();
                }
            }
        );


    }

}
