import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "../services";

@Pipe({
  name: "hasPermission",
  pure: false
})
export class HasPermissionPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(value: any, args?: any): any {
    if (this.authService.getLoggedInUser()) {
      const permissions = this.authService.getPermissions() as any;
      if (permissions.find(x => x === value)) {
        return true;
      }

      return false;
    }

    return false;
  }
}
