<section class="flex flex-col t container pb-6">
    <div class="flex flex-grow rounded flex-wrap justify-center mt-4" *ngIf="!startTrialStep">
        <app-base-card class="flex {{plan.type == selectedPlanType ? 'w-96 ': 'w-48'}}  min-h-32 my-1 mx-4 cursor-pointer transition-property-all transition" *ngFor="let plan of planOptions"
                       (click)="planChanged(plan)">
            <ng-container slot="body">
                <div
                    class="flex flex-col p-4 t w-full h-full items-center {{plan.type == selectedPlanType ? 'text-white bg-blue': ''}}">
                    <div class="flex flex-col text-center  w-full h-full items-center">
                        <h3 class="{{plan.type != selectedPlanType ? 'text-blue': ''}}">{{plan.name}}</h3>
                        <span *ngIf="plan.price_yearly > 0">${{plan.price_yearly}} / annually</span>
                        <span *ngIf="plan.price_monthly > 0" class="text-xs">(or ${{plan.price_monthly}} billed monthly)</span>
                        <div class="flex mt-2 {{plan.type == selectedPlanType ? 'text-xl': ''}}">{{plan.description}}</div>
                        <ul class="m-4 text-left list-disc text-left {{plan.type == selectedPlanType ? '': 'text-xs'}}"><li *ngFor="let feature of plan.features">{{feature}}</li></ul>
                    </div>
                    <div>
                        <button class="button-white" *ngIf="plan.type == selectedPlanType && selectedPlanType == 0" routerLink="/">Start Using Presentr</button>
                        <button class="button-white" *ngIf="plan.type == selectedPlanType && selectedPlanType != 0" (click)="subscribeClicked()">Activate Trial</button>
                    </div>
                </div>
            </ng-container>
        </app-base-card>
    </div>

    <div class="w-full md:w-1/2 mx-auto mb-6" *ngIf="paymentStep">
        <h2 class="text-center mb-6">Purchase Subscription</h2>
        <div *ngIf="selectedPlanType > 0">
            <app-subscription-add-form #subscriptionAddForm (completed)="completedSubscription()"></app-subscription-add-form>
            <button class="button-white" (click)="paymentStep = false"> Back </button>
        </div>
    </div>

    <div class="w-full md:w-1/2 mx-auto mb-6" *ngIf="startTrialStep">
        <h2 class="text-center mb-6">Confirm and Start Free Trial</h2>
        <p>Please review your options and confirm to complete your free trial subscription or click Back to select another option.</p>
        <div *ngIf="selectedPlanType > 0">
            <app-subscription-trial-form #subscriptionTrialForm (completed)="completedSubscription()"></app-subscription-trial-form>
            <button class="button-white" (click)="startTrialStep = false"> Back </button>
        </div>
    </div>
</section>
