import {Component, OnInit, TemplateRef} from '@angular/core';
import {PopupManagerComponent} from '../popup-manager.component';
import {PopupSetPayload} from '../PopupSetPayload';

@Component({
  selector: 'app-confirmation-popup-modal',
  templateUrl: './confirmation-popup-modal.component.html',
  styleUrls: ['./confirmation-popup-modal.component.scss']
})
export class ConfirmationPopupModalComponent implements OnInit , PopupSetPayload  {
    title = "Generic";
    text = "";
    elementRef : TemplateRef<any>;

    constructor(private popupManager: PopupManagerComponent) { }

    ngOnInit() {
    }

    setPayload(payload): void {
        this.title = payload.title;
        this.text = payload.text;
        this.elementRef = payload.elementRef;
    }

    submit(){
        this.popupManager.closeOpenModal(true);
    }

    cancel() {
        this.popupManager.closeOpenModal(null);
    }

}
