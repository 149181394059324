<div class="flex flex-wrap justify-end my-2" >
  <button (click)="reviewPurchase()" class="button-blue">Review and Confirm</button>
</div>

<ng-template #reviewForm>
  <div class="flex flex-col flex-grow p-4 vw-50">
      <app-svg-component type="logo" class="svg fill-blue h-32"></app-svg-component>
      <table class="w-full mt-6 border-2 border-gray">
          <tr class="border-b border-gray">
              <td>Plan</td>
              <td>{{model.name}}</td>
          </tr>

          <tr class="border-b border-gray">
              <td>Number of Seats</td>
              <td>{{model.seats}}</td>
          </tr>

      </table>

      <div class="text-sm mt-4 text-left mx-6">
          By clicking "Confirm" you agree to be bound by the terms and conditions of Presentr. You will be
          begin your free trial starting today and will be prompted to subscribe throughout the trial period.
      </div>

  </div>
</ng-template>

