import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  host: {
    class:'flex'
  },
  selector: 'app-base-dynamic-card',
  templateUrl: './base-dynamic-card.component.html',
  styleUrls: ['./base-dynamic-card.component.scss']
})
export class BaseDynamicCardComponent implements OnInit {

  @Input() showOverlay = true;
  @Input() overlayHeight = 'h-full';
  @Input() bgColorClass = 'bg-white';
  @Output() cardClicked : EventEmitter<any> = new EventEmitter<any>();
  @Output() overlayClicked : EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  handleCardClicked(){
    this.cardClicked.emit();
  }
  handleOverlayClicked(){
    this.overlayClicked.emit();
  }
}
