import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {MediaStreamService} from '../services/core/media-stream.service';

@Injectable()
export class MicPermissionGuard implements CanActivate {

    constructor(private router: Router, private mediaRecorder: MediaStreamService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let micPermission = this.mediaRecorder.hasMicPermissions();
        if (!micPermission) {
            this.router.navigate(['/app/app-settings/mic-permission'], {queryParams: {returnUrl: state.url}});
        }

        return micPermission;
    }
}