import { Injectable } from '@angular/core';
import { ApiService} from "./api.service";
import { PingsActionEnum, PingsPageEnum} from "../../enums/pings-page.enum";

@Injectable()
export class PingsService {

	constructor(private apiService: ApiService) {
	}

	sendPing(pingPage: PingsPageEnum, pingAction:PingsActionEnum = PingsActionEnum.viewed, pageActionId=0){
		let data = {
			source:'web', //web enum
			action:pingAction,
			page:pingPage,
			page_action_id:pageActionId

		};

		this.apiService.post('/interaction/ping', data)
			.subscribe((response) => {
				let ping =  JSON.parse(JSON.stringify(response));
				return ping;
			});
	}
}
