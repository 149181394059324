import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-base-circle-score',
  templateUrl: './base-circle-score.component.html',
  styleUrls: ['./base-circle-score.component.scss']
})
export class BaseCircleScoreComponent implements OnInit {
  @Input() percentage: any;
  @Input() rangeOverride: any[];
  @Input() fillColor:string;
  linear_gradient = ['#d84844','#e18432','#ecd824','#ACBF00','#3bbd0f'];

  constructor() { }

  ngOnInit() {
    if(!this.fillColor){
      const interpolator = require('color-interpolate');
      let colorMap = interpolator(this.rangeOverride ? this.rangeOverride : this.linear_gradient);
      this.fillColor = colorMap(this.percentage/100);
    }
  }
}
