import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-base-chat-bubble',
    templateUrl: './base-chat-bubble.component.html',
    styleUrls: ['./base-chat-bubble.component.scss']
})
export class BaseChatBubbleComponent implements OnInit {

    private tailClass: string = '';
    @Input() tailLocation: string = 'left';
    @Input() colorClass: string = 'bg-blue fill-blue text-white';
    @Input() message;

    constructor() {
    }

    ngOnInit() {
        this.tailClass = 'shift-'+this.tailLocation;
    }

}
