import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {SettingsService} from "../services/core/settings.service";
import {MicTestComponent} from '../../presentr-app/media-test/mic-test.component';
import {MediaTestComponent} from '../../presentr-app/media-test/media-test.component';

@Injectable()
export class MicTestedGuard implements CanActivate {

    constructor(private router: Router, private settingsService: SettingsService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.settingsService.hasLocalSetting(MediaTestComponent.MEDIA_CHECKED_SETTINGS) || this.settingsService.hasLocalSetting(MicTestComponent.MIC_CHECKED_SETTINGS)){
            return true;
        }
        this.router.navigate(['/app/app-settings/mic-test'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}