import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BreadcrumbsComponent} from "../breadcrumbs/breadcrumbs.component";

@Component({
  selector: 'app-simple-router-outlet',
  templateUrl: './simple-router-outlet.component.html',
  styleUrls: ['./simple-router-outlet.component.scss']
})
export class SimpleRouterOutletComponent implements OnInit {

  @ViewChild('breadcrumbs') breadcrumbs : BreadcrumbsComponent;
  @Input() showBreadcrumbs: boolean = true;
  @Input() breadcrumbsContainerClass: string = 'container-fluid';
  breadcrumbsVisible:boolean = true;
  @Input() args:any;
  @Input() skipBreadcrumbsComponents: string[] = [];
  @Input() breadcrumbsComponentLabelMapper: any = {};

  constructor() { }

  ngOnInit() {
  }

  adjustBreadcrumbsVisibility(event){
    this.breadcrumbsVisible = event;
  }

  regenerateBreadcrumbs(){
    if(this.breadcrumbs){
      this.breadcrumbs.refreshBreadcrumbsLabels({});
    }
  }

}
