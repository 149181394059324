import {
    Component,
    NgZone,
    OnInit,
    AfterViewChecked,
    AfterViewInit,
    OnDestroy, ChangeDetectorRef
} from '@angular/core';
import {
  EventSubscriptionService,
  UserService,
  AuthService,
  SocketService
} from "../../classes/services";
import { AppModeEnum } from "../../classes/enums/app-mode.enum";
import { NotificationEvent } from "../../classes/models";
import { CoachService } from "../../classes/services/core/coach.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CoachPopupComponent } from "../../coach/coach-popup/coach-popup.component";
import { SettingsService } from "../../classes/services/core/settings.service";
import { HasPermissionPipe } from '../../classes/pipes/has-permission.pipe';
import {PopupNotificationService} from '../../classes/services/core/popup-notification.service';

@Component({
  selector: "app-app-layout-with-coach",
  templateUrl: "./app-coach-layout.component.html",
  styleUrls: ["./app-coach-layout.component.scss"]
})
export class AppLayoutWithCoachComponent implements OnInit, OnDestroy {
  currentUser: any;
  coachHasConnection: Boolean = false;

    isAttemptingshowCoachOnFirstSession = false;

  constructor(
    protected notificationService: EventSubscriptionService,
    private authService: AuthService,
    private coachService: CoachService,
    private socketService: SocketService,
    private modalService: NgbModal,
    private settingService: SettingsService,
    private hasPermissionPipe: HasPermissionPipe
  ) {
  }

  showCoachOnFirstSession() {
    if (this.isAttemptingshowCoachOnFirstSession) {
      return;
    }
    this.isAttemptingshowCoachOnFirstSession = true;
    if (this.coachHasConnection
      && !this.settingService.hasLocalSetting("coach-opened-this-session")
    && this.hasPermissionPipe.transform("CanSeeCoach")) {

    this.settingService
      .getSettingForUser(
        this.authService.getLoggedInUser().id,
          PopupNotificationService.coachShownSettings
      )
      .subscribe(response => {
        const views =
          response != null && response.description != null
            ? Number(response.description)
            : 0;
        if (views > 3) {
          this.notificationService.sendNotificationEvent(
            new NotificationEvent(
                PopupNotificationService.COACH_CLOSED_AFTER_FIRST_VIEW,
              null
            )
          );
        } else {
          this.settingService.setSettingForUser(
            this.authService.getLoggedInUser().id,
              PopupNotificationService.coachShownSettings,
            true,
            String(views + 1)
          );
          setTimeout(() => {
            this.modalService.open(CoachPopupComponent, {
              windowClass: "coach-modal"
            });
          }, 100);
        }
      });
    } else {
      this.notificationService.sendNotificationEvent(new NotificationEvent(PopupNotificationService.COACH_CLOSED_AFTER_FIRST_VIEW, null));
    }
  }

  ngOnInit() {
    this.notificationService.sendNotificationEvent(
      new NotificationEvent("APP_MODE_CHANGED", AppModeEnum.WebApp)
    );

    this.notificationService.subscribeToNotificationEvent(
      "current_user_changed",
      this,
      this.handleUserChange
    );

    this.notificationService.subscribeToNotificationEvent(
      "ws_connection_changed",
      this,
      this.handleWSConnectionChanged
    );

    this.currentUser = this.authService.getLoggedInUser();
    this.manageCoach();
    this.coachHasConnection = this.socketService.isConnected;

    this.notificationService.subscribeToNotificationEvent(
        PopupNotificationService.WELCOME_MESSAGE_CLOSED,
      this,
      (scope, data) => {
        if (data.data.shouldOpenCoach) {
          scope.showCoachOnFirstSession();
        }
      }
    );
  }

  ngOnDestroy() {
    this.coachService.destroy();
    this.coachHasConnection = false;
  }

  manageCoach() {
    if (this.currentUser != null && this.hasPermissionPipe.transform("CanSeeCoach")) {
      this.coachService.init();
    } else {
      this.coachService.destroy();
    }
  }

  private handleUserChange(scope, data: NotificationEvent) {
    scope.currentUser = data.data;
    scope.manageCoach();
  }

  private handleWSConnectionChanged(scope, data: NotificationEvent) {
    scope.coachHasConnection = data.data as Boolean;
  }
}
