


<app-popup-base>
    <ng-container>
        <div class="modal-body container">
            <div class="p-4 text-center">
                <h2 class="text-blue">{{title}}</h2>
                <p *ngIf="message" [innerHTML]="message"></p>
                <div *ngIf="!message && content" [innerHTML]="content"></div>
                <img *ngIf="imageSrc" [src]="imageSrc" class="m-4 min-w-32">
            </div>
        </div>
    </ng-container>
</app-popup-base>
