import {Injectable} from '@angular/core';

import {ApiService} from './api.service';
import {DebugConsole} from '../../helpers/debug-console';
import {SettingsService} from '../core/settings.service';
import {AuthService} from './auth.service';
import {ResultType} from '../../models/result';
import {EnvironmentAPIs} from '../../enums/environment-apis';
import {AwsUploaderService} from '../core/aws-uploader.service';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Injectable()
export class ResultService {
    constructor(
        private apiService: ApiService,
        private authService: AuthService,
        private settingsService: SettingsService,
        private awsUploader: AwsUploaderService
    ) {
    }

    getAll(options) {
        return this.apiService.post('/user/results', options).pipe( map(response => {
            return JSON.parse(JSON.stringify(response));
        })
        )
    }
    deleteRecordingAndTranscript(resultId, options = null) {
        return this.apiService
                .get('/result/delete-recording-and-transcript/' + resultId)
                .pipe(
                map(response => {
                   return JSON.parse(JSON.stringify(response));
                })
                )
    }

    getLast(options) {
        return this.apiService
            .post('/result/last-result', options)
            .pipe(
            map(response => {
                return JSON.parse(JSON.stringify(response));
            })
            )
    }

    get(id) {
        return this.apiService.get('/result/' + id).pipe( map(response => {
            return JSON.parse(JSON.stringify(response));
        })
        )
    }

    create(result, logs = null, resultDataDetails = null) {
        return this.apiService
            .post('/result', result)
            .pipe(
            map(response => {
                let resp = JSON.parse(JSON.stringify(response));

                try{
                    if (logs && resp.success) {
                        this.sendResultLogs(logs, resp.response.result_id);
                    }
                    // DebugConsole.log("RESULT DATA DETAILS", resultDataDetails);
                    if(resultDataDetails && resp.success){
                        for(let dataDetail of resultDataDetails){
                            this.sendResultDetailsData(resp.response.result_id, dataDetail.key, dataDetail.data).subscribe(resp => {
                                if(!resp.success){
                                    let blob : any = new Blob([JSON.stringify(dataDetail)] , {type: 'text/javascript'});
                                    blob.lastModifiedDate = new Date();
                                    blob.name = 'ResultData_' + resp.response.result_id + '_' + dataDetail.key;
                                    this.awsUploader.uploadFile( blob,'failed_result_details/', true);
                                }
                            });
                        }
                    }
                }
                catch (e) {
                    console.error("failed to upload result details", e);
                }
                return resp;
                
            })
            )
    }

    createGameResult(gameResult, licenseId, logs = null) {
        return this.apiService
            .post(
                '/result/game-result',
                gameResult,
                EnvironmentAPIs.Ketchup,
                // this.licenseService.getLicenseHeader(licenseId)
            )
            .pipe(
            map(response => {
                let resp = JSON.parse(JSON.stringify(response));
                if (logs && resp.success) {
                    //this.sendResultLogs(logs, resp.response.result_id);
                }


                return resp;
            })
            )
    }

    sendResultLogs(logs, id) {
        for (let l of logs) {
            l.key_id = id;
            l.class_type = 'result';
        }
        DebugConsole.log(logs);
        this.apiService.post('/user/logs', logs).subscribe(resp => {
            DebugConsole.log(resp);
        });
    }

    sendResultDetailsData(result_id, key, data) {
        let d = {
            result_id,
            data,
            key
        };
        return this.apiService.post('/result/result-data', d).pipe( map(resp => {
            return JSON.parse(JSON.stringify(resp));
        })
        )

    }

    getRankedGameResults(game, licenseId) {
        return this.apiService
            .get(
                '/result/ranked-game-results?game=' + game,
                EnvironmentAPIs.Ketchup,
                // this.licenseService.getLicenseHeader(licenseId)
            )
            .pipe(
            map(response => {
                return JSON.parse(JSON.stringify(response));
            })
            )
    }

    setResultLabel(resultId, label, licenseId) {
        return this.apiService
            .post(
                '/result/rename',
                {label: label, result_id: resultId},
                EnvironmentAPIs.Ketchup,
                // this.licenseService.getLicenseHeader(licenseId)
            )
            .pipe(
            map(response => {
                return JSON.parse(JSON.stringify(response));
            })
            )
    }

    private resultTypeFilterKey = 'web-selected-user-results-types-filter';

    getResultData(id) {
        return this.apiService.get('/result/result-data/' + id).pipe( map(response => {
            return JSON.parse(JSON.stringify(response));
        })
        )
    }

    getSelectedResultFilter() {
        return this.settingsService
            .getSettingForUser(
                this.authService.getLoggedInUser().id,
                this.resultTypeFilterKey
            )
            .pipe(
            map(resp => {
                return resp
                    ? JSON.parse(resp.description)
                    : [ResultType.mobile, ResultType.web, ResultType.kinect];
                // return  (resp) ? JSON.parse(resp.description) : [ResultType.mobile, ResultType.web];
            })
            )
    }

    setSelectedResultFilter(resultType: ResultType[]) {
        return this.settingsService.setSettingForUserObserver(
            this.authService.getLoggedInUser().id,
            this.resultTypeFilterKey,
            true,
            JSON.stringify(resultType)
        );
    }

    getWordCloud(result_id: number) {
        DebugConsole.log("resultWordCloud", result_id);
        return this.apiService
            .get('/result/word-cloud/' + result_id)
            .pipe(
            map(response => {
                DebugConsole.log("resultWordCloud", response);
                return JSON.parse(JSON.stringify(response));
            })
            )
    }
}
