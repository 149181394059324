<div class="flex md:flex-col py-4 bg-white shadow rounded-br" data-tutorial="side-menu" *ngIf="sidebarTemplateProvider.templateReferences && sidebarTemplateProvider.templateReferences.length > 0">
  <div *ngFor="let ref of sidebarTemplateProvider.templateReferences" >
    <ng-container [ngTemplateOutlet]="ref"></ng-container>
  </div>
</div>
<div class="flex md:flex-col py-4" *ngIf="sidebarTemplateProvider.underTemplateReferences && sidebarTemplateProvider.underTemplateReferences.length > 0">
  <div *ngFor="let ref of sidebarTemplateProvider.underTemplateReferences" >
    <ng-container [ngTemplateOutlet]="ref"></ng-container>
  </div>
</div>
