import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import * as DetectRTC from 'detectrtc';
import {SettingsService} from "../services/core/settings.service";
import {AuthService} from "../services";
import {BrowserRecommendedComponent} from '../../presentr-app/app-settings/browser/browser-recommended/browser-recommended.component';
import {MediaTestComponent} from '../../presentr-app/media-test/media-test.component';

@Injectable()
export class MediaTestedGuard implements CanActivate {

    constructor(private router: Router, private settingsService: SettingsService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.settingsService.hasLocalSetting(MediaTestComponent.MEDIA_CHECKED_SETTINGS)){
            return true;
        }

        this.router.navigate(['/app/app-settings/media-test'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}