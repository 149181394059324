import { Component, OnInit, Input } from "@angular/core";
import { ContentService } from "../../../../classes/services";
import { LearnCardData } from "../../../../classes/models/learn-card-data";

@Component({
  selector: "app-coach-game-card",
  templateUrl: "./coach-game-card.component.html",
  styleUrls: ["./coach-game-card.component.scss"]
})
export class CoachGameCardComponent implements OnInit {
  @Input() id: number;
  card: any;

  volumeCard : LearnCardData = <LearnCardData>{
    title:"Pump Up the Volume",
    short_description:"Real time meter that tells you how loud you need to be while giving a talk.",
  button_settings:{
        router_link:'/app/learn/games/game/volume-meter',
        label:'Play Game'
    },
  small_image:'../../../../assets/learn/games/volume-meter/volume-1.png',
  stretch_image : true,
is_video: false,
};

paceCard :LearnCardData = <LearnCardData>{
    title:"Pace Yourself",
    short_description:"Let's work on keeping pace. Your goal is to try to speak and keep pace with the Presentr blue car.",
    button_settings:{
        router_link:'/app/learn/games/game/pace-car',
        label:'Play Game'
    },
    small_image:'../../../../assets/learn/games/car-pace/pace-1.png',
    stretch_image : true,
    is_video: false,
};

fillerCard:LearnCardData = <LearnCardData>{
    title:"Don't drown in Fillers",
    short_description:"Pay attention to your filler words! Your goal is to keep the water level as low as possible.",
    button_settings:{
        router_link:'/app/learn/games/game/filler-water',
        label:'Play Game'
    },
    small_image:'../../../../assets/learn/games/filler-water/fillerwords-1.png',
    stretch_image : true,
    is_video: false,
};

volumeRocketCard:LearnCardData = <LearnCardData>{
      title:"Ignite Your Voice!",
      short_description:"Use your voice to keep the rocket in the middle of the screen or the \"Sweet Zone\".",
      button_settings:{
          router_link:'/app/learn/games/game/volume-rocket',
          label:'Play Game'
      },
      small_image:'../../../../assets/learn/games/rocket-volume/ignite-thunmbnail.png',
      stretch_image : true,
      is_video: false,
  };

    paceMeterCard: LearnCardData = <LearnCardData>{
        title: 'Control Your Pace',
        short_description: 'Let\'s work on keeping pace.',
        button_settings: {
            router_link: '/app/learn/games/game/pace-meter',
            label: 'Start Activity'
        },
        small_image: '../../../../assets/learn/games/pace-meter/thumbnail.png',
        stretch_image: true,
        is_video: false,
    };

    fillerMeterCard: LearnCardData = <LearnCardData>{
        title: 'Count Your Filler Words',
        short_description: 'Pay attention to your filler words! Practice your filler words.',
        button_settings: {
            router_link: '/app/learn/games/game/filler-meter',
            label: 'Start Activity'
        },
        small_image: '../../../../assets/learn/games/filler-meter/thumbnail.png',
        stretch_image: true,
        is_video: false,
    };

  constructor(private contentService: ContentService) {}

  ngOnInit() {
    switch (this.id) {
      case 1:
        this.card = this.volumeCard;
        break;
        case 2:
        this.card = this.paceCard;
        break;
        case 3:
        this.card = this.fillerCard;
        break;
        case 4:
        this.card = this.volumeRocketCard;
        break;
        case 5:
        this.card = this.paceMeterCard;
        break;
        case 6:
        this.card = this.fillerMeterCard;
        break;
    }
  }
}
