import {ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MediaStreamService} from '../../classes/services';
import {TextStoreService} from '../../classes/services/data/text-store.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '../../classes/services/core/settings.service';

export abstract class MediaTestStep{
    abstract start();
    abstract finish();
}

@Component({
    selector: 'app-media-test',
    templateUrl: './media-test.component.html',
    styleUrls: ['./media-test.component.scss']
})
export class MediaTestComponent implements OnInit, OnDestroy {

    static MEDIA_CHECKED_SETTINGS = 'mediaCheckComplete';

    protected mediaCheckedSetting = MediaTestComponent.MEDIA_CHECKED_SETTINGS;
    @Output('completed') completed: EventEmitter<any> = new EventEmitter<any>();

    // @ViewChild('volumeCheck') volumeCheckComponent: MediaTestStep;
    // @ViewChild('watsonCheck') watsonCheckComponent: MediaTestStep;

    @ViewChild('voiceCheck') voiceCheckComponent: MediaTestStep;
    @ViewChild('skeletalCheck') skeletalCheckComponent: MediaTestStep;

    currentStep = 0;
    steps = [];

    watsonReady:boolean;
    volumeReady:boolean;
    skeletalReady:boolean;

    stream : MediaStream;

    pageKey = 'media-test::';

    textCached:any[] =  [];
    returnUrl:string;
    audioOnly:boolean = false;

    constructor(protected mediaService:MediaStreamService,
                protected textStoreService:TextStoreService,
                protected zone: NgZone,
                protected ngCh: ChangeDetectorRef,
                protected route: ActivatedRoute,
                protected router:Router,
                protected settingsService: SettingsService) {
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

        this.textStoreService.getTextByKey(this.pageKey).subscribe(texts => {
            this.textCached = texts;
            this.steps = [];
            this.steps.push({text:this.textCached[this.pageKey+"intro"]});
            // this.steps.push({text:this.textCached[this.pageKey+"volume"]});
            // this.steps.push({text:this.textCached[this.pageKey+"watson"]});
            this.steps.push({text:this.textCached[this.pageKey+"voice"]});
            if(!this.audioOnly)
                this.steps.push({text:this.textCached[this.pageKey+"skeletal"]});

            this.steps.push({text:this.textCached[this.pageKey+"completed"]});
        });

        if(this.audioOnly){
            this.mediaService.getAudioStream((stream) => this.OnStreamSuccess(stream),null);
        }
        else{
            this.mediaService.getAudioVideoStream((stream) => this.OnStreamSuccess(stream),null);
        }
    }

    OnStreamSuccess(stream){
        this.stream = stream;
    }

    voiceCheckComplete(){
        this.volumeReady = true;
        this.watsonReady = true;
        this.ngCh.detectChanges();
    }

    volumeCheckComplete(){
        this.volumeReady = true;
        this.ngCh.detectChanges();
    }

    watsonCheckComplete(){
        this.watsonReady = true;
        this.ngCh.detectChanges();
    }

    skeletalCheckComplete(){
        this.skeletalReady = true;
        this.ngCh.detectChanges();
    }

    allowNext():boolean{
        switch (this.currentStep){
            case 0:
                return true;
            case 1:
                return this.volumeReady && this.watsonReady;
            case 2:
                return this.audioOnly || this.skeletalReady;
            default:
                return false;
        }
    }


    presentClicked() {
        this.finish();
        this.completed.emit();

        if(this.returnUrl){
            this.router.navigate([this.returnUrl]);
        }
    }

    finish(){
        this.mediaService.stopMediaStream(this.stream);
        this.settingsService.setLocalSetting(this.mediaCheckedSetting, true);
    }

    nextStep(){
        this.currentStep = this.currentStep+1;
        switch (this.currentStep){
            case 1:
                if(this.voiceCheckComponent)
                    this.voiceCheckComponent.start();
                break;

            case 2:
                if(this.voiceCheckComponent)
                    this.voiceCheckComponent.finish();

                if(!this.audioOnly){
                    if(this.skeletalCheckComponent)
                        this.skeletalCheckComponent.start();
                }
                else{
                    this.finishAllCheckComponents();
                }
                break;

            default:
                this.finishAllCheckComponents();
                break;
        }
    }

    private finishAllCheckComponents(){
        if(this.voiceCheckComponent)
            this.voiceCheckComponent.finish();

        if(this.skeletalCheckComponent)
            this.skeletalCheckComponent.finish();

        this.mediaService.stopMediaStream(this.stream);
    }

    ngOnDestroy(): void {
       this.finishAllCheckComponents();
    }


}
