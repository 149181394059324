import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TextStoreService } from '../../../../classes/services/data/text-store.service';
import { Router } from '@angular/router';
import { EventSubscriptionService } from '../../../../classes/services';
import { NotificationEvent } from '../../../../classes/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {LearnCardData} from '../../../../classes/models/learn-card-data';

@Component({
  selector: 'app-coach-practice-card',
  templateUrl: './practice-card.component.html',
  styleUrls: ['./practice-card.component.scss']
})
export class PracticeCardComponent implements OnInit {

  data : LearnCardData = new LearnCardData();
  constructor(){}

  ngOnInit() {
    this.data.stretch_image = true;
    this.data.title = 'Practice';
    this.data.description = '';
    this.data.button_settings = {label:'Click Here To Practice', router_link:'/app/record/practice'};
    this.data.small_image = '../../../../assets/audio/present/microphone@2x.png';
  }

}
