import {Component, OnInit, isDevMode, ViewChild} from '@angular/core';
import {
    AuthService,
    EventSubscriptionService, RedirectionService, UserService
} from '../../classes/services/index';
import {NotificationEvent} from '../../classes/models/index';
import {AppModeEnum} from '../../classes/enums/app-mode.enum';
import {TutorialService} from '../../classes/services/core/tutorial.service';
import { CustomerService } from '../../classes/services/data/customer.service';
import { Router } from "@angular/router";
import {HasPermissionPipe} from '../../classes/pipes/has-permission.pipe';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    currentUser: any;
    currentLicense: any;
    showMenu = true;
    licenses: any = [];
    logoColor = isDevMode() ? 'red' : 'white';
    isWebAppMode: boolean;
    trialDaysLeft: any;
    showTrialBadge = false;
    isAdminLoggedInAsUser: Boolean = false;
    //isAccountOwner: boolean = false;
    //canManageCustomerBilling: boolean = false;

    pendingCustomerInvites = [];
    @ViewChild('analyticsMenu') analyticsMenu;
    @ViewChild('scoreMenu') scoreMenu;
    @ViewChild('learnMenu') learnMenu;
    @ViewChild('accountMenu') accountMenu;
    @ViewChild('usersMenu') usersMenu;
    constructor(
        private authService: AuthService,
        private userService: UserService,
        private notificationService: EventSubscriptionService,
        private redirectionService: RedirectionService,
        private tutorialService: TutorialService,
        private customerService: CustomerService,
        private router: Router,
        private hasPermissionPipe: HasPermissionPipe
    ) { }
    

    ngOnInit() {
        this.currentUser = this.authService.getLoggedInUser();
        this.currentLicense = this.authService.getLicense();

        this.licenses = this.authService.getUserLicenses();
        this.isAdminLoggedInAsUser = localStorage.getItem('adminLoggedInAsUser') ? true : false;

        // if(this.currentLicense) {
        //     //console.log('----------license roles------------------', this.currentLicense.roles);
        //     var a = this.currentLicense.roles.forEach(element => {
        //         //console.log('header.permissions:', element.role.permissions);
        //         if(element.role.title==='Account Owner') {
        //             //console.log("account owner!!!");
        //             this.isAccountOwner = true;
        //         }
        //         var newArray = element.role.permissions.map(p=> p.permission === 'CanManageCustomerBilling')
        //         if(newArray.length > 0) {
        //             //console.log("can manage customer billing!");
        //             this.canManageCustomerBilling = true;
        //         }
        //     });
        // }


        if(this.currentUser) {
            this.loadPendingCustomerInvites();
        }
        this.loadSubscriptionInformation();

        this.notificationService.subscribeToNotificationEvent(
            'current_user_changed',
            this,
            this.handleUserChange
        );
        this.notificationService.subscribeToNotificationEvent(
            'licenses_changed',
            this,
            this.handleUserLicensesChange
        );
        this.notificationService.subscribeToNotificationEvent(
            'license_changed',
            this,
            this.handleLicenseChange
        );
        this.notificationService.subscribeToNotificationEvent(
            'current_license_changed',
            this,
            this.handleCurrentLicenseChange
        );

        this.notificationService.subscribeToNotificationEvent(
            'APP_MODE_CHANGED',
            this,
            this.handleModeChange
        );

        this.notificationService.subscribeToNotificationEvent(
            'header_hide_menu',
            this,
            this.handleHideMenu
        );
    }


    loadSubscriptionInformation() {
        if(this.currentUser) {
            if(this.hasPermissionPipe.transform("CanManageCustomerBilling")) {
                this.customerService.getAllSubscriptions().subscribe(response => {
                    if(response.success) {
                        if(response.response && response.response.length > 0) {
                            var status = response.response[0].status;
                            if(status=='active') {
                                this.showTrialBadge = false;
                            }
                            else {
                                this.showTrialBadge = true;
            
                                if(status!='trialing') {
                                    //inactive license or past-due. redirect.
                                    this.notificationService.sendNotificationEvent(new NotificationEvent('header_hide_menu', true));
                                    this.router.navigate(['/customer/trial-info']);
                                }
                            }
            
                            this.trialDaysLeft = this.customerService.calculateTrialDaysLeft(response.response[0].trial_end);
                        }
                    }
                    else {
                        this.showTrialBadge = false;
                    }
                });
    
            }
        }
        else {
            this.trialDaysLeft = 0;
            this.showTrialBadge = false;
        }
    }
    
    
    // get isFreeUser(){
    //     return this.licenses && this.licenses.length == 0;
    // }
    get multipleLicenses(){
        return this.licenses && this.licenses.length > 1;
    }

    handleHideMenu(scope, data: NotificationEvent){
        //console.log("===================>Event: handleHideMenu", data)
        
        //if(data.data) {
        //    scope.loadSubscriptionInformation();
        //}
        scope.showMenu = !data.data;
    }

    loadPendingCustomerInvites() {
        this.userService.pendingCustomerInvites().subscribe(resp => {
            this.pendingCustomerInvites = resp;
        });
    }

    private handleModeChange(scope, data: NotificationEvent) {
        //console.log("===================>Event: handleModeChange", data)
        scope.isWebAppMode = data.data == AppModeEnum.WebApp;
    }

    private handleUserChange(scope, data: NotificationEvent) {
        //console.log("===================>Event: handleUserChange", data)
        scope.currentUser = data.data;

        scope.isAdminLoggedInAsUser = localStorage.getItem('adminLoggedInAsUser') ? true : false;
        if(scope.currentUser) {
            scope.loadPendingCustomerInvites();
        }
    }

    private handleUserLicensesChange(scope, data: NotificationEvent) {
        //console.log("===================>Event: handleUserLicens[es]Change", data)
        scope.licenses = data.data;
        scope.isAdminLoggedInAsUser = localStorage.getItem('adminLoggedInAsUser') ? true : false;
        if(scope.currentUser) {
            scope.loadPendingCustomerInvites();
        }
    }

    private handleLicenseChange(scope, data: NotificationEvent) {
        //console.log("===================>Event: handleLicens[e]Change", data)
        scope.licenses = scope.authService.licenses();
        scope.isAdminLoggedInAsUser = localStorage.getItem('adminLoggedInAsUser') ? true : false;
        if(scope.currentUser) {
            scope.loadPendingCustomerInvites();
        }
    }

    private handleCurrentLicenseChange(scope, data: NotificationEvent) {
        //console.log("===================>Event: handleCurrentLiecenseChange", data)
        scope.currentLicense = data.data;
        scope.isAdminLoggedInAsUser = localStorage.getItem('adminLoggedInAsUser') ? true : false;
        if(scope.currentUser) {
            scope.loadPendingCustomerInvites();
            scope.loadSubscriptionInformation();
        }
    }

    public homeLink() {
        if(!this.showMenu) return this.router.url; //'/customer/trial-info';
        else return '';
        //return this.redirectionService.getRedirectionPath();
    }

    hasRole(user, roleId) {
        //Determines if a user has a certain role.
        //returns true if the user has the roleId given
        if(user.roles) {
            return user.roles.filter(x => x.id === roleId).length > 0;
        }
        else return false;
    }
    
    AnalyticsClick(){
        this.analyticsMenu.nativeElement.classList.remove("show")
    }
    LearnClick(){
        this.learnMenu.nativeElement.classList.remove("show")
    }
    AccountClick(){
        this.accountMenu.nativeElement.classList.remove("show")
    }
    ScoreClick(){
        this.scoreMenu.nativeElement.classList.remove("show")
    }
    UsersClick(){
        this.usersMenu.nativeElement.classList.remove("show")
    }
}
