import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
    MultiSelectComponent,
    NgMultiSelectDropDownModule
} from 'ng-multiselect-dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DateToLocalPipe} from '../classes/pipes/date-to-local.pipe';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {AngularDualListBoxModule, DualListComponent} from 'angular-dual-listbox';
import {UiCoreModule} from './ui/ui-core.module';
import {CorePipesModule} from './core-pipes.module';
import {SliderScoreComponent} from '../analytics/core/slider-scores/slider-score/slider-score.component';
import {SliderLiveScoreComponent} from '../analytics/core/slider-livescores/slider-livescore/slider-livescore.component';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {SimpleRouterOutletComponent} from './simple-router-outlet/simple-router-outlet.component';
import {TabSetComponent} from './tab-set/tab-set.component';
import {BiPercentageDataDetailCardComponent} from '../analytics/team-analytics/team-analytics-overall/bi-percentage-data-detail-card/bi-percentage-data-detail-card.component';
import {DotProgressBarComponent} from './dot-progress-bar/dot-progress-bar.component';
import {ResultTypeFilterComponent} from '../analytics/core/result-type-filter/result-type-filter.component';
import {ToggleSliderComponent} from './toggle-slider/toggle-slider.component';
import {HelpPopupByKeyComponent} from './popup-manager/help-popup-by-key/help-popup-by-key.component';
import {LearnModalComponent} from './popup-manager/learn-modal/learn-modal.component';
import {LearnCardComponent} from '../presentr-app/learn/learn-card/learn-card.component';
import {MediaPlayerComponent} from './media-player/media-player.component';
import {PopupManagerComponent} from './popup-manager/popup-manager.component';
import {WelcomePopupModalComponent} from './popup-manager/welcome-popup-modal/welcome-popup-modal.component';
import {SuccessPopupModalComponent} from './popup-manager/success-popup-modal/success-popup-modal.component';
import {FailurePopupModalComponent} from './popup-manager/failure-popup-modal/failure-popup-modal.component';
import {GenericPopupModalComponent} from './popup-manager/generic-popup-modal/generic-popup-modal.component';
import {MediaPopupModalComponent} from './popup-manager/media-popup-modal/media-popup-modal.component';
import {MediaNoAccessPopupModalComponent} from './popup-manager/media-no-access-popup-modal/media-no-access-popup-modal.component';
import { TutorialManagerComponent } from '../core/tutorial-manager/tutorial-manager.component';
import { InputPopupModalComponent } from '../core/popup-manager/input-popup-modal/input-popup-modal.component';
import { PopupBaseComponent } from '../core/popup-manager/popup-base/popup-base.component';
import { ConfirmationPopupModalComponent } from './popup-manager/confirmation-popup-modal/confirmation-popup-modal.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { NgxStripeModule } from "ngx-stripe";
import { CreateTopicComponent } from './create-topic/create-topic.component';
import { AddEditTopicFormComponent } from './create-topic/add-edit-topic-form/add-edit-topic-form.component';
import { BaseCustomerCreateComponent } from './base-customer-create/base-customer-create.component';
import { SubscriptionTrialFormComponent } from './base-customer-create/subscription-trial-form/subscription-trial-form.component';
import { SubscriptionAddFormComponent } from './base-customer-create/subscription-add-form/subscription-add-form.component';
import { SubscriptionSelectionComponent } from './base-customer-create/subscription-selection/subscription-selection.component';
import { SubscriptionTrialPurchaseFormComponent } from './base-customer-create/subscription-trial-purchase-form/subscription-trial-purchase-form.component';
import { ConfirmOkOnlyPopupModalComponent } from './popup-manager/confirm-ok-only-popup-modal/confirm-ok-only-popup-modal.component';
import { UserNotificationsComponent } from '../home/user-notifications/user-notifications.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        RouterModule,
        NgbModule,
        NgxStripeModule.forRoot('null'),
        NgMultiSelectDropDownModule.forRoot(),
        AngularDualListBoxModule,
        UiCoreModule,
        CorePipesModule,
        ReactiveFormsModule
    ],
    declarations: [
        SliderScoreComponent,
        SliderLiveScoreComponent,
        BreadcrumbsComponent,
        SimpleRouterOutletComponent,
        TabSetComponent,
        BiPercentageDataDetailCardComponent,
        DotProgressBarComponent,
        ResultTypeFilterComponent,
        ToggleSliderComponent,
        HelpPopupByKeyComponent,
        LearnModalComponent,

        LearnCardComponent,
        MediaPlayerComponent,
        PopupManagerComponent,
        WelcomePopupModalComponent,
        SuccessPopupModalComponent,
        FailurePopupModalComponent,
        GenericPopupModalComponent,
        MediaPopupModalComponent,
        MediaNoAccessPopupModalComponent,
        TutorialManagerComponent,
        InputPopupModalComponent,
        PopupBaseComponent,
        ConfirmationPopupModalComponent,
        ConfirmOkOnlyPopupModalComponent,
        ProgressBarComponent,
        BaseCustomerCreateComponent,
        SubscriptionAddFormComponent,
        CreateTopicComponent,
        AddEditTopicFormComponent,
        SubscriptionTrialFormComponent,
        SubscriptionSelectionComponent,
        SubscriptionTrialPurchaseFormComponent,
        UserNotificationsComponent
    ],
    exports: [
        SliderScoreComponent,
        BreadcrumbsComponent,
        SimpleRouterOutletComponent,
        TabSetComponent,
        BiPercentageDataDetailCardComponent,
        DotProgressBarComponent,
        ResultTypeFilterComponent,
        ToggleSliderComponent,
        HelpPopupByKeyComponent,
        LearnModalComponent,
        DualListComponent,
        MultiSelectComponent,
        LearnCardComponent,
        MediaPlayerComponent,
        PopupManagerComponent,
        TutorialManagerComponent,
        PopupBaseComponent,
        ConfirmationPopupModalComponent,
        ConfirmOkOnlyPopupModalComponent,
        ProgressBarComponent,
        BaseCustomerCreateComponent,
        SubscriptionAddFormComponent,
        CreateTopicComponent,
        AddEditTopicFormComponent,
        SubscriptionTrialFormComponent,
        SubscriptionSelectionComponent,
        SubscriptionTrialPurchaseFormComponent,
        UserNotificationsComponent
    ],
    entryComponents: [
        // WordDetailsPopupModalComponent,
        MediaPopupModalComponent,
        GenericPopupModalComponent,
        FailurePopupModalComponent,
        SuccessPopupModalComponent,
        WelcomePopupModalComponent,
        HelpPopupByKeyComponent,
        LearnModalComponent,
        MediaNoAccessPopupModalComponent,
        InputPopupModalComponent,
        ConfirmationPopupModalComponent,
        ConfirmOkOnlyPopupModalComponent
    ],
    providers:[
        PopupManagerComponent
    ]

})
export class CoreModule {
}
