import {
  Component,
  OnInit,
  AfterViewInit,
  AfterContentInit, Input
} from '@angular/core';
import { TextStoreService } from "../../../classes/services/data/text-store.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {PopupSetPayload} from '../PopupSetPayload';

@Component({
  selector: "app-help-popup-by-key",
  templateUrl: "./help-popup-by-key.component.html",
  styleUrls: ["./help-popup-by-key.component.scss"]
})
export class HelpPopupByKeyComponent implements AfterViewInit, PopupSetPayload {
  textCached = [];
  helpPageKey = "";

  constructor(private textStoreService: TextStoreService) {}

  ngAfterViewInit() {
  }

  setPayload(payload): void {
    this.helpPageKey = payload;
    this.textStoreService.getTextByKey(this.helpPageKey).subscribe( r =>{
      this.textCached = r;
    });
  }
}
