<!--<div id='inner-overflow'>-->
<!--    <div class="header-title">Scheduled Sessions-->
<!--        <img src="../../../../assets/analytics/core/score-card/question-btn_large.png"-->
<!--        class="help-button small" (click)="openHelpModal()">-->
<!--    </div>-->

<!--    <div *ngFor="let item of schedule | slice: ((page -1) * itemsPerPage):(page * itemsPerPage) " class="recomender-box col-12">-->
<!--                    <div class="row parent">-->
<!--            <div class="col">-->
<!--                {{item.date | dateToLocal | date}} ({{item.date | dateToLocal | date: 'shortTime'}})-->
<!--                <button *ngIf="!isReadOnly" (click)="removeSchedule(item)" class="btn btn-delete btn-danger btn-sm" type="button">Delete</button>-->
<!--            </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <ngb-pagination *ngIf="schedule.length > itemsPerPage" [collectionSize]="schedule.length" [pageSize]="itemsPerPage" [maxSize]="3" class="d-flex justify-content-center" size="sm" [(page)]="page" [directionLinks]="true"></ngb-pagination>-->


<!--    <p *ngIf="schedule.length == 0" style="text-align: center; color: #fff; font-size: 12px;">You have no coaching sessions scheduled.</p>-->
<!--&lt;!&ndash;-->
<!--    <button id='schedule-more' (click)="scheduleMore()" class="btn btn-lg">Schedule Sessions</button> &ndash;&gt;-->
<!--</div>-->

<div class='flex flex-col w-full h-full items-center'>
    <div class="flex flex-col container w-full h-full overflow-auto">

        <div class="">
            <app-svg-component type="icon_help" class="svg ml-auto w-10 h-10 cursor-pointer fill-gray"
                               (click)="openHelpModal()"></app-svg-component>
        </div>
        <div class="flex flex-col items-center">
            <div *ngFor="let item of schedule | slice: ((page -1) * itemsPerPage):(page * itemsPerPage) "
                 class="flex justify-between bg-white rounded p-4 min-w-1/2 my-1">
                <span>{{item.date | dateToLocal | date}} ({{item.date | dateToLocal | date: 'shortTime'}})</span>
                <button *ngIf="!isReadOnly" (click)="removeSchedule(item)" class="flex w-6 h-full bg-blue text-white float-right justify-center cursor-pointer hover:font-bold"
                        type="button">x</button>
            </div>
        </div>


        <ngb-pagination *ngIf="schedule.length > itemsPerPage" [collectionSize]="schedule.length"
                        [pageSize]="itemsPerPage" [maxSize]="3" class="flex justify-center" size="sm"
                        [(page)]="page" [directionLinks]="true"></ngb-pagination>
        <span *ngIf="schedule.length == 0"
              class="text-center text-white">You have no coaching sessions scheduled.</span>
    </div>
</div>

