import { Routes } from "@angular/router";

export const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule) //_homeModule
  },
  {
    path: "",
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) //_authModule
  },
  {
    path: "app",
    loadChildren: () => import('./presentr-app/presentr-app.module').then(m => m.PresentrAppModule) //_presentrAppModule
  },
  {
    path: "account",
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule) //_accountModule
  },
  {
    path: "analytics",
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule) //_analyticsModule
  },
  {
    path: "customer",
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
  }
];
