import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, PRIMARY_OUTLET, Router} from "@angular/router";
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';





export class IBreadcrumb{
  label:string;
  url:string;
  params: Params;
  component:string;

  constructor(label, url, params, component){
    this.label = label;
    this.url = url;
    this.params = params;
    this.component = component;
  }
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input() showHomeOnly: boolean = false;
  @Input() generateWithRoute: boolean = true;
  @Input() breadcrumbs: IBreadcrumb[] = [];
  @Input() skipComponents:string[] = [];
  @Input() componentLabelMapper:any = {};


  @Output() visible: EventEmitter<boolean> = new EventEmitter<boolean>();

  navEnd: Observable<NavigationEnd>;



  constructor(private route: ActivatedRoute,
              private router:Router) { }

	ngOnInit() {
	if(this.generateWithRoute){
		this.breadcrumbs = this.generateBreadcrumbs(this.route.parent);
		this.checkVisibility();
		this.navEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd)) as Observable<NavigationEnd>;
		this.navEnd.subscribe(event => {
			//set breadcrumbs
			let root: ActivatedRoute = this.route.parent;
			this.breadcrumbs = this.generateBreadcrumbs(root);
			this.checkVisibility();
		});
	}
}

  refreshBreadcrumbsLabels(mapper){

	  for (let bc of this.breadcrumbs) {
		  if(bc.component && mapper[bc.component]){
			  bc.label = mapper[bc.component];
		  }else if(bc.component && this.componentLabelMapper[bc.component]){
			  bc.label = this.componentLabelMapper[bc.component];
		  }
	  }
  }

  checkVisibility(){
	  this.visible.emit(this.breadcrumbs.length > 1 || (this.showHomeOnly && this.breadcrumbs.length <= 1));
  }

  navigateBreadcrumb(bc){
    // if parent route is root, then do not make it relative
    let routeURL: string = this.route.parent.snapshot.url.map(segment => segment.path).join("/");
    if(routeURL){
        // this.router.navigate([bc.url], {relativeTo : this.route.parent})
        this.router.navigate([bc.url])
    }
    else{
	    this.router.navigate(['/'+bc.url])
    }
  }
	private ROUTE_DATA_BREADCRUMB: string = "breadcrumb";
	private ROUTE_DATA_COMPONENT: string = "component";

  generateBreadcrumbs(route, url: string="", breadcrumbs: IBreadcrumb[]=[]): IBreadcrumb[] {


	  //get the child routes
	  let children: ActivatedRoute[] = route.children;

	  //return if there are no more children
	  if (children.length === 0) {
		  return breadcrumbs;
	  }

	  //iterate over each children
	  for (let child of children) {
		  //verify primary route
		  if (child.outlet !== PRIMARY_OUTLET) {
			  continue;
		  }

		  //get the route's URL segment
		  let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");

		  let component = '';
		  if (child.snapshot.data.hasOwnProperty(this.ROUTE_DATA_COMPONENT)) {
			  component = child.snapshot.data[this.ROUTE_DATA_COMPONENT];
		  }

		  if(!routeURL || (component && this.skipComponents && this.skipComponents.indexOf(component) >= 0)){
			  return this.generateBreadcrumbs(child, url, breadcrumbs);
          }

		  //append route URL to URL
          if(url)
            url+= '/';
		  url += `${routeURL}`;


		  let label = url;
		  //verify the custom data property "breadcrumb" is specified on the route

		  if(component && this.componentLabelMapper[component]){
			  label = this.componentLabelMapper[component];
		  }
		  else if (child.snapshot.data.hasOwnProperty(this.ROUTE_DATA_BREADCRUMB)) {
		    label = child.snapshot.data[this.ROUTE_DATA_BREADCRUMB];
		  }

		  //add breadcrumb
		  let breadcrumb: IBreadcrumb = {
			  label: label,
			  params: child.snapshot.params,
			  url: url,
			  component :component
		  };

		  breadcrumbs.push(breadcrumb);

		  //recursive
		  return this.generateBreadcrumbs(child, url, breadcrumbs);
	  }
  }

}
