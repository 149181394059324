<app-base-dynamic-card *ngIf="data" class="flex w-64 h-full hover:border-blue">
    <ng-container slot="header">
        <div class="flex w-full flex-col text-center">
            <div class="w-full h-auto relative min-h-16">
                <img class="rounded-t {{data.stretch_image ? 'w-full' : 'w-auto'}} h-auto" src="{{data.small_image}}">
                <div class="absolute left-0 bottom-0 m-2">
                    <div class="flex">
                        <div *ngFor="let o of topicOptions" class="w-6 h-6 {{o.bgClass}} rounded {{o.textClass ? o.textClass : 'text-white'}} text-center mr-2 font-bold">{{o.initial}}</div>
                    </div>
                </div>
            </div>
            <h4 class="text-blue mt-2">{{data.title}}</h4>
        </div>
    </ng-container>
    <ng-container slot="body">
        <div class="flex flex-col p-4">
            <div class="flex-1">
                <p>{{data.short_description}}</p>
            </div>
        </div>
    </ng-container>
    <ng-container slot="overlay">

        <div class="flex flex-row flex-grow justify-around">
            <button *ngIf="data.button_settings"
                    class="flex-1 button-text {{data.button_settings.class ? data.button_settings.class : ''}}"
                    (click)="data.button_settings.router_link ? routerLinkClicked() : openInModal()">
                {{ data.button_settings.label }}
            </button>
        </div>
    </ng-container>
</app-base-dynamic-card>
