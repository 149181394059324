import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-toggle-slider',
  templateUrl: './toggle-slider.component.html',
  styleUrls: ['./toggle-slider.component.scss']
})
export class ToggleSliderComponent implements OnInit {

  @Output() changed: EventEmitter<boolean> = new EventEmitter<any>();
  @Input() value:boolean;
  @Input() trueText: string;
  @Input() falseText: string;
  @Input() elementId:string;
  @Input() disabled:boolean;
  constructor() { }

  ngOnInit() {
  }

  clicked(){
    this.value = !this.value;
    this.valueChanged();
  }

  valueChanged(){
    this.changed.emit(this.value);
  }

}
