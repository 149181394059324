<div class="relative flex w-16 h-10 font-bold justify-end items-center" #textElement>

    <svg class="absolute top-0 right-0 w-full h-full svg stroke-gray text-gray-darker" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         x="0px" y="0px"
         viewBox="0 0 152.8 94" style="enable-background:new 0 0 152.8 94;" xml:space="preserve">

        <path d="M4.7,41L39.9,5.7c1.3-1.3,3-2.1,4.7-2.4V3.1H82h19.7h47v88.2h-47H82H44.7v-0.1c-1.8-0.3-3.4-1.1-4.7-2.4
            L4.7,53.5c-1.7-1.7-2.6-3.9-2.6-6.2C2.1,44.9,3,42.6,4.7,41z"/>
    </svg>

<!--    <app-svg-component type="thumbnail"-->
<!--                       ></app-svg-component>-->
    <span class="absolute ml-1 items-center w-full font-bold text-1xl text-center">{{value | number : '.0-0'}}</span>
</div>
