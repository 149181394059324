import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {EnvironmentAPIs} from '../../enums/environment-apis';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiService {
    private actionUrl: string;
    private hasShownDnsFailoverAlert: Boolean = false;

    constructor(private http: HttpClient, private router: Router) {
        this.actionUrl = environment.ketchupUrl; // will be overwritten in most cases with setupActionURl
    }

    public post<T>(
        path: string,
        object: any,
        environment?: EnvironmentAPIs,
        header_values?: HttpHeaders,
        actionUrl?:string
    ): Observable<T> {
        this.setupActionUrl(environment);
        return this.http
            .post<T>((actionUrl ? actionUrl : this.actionUrl) + path, object, {headers: header_values})
            .pipe(
            map(response => {
                this.logoutOn401s(response);
                this.redirectToNoAccess403(response);
                return response;
            })
        )
    }

    public get<T>(
        path: string,
        environment?: EnvironmentAPIs,
        header_values?: HttpHeaders,
        actionUrl?:string
    ): Observable<T> {
        this.setupActionUrl(environment);

        return this.http
            .get<T>((actionUrl ? actionUrl : this.actionUrl) + path, {headers: header_values})
            .pipe(
            map(response => {
                this.logoutOn401s(response);
                this.redirectToNoAccess403(response);
                return response;
            })
        )
    }

    public put<T>(
        path: string,
        object: any,
        environment?: EnvironmentAPIs,
        header_values?: HttpHeaders,
        actionUrl?:string
    ): Observable<T> {
        this.setupActionUrl(environment);

        return this.http
            .put<T>((actionUrl ? actionUrl : this.actionUrl) + path, object, {headers: header_values})
            .pipe(
             map(response => {
                this.logoutOn401s(response);
                this.redirectToNoAccess403(response);
                return response;
            })
        )
    }

    public delete<T>(path: string,
                     header_values?: HttpHeaders,
                     actionUrl?:string): Observable<T> {
        return this.http
            .delete<T>((actionUrl ? actionUrl : this.actionUrl) + path, {headers: header_values})
            .pipe(
            map(response => {
                this.logoutOn401s(response);
                this.redirectToNoAccess403(response);
                return response;
            })
        )
    }

    private setupActionUrl(envAPI: EnvironmentAPIs) {
        switch (envAPI) {
            case EnvironmentAPIs.Ketchup:
                this.actionUrl = environment.ketchupUrl;
                break;
            case EnvironmentAPIs.BBQ:
                this.actionUrl = environment.bbqUrl;
                break;
            default:
                this.actionUrl = environment.ketchupUrl;
                break;
        }
    }

    private logoutOn401s(response) {
        // login successful if there's a jwt token in the response
        let responseObj = JSON.parse(JSON.stringify(response));
        if (
            !responseObj.success &&
            (responseObj.code == 401 || responseObj.code == 498)
        ) {
            if (!this.hasShownDnsFailoverAlert &&
                responseObj.options.location &&
                responseObj.options.location === 'dns-failover'
            ) {
                this.hasShownDnsFailoverAlert = true;
                alert(responseObj.response.error);
            }

            this.router.navigate(['logout']);
        } else {
            this.hasShownDnsFailoverAlert = false;
            // Reset the alert in case the API comes back online and then goes off again
        }
    }
    private redirectToNoAccess403(response){
        let responseObj = JSON.parse(JSON.stringify(response));
        if (!responseObj.success && responseObj.code == 403) {
            console.error(response.response.error);
            this.router.navigate(["/no-access"]);
        }
    }
}
