import { Component, OnInit } from '@angular/core';
import {TextStoreService} from '../../../classes/services/data/text-store.service';
import {PopupSetPayload} from '../PopupSetPayload';

@Component({
  selector: 'app-welcome-popup-modal',
  templateUrl: './welcome-popup-modal.component.html',
  styleUrls: ['./welcome-popup-modal.component.scss']
})
export class WelcomePopupModalComponent implements OnInit, PopupSetPayload {

  welcomeText: string = null;
  welcomeTextKey = 'home::welcome-message::description';

  constructor(private textStoreService: TextStoreService) {}

  ngOnInit() {

    this.textStoreService.getTextByKey(this.welcomeTextKey).subscribe(r => {
      this.welcomeText = r[this.welcomeTextKey];
    });

  }
  setPayload(payload): void {
  }
}
