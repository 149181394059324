import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ContentService} from '../../../classes/services';
import {PopupNotificationService} from '../../../classes/services/core/popup-notification.service';

@Component({
  selector: 'app-add-edit-topic-form',
  templateUrl: './add-edit-topic-form.component.html',
  styleUrls: ['./add-edit-topic-form.component.scss']
})
export class AddEditTopicFormComponent implements OnInit {

    @Input() customerId: Number;
    @Input() userId: Number;
    @Input() title: string = 'Custom Topics';
    @Input() returnUrl: string;

    model:any = {
        enabled:1,
        prompts:[]
    };
    addPrompt:any = {};

    originalId : string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private contentService: ContentService,
        private popupService: PopupNotificationService) { }

    ngOnInit() {
        this.originalId = this.route.snapshot.paramMap.get('id');
        if(this.originalId){
            this.contentService.getAllTopics().subscribe(resp => {
                if(resp.success){
                    this.model = resp.response.find(x => x.id == this.originalId);
                }
                else{
                    alert("Could not find Topic");
                    this.router.navigate([this.returnUrl])
                }
            })
        }
        else {
            this.model = {
                enabled:1,
                user_id:this.userId,
                customer_id:this.customerId,
                prompts:[]
            };
        }
    }
    submitPrompt(){
        if(this.addPrompt && this.addPrompt.prompt){
            this.addPrompt.order = this.model.prompts ? this.model.prompts.length : 0;
            this.model.prompts.push(this.addPrompt);
            this.addPrompt = {}
        }
    }
    removePrompt(index){
        this.model.prompts.splice(index, 1);
    }

    saveChanges(){
        if(this.model.id){
            this.contentService.updateTopic(this.model).subscribe(resp => {
                if(resp.success){
                    this.popupService.openSuccessPopup("Topic Updated Successfully", () => {this.router.navigate([this.returnUrl]);});

                }
                else{
                    this.popupService.openFailurePopup("Topic Failed To Update");
                }
            })
        }
        else{
            this.contentService.storeTopic(this.model).subscribe(resp => {
                if(resp.success){
                    this.popupService.openSuccessPopup("Topic Created Successfully", () => {this.router.navigate([this.returnUrl]);});
                }
                else{
                    this.popupService.openFailurePopup("Topic Failed To Create");
                }
            })
        }
    }
}
