import { NgModule } from "@angular/core";
import { DateToLocalPipe } from "../classes/pipes/date-to-local.pipe";
import {SecondsToTimePipe} from '../classes/pipes/seconds-to-time-pipe';
import {NoSanitizePipe} from '../classes/pipes/safe-html';
import {DomChangeDirective} from '../classes/directives/dom-change.directive';
import {ScoreToCategoryPipe} from '../classes/pipes/score-to-category-pipe';
import {OrdinalPipe} from '../classes/pipes/ordinal-pipe';
import {CommonModule, DatePipe} from '@angular/common';
import {HasPermissionPipe} from '../classes/pipes/has-permission.pipe';
import { NotificationSourcePipe } from '../classes/pipes/notification-source.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        NoSanitizePipe,
        DateToLocalPipe,
        SecondsToTimePipe,
        DomChangeDirective,
        ScoreToCategoryPipe,
        OrdinalPipe,
        HasPermissionPipe,
        NotificationSourcePipe
    ],
    providers: [
        NotificationSourcePipe
    ],
    exports: [
        DatePipe,
        NoSanitizePipe,
        DateToLocalPipe,
        SecondsToTimePipe,
        DomChangeDirective,
        ScoreToCategoryPipe,
        OrdinalPipe,
        HasPermissionPipe,
        NotificationSourcePipe
    ]
})

export class CorePipesModule {}
