<div class='flex flex-col w-full h-full items-center'>
    <div class="flex flex-col container w-full h-full overflow-auto">
        <div class="">
            <app-svg-component type="icon_help" class="svg ml-auto w-10 h-10 cursor-pointer fill-gray"
                               (click)="openHelpModal()"></app-svg-component>
        </div>

        <div class="flex flex-col items-center">
            <div *ngFor="let task of tasks | slice: ((page -1) * itemsPerPage):(page * itemsPerPage) "
                 class="flex flex-col bg-white rounded p-4 min-w-1/2 my-1">
                <div class="flex flex-row w-full justify-between">
                    <a *ngIf="task.type == 'present'" (click)="presentClicked()" class="text-blue cursor-pointer hover:font-bold">{{task.title}}</a>
                    <a *ngIf="task.type == 'practice'" (click)="practiceClicked()" class="text-blue cursor-pointer hover:font-bold">{{task.title}}</a>
                    <a *ngIf="task.type == 'game'" (click)="gameLinkClicked(task.game_id)" class="text-blue cursor-pointer hover:font-bold">{{task.title}}</a>
                    <a *ngIf="task.type == 'watch'" (click)="watchLinkClicked(task.watch_id)" class="text-blue cursor-pointer hover:font-bold">{{task.title}}</a>
                    <a *ngIf="task.type == 'read'" (click)="readLinkClicked(task.read_id)" class="text-blue cursor-pointer hover:font-bold">{{task.title}}</a>
                    <button *ngIf="!isReadOnly" (click)="removeTask(task)" class="flex w-6 h-full bg-blue text-white float-right justify-center cursor-pointer hover:font-bold"
                            type="button">x</button>

                </div>

                <div *ngIf="task.children && task.children.length > 0" class="flex flex-col items-start">

                    <div *ngFor="let sub of task.children" class="flex justify-between bg-white border border-gray rounded p-2 min-w-1/2 my-1 ml-4">
<!--                        <input readonly name='subTaskComplete' [checked]="sub.complete" type='checkbox'/>-->
                        <a *ngIf="sub.type == 'present'" (click)="presentClicked()" class="text-blue cursor-pointer hover:font-bold">{{sub.title}}</a>
                        <a *ngIf="sub.type == 'practice'" (click)="practiceClicked()" class="text-blue cursor-pointer hover:font-bold">{{sub.title}}</a>
                        <a *ngIf="sub.type == 'game'" (click)="gameLinkClicked(sub.game_id)" class="text-blue cursor-pointer hover:font-bold">{{sub.title}}</a>
                        <a *ngIf="sub.type == 'watch'" (click)="watchLinkClicked(sub.watch_id)" class="text-blue cursor-pointer hover:font-bold">{{sub.title}}</a>
                        <a *ngIf="sub.type == 'read'" (click)="readLinkClicked(sub.read_id)" class="text-blue cursor-pointer hover:font-bold">{{sub.title}}</a>

                        <button *ngIf="!isReadOnly" (click)="removeTask(sub, task)"
                                class="flex w-6 h-auto bg-blue text-white float-right justify-center cursor-pointer hover:font-bold" type="button">x
                        </button>

                    </div>
                </div>
            </div>
        </div>
        <ngb-pagination *ngIf="tasks.length > itemsPerPage" [collectionSize]="tasks.length" [pageSize]="itemsPerPage"
                        [maxSize]="3" class="flex justify-center" size="sm" [(page)]="page"
                        [directionLinks]="true"></ngb-pagination>
        <span *ngIf="tasks.length == 0" class="text-center text-white">You have no pending coaching tasks.</span>
    </div>
</div>


