import { Injectable } from "@angular/core";
import { AuthService } from "../data/auth.service";
import { DebugConsole } from "../../helpers/debug-console";

@Injectable()
export class RedirectionService {
  constructor(private authService: AuthService) {}

  getRedirectionPath() {
      return "/";
  }
}
