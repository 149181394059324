import {Injectable} from '@angular/core';
import {EventSubscriptionService} from './event-subscription.service';
import {NotificationEvent} from '../../models';
import {SettingsService} from './settings.service';
import {AuthService, ContentService} from '..';
import {NavigationStart, Router} from '@angular/router';

@Injectable()
export class TutorialService {

    static RUN_TUTORIAL = 'RUN_TUTORIAL';

    private tutorialKey;

    constructor(private notificationService: EventSubscriptionService,
                private authService: AuthService,
                private settingService: SettingsService,
                private contentService: ContentService,
                private router: Router) {

        router.events.subscribe((val) => {
            if(val instanceof NavigationStart)
                this.enableTutorial(null);
        });
    }

    get hasTutorial(){
        return this.tutorialKey;
    }

    enableTutorial(tutorialKey){
        this.tutorialKey = tutorialKey;
    }

    startCurrentTutorial(){
        if(this.tutorialKey){

            this.contentService.getTutorial(this.tutorialKey).subscribe(resp => {
                let r: any = resp;

                if (r.success) {
                    this.notificationService.sendNotificationEvent(new NotificationEvent(TutorialService.RUN_TUTORIAL, {
                        elements: r.response.elements,
                        tutorialKey:this.tutorialKey
                    }));
                }
            });
        }
    }
}
