import {Injectable} from '@angular/core';
import {EventSubscriptionService} from './event-subscription.service';
import {NotificationEvent} from '../../models';

declare const MediaRecorder: any;

export enum MediaRecorderFileType {
    AUDIO,
    VIDEO
}

@Injectable()
export class MediaRecorderService {

    static readonly FILE_CREATED_EVENT = 'MEDIA_FILE_CREATED';
    private mediaRecorder: any;
    private chunks: any = [];
    paused: boolean = false;

    private file: any;

    constructor(private notificationService: EventSubscriptionService) {
    }


    subscribeForNotification(scope, callback) {
        this.notificationService.subscribeToNotificationEvent(MediaRecorderService.FILE_CREATED_EVENT, scope, callback);
    }

    unsubscribeNotification(scope) {
        this.notificationService.unsubscribeToNotificationEvent(MediaRecorderService.FILE_CREATED_EVENT, scope);
    }

    public blobToFile(blob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        blob.lastModifiedDate = new Date();
        blob.name = fileName;
        return <File>blob;
    }


    startRecordStream(stream: MediaStream, fileType: MediaRecorderFileType) {
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = e => {
            this.saveStream(e, fileType);
        };

        this.mediaRecorder.start();
    }

    saveStream(data, fileType: MediaRecorderFileType) {
        this.chunks.push(data.data);

        let blob = new Blob(this.chunks, {'type': fileType == MediaRecorderFileType.AUDIO ? 'audio/webm' : 'video/webm'});
        let currentTime = new Date().getTime();
        let jsTicks = currentTime * 10000;

        let fileName = fileType == MediaRecorderFileType.AUDIO ? 'web-audio_' + jsTicks.toString() + '.webm' : 'web-video_' + jsTicks.toString() + '.webm';
        this.file = this.blobToFile(blob, fileName);
        this.notificationService.sendNotificationEvent(new NotificationEvent(MediaRecorderService.FILE_CREATED_EVENT, this.file));

        this.chunks = [];
    }

    stopRecordStream() {
        if (this.mediaRecorder && (this.mediaRecorder.state == 'recording' || this.mediaRecorder.state == 'paused')) {
            this.mediaRecorder.stop();
        }
    }

    pauseRecordStream() {
        this.mediaRecorder.pause();
    }

    resumeRecordStream() {
        this.mediaRecorder.resume();
    }
}
