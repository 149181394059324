'use strict';

/**
 * IBM Watson Speech to Text JavaScript SDK
 *
 * The primary methods for interacting with the Speech to Text JS SDK are:
 *  * `recognizeMicrophone()` for live microphone input
 *  * `recognizeFile()` for file `<input>`'s and other data sources (e.g. a Blob loaded via AJAX)
 *
 * However, the underlying streams and utils that they use are also exposed for advanced usage.
 *
 * @module watson-speech/speech-to-text
 */

module.exports = {
    // "easy-mode" API
    /**
     * @see module:watson-speech/speech-to-text/recognize-microphone
     */
    recognizeMicrophone: require('watson-speech/speech-to-text/recognize-microphone'),
    /**
     * @see module:watson-speech/speech-to-text/recognize-blob
     */
    //recognizeFile: require('watson-speech/speech-to-text/recognize-file'),
    /**
     * @see module:watson-speech/speech-to-text/get-models
     */
    getModels: require('watson-speech/speech-to-text/get-models'),
    // individual components to build more customized solutions
    /**
     * @see WebAudioL16Stream
     */
    WebAudioL16Stream: require('watson-speech/speech-to-text/webaudio-l16-stream'),
    /**
     * @see RecognizeStream
     */
    RecognizeStream: require('watson-speech/speech-to-text/recognize-stream'),
    /**
     * @see FilePlayer
     */
    FilePlayer: require('watson-speech/speech-to-text/file-player'),
    /**
     * @see FormatStream
     */
    FormatStream: require('watson-speech/speech-to-text/format-stream'),
    /**
     * @see TimingStream
     */
    TimingStream: require('watson-speech/speech-to-text/timing-stream'),
    /**
     * @see ResultStream
     */
    ResultStream: require('watson-speech/speech-to-text/result-stream'),
    /**
     * @see SpeakerStream
     */
    SpeakerStream: require('watson-speech/speech-to-text/speaker-stream'),
    /**
     * @see WritableElementStream
     */
    WritableElementStream: require('watson-speech/speech-to-text/writable-element-stream'),
    // external components exposed for convenience

    /**
     * @see https://www.npmjs.com/package/get-user-media-promise
     */
    getUserMedia: require('get-user-media-promise'),
    /**
     * @see https://www.npmjs.com/package/microphone-stream
     */
    MicrophoneStream: require('microphone-stream'),
    /**
     * @see https://nodejs.org/api/buffer.html
     */
    Buffer: Buffer
};
