import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseCardComponent } from './base-card/base-card.component';
import { BaseListComponent } from './base-list/base-list.component';
import { BaseCircleScoreComponent } from './base-circle-score/base-circle-score.component';
import { BaseStaticCardComponent } from './base-static-card/base-static-card.component';
import { BaseBsStaticCardComponent } from './base-bsstatic-card/base-bsstatic-card.component';
import { BaseChatBubbleComponent } from './base-chat-bubble/base-chat-bubble.component';
import { BaseDynamicCardComponent } from './base-dynamic-card/base-dynamic-card.component';
import { BaseBSDynamicCardComponent } from './base-bsdynamic-card/base-bsdynamic-card.component';
import {CorePipesModule} from '../core-pipes.module';
import { BaseScaleThumbScoreComponent } from './base-scale-thumb-score/base-scale-thumb-score.component';
import { BaseScaleThumbLiveScoreComponent } from './base-scale-thumb-livescore/base-scale-thumb-livescore.component';
import { SvgComponentComponent } from './svg-component/svg-component.component';

@NgModule({
  imports: [
    CommonModule,
    CorePipesModule
  ],
    declarations: [
        BaseCardComponent,
        BaseListComponent,
        BaseCircleScoreComponent,
        BaseStaticCardComponent,
        BaseBsStaticCardComponent,
        BaseChatBubbleComponent,
        BaseDynamicCardComponent,
        BaseBSDynamicCardComponent,
        BaseScaleThumbScoreComponent,
        BaseScaleThumbLiveScoreComponent,
        SvgComponentComponent
    ],
  exports: [
    BaseCardComponent,
    BaseCircleScoreComponent,
    BaseStaticCardComponent,
    BaseBsStaticCardComponent,
    BaseDynamicCardComponent,
    BaseBSDynamicCardComponent,
    BaseListComponent,
    BaseChatBubbleComponent,
    BaseScaleThumbScoreComponent,
    BaseScaleThumbLiveScoreComponent,
    SvgComponentComponent
  ]
})

export class UiCoreModule {}
