<!--<svg [attr.height]="size" [attr.width]="size">-->
<!--  <circle [attr.class]="'circle-background-'+ ((percentage || 0) | scoreToCategory:true:rangeOverride)" id="_cir_P_x" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="outerRadius" stroke-width="0"></circle>-->
<!--  <circle [attr.class]="'circle-background-'+ ((percentage || 0) | scoreToCategory:true:rangeOverride) +' progress-circle-bg'" id="_cir_P_v" [attr.cx]="middlePoint" [attr.cy]="middlePoint"-->
<!--          [attr.r]="innerRadius" [attr.stroke-width]="strokeWidth" [attr.stroke-dasharray]="perimeter+','+perimeter"></circle>-->
<!--  <circle class="progress-circle" id="_cir_P_y" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="innerRadius" [attr.stroke-width]="strokeWidth" [attr.stroke-dasharray]="progressNumber()+','+perimeter" stroke-dashoffset="" stroke="#FFFFFF" fill="none"></circle>-->
<!--  <circle [attr.class]="'circle-background-'+ ((percentage || 0) | scoreToCategory:true:rangeOverride)" id="_cir_P_z" [attr.cx]="middlePoint" [attr.cy]="middlePoint" [attr.r]="bgInnerRadius" stroke-width="0"></circle>-->
<!--  <text class="percentage-text" [attr.font-size]="textSize+'px'" x="50%" y="50%" text-anchor="middle" fill="#FFFFFF" stroke="none" stroke-width="1px" dy=".4em" id="_cir_Per">{{percentage ? percentage : 0}}%</text>-->
<!--</svg>-->

<div class="w-full h-full p-2 relative" >
  <svg class="w-full h-full" viewBox="0 0 300 300">
    <defs>
        <filter id="circle_score_dropshadow" x="-40%" y="-40%" width="180%" height="180%" filterUnits="userSpaceOnUse">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3"/> <!-- stdDeviation is how much to blur -->
            <feOffset dx="5" dy="5" result="offsetblur"/>
            <feOffset dx="-5" dy="-5" result="offsetblur"/>
            <feMerge>
                <feMergeNode/>
                <feMergeNode in="SourceGraphic"/>
                <feMergeNode in="SourceGraphic"/>
            </feMerge>
        </filter>
    </defs>
    <circle stroke-width="1" stroke="#CCCCCC" r="45%" cx="50%" cy="50%" fill="white" style="filter:url(#circle_score_dropshadow)"></circle>
    <circle stroke="red" [attr.stroke]="fillColor" stroke-width="5%" r="38%" cx="50%" cy="50%" fill="transparent"></circle>
    <text x="50%" y="50%" [attr.fill]="fillColor" dominant-baseline="middle" text-anchor="middle" font-size="7em" font-weight="700">{{ (percentage? percentage : 0) | number : '.0-0'}}</text>
  </svg>

</div>

