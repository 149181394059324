import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TipProviderService {

    tips: any = [
        [[], [], [], [], []],
        [[], [], [], [], []],
        [[], [], [], [], []]
    ];

    cached: boolean;

    constructor(private apiService: ApiService) {

    }

    // Types
    // 1 = volume
    // 2 = pace
    // 3 = filler


    // Range
    // 1 = poor
    // 2 = fair
    // 3 = average
    // 4 = great
    // 5 = pro

    randomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    private getCachedTip(type, scale) : string{
        let types = this.tips[Math.min(type - 1, this.tips.length - 1)];
        if(!types || types.length == 0)
          return '';

        let scales = types[Math.min(scale, types.length - 1)];
        if(!scales || scales.length == 0)
          return '';

        let tipIndex = this.randomInt(0, scales.length - 1);
        return scales[tipIndex];
    }
    getTip(type, scale) {

        if(this.cached){
            return new Observable<any>((observer) => {
                // observable execution
                observer.next(this.getCachedTip(type, scale));
                observer.complete();
            });
        }
        else {
            return this.apiService.get('/content/tips').pipe( map(response => {

                let resp = JSON.parse(JSON.stringify(response));
                if (resp.success) {
                    resp.response.map(x => {
                        this.tips[x.tip_type_id - 1][x.tip_range_id - 1].push(x.description);
                    });
                    this.cached = true;
                    return this.getCachedTip(type,scale);
                }
                else{
                    return '';
                }

            })
            )
        }
    }

}
