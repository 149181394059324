export class NotificationEvent {
    name: string;
    source: string;
    target: string;
    data: any;

    constructor(_name: string, _data: any) {
        this.name = _name;
        this.data = _data;
    }
}