import {Component, Input, OnInit} from '@angular/core';
import {PopupSetPayload} from '../PopupSetPayload';

@Component({
  selector: 'app-media-popup-modal',
  templateUrl: './media-popup-modal.component.html',
  styleUrls: ['./media-popup-modal.component.scss']
})
export class MediaPopupModalComponent implements OnInit, PopupSetPayload {
  recording;
  constructor() { }

  ngOnInit() {
  }

  setPayload(payload): void {
    this.recording = payload;
  }

}
