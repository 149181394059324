import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild,AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationPopupModalComponent } from '../../popup-manager/confirmation-popup-modal/confirmation-popup-modal.component';
import { environment } from '../../../../environments/environment';
import { StripeService } from 'ngx-stripe';
import { StripeElement as StripeElement, StripeElements, StripeElementsOptions } from '@stripe/stripe-js'
import { ApiService, AuthService, CustomerService, LoadingService, PingsService } from '../../../classes/services';
import { Router } from '@angular/router';
import { PopupNotificationService } from '../../../classes/services/core/popup-notification.service';
import { HasPermissionPipe } from '../../../classes/pipes/has-permission.pipe';

@Component({
    selector: 'app-subscription-trial-form',
    templateUrl: './subscription-trial-form.component.html',
    styleUrls: ['./subscription-trial-form.component.scss']
})
export class SubscriptionTrialFormComponent implements AfterViewInit {

    public static PlanOptions = [
        {
            name: 'Freemium',
            description: 'All the basics to allow you to experience the Presentr app.',
            features: [],
            type: 0,
            price_monthly: 0,
            price_yearly: 0,
            purchasing_for: null
        },
        {
            name: 'Single User',
            description: '',
            features: ['Record', 'Scores and Analytics', 'Tips For Improvement', 'Practice with real time feedback', 'Coaching from AI Coach, Abbie', 'Access to games and content library'],
            type: 1,
            price_monthly: 9,
            price_yearly: 99,
            purchasing_for: 'single'
        },
        {
            name: 'Team',
            description: 'All single user features plus:',
            features: ['Team Analytics', 'Transferable Seats', 'Manager / Coach access'],
            type: 2,
            price_monthly: 25,
            price_yearly: 250,
            purchasing_for: 'team'
        }
    ];

    model: any = { billing_frequency: 'monthly', purchasing_for: 'self' };
    totalPrice = 0;

    @ViewChild('reviewForm') reviewFormElement: TemplateRef<any>;
    token: String = '';

    stripeKey = environment.stripePublicKey;
    elements: StripeElements;
    card: StripeElement;

    existingCards: any[];

    discount: any = {};
    // optional parameters
    elementsOptions: StripeElementsOptions = {
        locale: 'en'
    };

    stripeForm: FormGroup;
    @Output() completed = new EventEmitter();

    constructor(private fb: FormBuilder,
        private stripeService: StripeService,
        private apiService: ApiService,
        private customerService: CustomerService,
        private authService: AuthService,
        private router: Router,
        private pingsService: PingsService,
        private loadingService: LoadingService,
        private popupService: PopupNotificationService,
        private hasPermissionPipe: HasPermissionPipe) {
    }

    customer: any;
    freeTrialDays = 14;

    ngAfterViewInit() {

        this.updatePurchasingFor('team');
        this.setupStripe();
        this.customerService.getCustomer().subscribe(resp => {
            if (resp.success) {
                this.customer = resp.response;
                if (this.hasCustomerPermission) {
                    this.customerService.getAllCards().subscribe(resp => {
                        this.existingCards = resp.response;
                    });
                }
            }
        });
        
        this.customerService.getAppInfo().subscribe(response => {
            this.freeTrialDays = response.response.trial_period_days;
        });

        this.reviewPurchase();
    }

    get hasCustomerPermission() {
        return this.hasPermissionPipe.transform("CanManageCustomerBilling");
    }
    setupStripe() {
        this.stripeService.changeKey(this.stripeKey);
        this.stripeForm = this.fb.group({
            name: ['', [Validators.required]]
        });

        if (!this.card) {
            this.stripeService.elements(this.elementsOptions).subscribe(elements => {
                this.elements = elements;
                // Only mount the element the first time
                this.card = this.elements.create('card', {
                    style: {
                        base: {
                            iconColor: '#666EE8',
                            color: '#31325F',
                            lineHeight: '40px',
                            fontWeight: 300,
                            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                            fontSize: '18px',
                            '::placeholder': {
                                color: '#CFD7E0'
                            }
                        }
                    }
                });

                this.card.on('change', (data) => {
                    if (this.model.token)
                        this.model.token = '';
                });

                this.mountStripeCardElement();
            });
        } else {
            this.mountStripeCardElement();
        }

    }

    mountStripeCardElement() {
        setTimeout(() => {
            let cardElement = document.getElementById('card-element');
            if (!cardElement || cardElement.childElementCount > 0)
                return;
            if (cardElement && this.card) {
                this.card.mount('#card-element');
            }
        }, 50);

    }

    reviewPurchase() {
        this.popupService.openPopup(ConfirmationPopupModalComponent, {
            title: 'Confirm Free Trial',
            elementRef: this.reviewFormElement
        }, (result) => {
            if (result)
                this.completePurchase();
        });
    }

    completePurchase() {
        this.loadingService.startLoading();
        if (this.customer && this.hasCustomerPermission) {
            this.customerService.addSubscription(this.model).subscribe(data => {
                let response = JSON.parse(JSON.stringify(data));
                this.loadingService.stopLoading();
                if (!response.success) {
                    alert(response.response.error);
                } else {
                    this.completed.emit();
                }
            });
        }
        else {
            this.customerService.create(this.model).subscribe(data => {
                let response = JSON.parse(JSON.stringify(data));
                this.loadingService.stopLoading();
                if (!response.success) {
                    alert(response.response.error);
                } else {
                    response = response.response;
                    if (response.id) {
                        // customer created
                        this.completed.emit();
                    } else {
                        alert('Something went wrong. Please refresh and try again.');
                    }
                }
            });
        }

    }


    updatePurchasingFor(purchasingFor) {
        this.model.purchasing_for = purchasingFor;
        if (purchasingFor == 'team') {
            this.model.seats = 5;
            this.model.name = "Team";
        }
        else if (purchasingFor == 'single') {
            this.model.seats = 1;
            this.model.name = "Single";
        }
        else {
            this.model.seats = 0;
            this.model.name = "Other";
        }

        this.mountStripeCardElement();
        this.calculate();
    }

    get selectedPlan() {
        let planIndex = this.model.purchasing_for ? (this.model.purchasing_for == 'team' ? 2 : 1) : 0;
        //console.log('model:', this.model);

        return SubscriptionTrialFormComponent.PlanOptions[planIndex];
    }

    calculate() {
        if (this.model.billing_frequency === 'monthly') {
            this.totalPrice = this.model.seats * this.selectedPlan.price_monthly;
        } else {
            this.totalPrice = this.model.seats * this.selectedPlan.price_yearly;
        }

        if (this.discount && this.discount.percent_off) {
            this.totalPrice = this.totalPrice - (this.totalPrice * (0.01 * this.discount.percent_off));
        }

        if (this.discount && this.discount.amount_off) {
            this.totalPrice = this.totalPrice - this.discount.amount_off;
        }

        if (this.totalPrice < 0) {
            this.totalPrice = 0.00;
        }

    }


}
