import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {PopupSetPayload} from '../PopupSetPayload';

@Component({
  selector: 'app-generic-popup-modal',
  templateUrl: './generic-popup-modal.component.html',
  styleUrls: ['./generic-popup-modal.component.scss']
})

export class GenericPopupModalComponent implements OnInit, PopupSetPayload {
  title = "Generic";
  message = "Generic Message";
  content: SafeHtml = "Generic Content";
  imageSrc = '';
  tag = '';
  constructor(private sanitized: DomSanitizer) { }

  ngOnInit() {
  }
  setPayload(payload): void {
    this.title = payload.title;
    this.message = payload.message;
    this.content = this.sanitized.bypassSecurityTrustHtml(payload.content);
    this.imageSrc = payload.image;
    this.tag = payload.tag;
  }

}
