

<app-popup-base>
    <ng-container>
        <div class="modal-body p-4 center">
            <h1 class="red-text">Error Requesting Access</h1>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <img src="../../../../assets/app-settings/permission-fail-bar.png">
                            <p>Please make sure that the access was not previously blocked.</p>
                        </div>
                        <div class="col-12 m-2">
                            <a href="https://presentr.zendesk.com/hc/en-us" target="_blank" class="text-xs"> Having Issues?
                                Go to our Help Center! </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>
</app-popup-base>
