import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-bi-percentage-data-detail-card',
  templateUrl: './bi-percentage-data-detail-card.component.html',
  styleUrls: ['./bi-percentage-data-detail-card.component.scss']
})
export class BiPercentageDataDetailCardComponent implements OnInit {
  blue: '#0081e6';

  @Input() percentageIn: number = 20;
  @Input() percentageOut: number = 40;
  @Input() inValue: string = "x";
  @Input() outValue: string = "x";
  @Input() size: number = 200;
  @Input() middleValue: number = 2000;
  @Input() middleValueLabel: string = 'TOTAL USERS';
  @Input() innerDetailLabel: string = 'Users who have recorded';
  @Input() outerDetailLabel: string = 'Users who have used the app';

  outerRadius:number = 90;
  innerRadius:number = 70;
  bgInnerRadius:number = 65;
  textSize:number = .4;
  labelTextSize:number = .1;
  strokeWidth:number = 20;
  strokeWidthOut:number = 20;
  perimeter:number= 440;
  perimeterOut:number= 566;
  middlePoint:number = 100;

  constructor() { }

  ngOnInit() {
    this.middlePoint = this.size / 2;
    this.outerRadius = this.size * 90 / 200;
    this.innerRadius = this.size * 70 / 200;
    this.bgInnerRadius = this.size * 65 / 200;
    this.textSize = Math.round(this.size * 40 / 200);
    this.labelTextSize = Math.round(this.size * 12 / 200);
    this.strokeWidth = this.size * 20 / 200;
    this.strokeWidthOut = this.size * 20/ 200;
    this.perimeter = 2 * 3.1416 * this.innerRadius;
    this.perimeterOut = 2 * 3.1416 * this.outerRadius;
  }

  progressNumber() : number{
    return Math.round(this.percentageIn * this.perimeter / 100);
  }
  progressOutNumber() : number{
    return Math.round(this.percentageOut * this.perimeterOut / 100);
  }


}
