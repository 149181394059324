export class User {
    id: number;
    username: string;
    password: string;
    first_name: string;
    last_name: string;
    phone: string;
    email:string;
    profile_image:string;
    permissions: Array<any>;
}
