<!--<button-->
<!--  type="button"-->
<!--  class="close close-modal-button"-->
<!--  aria-label="Close"-->
<!--  (click)="closePopup()"-->
<!--&gt;-->
<!--  <span aria-hidden="true">&times;</span>-->
<!--</button>-->

<div class="flex w-full h-full items-end">

  <app-coach-chat [overrideUserId]="overrideUserId" class="w-full h-full"></app-coach-chat>
<!--    <div class="flex w-full h-full">-->
<!--        <div class="col-md-4 col-sm-5">-->
<!--            <div id='overflow-container'>-->
<!--                <app-coach-scheduler [overrideUserId]="overrideUserId"></app-coach-scheduler>-->
<!--                <app-coach-tasks [overrideUserId]="overrideUserId"></app-coach-tasks>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="w-full h-full">-->
<!--            <app-coach-chat [overrideUserId]="overrideUserId"></app-coach-chat>-->
<!--        </div>-->
<!--    </div>-->
</div>
