import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {VolumeMeterRangeComponent} from '../../learn/games/volume-meter-game/volume-meter-range/volume-meter-range.component';
import {AudioScoreProcessorService, VolumeProviderService, WatsonService} from '../../../classes/services';
import {MediaTestStep} from '../media-test.component';
import {DebugConsole} from '../../../classes/helpers/debug-console';
import {Router} from '@angular/router';

@Component({
  selector: 'app-voice-check',
  templateUrl: './voice-check.component.html',
  styleUrls: ['./voice-check.component.scss']
})
export class VoiceCheckComponent implements OnInit, MediaTestStep, OnDestroy {
    @ViewChild('volumeMeterRange') meterRange: VolumeMeterRangeComponent;

    @Input('stream') stream: MediaStream;
    volumeReading:number;
    successWatson:boolean = false;
    successVolume:boolean = false;

    watsonText: string[] = [];
    words: any[] = [
        {word: 'one', found: false},
        {word: 'two', found: false},
        {word: 'three', found: false},
    ];


    @Output('completed') completed: EventEmitter<any> = new EventEmitter<any>();
    constructor(private volumeService: VolumeProviderService, private watsonService: WatsonService,
                private zone: NgZone, private ngCh: ChangeDetectorRef,
                private router:Router) {
    }

    ngOnInit() {
        this.successWatson = false;
        this.successVolume = false;

        this.volumeService.subscribeForNotification(this, (scope, notification) => scope.volumeReceived(notification.data));
        this.watsonService.subscribeForNotification(this, (scope, notification) => scope.watsonReceived(notification.data));
    }

    watsonReceived(reading) {
        switch (reading.event) {
            case 'recognized':

                if (!this.successWatson) {
                    for (let w of reading.data.alternatives[0].timestamps) {

                        if (this.words.map(x => x.word).indexOf(w[0]) >= 0) {
                            let index = this.words.map(x => x.word).indexOf(w[0]);
                            this.words[index].found = true;

                            if (this.allWordsFound()) {
                                this.successWatson = true;
                                this.submitCompletedVoice();
                            }
                        }
                    }
                    this.ngCh.detectChanges();
                }
                break;
        }
    }

    private allWordsFound(): boolean {
        for (let w of this.words) {
            if (!w.found)
                return false;
        }
        return true;
    }

    submitCompletedVoice(){
      if(this.successWatson && this.successVolume){
          this.finish();
          this.completed.emit();
      }
    }
    volumeReceived(reading){
        this.volumeReading = reading.dbSPL;

        if(reading.dbSPL > AudioScoreProcessorService.MIN_RANGE && reading.dbSPL < AudioScoreProcessorService.MAX_RANGE && !this.successVolume){
            this.successVolume = true;
            this.submitCompletedVoice();
            //this.finish();
        }

        if(this.meterRange)this.meterRange.updateDial(180 * reading.percentage / 100)
    }

    start(){
        this.volumeService.startVolumeStream(this.stream);
        this.watsonService.getWatsonAuthorizationToken().subscribe((resp) => {
            if (resp.success) {
                this.watsonService.startRecognize(resp.response.access_token, this.stream, ['present'], 'en-US_BroadbandModel');
            }
            else{
                DebugConsole.log('token failed');
                alert(resp.response.error);
                this.router.navigate(['/present'])
            }
        });
    }


    finish() {
        this.volumeService.stopVolumeStream();
        this.volumeService.unsubscribeNotification(this);
        this.watsonService.unsubscribeNotification(this);
        this.watsonService.stopRecognize();
    }

    ngOnDestroy(){
        this.finish();
    }

}
