<section class="flex flex-wrap">
    <div class="w-full">
        <app-top-bar></app-top-bar>
    </div>
    <div class="relative container flex-1 p-2 pb-8 mx-auto">
        <router-outlet></router-outlet>
    </div>

    <app-loading></app-loading>
</section>
