import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { User } from "../../models";
import { EventSubscriptionService } from "../core/event-subscription.service";
import { NotificationEvent } from "../../models";
import { Router } from '@angular/router';
import { map } from 'rxjs/operators'

export interface UserLoginModel {
    Email: string;
    Password: string;
}

@Injectable()
export class AuthService {
    constructor(
        private route: Router,
        private apiService: ApiService,
        private notificationService: EventSubscriptionService
    ) {
        if (this.getLoggedInUser()) {
            this.notificationService.subscribeToNotificationEvent(
                "ws_reload_customers",
                this,
                this.handleReloadLicensesWS
            );
        }
    }

    login(username: string, password: string) {
        let userLoginModel = {
            email: username,
            password: password
        };

        return this.apiService
            .post("/auth/login", JSON.stringify(userLoginModel))
            .pipe(
             map(response => {
                // login successful if there's a jwt token in the response
                let responseObj = JSON.parse(JSON.stringify(response));
                let resp = responseObj.response;

                if (resp && resp.access_token) {
                    this.setUser(resp);
                }
                return responseObj;
            })
            )
    }

    licenses(loadInactive: Boolean = false) {
        return this.apiService.get("/user/licenses?all=" + loadInactive).pipe(map(response => {
            let responseObj = JSON.parse(JSON.stringify(response));
            let resp = responseObj.response;

            if (resp) {
                this.setUserLicenses(resp);
                if (resp.length > 0 && this.getLicense()) {
                    const licenseId = this.getLicense().id;
                    // we need to reset the license so it has the most up to date info
                    this.setCurrentLicense(resp.find(x => x.id == licenseId));
                }

                if (resp.length > 0 && !this.getLicense()) {
                    this.setCurrentLicense(resp[0]);
                }

            }
            return responseObj;
        })
        )
    }

    forgotPassword(email: string) {
        return this.apiService
            .post("/user/password/forgot-password", JSON.stringify({ email: email }))
            .pipe(
             map(response => {
                return JSON.parse(JSON.stringify(response));
            })
            )
    }

    resetPasswordWithToken(token: String, id: String, password: String) {
        return this.apiService
            .post("/user/password/reset/update", JSON.stringify({ token, id, password }))
            .pipe(
             map(response => {
                return JSON.parse(JSON.stringify(response));
            })
            )
    }

    passwordTokenDetails(token: String, id: String) {
        return this.apiService
            .post("/user/password/reset", JSON.stringify({ token, id }))
            .pipe(
             map(response => {
                return JSON.parse(JSON.stringify(response));
            })
         )
    }

    logout() {
        // remove user from local storage to log user out
        this.setUser(null);
        this.setCurrentLicense(null);
        this.setUserLicenses(null);

        localStorage.removeItem("coach-remember-me-message-this-session");
        localStorage.removeItem("coach-opened-this-session");
        localStorage.removeItem("adminLoggedInAsUser");

        this.notificationService.unsubscribeToNotificationEvent("ws_reload_customers", this);
    }

    register(user: User, logIn: boolean) {
        return this.apiService.post("/auth/register", user).pipe(map(response => {
            // login successful if there's a jwt token in the response
            let responseObj = JSON.parse(JSON.stringify(response));
            if (logIn && responseObj.success) {
                let resp = responseObj.response;
                if (resp && resp.access_token) {
                    this.setUser(resp);
                }
            }
            return responseObj;
        })
        )
    }

    getToken() {
        let currentUser = JSON.parse(localStorage.getItem("currentUser"));
        if (currentUser && currentUser.access_token) {
            return currentUser.access_token;
        }
        return null;
    }



    forceLogin(toUserId) {

        this.apiService.post("/auth/force-login", { user_id: toUserId }).subscribe(response => {
            // login successful if there's a jwt token in the response
            const responseObj = JSON.parse(JSON.stringify(response)).response;
            this.setUser(responseObj);

            this.licenses(false).subscribe(licResp => {
                localStorage.setItem('adminLoggedInAsUser', 'true');
                alert('Logged in to: ' + responseObj.username);
                this.route.navigate(['/']);
            });

        });


        // alert('Logged in to account!');
    }

    getLoggedInUser() {
        return JSON.parse(localStorage.getItem("currentUser"));
    }

    getLicense() {
        return JSON.parse(localStorage.getItem("currentLicense"));
    }

    getPermissions(): [] {
        const permissions: any = [];
        const license = this.getLicense();

        if (license) {
            license.roles.forEach(role => {
                if(role.permissions) {
                    role.permissions.forEach(permission => {
                        permissions.push(permission.permission);
                    });
                }
            });
        }
        const currentUser = this.getLoggedInUser();

        if (currentUser && currentUser.permissions) {
            currentUser.permissions.forEach(permission => {
                permissions.push(permission.permission);
            });
        }

        return permissions;
    }

    getUserLicenses() {
        const licenses = JSON.parse(localStorage.getItem("licenses"));
        return licenses === null ? [] : licenses;
    }

    getGroupUsers() {
        //Returns the users of the group that this user is in.
        let currentLicense = this.getLicense();
        let currentUser = this.getLoggedInUser();

        if (currentLicense.group.users) {
            //console.log('group:', currentLicense.group);
            return currentLicense.group.users;
        }
        else return [currentUser.id];
    }

    setUser(user) {
        if (user === null) {
            localStorage.removeItem("currentUser");
            this.notificationService.unsubscribeToNotificationEvent("ws_reload_customers", this);

        } else {
            localStorage.setItem("currentUser", JSON.stringify(user));

            this.notificationService.subscribeToNotificationEvent(
                "ws_reload_customers",
                this,
                this.handleReloadLicensesWS
            );

        }

        this.notificationService.sendNotificationEvent(
            new NotificationEvent("current_user_changed", user)
        );

    }

    protected setUserLicenses(licenses) {
        if (licenses === null) {
            localStorage.removeItem("licenses");
        } else {
            localStorage.setItem("licenses", JSON.stringify(licenses));
        }
        this.notificationService.sendNotificationEvent(
            new NotificationEvent("licenses_changed", licenses)
        );
    }

    setCurrentLicense(license) {
        if (license === null) {
            localStorage.removeItem("currentLicense");
        } else {
            localStorage.setItem("currentLicense", JSON.stringify(license));
        }
        this.notificationService.sendNotificationEvent(
            new NotificationEvent("current_license_changed", license)
        );
    }


    handleReloadLicensesWS(scope, data: NotificationEvent) {
        scope.licenses().subscribe();
    }
}
