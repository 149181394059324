import {Injectable} from '@angular/core';
import {EventSubscriptionService} from './event-subscription.service';
import {NotificationEvent} from '../../models';
import {DebugConsole} from '../../helpers/debug-console';

@Injectable()
export class VolumeProviderService {

    static readonly NOTIFY_EVENT = 'VOLUME_NOTIFY_EVENT';
    audioCtx: any;
    analyzer: any;
    gainNode: any;
    scriptProcessor: any;

    count = 0;
    startTime:any;
    private localStartTime:any;

    public notificationFrameFilterCount: number = 0;

    constructor(private notificationService: EventSubscriptionService) {
    }

    subscribeForNotification(scope, callback) {
        this.notificationService.subscribeToNotificationEvent(VolumeProviderService.NOTIFY_EVENT, scope, callback);
    }

    unsubscribeNotification(scope) {
        this.notificationService.unsubscribeToNotificationEvent(VolumeProviderService.NOTIFY_EVENT, scope);
    }

    startVolumeStream(stream) {
const gainValue = localStorage.getItem('gain') || 1;

        // Create an AudioNode from the stream.
        DebugConsole.log('start volume');

        this.audioCtx = new AudioContext();
        // GainNode is to mute/unmute or change the volume gain of the audio stream / microphone
        this.gainNode = this.audioCtx.createGain();
this.gainNode.gain.value = gainValue;
        this.analyzer = this.audioCtx.createAnalyser();
        let micStream = this.audioCtx.createMediaStreamSource(stream);

        this.analyzer.smoothingTimeConstant = 0.8;
        this.analyzer.fftSize = 512;


        this.scriptProcessor = this.audioCtx.createScriptProcessor(512, 1, 1);
        //gainNode.connect(this.scriptProcessor);

        micStream.connect(this.gainNode);
        //micStream.connect(this.analyzer);

        this.gainNode.connect(this.analyzer);
        this.analyzer.connect(this.scriptProcessor);

        this.scriptProcessor.connect(this.audioCtx.destination);

        this.scriptProcessor.onaudioprocess = (event) => {
            this.volumeAudioProcess(event);
        };


        this.startTime = performance.timeOrigin + performance.now();
        this.localStartTime = performance.now();
        this.previousRelativeTime = this.localStartTime;
        //micStream.connect(gainNode);
    }

    previousRelativeTime = 0;
    private volumeAudioProcess(event) {
        // let array = new Uint8Array(this.analyzer.frequencyBinCount);
        // this.analyzer.getByteFrequencyData(array);
        //
        // let values = 0;
        //
        // let length = array.length;
        // for (let i = 0; i < length; i++) {
        // 	values += (array[i]);
        // }
        //
        // let dbSPL =  values / length;
        //let volumeReading = {dbSPL, ticks:performance.timing.navigationStart + performance.now()};

        let buf = event.inputBuffer.getChannelData(0);
        let bufLength = buf.length;
        let sum = 0;
        let x;

        // Do a root-mean-square on the samples: sum up the squares...
        for (let i = 0; i < bufLength; i++) {
            x = buf[i];
            sum += x * x;
        }

        // ... then take the square root of the sum.
        let rms = Math.sqrt(sum / (bufLength));
        let decibel = 20 * (Math.log(rms) / Math.log(10));
        let db = Math.min(decibel, 0);
        let percentage = 100 + (Math.max(-48, db) * 2.083);

        let volumeReading = {
            db: decibel,
            dbSPL: percentage,
            rms: rms,
            percentage: percentage,
            relative_time : performance.now() - this.localStartTime,
            prev_relative_time : this.previousRelativeTime
        };

        this.previousRelativeTime = performance.now() - this.localStartTime;
        //DebugConsole.log(volumeReading);

        if (this.notificationFrameFilterCount == 0) {
            this.notificationService.sendNotificationEvent(new NotificationEvent(VolumeProviderService.NOTIFY_EVENT, volumeReading));
        }
        else {
            this.count++;
            if (this.count % this.notificationFrameFilterCount == 0) {
                this.notificationService.sendNotificationEvent(new NotificationEvent(VolumeProviderService.NOTIFY_EVENT, volumeReading));
            }
        }
    }

    stopVolumeStream() {
        if (this.scriptProcessor) {
            this.scriptProcessor.onaudioprocess = null;
            this.scriptProcessor.disconnect();
        }
    }
}
