import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../services/data/auth.service";

@Injectable()
export class CustomerInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authService: AuthService = this.injector.get(AuthService);
    const license = authService.getLicense();

    if (license && license.id && license.customer && license.customer.id) {
      const changedReq = req.clone({
        headers: req.headers
          .set("customer", "" + license.customer.id)
      });
      return next.handle(changedReq);
    }

    return next.handle(req);
  }
}
