import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {AuthService, EventSubscriptionService} from '../../classes/services';
import {ActivatedRoute} from '@angular/router';
import {NotificationEvent} from '../../classes/models';
import {AppModeEnum} from '../../classes/enums/app-mode.enum';

@Component({
  selector: 'app-app-layout-base',
  templateUrl: './app-layout-base.component.html',
  styleUrls: ['./app-layout-base.component.scss']
})
export class AppLayoutBaseComponent implements OnInit {
    currentUser: any;
    coachHasConnection: Boolean = false;

    constructor(
        protected notificationService: EventSubscriptionService,
        private authService: AuthService,
        private activatedRoute: ActivatedRoute
    ) {
    }

    ngOnInit() {
        this.notificationService.sendNotificationEvent(
            new NotificationEvent('APP_MODE_CHANGED', AppModeEnum.WebApp)
        );

        this.notificationService.subscribeToNotificationEvent(
            'current_user_changed',
            this,
            this.handleUserChange
        );

        this.currentUser = this.authService.getLoggedInUser();
    }

    private handleUserChange(scope, data: NotificationEvent) {
        scope.currentUser = data.data;
    }

    private handleWSConnectionChanged(scope, data: NotificationEvent) {
        scope.coachHasConnection = data.data as Boolean;
    }

}
