import {Component, Input, OnInit} from '@angular/core';
import {PopupSetPayload} from '../PopupSetPayload';

@Component({
  selector: 'app-failure-popup-modal',
  templateUrl: './failure-popup-modal.component.html',
  styleUrls: ['./failure-popup-modal.component.scss']
})
export class FailurePopupModalComponent implements OnInit, PopupSetPayload {
  title = "Error";
  message = "Error Message";
  constructor() { }

  ngOnInit() {
  }

  setPayload(payload): void {
    this.message = payload;
  }
}
