import {Injectable, isDevMode} from '@angular/core';
import {CustomTimer} from '../../helpers/custom-timer';
import {DebugConsole} from '../../helpers/debug-console';
import {SkeletalProviderService} from './skeletal-provider.service';
import {VideoScoreProcessorResult} from '../../models/video-score-processor-result';
import {JOINT_KEY_POINT_ENUM} from '../../enums/joint-key-point.enum';
import {AudioScoreProcessorService} from './audio-score-processor.service';
import {EventSubscriptionService} from './event-subscription.service';
import {NotificationEvent} from '../../models';
import {AwsUploaderService} from './aws-uploader.service';
import {VideoScoreProcessorPoseResult} from '../../models/video-score-processor-pose-result';
import {ResultDataDetail} from '../../models/result-data-detail';


@Injectable()
export class VideoScoreProcessorService {
    skeletalReadings: any = [];
    readingCount: number = 0;
    closedGestureCount: number = 0;
    closedPostureCount: number = 0;
    lastPose: any;
    paused: boolean = false;
    lastPauseTime: any;
    lastResumeTime: any;
    startTime: any;
    endTime: any;

    closedGestureReadings: any = [];
    closedPostureReadings: any = [];
    gestureClosedIndex: number = 0;
    postureClosedIndex: number = 0;

    static readonly VIDEO_POSE_RECEIVED_EVENT = 'vsp_pose_received';

    constructor(private skeletalService: SkeletalProviderService, private notificationService: EventSubscriptionService) {
    }

    private initScoreProcessing() {
        this.lastPose = null;
        this.lastPauseTime = 0;
        this.lastResumeTime = 0;
        this.readingCount = 0;
        this.skeletalReadings = [];
        this.closedGestureReadings = [];
        this.closedPostureReadings = [];
        this.gestureClosedIndex = -1;
        this.postureClosedIndex = -1;
        this.closedGestureCount = 0;
        this.closedPostureCount = 0;

        this.startTime = performance.timeOrigin + performance.now();
        this.endTime = this.startTime;
        this.paused = false;
    }

    subscribeForNotification(scope, callback){
        this.notificationService.subscribeToNotificationEvent(VideoScoreProcessorService.VIDEO_POSE_RECEIVED_EVENT, scope, callback);
    }

    unsubscribeNotification(scope){
        this.notificationService.unsubscribeToNotificationEvent(VideoScoreProcessorService.VIDEO_POSE_RECEIVED_EVENT, scope);
    }

    private subscribeToServices() {
        this.skeletalService.subscribeForNotification(this, (scope, notification) => {
            switch (notification.data.event) {
                case 'recognized':
                    scope.skeletonReading(notification.data.data); // 1 data is the event data, 2 data of the result
                    break;

                case 'error':
                    //DebugConsole.log(notification);
                    // error handling.
                    break;
            }

        });
    }

    startScoreProcessing() {
        DebugConsole.log('start processing');
        this.initScoreProcessing();
        this.subscribeToServices();
    }


    private isValidData(data) : boolean{
        return data.score > SkeletalProviderService.MIN_OVERALL_POSES_CONFIDENCE;
    }

    skeletonReading(data) {
        if (this.paused)
            return;

        if(!this.isValidData(data)){
            return;
        }

        this.skeletalReadings.push(data);
        this.readingCount++;

        // return;
        let pose = data.keypoints;

        let currPose : VideoScoreProcessorPoseResult = {
            leftClosed: false,
            rightClosed: false,
            straight: true
        };


        if (pose[JOINT_KEY_POINT_ENUM.leftShoulder].score > SkeletalProviderService.MIN_POSE_CONFIDENCE && pose[JOINT_KEY_POINT_ENUM.leftWrist].score > SkeletalProviderService.MIN_POSE_CONFIDENCE) {// valid left side
            if (pose[JOINT_KEY_POINT_ENUM.leftShoulder].position.x > pose[JOINT_KEY_POINT_ENUM.leftWrist].position.x) { // left is closed
                currPose.leftClosed = true;
            }
        }

        if (pose[JOINT_KEY_POINT_ENUM.rightShoulder].score > SkeletalProviderService.MIN_POSE_CONFIDENCE && pose[JOINT_KEY_POINT_ENUM.rightWrist].score > SkeletalProviderService.MIN_POSE_CONFIDENCE) {            // valid right side
            if (pose[JOINT_KEY_POINT_ENUM.rightShoulder].position.x < pose[JOINT_KEY_POINT_ENUM.rightWrist].position.x) { // left is closed
                currPose.rightClosed = true;
            }
        }

        if (pose[JOINT_KEY_POINT_ENUM.leftShoulder].score > SkeletalProviderService.MIN_POSE_CONFIDENCE && pose[JOINT_KEY_POINT_ENUM.rightShoulder].score > SkeletalProviderService.MIN_POSE_CONFIDENCE) {
            // valid posture
            let facingAudience = pose[JOINT_KEY_POINT_ENUM.leftEar].score > SkeletalProviderService.MIN_POSE_CONFIDENCE && pose[JOINT_KEY_POINT_ENUM.rightEar].score > SkeletalProviderService.MIN_POSE_CONFIDENCE;
            currPose.straight = Math.abs((pose[JOINT_KEY_POINT_ENUM.leftShoulder].position.y - pose[JOINT_KEY_POINT_ENUM.rightShoulder].position.y) /
                (pose[JOINT_KEY_POINT_ENUM.leftShoulder].position.x - pose[JOINT_KEY_POINT_ENUM.rightShoulder].position.x)) < SkeletalProviderService.MAX_POSTURE_SHOULDER_SLOPE;
        }

        if (this.lastPose) {

            if (!currPose.straight) { // if we are on a negative pose.
                this.closedPostureCount++;
                if (this.lastPose.straight) {// and we were straight before, start a frequency instance
                    this.postureClosedIndex++;
                }
                if (!this.closedPostureReadings[this.postureClosedIndex])
                    this.closedPostureReadings[this.postureClosedIndex] = [];

                this.closedPostureReadings[this.postureClosedIndex].push(data);
            }

            if (currPose.rightClosed || currPose.leftClosed) { // if we are closed gesture
                this.closedGestureCount++;
                if (!(this.lastPose.rightClosed || this.lastPose.leftClosed)) { // and we were not closed before, start a frequency instance
                    this.gestureClosedIndex++;
                }
                if (!this.closedGestureReadings[this.gestureClosedIndex])
                    this.closedGestureReadings[this.gestureClosedIndex] = [];
                this.closedGestureReadings[this.gestureClosedIndex].push(data);
            }
        }
        this.lastPose = currPose;
        this.notificationService.sendNotificationEvent(new NotificationEvent(VideoScoreProcessorService.VIDEO_POSE_RECEIVED_EVENT, currPose));

    }

    stopScoreProcessing() {
        this.endTime = performance.timeOrigin + performance.now();
        // if (this.customTimer != null) {
        //     this.customTimer.stop();
        // }
        this.skeletalService.unsubscribeNotification(this);
        // do not unsubscribe to Watson. it will happen when its closed
    }

    static MinimumSkeletalReadings = 10;

    validateMinimumDataRequirements(): boolean {
        return this.skeletalReadings.length > VideoScoreProcessorService.MinimumSkeletalReadings;
    }

    minimumDataRequirementsMessage() : string{
        return 'in front of the camera';
        // return `We require a minimum of ${ VideoScoreProcessorService.MinimumSkeletalReadings * SkeletalProviderService.POSE_NET_TIME_INTERVAL / 1000 } seconds of valid skeletal data.`;
    }

    calculateResult(): VideoScoreProcessorResult {
        let time = (this.endTime - this.startTime) / 1000; // to make it in seconds

        /// simple calculations.
        // count closed / total count.
        let gesture_overall = 0;
        let posture_overall = 0;
        if (this.readingCount > 0) {
            gesture_overall = 100 - Math.min(100, Math.round(this.closedGestureCount * 100 / this.readingCount));
            posture_overall = 100 - Math.min(100, Math.round(this.closedPostureCount * 100 / this.readingCount));
        }

        DebugConsole.log('VIDEO COUNTS', this.closedPostureCount, this.closedGestureCount, this.readingCount);
        DebugConsole.log('VIDEO Gesture Closed Readings', this.closedGestureReadings);
        DebugConsole.log('VIDEO Posture Closed Readings', this.closedPostureReadings);

        let overall_score = Math.round(0.5 * gesture_overall + 0.5 * posture_overall);
        // $pose['pose_name'], 'description' => $pose['pose_time'],
        //     'value' => $pose['pose_count'], 'note' => $pose['pose_note'

        let gestureDetails = [{pose_name:"Closed", pose_time:this.closedGestureCount * 0.5, pose_count:this.closedGestureReadings.length, pose_note:"" }];
        let postureDetails = [{pose_name:"Closed", pose_time:this.closedPostureCount * 0.5, pose_count:this.closedPostureReadings.length, pose_note:"" }];


        return {
            time: Math.round(time),
            overall_score,
            gesture_overall_score: gesture_overall,
            posture_overall_score: posture_overall,
            gesture_score_details: gestureDetails,
            posture_score_details: postureDetails,
            skeletal_count: this.readingCount,
            skeletal_interval: SkeletalProviderService.POSE_NET_TIME_INTERVAL,
            closed_posture_freq: this.closedPostureReadings.length,
            closed_gesture_freq: this.closedGestureReadings.length,
            closed_gestures: this.closedGestureCount,
            closed_postures: this.closedPostureCount
        };
    }
    public getResultDetailData() :ResultDataDetail[] {
        return [
            new ResultDataDetail("posture_poses", this.closedPostureReadings),
            new ResultDataDetail("gesture_poses", this.closedGestureReadings),
        ];
    }

    pauseScoreProcessor() {
        this.paused = true;
        this.lastPauseTime = performance.timeOrigin + performance.now() - this.startTime;
    }

    resumeScoreProcessor() {
        this.paused = false;
        this.lastResumeTime = performance.timeOrigin + performance.now() - this.startTime;
    }
}
