import {Injectable, isDevMode} from '@angular/core';
import {VolumeProviderService} from './volume-provider.service';
import {WatsonService} from './watson.service';
import {WordManagementService} from '../data/word-management.service';
import {environment} from '../../../../environments/environment';
import {DebugConsole} from '../../helpers/debug-console';
import {AudioScoreProcessorResult} from '../../models/audio-score-processor-result';
import {EventSubscriptionService} from './event-subscription.service';
import {NotificationEvent} from '../../models';
import {ResultDataDetail} from '../../models/result-data-detail';

@Injectable()
export class AudioScoreProcessorService {

    static MIN_RANGE = 30;
    static MAX_RANGE = 90;
    public static readonly MinimumValidWords = 2;
    public static readonly MinimumRecordingTime = 30;
    public static readonly MinimumDevRecordingTime = 2;

    static readonly WORD_RECEIVED_EVENT = 'asp_word_received_event';
    static readonly VOLUME_RECEIVED_EVENT = 'asp_volume_received_event';

    volumeReading: any = [];
    //watsonReadings :any = [];
    finalWatsonReadings: any = [];
    tempWatsonReading:any;
    transcript: string;
    word_data: any = [];

    paused: boolean = false;
    lastPauseTime: any;
    lastResumeTime: any;
    words: any = {
        powerWords: [],
        fillerWords: []
    };
    startTime: any;
    //watsonStartTime: any;
    endTime: any;

    constructor(private watsonService: WatsonService,
                private volumeService: VolumeProviderService,
                private wordService: WordManagementService,
                private notificationService: EventSubscriptionService) {
    }

    private initScoreProcessing() {
        this.volumeReading = [];
        this.transcript = '';
        this.lastPauseTime = 0;
        this.lastResumeTime = 0;
        //this.watsonReadings = [];
        this.finalWatsonReadings = [];
        this.paused = false;
    }

    private subscribeToServices() {
        this.volumeService.subscribeForNotification(this, (scope, notification) => {
            scope.volumeReadingReceived(notification.data);
        });
        this.watsonService.subscribeForNotification(this, (scope, notification) => {
            switch (notification.data.event) {
                case 'recognized':
                    scope.watsonReadingReceived(notification.data.data); // 1 data is the event data, 2 data of the result
                    break;

                case 'error':
                    //DebugConsole.log(notification);
                    // error handling.
                    scope.watsonService.unsubscribeNotification(scope);
                    break;

                case 'close':
                    //DebugConsole.log("closed watson score processor");
                    //DebugConsole.log(notification);
                    scope.watsonService.unsubscribeNotification(scope);
                    break;

                case 'message':
                    // debug purpose
                    // DebugConsole.log("message");
                    //DebugConsole.log(notification);
                    break;

                case 'stop':
                    //DebugConsole.log("stopped watson score processor");
                    //DebugConsole.log(notification);
                    // new data is no longer being processed. the stream stopped
                    break;

                case 'open':
                    // in theory this is where watson connected
                    //DebugConsole.log("watson connection opened");
                    //DebugConsole.log(notification);
                    break;

                case 'listening':
                    // in theory this is where watson connected
                    //DebugConsole.log("watson is listening now");
                    //DebugConsole.log(notification);
                    break;
            }

        });
    }

    subscribeForWordNotification(scope, callback){
        this.notificationService.subscribeToNotificationEvent(AudioScoreProcessorService.WORD_RECEIVED_EVENT, scope, callback);
    }

    unsubscribeWordNotification(scope){
        this.notificationService.unsubscribeToNotificationEvent(AudioScoreProcessorService.WORD_RECEIVED_EVENT, scope);
    }

    subscribeForVolumeNotification(scope, callback){
        this.notificationService.subscribeToNotificationEvent(AudioScoreProcessorService.VOLUME_RECEIVED_EVENT, scope, callback);
    }

    unsubscribeVolumeNotification(scope){
        this.notificationService.unsubscribeToNotificationEvent(AudioScoreProcessorService.VOLUME_RECEIVED_EVENT, scope);
    }


    startScoreProcessing() {
        DebugConsole.log('start processing');
        this.initScoreProcessing();
        DebugConsole.log("TIME START", performance.timeOrigin, performance.now());

        this.startTime = performance.timeOrigin + performance.now();
        // this.endTime = this.startTime;
        this.wordService.trackedWords().subscribe(resp => {

            if (resp.success) {
                this.words.fillerWords = resp.response.filler_words;
                this.words.powerWords = resp.response.power_words;
            }
        });
        this.subscribeToServices();
    }

    minimumDataRequirementsMessage() : string{
        return `for at least ${ isDevMode() ? AudioScoreProcessorService.MinimumDevRecordingTime : AudioScoreProcessorService.MinimumRecordingTime} seconds and say at least ${AudioScoreProcessorService.MinimumValidWords} words.`;
    }

    tryToGetWordsWithinPauseRange(alternative) {
        let tempData = [];
        let diffBetweenWatsonAnScoreStart = this.volumeService.startTime - this.watsonService.openTime;
        let finalizePause = false;
        for (let word of alternative.timestamps) {

            if (this.paused) {
                let wEndTime = word[2] * 1000;
                DebugConsole.log(this.lastPauseTime, wEndTime - diffBetweenWatsonAnScoreStart, wEndTime, diffBetweenWatsonAnScoreStart);
                if (this.lastPauseTime > wEndTime - diffBetweenWatsonAnScoreStart) {
                    DebugConsole.log('should add', word);
                    // maybe ad this words to a temp data?
                    tempData.push(word);
                }
                else {
                    //DebugConsole.log("SKIPPED THE REST OF THE WORDS",word, alternative);
                    // means that all other words started after the last pause time
                    break;
                }
            }
            else {
                // this probably would be better to do back to front so we can skip the rest of the words.
                let wStartTime = word[1] * 1000;
                DebugConsole.log(this.lastResumeTime, wStartTime - diffBetweenWatsonAnScoreStart, wStartTime, diffBetweenWatsonAnScoreStart);
                if (this.lastResumeTime < wStartTime - diffBetweenWatsonAnScoreStart) {
                    // maybe ad this words to a temp data?
                    DebugConsole.log('added word', word);
                    tempData.push(word);
                    finalizePause = true;
                }
                else {
                    //DebugConsole.log('PAUSE skipped add', word);
                }
            }
        }
        if (finalizePause) {
            this.lastPauseTime = 0;
            this.lastResumeTime = 0;
        }
        alternative.transcript = tempData.map(x => x[0]).join(' ');
        alternative.timestamps = tempData;
        return alternative;
    }

    private evaluateWord(data){

        if (data && data.final && data.alternatives.length > 0) {

            let diffToOpen = Math.abs(this.volumeService.startTime - this.watsonService.openTime);
            let diffToListen = Math.abs(this.volumeService.startTime - this.watsonService.listenTime);

            for (let alternative of data.alternatives) {
                for (let word of alternative.timestamps) {

                        let wEndTime = word[2] * 1000;
                        let wStartTime = word[1] * 1000;
                }

            }

        }
    }

    watsonReadingReceived(data) {
        // TODO: we need to make sure to include the words in between the time the time pause is clicked and the word recognition.


        // this.evaluateWord(data);
        if (data && data.final && data.alternatives.length > 0) {

            this.tempWatsonReading = null;
            if (this.lastResumeTime || this.lastPauseTime) {
                // User paused at some point, so now we have to check if the words should be included.
                for (let alternative of data.alternatives) {
                    alternative = this.tryToGetWordsWithinPauseRange(alternative);
                }
            }

            //scope.watsonReadings.push(data);
            this.notificationService.sendNotificationEvent(new NotificationEvent(AudioScoreProcessorService.WORD_RECEIVED_EVENT, data));
            this.finalWatsonReadings.push(data);

        }
        else if (data && data.alternatives.length > 0){
            this.tempWatsonReading = data;

            // this.notificationService.sendNotificationEvent(new NotificationEvent(AudioScoreProcessorService.WORD_RECEIVED_EVENT, data));
        }
    }

    volumeReadingReceived(data) {
        if (this.paused)
            return;

        if (data.dbSPL < 10)
            return;

        // data.relative_time = data.ticks - this.startTime;

        this.notificationService.sendNotificationEvent(new NotificationEvent(AudioScoreProcessorService.VOLUME_RECEIVED_EVENT, data));
        this.volumeReading.push(data);
    }

    stopScoreProcessing() {
        this.endTime = performance.timeOrigin + performance.now();
        this.volumeService.unsubscribeNotification(this);
        // do not unsubscribe to Watson. it will happen when its closed
    }

    validateMinimumDataRequirements(): boolean {

        let endTime = performance.timeOrigin + performance.now();
        if(endTime - this.startTime <  (isDevMode() ? AudioScoreProcessorService.MinimumDevRecordingTime : AudioScoreProcessorService.MinimumRecordingTime )){
            return false;
        }

        let count = 0;
        for (let reading of this.finalWatsonReadings) {
            for (let alternative of reading.alternatives) {
                count += alternative.timestamps.length;
                if (count >= AudioScoreProcessorService.MinimumValidWords) {
                    return true;
                }
            }
        }

        return false;
    }

    phraseCount(phrase, transcript): number {
        let text = transcript;
        let count = 0;
        let index = 0;
        do {
            index = text.indexOf(phrase);
            if (index >= 0) {
                count++;
                text = text.slice(index + phrase.length);
            }
        }
        while (index >= 0);
        return count;
    }

    private word_volumeReading = [];
    calculateResult(): AudioScoreProcessorResult {
        this.word_volumeReading = [];
        this.transcript = "";
        let wordCount = 0;
        let time = (this.endTime - this.startTime) / 1000; // to make it in seconds
        let totalVolume = 0;
        let totalVolumeInRange = 0;
        let totalVolumeReadings = 0;
        let totalVolumeUnderRange = 0;
        let totalVolumeOverRange = 0;

        let fillerCount = 0;
        let powerCount = 0;

        let fillerWords = [];
        let powerWords = [];
        /// THIS IS THE TROUBLE SPOT
        this.words.powerWords.map((x) => {
            powerWords[x] = 0;
        });
        this.words.fillerWords.map((x) => {
            fillerWords[x] = 0;
        });

        let fillerPhrases = [];
        let powerPhrases = [];
        for (let w in fillerWords) {
            if (w.split(' ').length > 1) {
                fillerPhrases.push(w);
            }
        }
        for (let w in powerWords) {
            if (w.split(' ').length > 1) {
                powerPhrases.push(w);
            }
        }

        let words = {};
        let diffBetweenWatsonAnScoreStart = this.volumeService.startTime - this.watsonService.openTime;

        //

        let maxVolume = 0;
        let minVolume = 1000;


        let lastVolumeIndex = 0;
        for (let reading of this.finalWatsonReadings) {

            for (let alternative of reading.alternatives) {
                wordCount += alternative.timestamps.length;
                this.transcript += alternative.transcript;

                // figure out if its a phrase
                for (let p of fillerPhrases) {
                    let c = this.phraseCount(p, alternative.transcript);
                    if (c > 0) {
                        fillerCount++;
                        if (fillerWords[p] == undefined || fillerWords[p] == 0) {
                            fillerWords[p] = c;
                        }
                        else {
                            fillerWords[p] = fillerWords[p] + c;
                        }
                    }
                }

                for (let p of powerPhrases) {
                    let c = this.phraseCount(p, alternative.transcript);
                    if (c > 0) {
                        powerCount++;
                        if (powerWords[p] == undefined || powerWords[p] == 0) {
                            powerWords[p] = c;
                        }
                        else {
                            powerWords[p] = powerWords[p] + c;
                        }
                    }
                }

                for (let word of alternative.timestamps) {

                    let w = word[0].toLowerCase();
                    if (word[0] == '%HESITATION') {
                        w = environment.hesitationWord;
                    }

                    if (fillerWords.hasOwnProperty(w)) {
                        fillerWords[w]++;
                        fillerCount++;
                    }
                    else if (powerWords.hasOwnProperty(w)) {
                        powerWords[w]++;
                        powerCount++;
                    }
                    else {
                        if (w in words) {
                            words[w]++;
                        }
                        else {
                            words[w] = 1;
                        }
                    }

                    let wordVolume = [];

                    for (; lastVolumeIndex < this.volumeReading.length; lastVolumeIndex++) {
                        //{db: -Infinity, rms: 0, percentage: 0.015999999999991132, ticks: 1523558825267.5, relative_time: 23007.10009765625}
                        // timestamps:Array(3)
                        // 	["hello", 11.19, 11.53]
                        // 	["testing", 11.53, 12.06]
                        // 	["testing", 12.06, 12.59]
                        let endTime = word[2] * 1000;
                        let startTime = word[1] * 1000;

                        if (this.volumeReading[lastVolumeIndex].relative_time > endTime - diffBetweenWatsonAnScoreStart) // we are no longer in the word
                            break;

                        if (this.volumeReading[lastVolumeIndex].relative_time > startTime - diffBetweenWatsonAnScoreStart) {
                            //

                            if (maxVolume < this.volumeReading[lastVolumeIndex].dbSPL) {
                                maxVolume = this.volumeReading[lastVolumeIndex].dbSPL;
                            }
                            if (minVolume > this.volumeReading[lastVolumeIndex].dbSPL) {
                                minVolume = this.volumeReading[lastVolumeIndex].dbSPL;
                            }

                            wordVolume.push(this.volumeReading[lastVolumeIndex]);
                            totalVolumeReadings++;
                            totalVolume += this.volumeReading[lastVolumeIndex].dbSPL;

                            if (this.volumeReading[lastVolumeIndex].dbSPL >= AudioScoreProcessorService.MIN_RANGE && this.volumeReading[lastVolumeIndex].dbSPL <= AudioScoreProcessorService.MAX_RANGE) {
                                totalVolumeInRange++;
                            }
                            else if (this.volumeReading[lastVolumeIndex].dbSPL < AudioScoreProcessorService.MIN_RANGE) {
                                totalVolumeUnderRange++;
                            }
                            else if (this.volumeReading[lastVolumeIndex].dbSPL > AudioScoreProcessorService.MAX_RANGE) {
                                totalVolumeOverRange++;
                            }
                        } // now we are in the word

                    }

                    let w_avg = 0;
                    let p_avg = 0;
                    for (let w of wordVolume) {
                        w_avg += w.dbSPL;
                        p_avg += w.percentage;
                    }
                    let relativeWord = [
                        word[0],
                        word[1]*1000 - diffBetweenWatsonAnScoreStart,
                        word[2]*1000 - diffBetweenWatsonAnScoreStart
                    ];
                    this.word_volumeReading.push({word:relativeWord, wordVolume, pAvg: p_avg / wordVolume.length, dbAvg: w_avg / wordVolume.length});
                }
            }
        }

        let wpm = wordCount * 60 / time;

        let volume_score = this.doVolumeScore(totalVolumeInRange, totalVolumeReadings);
        let pace_score = this.doPaceScore(time, wordCount);
        let filler_word_score = this.doFillerScore(time, fillerCount, wordCount);
        let power_word_score = this.doPowerScore(time, powerCount, wordCount);
        let filler_power_word_score = Math.abs(filler_word_score - power_word_score);

        //Volume Score Calc
        let overall_score = Math.round((pace_score * .25) + (filler_power_word_score * .25) + (volume_score * .5));

        // avgBaseline
        // low Volume
        // highVolume
        // medianVolume
        // avgVolume
        // diffInVolumeRange
        // totalWords
        // avgPace

        return {
            time: Math.round(time),
            wordCount,
            wpm,
            maxVolume,
            minVolume,
            totalVolume,
            readingInRange: totalVolumeInRange,
            totalVolumeReadings,
            avg_volume: (totalVolume / totalVolumeReadings),
            sweet_zone: volume_score,
            fillerWords,
            powerWords,
            words,
            volume_score,
            pace_score,
            filler_word_score,
            power_word_score,
            filler_power_word_score,
            overall_score,
            transcript: this.transcript,
            word_data: this.word_data
        };
    }

    calculatePartialResult(timeAccumulationMs = 15000) {
        let word_volumeReading = [];
        let wordCount = 0;
        let endTime = performance.timeOrigin + performance.now();
        let time = (endTime - this.startTime) / 1000;

        let timeStamp = endTime - this.startTime;

        let totalVolume = 0;
        let totalVolumeInRange = 0;
        let totalVolumeUnderRange = 0;
        let totalVolumeOverRange = 0;
        let totalVolumeReadings = 0;

        let fillerCount = 0;
        let fillerWords = [];
        let powerCount = 0;
        let powerWords = [];
        if(this.words && this.words.fillerWords){
            this.words.fillerWords.map((x) => {
                fillerWords[x] = 0;
            });
        }

        let fillerPhrases = [];
        for (let w in fillerWords) {
            if (w.split(' ').length > 1) {
                fillerPhrases.push(w);
            }
        }

        let powerPhrases = [];
        for (let w in powerWords) {
            if (w.split(' ').length > 1) {
                powerPhrases.push(w);
            }
        }

        let diffBetweenWatsonAnScoreStart = this.volumeService.startTime - this.watsonService.openTime;

        let maxVolume = 0;
        let minVolume = 1000;

        let volumeIndex = this.volumeReading.length - 1;
        if(this.tempWatsonReading){

            for (let altIndex = this.tempWatsonReading.alternatives.length - 1; altIndex >= 0; altIndex--) {
                let alternative = this.tempWatsonReading.alternatives[altIndex];

                for (let p of fillerPhrases) {
                    let c = this.phraseCount(p, alternative.transcript);
                    if (c > 0) {
                        fillerCount++;
                    }
                }

                for (let p of powerPhrases) {
                    let c = this.phraseCount(p, alternative.transcript);
                    if (c > 0) {
                        powerCount++;
                    }
                }

                for (let wordIndex = alternative.timestamps.length - 1; wordIndex >= 0; wordIndex--) {
                    let wordData = alternative.timestamps[wordIndex];
                    let wStartTime = wordData[1] * 1000;
                    let wEndTime = wordData[2] * 1000;

                    if (wStartTime - diffBetweenWatsonAnScoreStart < timeStamp - timeAccumulationMs) {
                        // stop processing scores. we should not have any more words inside the range
                        // because we are going back to front on an ordered structure
                        wordIndex = -1;
                        altIndex = -1;
                        break;
                    }

                    wordCount++;
                    let w = wordData[0].toLowerCase();
                    if (wordData[0] == '%HESITATION') {
                        w = environment.hesitationWord;
                    }

                    if (fillerWords.hasOwnProperty(w)) {
                        fillerWords[w]++;
                        fillerCount++;
                    }

                    if (powerWords.hasOwnProperty(w)) {
                        powerWords[w]++;
                        powerCount++;
                    }

                    let wordVolume = [];

                    // volume index can go down a single time, no word should overlap, therefore we do not need to worry about this.
                    for (; volumeIndex >= 0; volumeIndex--) {
                        if (this.volumeReading[volumeIndex].relative_time < wStartTime - diffBetweenWatsonAnScoreStart) // we are no longer in the word
                            break;

                        if (this.volumeReading[volumeIndex].relative_time <= wEndTime - diffBetweenWatsonAnScoreStart) {
                            if (maxVolume < this.volumeReading[volumeIndex].dbSPL) {
                                maxVolume = this.volumeReading[volumeIndex].dbSPL;
                            }
                            if (minVolume > this.volumeReading[volumeIndex].dbSPL) {
                                minVolume = this.volumeReading[volumeIndex].dbSPL;
                            }

                            wordVolume.push(this.volumeReading[volumeIndex]);
                            totalVolumeReadings++;
                            totalVolume += this.volumeReading[volumeIndex].dbSPL;

                            if (this.volumeReading[volumeIndex].dbSPL >= AudioScoreProcessorService.MIN_RANGE && this.volumeReading[volumeIndex].dbSPL <= AudioScoreProcessorService.MAX_RANGE) {
                                totalVolumeInRange++;
                            }
                            else if (this.volumeReading[volumeIndex].dbSPL < AudioScoreProcessorService.MIN_RANGE) {
                                totalVolumeUnderRange++;
                            }
                            else if (this.volumeReading[volumeIndex].dbSPL > AudioScoreProcessorService.MAX_RANGE) {
                                totalVolumeOverRange++;
                            }
                        } // now we are in the word
                    }
                    let w_avg = 0;
                    let p_avg = 0;
                    for (let w of wordVolume) {
                        w_avg += w.dbSPL;
                        p_avg += w.percentage;
                    }

                    word_volumeReading.push({wordData, wordVolume, pAvg: p_avg / wordVolume.length, dbAvg: w_avg / wordVolume.length});
                }
            }
        }


        for (let readingIndex = this.finalWatsonReadings.length - 1; readingIndex >= 0; readingIndex--) {
            let reading = this.finalWatsonReadings[readingIndex];
            for (let altIndex = reading.alternatives.length - 1; altIndex >= 0; altIndex--) {
                let alternative = reading.alternatives[altIndex];

                for (let p of fillerPhrases) {
                    let c = this.phraseCount(p, alternative.transcript);
                    if (c > 0) {
                        fillerCount++;
                    }
                }

                for (let p of powerPhrases) {
                    let c = this.phraseCount(p, alternative.transcript);
                    if (c > 0) {
                        powerCount++;
                    }
                }

                for (let wordIndex = alternative.timestamps.length - 1; wordIndex >= 0; wordIndex--) {
                    let wordData = alternative.timestamps[wordIndex];
                    let wStartTime = wordData[1] * 1000;
                    let wEndTime = wordData[2] * 1000;

                    if (wStartTime - diffBetweenWatsonAnScoreStart < timeStamp - timeAccumulationMs) {
                        // stop processing scores. we should not have any more words inside the range
                        // because we are going back to front on an ordered structure
                        wordIndex = -1;
                        altIndex = -1;
                        readingIndex = -1;
                        break;
                    }

                    wordCount++;
                    let w = wordData[0].toLowerCase();
                    if (wordData[0] == '%HESITATION') {
                        w = environment.hesitationWord;
                    }

                    if (fillerWords.hasOwnProperty(w)) {
                        fillerWords[w]++;
                        fillerCount++;
                    }

                    if (powerWords.hasOwnProperty(w)) {
                        powerWords[w]++;
                        powerCount++;
                    }

                    let wordVolume = [];

                    // volume index can go down a single time, no word should overlap, therefore we do not need to worry about this.
                    for (; volumeIndex >= 0; volumeIndex--) {
                        if (this.volumeReading[volumeIndex].relative_time < wStartTime - diffBetweenWatsonAnScoreStart) // we are no longer in the word
                            break;

                        if (this.volumeReading[volumeIndex].relative_time <= wEndTime - diffBetweenWatsonAnScoreStart) {
                            if (maxVolume < this.volumeReading[volumeIndex].dbSPL) {
                                maxVolume = this.volumeReading[volumeIndex].dbSPL;
                            }
                            if (minVolume > this.volumeReading[volumeIndex].dbSPL) {
                                minVolume = this.volumeReading[volumeIndex].dbSPL;
                            }

                            wordVolume.push(this.volumeReading[volumeIndex]);
                            totalVolumeReadings++;
                            totalVolume += this.volumeReading[volumeIndex].dbSPL;

                            if (this.volumeReading[volumeIndex].dbSPL >= AudioScoreProcessorService.MIN_RANGE && this.volumeReading[volumeIndex].dbSPL <= AudioScoreProcessorService.MAX_RANGE) {
                                totalVolumeInRange++;
                            }
                            else if (this.volumeReading[volumeIndex].dbSPL < AudioScoreProcessorService.MIN_RANGE) {
                                totalVolumeUnderRange++;
                            }
                            else if (this.volumeReading[volumeIndex].dbSPL > AudioScoreProcessorService.MAX_RANGE) {
                                totalVolumeOverRange++;
                            }
                        } // now we are in the word
                    }
                    let w_avg = 0;
                    let p_avg = 0;
                    for (let w of wordVolume) {
                        w_avg += w.dbSPL;
                        p_avg += w.percentage;
                    }

                    word_volumeReading.push({wordData, wordVolume, pAvg: p_avg / wordVolume.length, dbAvg: w_avg / wordVolume.length});
                }
            }
        }

        let scoringTime = Math.min(time, timeAccumulationMs / 1000);
        let wpm = wordCount * 60 / scoringTime;

        let volume_score = this.doVolumeScore(totalVolumeInRange, totalVolumeReadings);
        let balance_volume_score = this.doBalancedVolumeScore(totalVolumeInRange, totalVolumeUnderRange, totalVolumeOverRange, totalVolumeReadings);
        let pace_score = this.doPaceScore(scoringTime, wordCount);
        let balance_pace_score = this.doPaceScore(scoringTime, wordCount, true);
        let filler_word_score = this.doFillerScore(scoringTime, fillerCount, wordCount);
        let power_word_score = this.doPowerScore(scoringTime, powerCount, wordCount);
        let filler_power_word_score = Math.abs(filler_word_score - power_word_score);

        let overall_score = Math.round((pace_score * .25) + (filler_power_word_score * .25) + (volume_score * .5));

        // avgBaseline
        // low Volume
        // hihgVolume
        // medianVolume
        // avgVolume
        // diffInVolumeRange
        // totalWords
        // avgPace

        let resultData = {
            time: Math.round(time),
            scoringTime,
            wordCount,
            wpm,
            maxVolume,
            minVolume,
            totalVolume,
            readingInRange: totalVolumeInRange,
            totalVolumeReadings,
            avg_volume: (totalVolume / totalVolumeReadings),
            sweet_zone: volume_score,
            fillerWords,
            powerWords,
            volume_score,
            balance_volume_score,
            pace_score,
            balance_pace_score,
            filler_word_score,
            power_word_score,
            filler_power_word_score,
            overall_score
        };

        // DebugConsole.log(resultData);
        return resultData;
    }

    public getResultDetailData() : ResultDataDetail[] {
        return [
            new ResultDataDetail("volume", this.volumeReading),
            new ResultDataDetail("word_volume", this.word_volumeReading),
            new ResultDataDetail("words", {
                word:this.finalWatsonReadings,
                startTimeDiff: (this.volumeService.startTime - this.watsonService.startTime),
                openTimeDiff: (this.volumeService.startTime - this.watsonService.openTime),
                listenTimeDiff: (this.volumeService.startTime - this.watsonService.listenTime)
            }),
        ];
    }

    doPaceScore(time, wordCount, balanced = false) {
        let expected = time * 2.2;
        if (expected == 0)
            return 0;

        let score = wordCount * 100 / expected;

        if (!balanced && score > 100) {
            score = (100 - score) + 100;
        }
        else if (balanced) {
            score = score * 0.5;
        }

        return Math.round(Math.max(0, Math.min(100, score)));
    }

    doVolumeScore(inRangeReadings, totalReadings) {
        if (totalReadings == 0)
            return 0;
        return Math.round(100 * inRangeReadings / totalReadings);
    }

    doBalancedVolumeScore(inRangeReadings, underRangeReadings, overRangeReadings, totalReadings) {
        if (totalReadings == 0)
            return 0;

        let score = 100 * inRangeReadings / totalReadings;
        // score is 0 - 100.
        score = score * 0.5;

        if (overRangeReadings > underRangeReadings) {
            // we are on the high side (right side)
            score = 100 - score;
        }

        return Math.round(score);
    }

    doFillerScore(time, fillerCount, wordCount) {
        let wpm = time * 0.333333333;
        if (wpm == 0)
            return 0;
        let score = (1 - fillerCount / wpm) * 100;
        return Math.round(Math.max(0, score));
    }

    doPowerScore(time, powerCount, wordCount) {
        let wpm = time * 0.333333333;
        if (wpm == 0)
            return 0;
        let score = (1 - powerCount / wpm) * 100;
        return Math.round(Math.max(0, score));
    }
    pauseScoreProcessor() {
        this.paused = true;
        this.lastPauseTime = performance.now() - this.startTime;
        // this.customTimer.pause();
    }

    resumeScoreProcessor() {
        this.paused = false;
        this.lastResumeTime = performance.now() - this.startTime;
        // this.customTimer.resume();
    }

}
