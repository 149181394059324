
<app-popup-base>
    <ng-container>
        <div class="modal-body container">
            <div class="p-4 text-center">
                <h2 class="text-blue">{{title}}</h2>
                <span *ngIf="text">{{text}}</span>
                <ng-container *ngIf="elementRef" [ngTemplateOutlet]="elementRef"></ng-container>
            </div>
            <div class="flex justify-end w-full">
                <button class="button-white float-right" (click)="cancel()">Cancel</button>
                <button class="button-blue float-right" (click)="submit()">Confirm</button>
            </div>
        </div>
    </ng-container>
</app-popup-base>
