import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConfirmationPopupModalComponent} from '../../popup-manager/confirmation-popup-modal/confirmation-popup-modal.component';
import {environment} from '../../../../environments/environment';
import { StripeService } from 'ngx-stripe';
import { StripeElement as StripeElement, StripeElements, StripeElementsOptions, StripeCardElement } from '@stripe/stripe-js'
import {ApiService, AuthService, CustomerService, LoadingService, PingsService} from '../../../classes/services';
import {Router} from '@angular/router';
import {PopupNotificationService} from '../../../classes/services/core/popup-notification.service';
import {HasPermissionPipe} from '../../../classes/pipes/has-permission.pipe';

@Component({
    selector: 'app-subscription-trial-purchase-form',
    templateUrl: './subscription-trial-purchase-form.component.html',
    styleUrls: ['./subscription-trial-purchase-form.component.scss']
})
export class SubscriptionTrialPurchaseFormComponent implements OnInit {

    public static PlanOptions = [
        {
            name: 'Freemium',
            description: 'All the basics to allow you to experience the Presentr app.',
            features:[],
            type: 0,
            price_monthly: 0,
            price_yearly: 0,
            purchasing_for: null
        },
        {
            name: 'Single User',
            description: '',
            features:['Record', 'Scores and Analytics', 'Tips For Improvement', 'Practice with real time feedback', 'Coaching from AI Coach, Abbie', 'Access to games and content library'],
            type: 1,
            price_monthly: 9,
            price_yearly: 99,
            purchasing_for: 'single'
        },
        {
            name: 'Team',
            description: 'All single user features plus:',
            features:['Team Analytics','Transferable Seats' , 'Manager / Coach access'],
            type: 2,
            price_monthly: 25,
            price_yearly: 250,
            purchasing_for : 'team'
        }
    ];

    model: any = {billing_frequency: 'yearly', purchasing_for: 'self'};
    totalPrice = 0;

    @ViewChild('reviewForm') reviewFormElement: TemplateRef<any>;
    @Output() completed = new EventEmitter();

    token: String = '';

    stripeKey = environment.stripePublicKey;
    elements: StripeElements;
    card: StripeCardElement;

    existingCards: any[];

    hasCouponCode: boolean = false;
    discount: any = {};
    // optional parameters
    elementsOptions: StripeElementsOptions = {
        locale: 'en'
    };

    stripeForm: FormGroup;

    constructor(private fb: FormBuilder,
                private stripeService: StripeService,
                private apiService: ApiService,
                private customerService: CustomerService,
                private authService: AuthService,
                private router: Router,
                private pingsService: PingsService,
                private loadingService: LoadingService,
                private popupService: PopupNotificationService,
                private hasPermissionPipe : HasPermissionPipe) {
    }

    customer:any;

    ngOnInit() {
        this.updatePurchasingFor('team');
        this.setupStripe();

        this.customerService.getCustomer().subscribe(resp => {
            if(resp.success){
                this.customer = resp.response;
                if(this.hasCustomerPermission){
                    this.customerService.getAllCards().subscribe(resp => {
                        this.existingCards = resp.response;
                    });
                }
            }
        });
    }

    get hasCustomerPermission(){
        return this.hasPermissionPipe.transform("CanManageCustomerBilling");
    }
    setupStripe() {
        this.stripeService.changeKey(this.stripeKey);
        this.stripeForm = this.fb.group({
            name: ['', [Validators.required]]
        });

        if (!this.card) {
            this.stripeService.elements(this.elementsOptions).subscribe(elements => {
                this.elements = elements;
                // Only mount the element the first time
                this.card = this.elements.create('card', {
                    style: {
                        base: {
                            iconColor: '#666EE8',
                            color: '#31325F',
                            lineHeight: '40px',
                            fontWeight: 300,
                            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                            fontSize: '18px',
                            '::placeholder': {
                                color: '#CFD7E0'
                            }
                        }
                    }
                });

                this.card.on('change', (data) => {
                    if (this.model.token)
                        this.model.token = '';
                });

                this.mountStripeCardElement();
            });
        } else {
            this.mountStripeCardElement();
        }

    }

    mountStripeCardElement() {
        setTimeout(() => {
            let cardElement = document.getElementById('card-element');
            if (!cardElement || cardElement.childElementCount > 0)
                return;
            if (cardElement && this.card) {
                this.card.mount('#card-element');
            }
        }, 50);

    }

    removeCoupon() {
        this.hasCouponCode = false;
        this.model.coupon = '';
        this.discount = {};

        this.calculate();
    }

    addCoupon() {

        if(this.hasCouponCode){
            if(!confirm("You can only apply one valid coupon. Continuing will remove your currently applied coupon. Select OK to continue?"))
                return;
        }
        this.loadingService.startLoading();

        this.apiService
            .post('/customer/validate-coupon', {code: this.model.coupon})
            .subscribe(data => {
                const response = JSON.parse(JSON.stringify(data));
                this.loadingService.stopLoading();

                if (!response.success) {
                    alert(response.response.error);
                    this.removeCoupon();
                } else {
                    this.hasCouponCode = true;
                    this.discount = response.response;
                    this.calculate();
                }

            });
    }

    reviewPurchase() {
        if (!this.model.token && !this.model.credit_card) {
            alert('Please enter your credit card details and try again.');
            return;
        }
        // this.popupService.openPopup()
        this.popupService.openPopup(ConfirmationPopupModalComponent, {
            title: 'Purchase Review',
            elementRef: this.reviewFormElement
        }, (result) => {
            if (result)
                this.completePurchase();
        });
    }

    completePurchase() {
        this.loadingService.startLoading();
        //if already customer, just add subscription. otherwise create a new account for this user to become a customer.

        if(this.customer && this.hasCustomerPermission) {
            //since we are in a trial and creating a new subscription, we need to remove the trial subscription
            //so that stripe doesn't attempt to bill it.

            let subscriptionId = 0;

            //Find the subscription id of the trial:
            this.customerService.getAllSubscriptions().subscribe(response => {
                if (response.response && response.response.length > 0) {
                    //console.log('response=>', response);
                    //console.log('model=>', this.model);

                    let status = response.response[0].status; //active, trialing, past_due, inactive
                    //this.latestInvoice = response.response[0].latest_invoice;
    
                    //if (status == 'trialing' || status == 'expired') {
                    if (status == 'trialing' || status == 'past_due' || status == 'expired' || status == 'unpaid') {
                        //this is the only subscription we want to remove.

                        subscriptionId = response.response[0].id;

                        let id:any = {subscription_id: subscriptionId};

                        this.customerService.cancelSubscription(id).subscribe(data => {
                            let response = JSON.parse(JSON.stringify(data));
                            if(!response.success) {
                                alert(response.response.error);
                            } else {

                                this.customerService.addSubscription(this.model).subscribe(data => {
                                    let response = JSON.parse(JSON.stringify(data));
                                    this.loadingService.stopLoading();
                                    if (!response.success) {
                                        alert(response.response.error);
                                    } else {
                                        this.completed.emit();
                                    }
                                });
                                
                                //this.refreshLicenses();
                            }
                        });
                    }
                    else {
                        //could not find a trial subscription to cancel.
                        this.loadingService.stopLoading();
                        alert('Could not find your trial subscription. Please contact Presentr Support for help with this issue.')
                    }
                }
    
            });
        }
        else{
            this.customerService.create(this.model).subscribe(data => {
                let response = JSON.parse(JSON.stringify(data));
                this.loadingService.stopLoading();
                if (!response.success) {
                    alert(response.response.error);
                } else {
                    response = response.response;
                    if (response.id) {
                        // customer created
                        this.completed.emit();
                    } else {
                        alert('Something went wrong. Please refresh and try again.');
                    }
                }
            });
        }

    }

    private refreshLicenses(){
        this.authService.licenses().subscribe(req => {
            // if(req.success){
            // }
        })
    }

    cardChanged(card){
        let ccard = this.existingCards.find(x => { return x.id == card});
        if(ccard){
            this.model.token = ccard.id;
            this.model.card_last_4 = ccard.last4;
            this.model.card_brand = ccard.brand;
        }
    }

    updateBillingFrequency() {
        this.calculate();
    }

    get selectedPlan(){
        let planIndex = this.model.purchasing_for ? (this.model.purchasing_for == 'team' ? 2 : 1) : 0;
        return SubscriptionTrialPurchaseFormComponent.PlanOptions[planIndex];
    }

    calculate() {
        if (this.model.billing_frequency === 'monthly') {
            this.totalPrice = this.model.seats * this.selectedPlan.price_monthly;
        } else {
            this.totalPrice = this.model.seats * this.selectedPlan.price_yearly;
        }

        if (this.discount && this.discount.percent_off) {
            this.totalPrice = this.totalPrice - (this.totalPrice * (0.01 * this.discount.percent_off));
        }

        if (this.discount && this.discount.amount_off) {
            this.totalPrice = this.totalPrice - this.discount.amount_off;
        }

        if (this.totalPrice < 0) {
            this.totalPrice = 0.00;
        }

    }

    addPaymentMethod() {
        const name = this.stripeForm.get('name').value;
        this.stripeService.createToken(this.card, {name}).subscribe(result => {
            if (result.token) {
                this.model.token = result.token.id;
                this.model.card_last_4 = result.token.card.last4;
                this.model.card_brand = result.token.card.brand;

                this.customerService
                    .addBillingCard({
                        token: result.token.id,
                        card_token: result.token.card.id,
                        card_last_4: result.token.card.last4,
                        card_brand: result.token.card.brand,
                        card_exp_month: result.token.card.exp_month,
                        card_exp_year: result.token.card.exp_year
                    })
                    .subscribe(
                        response => {
                            //this.loadingService.stopLoading();

                            if (!response.success) {
                                alert(response.response.error);
                            } else {
                                //alert("Success");
                                //console.log('addPaymentMethod()', response);
                                //this.router.navigate(["/customer/payment-methods"]);
                                //this.completed.emit(response);
                            }
                        },
                        error => {
                        alert("Something went wrong. Please contact Presentr Support.");
                        }
                    );

            } else if (result.error) {
                alert(
                    'Something went wrong creating the token. Please contact Presentr Support.'
                );
            }
        });
    }

    removePaymentMethod() {
        this.model.token = '';
        this.model.card_last_4 = '';
        this.model.card_brand = '';

        if (this.card) {
            this.card.unmount();
            this.card = null;
        }
        this.setupStripe();
    }

    updatePurchasingFor(purchasingFor) {
        //console.log('updatePurchasingFor()=', purchasingFor);

        this.model.purchasing_for = purchasingFor;
        if(purchasingFor == 'team')
            this.model.seats = 2;
        else if(purchasingFor == 'single')
            this.model.seats = 1;
        else
            this.model.seats = 0;

        this.mountStripeCardElement();
        this.calculate();
    }

}
