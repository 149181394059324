import {ChangeDetectorRef, Component, Input, Output, OnInit, EventEmitter, TemplateRef, ViewChild} from '@angular/core';
import {PingsService} from '../../../classes/services';
import {PingsPageEnum, PingsActionEnum} from '../../../classes/enums/pings-page.enum';
import {SubscriptionTrialFormComponent} from '../subscription-trial-form/subscription-trial-form.component';

@Component({
  selector: 'app-subscription-selection',
  templateUrl: './subscription-selection.component.html',
  styleUrls: ['./subscription-selection.component.scss']
})
export class SubscriptionSelectionComponent implements OnInit {
    selectedPlan = null;
    model: any = { billing_frequency: 'monthly', purchasing_for: 'self' };

    @Input() showFreemium = false;
    @Input() buttonTitle:string = "Select";
    @Output() planChanged: EventEmitter<any> = new EventEmitter<any>();

    public static PlanOptions = [
        {
            name: 'Freemium',
            description: 'All the basics to allow you to experience the Presentr app.',
            features: [],
            type: 0,
            price_monthly: 0,
            price_yearly: 0,
            purchasing_for: null
        },
        {
            name: 'Single User',
            description: '',
            features: ['Record', 'Scores and Analytics', 'Tips For Improvement', 'Practice with real time feedback', 'Coaching from AI Coach, Abbie', 'Access to games and content library'],
            type: 1,
            price_monthly: 9,
            price_yearly: 99,
            purchasing_for: 'single'
        },
        {
            name: 'Team',
            description: 'All single user features plus:',
            features: ['Team Analytics', 'Transferable Seats', 'Manager / Coach access'],
            type: 2,
            price_monthly: 25,
            price_yearly: 250,
            purchasing_for: 'team'
        }
    ];


    constructor(private ngCh: ChangeDetectorRef,
                private pingsService: PingsService) {
    }

    ngOnInit() {
        this.pingsService.sendPing(PingsPageEnum.CustomerSubscriptionsAdd, PingsActionEnum.viewed);
        this.determineCurrentPlan();
    }

    get planOptions(){
        return SubscriptionSelectionComponent.PlanOptions.filter(x => x.type > 0 || (this.showFreemium && x.type ==0 ));
    }


    private determineCurrentPlan(){
        this.selectedPlan = SubscriptionSelectionComponent.PlanOptions[SubscriptionTrialFormComponent.PlanOptions.length - 1];
    }

    get selectedPlanType(){
        return this.selectedPlan ? this.selectedPlan.type : 0;
    }

    planChosen(plan){
        this.selectedPlan = plan;
        this.ngCh.detectChanges();
    }

    selectionClicked(plan: any){
        
        this.ngCh.detectChanges();
        this.updatePurchasingFor(this.selectedPlan.purchasing_for)
        
        this.planChanged.emit(plan);
    }

    updatePurchasingFor(purchasingFor) {
        this.selectedPlan.purchasing_for = purchasingFor;

        if (purchasingFor == 'team') {
            this.selectedPlan.seats = 5;
            this.selectedPlan.name = "Team";
        }
        else if (purchasingFor == 'single') {
            this.selectedPlan.seats = 1;
            this.selectedPlan.name = "Single";
        }
        else {
            this.selectedPlan.seats = 0;
            this.selectedPlan.name = "Other";
        }
    }

}
