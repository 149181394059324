import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule
} from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing";

import {
  AuthService,
  AlertService,
  UserService,
  ApiService,
  SocketService,
  EventSubscriptionService,
  CustomerService
} from "./classes/services/";

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TokenInterceptor } from "./classes/interceptors/token.interceptor";

import { DataService } from "./classes/api";

import { AuthGuard } from "./classes/guards/index";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HasPermissionGuard } from "./classes/guards/has-permission-guard.guard";
import { RedirectionService } from "./classes/services/core/redirection.service";
import { WatsonService } from "./classes/services/core/watson.service";
import { MediaStreamService } from "./classes/services/core/media-stream.service";
import { MediaRecorderService } from "./classes/services/core/media-recorder.service";
import { AwsUploaderService } from "./classes/services/core/aws-uploader.service";
import { VolumeProviderService } from "./classes/services/core/volume-provider.service";
import { TipProviderService } from "./classes/services/data/tip-provider.service";
import { LoadingService } from "./classes/services/core/loading.service";
import { TutorialService } from "./classes/services/core/tutorial.service";
import { ResultService } from "./classes/services/data/result.service";
import { WordManagementService } from "./classes/services/data/word-management.service";
import { PingsService } from "./classes/services/data/pings.service";
import { ChromeGuard } from "./classes/guards/chrome.guard";
import { ChromeRecommendedGuard } from "./classes/guards/chrome-recommended.guard";
//import { MobileGuard } from "./classes/guards/mobile.guard";
import { ContentService } from "./classes/services/data/content.service";
import { SkeletalProviderService } from "./classes/services/core/skeletal-provider.service";
import { VideoScoreProcessorService } from "./classes/services/core/video-score-processor.service";
import { MicPermissionGuard } from "./classes/guards/mic-permission.guard";
import { MediaPermissionGuard } from "./classes/guards/media-permission.guard";
import { AudioScoreProcessorService } from "./classes/services/core/audio-score-processor.service";
import { SettingsService } from "./classes/services/core/settings.service";
import { CookieService } from "ngx-cookie-service";
import { TextStoreService } from "./classes/services/data/text-store.service";
import { AnalyticsDataService } from "./classes/services/data/analytics-data.service";
import { CoachPopupComponent } from "./coach/coach-popup/coach-popup.component";
import { CoachChatService } from "./classes/services/data/coach-chat.service";
import { CoachTaskService } from "./classes/services/data/coach-task.service";
import { CoachService } from "./classes/services/core/coach.service";
import { LearnModalComponent } from "./core/popup-manager/learn-modal/learn-modal.component";
import { CoachScheduleService } from "./classes/services/data/coach-schedule.service";
import { HelpPopupByKeyComponent } from "./core/popup-manager/help-popup-by-key/help-popup-by-key.component";
import { CoachChatFeedbackPopupComponent } from "./coach/coach-popup/coach-chat/coach-chat-feedback-popup/coach-chat-feedback-popup.component";
import { ContentSignedAuthGuard } from "./classes/guards/content-signed-auth.guard";
import {MediaTestedGuard} from './classes/guards/media-tested.guard';
import {MicTestedGuard} from './classes/guards/mic-tested.guard';
import {FeedbackMonitorService} from './classes/services/core/feedback-monitor.service';
import {LayoutsModule} from './layout/layouts.module';
import {CoreModule} from './core/core.module';
import {CommonModule} from '@angular/common';
import {GoogleAnalyticsService} from './classes/services/core/google-analytics.service';
import {AdrollService} from './classes/services/core/adroll.service';
import { HasPermissionPipe } from './classes/pipes/has-permission.pipe';
import { CustomerInterceptor } from './classes/interceptors/customer.interceptor';
import {SidebarTemplateProviderService} from './classes/services/core/sidebar-template-provider.service';
import {PopupNotificationService} from './classes/services/core/popup-notification.service';
import {FacebookTrackingService} from './classes/services/core/facebook-tracking.service';
//import {FirebaseMessagingService} from './classes/services/core/firebase-messaging.service';
//import {AngularFireDatabaseModule} from '@angular/fire/database';
//import {AngularFireAuthModule} from '@angular/fire/auth';
//import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {environment} from '../environments/environment';
//import {AngularFireModule} from '@angular/fire';
//import {FirebaseAnalyticsService} from './classes/services/core/firebase-analytics.service';
import { SafePipe } from './safe.pipe';
import {ViewSDKClient} from "./classes/services/core/view-sdk.service";
import {UserNotificationService} from "./classes/services/core/user-notification.service";

@NgModule({
    declarations: [
        AppComponent,
        SafePipe
    ],
    entryComponents: [
        CoachPopupComponent,
        CoachChatFeedbackPopupComponent
    ],
    imports: [
        LayoutsModule,
        CoreModule,
        CommonModule,
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
       // AngularFireModule.initializeApp(environment.firebase),
        //AngularFireDatabaseModule,
        //AngularFireAuthModule,
        //AngularFireMessagingModule,
        NgbModule
    ],
    providers: [
        HasPermissionPipe,
        AuthGuard,
        HasPermissionGuard,
        ContentSignedAuthGuard,
        ChromeGuard,
        ChromeRecommendedGuard,
        //MobileGuard,
        MediaTestedGuard,
        MicTestedGuard,
        CustomerService,
        CoachService,
        SettingsService,
        DataService,
        AlertService,
        EventSubscriptionService,
        AuthService,
        CoachChatService,
        CoachTaskService,
        CoachScheduleService,
        UserService,
        ResultService,
        SocketService,
        RedirectionService,
        MediaStreamService,
        MediaRecorderService,
        AwsUploaderService,
        VolumeProviderService,
        MediaPermissionGuard,
        MicPermissionGuard,
        AudioScoreProcessorService,
        VideoScoreProcessorService,
        FeedbackMonitorService,
        SkeletalProviderService,
        TipProviderService,
        WordManagementService,
        AnalyticsDataService,
        WatsonService,
        ContentService,
        ApiService,
        PingsService,
        LoadingService,
        TextStoreService,
        GoogleAnalyticsService,
        FacebookTrackingService,
        AdrollService,
        CookieService,
        TutorialService,
        SidebarTemplateProviderService,
        PopupNotificationService,
        //FirebaseMessagingService,
        //FirebaseAnalyticsService,
        NgbActiveModal,
        ViewSDKClient,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomerInterceptor,
            multi: true
        },
        UserNotificationService
    ],
    exports: [
        SafePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
