<div class="form text-xl text-left">
    <div class="flex flex-grow flex-wrap lg:flex-no-wrap my-2 items-center" *ngIf="model.purchasing_for == 'team'">
        <label class="w-1/2 lg:w-64">Members/Seats</label>
        <input min="2" (change)="calculate()" [(ngModel)]="model.seats" type="number" name="seats"
               class="form-input w-full ml-2"/>
    </div>

    <div class="flex flex-grow flex-wrap lg:flex-no-wrap my-2 items-center" >
        <label class="w-1/2 lg:w-64">Billing Frequency</label>
        <select (change)="updateBillingFrequency()" [(ngModel)]="model.billing_frequency"
                name="billing_frequency" class="form-input w-full ml-2">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
        </select>
    </div>

    <div class="flex flex-grow flex-wrap lg:flex-no-wrap my-2 items-center">
        <label class="w-1/2 lg:w-64">Coupon Code</label>
        <div class="flex flex-wrap w-full ml-2">
            <input type='text' [(ngModel)]="model.coupon" value='' [disabled]="hasCouponCode" placeholder="Coupon Code"
                   class="form-input flex-1" />
            <button (click)="addCoupon()" [disabled]="hasCouponCode || !(model.coupon && model.coupon.length > 0)" class="button-white mx-2">
                Apply Coupon
            </button>
        </div>
    </div>

    <div class="flex flex-grow flex-wrap lg:flex-no-wrap my-1 items-center justify-end">
        <div class="flex flex-1 justify-end w-full text-base" *ngIf="hasCouponCode" >
            <span class="text-blue font-bold">Coupon: {{ discount.name }} </span>
            <div class="ml-1">
                <span *ngIf="discount.percent_off">{{discount.percent_off}}% off </span>
                <span *ngIf="discount.amount_off">${{discount.amount_off}} off </span>
            </div>
            <div class="ml-1">
                <span *ngIf="discount.duration == 'once'"> for 1 billing cycle</span>
                <span *ngIf="discount.duration == 'repeating'"> for {{discount.duration_in_months}}
                    billing cycles</span>
                <span *ngIf="discount.duration == 'forever'"> for the length of the subscription</span>
            </div>
        </div>
        <button (click)="removeCoupon()" class="button-blue p-0 w-8 h-8 mx-2 rounded-none" *ngIf="hasCouponCode" >X</button>
    </div>

    <div class="flex flex-grow justify-end my-2">
        <h2 >Total: ${{ totalPrice | number : '1.2-2'}} <span class="text-sm">{{model.billing_frequency}}</span></h2>
    </div>


    <div class="flex flex-grow flex-wrap lg:flex-no-wrap my-2 items-center mt-4 mb-2">
        <label class="w-1/2 lg:w-64">Payment Method</label>
        <div class="flex flex-wrap w-full items-center"  *ngIf="!existingCards || existingCards.length == 0">
            <div class="flex flex-1 w-full">
                <form class="w-full" novalidate (ngSubmit)="addPaymentMethod()" [formGroup]="stripeForm">
                    <div id="card-element" class="form-input bg-white"></div>
                </form>
            </div>
            <button *ngIf="!model.token" (click)="addPaymentMethod()" class="button-blue mx-2">Verify</button>
        </div>
        <div class="flex flex-wrap w-full items-center" *ngIf="existingCards && existingCards.length > 0">
            <select class="form-input flex-1" [(ngModel)]="model.credit_card" (change)="cardChanged($event.target.value)">
                <option *ngFor="let card of existingCards" [value]="card.id">{{ card.last4 }} ( {{card.brand}} ) Exp: {{card.exp_month}} / {{card.exp_year}}</option>
            </select>
        </div>
    </div>

    <div class="text-sm mt-4 text-right">
        By clicking "Purchase" you agree to be bound by the terms and conditions of Presentr. You will be
        billed automatically starting today to the credit card on file.
    </div>

    <div class="flex flex-wrap justify-end my-2">
        <button (click)="reviewPurchase()" class="button-blue" [disabled]="(!model.token && !model.credit_card) || (model.coupon && !hasCouponCode)">Review and Purchase</button>
    </div>
    
</div>


<ng-template #reviewForm>
    <div class="flex flex-col flex-grow p-4 vw-50">
        <app-svg-component type="logo" class="svg fill-blue h-32"></app-svg-component>
        <table class="w-full mt-6 border-2 border-gray">
            <tr class="border-b border-gray">
                <td>Payment Method</td>
                <td>{{model.card_brand}} - Ending: {{ model.card_last_4 }}</td>
            </tr>

            <tr class="border-b border-gray">
                <td>Number of Seats</td>
                <td>{{model.seats}}</td>
            </tr>

            <tr *ngIf="hasCouponCode" class="border-b border-gray">
                <td>Coupon</td>
                <td>
                    {{ discount.name }} :
                    <span class="ml-1" *ngIf="discount.percent_off">{{discount.percent_off}}% off </span>
                    <span class="ml-1" *ngIf="discount.amount_off">${{discount.amount_off}} off </span>

                    <span class="ml-1" *ngIf="discount.duration == 'once'"> for 1 billing cycle</span>
                    <span class="ml-1" *ngIf="discount.duration == 'repeating'"> for {{discount.duration_in_months}}
                        billing cycles</span>
                    <span class="ml-1" *ngIf="discount.duration == 'forever'"> for the length of the subscription</span>
                </td>
            </tr>
            <tr class="border-b border-gray">
                <td>Total Price</td>
                <td> ${{ totalPrice | number : '1.2-2'}} {{model.billing_frequency}} <span class="text-xs" *ngIf="hasCouponCode"> * while coupon active</span></td>
            </tr>
        </table>

        <div class="text-sm mt-4 text-right mx-6">
            By clicking "Confirm" you agree to be bound by the terms and conditions of Presentr. You will be
            billed automatically starting today to the credit card on file.
        </div>

    </div>
</ng-template>
