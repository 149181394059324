import {Component, OnInit, AfterViewInit, Input} from '@angular/core';
import {
    EventSubscriptionService,
    ContentService
} from '../../../classes/services';
import {CoachTaskService} from '../../../classes/services/data/coach-task.service';
import {NotificationEvent} from '../../../classes/models';
import {Router} from '@angular/router';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LearnModalComponent} from '../../../core/popup-manager/learn-modal/learn-modal.component';
import {HelpPopupByKeyComponent} from '../../../core/popup-manager/help-popup-by-key/help-popup-by-key.component';
import {PopupNotificationService} from '../../../classes/services/core/popup-notification.service';

@Component({
    selector: 'app-coach-tasks',
    templateUrl: './coach-tasks.component.html',
    styleUrls: ['./coach-tasks.component.scss']
})
export class CoachTasksComponent implements OnInit {
    tasks: Array<Array<String>> = [];
    helpPageKey = 'coach::tasks::help::';

    page = 1;
    itemsPerPage = 4;

    isReadOnly = false;
    private _overrideUserId: number;

    @Input() set overrideUserId(value: number) {
        this._overrideUserId = value;
        this.isReadOnly = value !== 0 && value !== null;
    }

    get overrideUserId(): number {
        return this._overrideUserId;
    }

    constructor(
        private router: Router,
        private coachTaskService: CoachTaskService,
        private notificationService: EventSubscriptionService,
        private popupService: PopupNotificationService,
        private contentService: ContentService
    ) {
    }

    loadTasks() {
        this.coachTaskService.getTasks(this.overrideUserId).subscribe(response => {
            if (response.success) {
                this.tasks = [];
                this.tasks.unshift.apply(this.tasks, response.response.reverse());
            }
        });
    }

    ngOnInit() {
        this.loadTasks();

        this.notificationService.subscribeToNotificationEvent(
            'coach_task_modal',
            this,
            this.handleTaskWS
        );
    }

    removeTask(task: any, parent?: any) {
        this.coachTaskService.removeTask(task).subscribe(response => {
        });
    }

    presentClicked() {
        this.router.navigate(['/app/record/present']);
    }

    practiceClicked() {
        this.router.navigate(['/app/record/practice']);
    }

    gameLinkClicked(id: number) {
        let link = '';
        switch (id) {
            case 1:
                link = '/app/learn/games/game/volume-meter';
                break;
            case 2:
                link = '/app/learn/games/game/pace-car';
                break;
            case 3:
                link = '/app/learn/games/game/filler-water';
                break;
            case 4:
                link = '/app/learn/games/game/volume-rocket';
                break;
            case 5:
                link = '/app/learn/games/game/pace-meter'; // pace
                break;
            case 6:
                link = '/app/learn/games/game/filler-meter'; //filler
                break;
        }

        this.router.navigate([link]);
    }

    readLinkClicked(id: number) {
        this.contentService.getBlogsLearnCards(id).subscribe(resp => {
            if (resp.success) {

                this.popupService.openPopup(LearnModalComponent, resp.response[0]);
            }
        });
    }

    watchLinkClicked(id: number) {
        this.contentService.getVideoBlogsLearnCards(id).subscribe(resp => {
            if (resp.success) {
                this.popupService.openPopup(LearnModalComponent, resp.response[0]);
            }
        });
    }

    openHelpModal() {
        this.popupService.openHelpKeyPopup(this.helpPageKey);
    }

    //================================================================================
    // Web socket functions
    //================================================================================

    handleTaskWS(scope, data: NotificationEvent) {
        switch (data.data.action) {
            case 'reload':
                // delay here to make sure the database has beem updated
                setTimeout(function () {
                    scope.loadTasks();
                }, 1500);

                break;
            case 'add':
                scope.tasks.unshift(data.data.task);
                break;
            case 'remove':
                scope.tasks = scope.tasks.filter(function (element) {
                    if (
                        element.id !== data.data.task.id &&
                        element.children.length > 0
                    ) {
                        element.children = element.children.filter(function (subElement) {
                            return subElement.id !== data.data.task.id;
                        });
                    }

                    return element.id !== data.data.task.id;
                });

                break;
            default:
                break;
        }
    }
}
