import { Injectable } from '@angular/core';
import {EventSubscriptionService} from "./event-subscription.service";
import {NotificationEvent} from "../../models";

@Injectable()
export class LoadingService {

	static START_LOADING = 'START_LOADING';
	static FINISH_LOADING = 'FINISH_LOADING';

	constructor(private notificationService:EventSubscriptionService) {	}

	startLoading(){
		this.notificationService.sendNotificationEvent(new NotificationEvent(LoadingService.START_LOADING, null));
	}

	stopLoading(){
		this.notificationService.sendNotificationEvent(new NotificationEvent(LoadingService.FINISH_LOADING, null));
	}
}
