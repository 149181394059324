
<div class="t flex flex-col  w-full justify-between {{bgColorClass}} rounded border border-gray {{showOverlay ? 'hover:border-blue' : ''}} shadow group" (click)="handleCardClicked()">
  <div class="flex w-full">
    <ng-content select="[slot='header']"></ng-content>
  </div>

  <div class="relative flex w-full h-full">
    <ng-content select="[slot='body']"></ng-content>
    <div *ngIf="showOverlay" class="absolute flex justify-center overlay bottom-0 left-0 w-full {{overlayHeight}} rounded-b max-h-0 group-hover:max-{{overlayHeight}} group-hover:visible">
        <ng-content select="[slot='overlay']" ></ng-content>
    </div>
  </div>
</div>
