<app-popup-base>
    <ng-container>

        <div class="modal-body container bg-blue rounded">
            <div class="p-4 text-center">
                <h2 class="text-white justify-center">Welcome To</h2>
                <app-svg-component type="logo" class="svg w-128 mx-auto"></app-svg-component>
                <div class="bg-white rounded-large mt-6 px-6 py-4 w-128 ml-32">
                    <p [innerHTML]="welcomeText" class="flex"></p>
                </div>
                <app-svg-component type="abbie" class="svg w-32 h-32"></app-svg-component>
            </div>
        </div>
    </ng-container>
</app-popup-base>
