import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators"
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { EnvironmentAPIs } from "../../enums/environment-apis";
import { ApiService } from "./api.service";
import {AuthService} from './auth.service';

@Injectable()
export class CoachChatService {
  constructor(private apiService: ApiService, private authService:AuthService) {}

  getUserName(overrideUserId:number){
    let user = this.authService.getLoggedInUser();
    if(overrideUserId && user && user.id != overrideUserId){
      return 'UserID: ' + overrideUserId;
    }
    else if(user)
      return user.first_name + ' ' + user.last_name;

    return '';
  }
  getChats(nextPage: number, overrideUserId: number) {
    const overrideQuery =
      overrideUserId != 0 && overrideUserId != null
        ? "&userId=" + overrideUserId
        : "";
    return this.apiService
      .get("/chats?page=" + nextPage + overrideQuery, EnvironmentAPIs.BBQ)
      .pipe(
        map(response => {
          return JSON.parse(JSON.stringify(response));
        })
      )

  }

  sendMessage(message: String) {
    return this.apiService
      .post("/chat", { message: message }, EnvironmentAPIs.BBQ)
      .pipe(
      map(response => {
        return JSON.parse(JSON.stringify(response));
      })
     )
  }

  sendFeedback(feedback: Object) {
    return this.apiService
      .post("/other/feedback", feedback, EnvironmentAPIs.BBQ)
      .pipe(
      map(response => {
        return JSON.parse(JSON.stringify(response));
      })
    )
  }

  getBadgeNumber() {
    return this.apiService
      .get("/chat/badge", EnvironmentAPIs.BBQ)
      .pipe(
      map(response => {
        return JSON.parse(JSON.stringify(response));
      })
    )
  }

  markAllRead() {
    return this.apiService
      .post("/chat/read", {}, EnvironmentAPIs.BBQ)
      .pipe (
      map(response => {
        return JSON.parse(JSON.stringify(response));
      })
    )
  }
}
