<nav class="sidebar-nav">
    <ul class="presentrmenu">
        <li class="">
            <a class="has-arrow nav-link" data-bs-toggle="collapse"
                href="#profileMenu" role="button" aria-expanded="false" aria-controls="profileMenu">
                <img src="../../../assets/header/presentr-logo-21.jpg" class="menu-logo" /> {{currentUser.first_name}}
            </a>

            <div class="collapse" id="profileMenu">
                <a class="nav-link" [routerLink]="['/account/manager']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><span class="fa fa-blank fa-fw"></span>Profile</a>
                <a class="nav-link" [routerLink]="['/account/manager/word-settings']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>My Tracked Words</a>
                <a class="nav-link" [routerLink]="['/account/manager/custom-topics']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>My Topics</a>
                <a class="nav-link" [routerLink]="['/account/manager/manage-presentations']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>My Presentations</a>
                <a class="nav-link" [routerLink]="['/logout']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Log Out</a>
            </div>
        </li>
    </ul>

    <a class="nav-link" [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><span class="fa fa-solid fa-grip-horizontal fa-fw fa-lg"></span> Dashboard</a>
    <a class="nav-link" [routerLink]="['/app/record']" routerLinkActive="active"><span class="fa fa-microphone fa-fw fa-lg"></span> Present</a>

    <ul class="presentrmenu">
        <li class="">
            <a class="has-arrow nav-link" data-bs-toggle="collapse"
                href="#learnMenu" role="button" aria-expanded="false" aria-controls="learnMenu">
                <span class="fa fa-graduation-cap fa-fw fa-lg"></span> Lessons
            </a>

            <div class="collapse" id="learnMenu">
                <a class="nav-link" [routerLink]="['/app/lessons/articles']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Articles</a>
                <a class="nav-link" [routerLink]="['/app/lessons/videos']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Videos</a>
            </div>
        </li>
    </ul>
    
    <a class="nav-link" [routerLink]="['/app/activities/games']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><span class="fa fa-solid fa-lightbulb-o fa-fw fa-lg"></span> Activities</a>

    <ul class="presentrmenu">
        <li class="">
            <a class="has-arrow nav-link" data-bs-toggle="collapse"
                href="#analyticsMenu" role="button" aria-expanded="false" aria-controls="analyticsMenu">
                <span class="fa fa-solid fa-line-chart fa-fw fa-lg"></span> Analytics
            </a>

            <div class="collapse" id="analyticsMenu">
                <a class="nav-link child" [routerLink]="['/app/score']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><span class="fa fa-blank fa-fw"></span>Last Score</a>
                <a class="nav-link child" [routerLink]="['/app/score/overview']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Analytics</a>
                <a class="nav-link child" [routerLink]="['/app/score/result-list']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>My Recordings</a>
            </div>
        </li>
    </ul>

    <ul class="presentrmenu">
        <li class="">
            <a class="has-arrow nav-link" data-bs-toggle="collapse"
                href="#adminMenu" role="button" aria-expanded="false" aria-controls="adminMenu">
                <span class="fa fa-user-o fa-fw fa-lg"></span> Admin
            </a>

            <div class="collapse" id="adminMenu">
                <a class="nav-link" [routerLink]="['/customer/manager/account']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Manage Account</a>
                <a class="nav-link" [routerLink]="['/customer/manager/users']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Manage Users</a>
                <a class="nav-link" [routerLink]="['/customer/manager/groups']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Manage Groups</a>
            </div>
        </li>
    </ul>

    <ul class="presentrmenu">
        <li class="">
            <a class="has-arrow nav-link" data-bs-toggle="collapse"
                href="#groupsMenu" role="button" aria-expanded="false" aria-controls="groupsMenu">
                <span class="fa fa-users fa-fw fa-lg"></span> Groups
            </a>

            <div class="collapse" id="groupsMenu">
                <a class="nav-link" [routerLink]="['/customer/manager/custom-topics']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Topics</a>
                <a class="nav-link" [routerLink]="['/customer/manager/presentations']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Presentations</a>
                <a class="nav-link" [routerLink]="['/analytics/team']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>User Recordings</a>
                <a class="nav-link" [routerLink]="['/analytics/team-analytics']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><span class="fa fa-blank fa-fw"></span>Analytics</a>
                <a class="nav-link" [routerLink]="['/analytics/team-analytics/trending']" routerLinkActive="active"><span class="fa fa-blank fa-fw"></span>Trending</a>
            </div>
        </li>
    </ul>

    <a class="nav-link"><span class="fa fa-question-circle fa-lg fa-fw"></span> Help Center</a>
</nav>