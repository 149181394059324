import {Injectable} from '@angular/core';
import {ApiService} from '../data/api.service';
import {NotificationEvent} from '../../models';
import {EventSubscriptionService} from './event-subscription.service';
import {DebugConsole} from '../../helpers/debug-console';
import {SettingsService} from './settings.service';
import {AuthService} from '../data/auth.service';
import {environment} from '../../../../environments/environment';
import {EnvironmentAPIs} from '../../enums/environment-apis';
import { map } from 'rxjs/operators';
let recognizeMicrophone = require('../../helpers/watson-index');

@Injectable()
export class WatsonService {

    static readonly NOTIFY_EVENT = 'WATSON_NOTIFY_EVENT';

    static PAUSE_TIME = 500; // half a second

    private resultStream;
    isRunning: boolean;
    startTime: any;

    constructor(private apiService: ApiService,
                private notificationService: EventSubscriptionService,
                private settingsService:SettingsService,
                private authService: AuthService) {
    }

    getWatsonAuthorizationToken() {

        if(environment.name === 'local'){
            return this.apiService.post('/auth/watson-credentials', null, EnvironmentAPIs.Ketchup, null, environment.ketchupUrl).pipe( map(
                resp => {
                    return JSON.parse(JSON.stringify(resp));
                })
            )
        }
        else {
            return this.apiService.post('/auth/watson-credentials', null).pipe( map(
                resp => {
                    return JSON.parse(JSON.stringify(resp));
                })
            )

        }

    }

    subscribeForNotification(scope, callback) {
        this.notificationService.subscribeToNotificationEvent(WatsonService.NOTIFY_EVENT, scope, callback);
    }

    unsubscribeNotification(scope) {
        this.notificationService.unsubscribeToNotificationEvent(WatsonService.NOTIFY_EVENT, scope);
    }

    openTime:any;
    listenTime:any;
    private startRecognizeInternal(token, stream, keywords, lang_model){
        this.resultStream = recognizeMicrophone.recognizeMicrophone(this.getWatsonRecognizeOptions(token, stream, keywords, lang_model));
        this.startTime = performance.timeOrigin + performance.now();
        DebugConsole.log("startWatson " + (performance.timeOrigin + performance.now()));
        //DebugConsole.log("startTime " + performance.timing.navigationStart + performance.now());

        //this.notificationService.sendNotificationEvent(new NotificationEvent(WatsonService.NOTIFY_EVENT, {event:'started', }));
        this.resultStream.recognizeStream.on('listening', (data) => {

            this.listenTime = performance.timeOrigin + performance.now();
            DebugConsole.log("listeningWatson " + ( this.listenTime - this.startTime));
            this.notificationService.sendNotificationEvent(new NotificationEvent(WatsonService.NOTIFY_EVENT, {event: 'listening', data}));
            //DebugConsole.log("listening " + performance.timing.navigationStart + performance.now());
        });

        this.resultStream.recognizeStream.on('open', (data) => {
            this.notificationService.sendNotificationEvent(new NotificationEvent(WatsonService.NOTIFY_EVENT, {event: 'open', data}));
            //this.startTime = performance.timing.navigationStart + performance.now();
            this.openTime = performance.timeOrigin + performance.now();
            DebugConsole.log("openWatson " + ( this.openTime - this.startTime));
            this.isRunning = true;
        });

        this.resultStream.on('error', (data) => {
            DebugConsole.log(data);
            this.notificationService.sendNotificationEvent(new NotificationEvent(WatsonService.NOTIFY_EVENT, {event: 'error', data}));
            this.isRunning = false;
        });

        this.resultStream.recognizeStream.on('close', (data) => {
            this.notificationService.sendNotificationEvent(new NotificationEvent(WatsonService.NOTIFY_EVENT, {event: 'close', data}));
            this.isRunning = false;
        });

        this.resultStream.recognizeStream.on('stop', (data) => {
            this.notificationService.sendNotificationEvent(new NotificationEvent(WatsonService.NOTIFY_EVENT, {event: 'stop', data}));
        });

        // this.resultStream.recognizeStream.on('message', (data) =>  {
        // 	this.notificationService.sendNotificationEvent(new NotificationEvent(WatsonService.NOTIFY_EVENT, {event:'message', data}));
        // });

        this.resultStream.on('data', (data) => {
            this.notificationService.sendNotificationEvent(new NotificationEvent(WatsonService.NOTIFY_EVENT, {event: 'recognized', data}));
        });

    }

    startRecognize(token, stream, keywords, langModel = null) {

        if(langModel){
            this.startRecognizeInternal(token, stream, keywords, langModel);
        }
        else{
            this.settingsService.getSettingForUser(this.authService.getLoggedInUser().id, 'web-lang-model').subscribe( setting =>{
                let lang = setting ? setting.description : 'en-US_BroadbandModel';
                this.startRecognizeInternal(token, stream, keywords, lang);
            });
        }


    }

    /**
     * Create and return a RecognizeStream sourcing audio from the user's microphone
     *
     * @param {Object} options - Also passed to {RecognizeStream}, and {FormatStream} when applicable
     * @param {String} options.token - Auth Token for CF services - see https://github.com/watson-developer-cloud/node-sdk#authorization
     * @param {String} options.accessToken - IAM Access Token for RC services - see https://github.com/watson-developer-cloud/node-sdk#authorization
     * @param {String} [options.url='wss://stream.watsonplatform.net/speech-to-text/api'] - Base URL for a service instance
     * @param {Boolean} [options.format=true] - pipe the text through a FormatStream which performs light formatting. Also controls smart_formatting option unless explicitly set.
     * @param {Boolean} [options.keepMicrophone=false] - keeps an internal reference to the microphone stream to reuse in subsequent calls (prevents multiple permissions dialogs in firefox)
     * @param {String|DOMElement} [options.outputElement] pipe the text to a [WriteableElementStream](WritableElementStream.html) targeting the specified element. Also defaults objectMode to true to enable interim results.
     * @param {Boolean} [options.extractResults=false] pipe results through a ResultStream stream to simplify the objects. (Default behavior before v0.22) Requires objectMode.
     * @param {Boolean} [options.resultsBySpeaker=false] Pipe results through a SpeakerStream. Forces speaker_labels and objectMode to be true.
     * @param {MediaStream} [options.mediaStream] Optionally pass in an existing MediaStream
     *
     *
     * @param {Object} options
     * @param {String} [options.model='en-US_BroadbandModel'] - voice model to use. Microphone streaming only supports broadband models.
     * @param {String} [options.url='wss://stream.watsonplatform.net/speech-to-text/api'] base URL for service
     * @param {String} [options.token] - Auth token
     * @param {Object} [options.headers] - Only works in Node.js, not in browsers. Allows for custom headers to be set, including an Authorization header (preventing the need for auth tokens)
     * @param {String} [options.content-type='audio/wav'] - content type of audio; can be automatically determined from file header in most cases. only wav, flac, and ogg/opus are supported
     * @param {Boolean} [options.interim_results=true] - Send back non-final previews of each "sentence" as it is being processed. These results are ignored in text mode.
     * @param {Boolean} [options.continuous=true] - set to false to automatically stop the transcription after the first "sentence"
     * @param {Boolean} [options.word_confidence=false] - include confidence scores with results. Defaults to true when in objectMode.
     * @param {Boolean} [options.timestamps=false] - include timestamps with results. Defaults to true when in objectMode.
     * @param {Number} [options.max_alternatives=1] - maximum number of alternative transcriptions to include. Defaults to 3 when in objectMode.
     * @param {Array<String>} [options.keywords] - a list of keywords to search for in the audio
     * @param {Number} [options.keywords_threshold] - Number between 0 and 1 representing the minimum confidence before including a keyword in the results. Required when options.keywords is set
     * @param {Number} [options.word_alternatives_threshold] - Number between 0 and 1 representing the minimum confidence before including an alternative word in the results. Must be set to enable word alternatives,
     * @param {Boolean} [options.profanity_filter=false] - set to true to filter out profanity and replace the words with *'s
     * @param {Number} [options.inactivity_timeout=30] - how many seconds of silence before automatically closing the stream (even if continuous is true). use -1 for infinity
     * @param {Boolean} [options.readableObjectMode=false] - emit `result` objects instead of string Buffers for the `data` events. Does not affect input (which must be binary)
     * @param {Boolean} [options.objectMode=false] - alias for options.readableObjectMode
     * @param {Number} [options.X-Watson-Learning-Opt-Out=false] - set to true to opt-out of allowing Watson to use this request to improve it's services
     * @param {Boolean} [options.smart_formatting=false] - formats numeric values such as dates, times, currency, etc.
     * @param {String} [options.customization_id] - not yet supported on the public STT service
     ];
     */
    private getWatsonRecognizeOptions(token, stream, keywords, lang_model) {
        return {
            model: lang_model,
            accessToken: token,
            mediaStream: stream,
            extractResults: true,
            resultsBySpeaker: false,
            interim_results: true,
            timestamps: true,
            url: environment.watsonTextToSpeechUrl,
            // keywords: keywords,
            // keywords_threshold: 0.6,
            inactivity_timeout: 60,
            profanity_filter: false,
            objectMode: true, // send objects instead of text
            format: false // optional - performs basic formatting on the results such as capitals an periods
        };
    }

    stopRecognize() {
        if (this.resultStream) {
            DebugConsole.log('stop recognize');
            this.resultStream.stop();
            this.resultStream = null;
        }
    }
}
