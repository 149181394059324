import {Component, Input, OnInit} from '@angular/core';
import {PopupSetPayload} from '../PopupSetPayload';

@Component({
  selector: 'app-success-popup-modal',
  templateUrl: './success-popup-modal.component.html',
  styleUrls: ['./success-popup-modal.component.scss']
})
export class SuccessPopupModalComponent implements OnInit, PopupSetPayload {
  title = "Success";
  message = "Success Message";
  constructor() { }

  ngOnInit() {
  }

  setPayload(payload): void {
    this.message = payload;
  }
}
