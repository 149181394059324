import {ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DebugConsole} from '../../../../classes/helpers/debug-console';

@Component({
    selector: 'app-slider-livescore',
    templateUrl: './slider-livescore.component.html',
    styleUrls: ['./slider-livescore.component.scss']
})
export class SliderLiveScoreComponent implements OnInit, OnDestroy {

    @Input() value: number;
    @ViewChild('thumb') thumbElement : ElementRef;
    fillColor:string;

    linear_gradient = ['#d84844','#e18432','#ecd824','#ACBF00','#3bbd0f'];

    constructor() {
    }

    ngOnInit() {
        const interpolator = require('color-interpolate');
        let colorMap = interpolator(this.linear_gradient);
        this.fillColor = colorMap(this.value/100);
        this.updateScale(this.value);
    }


    public updateScale(value) {
      if(this.thumbElement && this.thumbElement.nativeElement){
        const interpolator = require('color-interpolate');
        let colorMap = interpolator(this.linear_gradient);
        this.fillColor = colorMap(this.value/100);
        this.thumbElement.nativeElement.setAttribute("style", 'left:' + value + '%; transform: translateX(-50%);');
      }
    }

    ngOnDestroy(): void {
        // clearInterval(this.animateHandle);
    }
}
