import { EventEmitter, Output, Directive } from '@angular/core';
import {timer} from 'rxjs';


@Directive()
export class CustomTimer {

    @Output('ticked') tick: EventEmitter<any> = new EventEmitter<any>();
    @Output('finished') finished: EventEmitter<any> = new EventEmitter<any>();


    protected timer: any;
    protected timerSubscription: any;
    protected isCountdown: boolean;
    protected finalTick: number;
    protected startTick: number;
    protected interval: number;
    protected loop: boolean;
    protected finalString: string;
    lastTick: number = 0;

    constructor(_startTick: number = 0, _finalTick: number = Number.MAX_VALUE, _finalText: string = '', _interval: number = 1000, _loop: boolean = false) {
        this.isCountdown = _startTick > _finalTick;
        this.startTick = _startTick;
        this.finalTick = _finalTick;
        this.interval = _interval;
        this.loop = _loop;
        this.finalString = _finalText;
        this.timer = timer(0, _interval);
    }

    start() {
        this.lastTick = 0;
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        this.timer = timer(0, this.interval);
        this.timerSubscription = this.timer.subscribe(t => this.handleTick(this.lastTick++));
    }

    stop() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }

    pause() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }

    resume() {
        this.timerSubscription = this.timer.subscribe(t => this.handleTick(this.lastTick++));
    }

    handleTick(tick) {
        let tickDiff = Math.abs(this.finalTick - this.startTick);
        let currentTick = this.loop ? tick % (tickDiff + 1) : tick;

        let t = (this.isCountdown ? this.startTick - currentTick : this.startTick + currentTick).toString();
        if ((this.finalString && currentTick == tickDiff)) {
            t = this.finalString;
        }
        this.tick.emit(t);

        if ((this.finalString && currentTick > tickDiff) || (!this.finalString && currentTick >= tickDiff)) {
            // it reached to limit
            this.finished.emit();
            if (!this.loop) {
                this.timerSubscription.unsubscribe();
            }
        }

    }

    static formatTime(seconds): string {
        // Hours, minutes and seconds
        let hrs = ~~(seconds / 3600);
        let mins = ~~((seconds % 3600) / 60);
        let secs = seconds % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = '';

        if (hrs > 0) {
            ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
        }

        ret += '' + mins + ':' + (secs < 10 ? '0' : '');
        ret += '' + secs;
        return ret;
    }
}
