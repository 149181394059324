import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {NgModule} from '@angular/core';
import {routes} from './app-module-routing.module';
import {ContentSignedAuthGuard} from './classes/guards/content-signed-auth.guard';
import {RoomSetupComponent} from './presentr-app/app-settings/room-setup/room-setup.component';

const appRoutes: Routes = [
    {
        path: '',
        children: routes
    },
    {
        path: '**',
        redirectTo: '/'
    }
];


@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            relativeLinkResolution: 'corrected',
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: "enabled",
            scrollPositionRestoration: "enabled"
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}

