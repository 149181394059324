import { Component, OnInit } from '@angular/core';

@Component({
  host: {
    class:'flex w-full'
  },
  selector: 'app-base-list',
  templateUrl: './base-list.component.html',
  styleUrls: ['./base-list.component.scss']
})
export class BaseListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
