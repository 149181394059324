import {Injectable} from '@angular/core';

import * as DetectRTC from 'detectrtc';

@Injectable()
export class MediaStreamService {

    constructor() {
        this.detectRTCInitialize(null);
    }

    detectRTCInitialize(callback) {
        DetectRTC.load(callback);
    }


    static readonly videoDimensions = {
        width:640,
        height:360
    };

    hasMicPermissions() {
        return DetectRTC.isWebsiteHasMicrophonePermissions;
    }

    hasCameraPermissions() {
        return DetectRTC.isWebsiteHasWebcamPermissions;
    }

    getMediaStream(options, onSuccess, onFail): Promise<any> {
        let n = <any>navigator;
        n.getUserMedia = n.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia || n.msGetUserMedia;


        return navigator.mediaDevices.getUserMedia(options)
            .then((stream) => {

                if(stream.getVideoTracks().length > 0){
                    let videoCapabilities = stream.getVideoTracks()[0].getCapabilities();

                    let constraints = {
                        width : MediaStreamService.videoDimensions.width,
                        height : MediaStreamService.videoDimensions.height,
                        frameRate : Math.min(30, (videoCapabilities.frameRate).max)
                    };
                    stream.getVideoTracks()[0].applyConstraints(constraints);
                }

                if (onSuccess)
                    onSuccess(stream);

                return {success: true, data: stream};
            })
            .catch((err) => {
                if (onFail)
                    onFail(err);
                else
                    alert("Error acquiring Media Streams. Visit us at for FAQs at https://presentr.zendesk.com/hc/en-us  ERROR:" + err);
                return {success: false, data: err};
            });

        //
        // return n.getUserMedia(options)
        // 	.then((stream) =>{
        // 		onSuccess(stream);
        // 		return {success:true, data:stream};
        // 	})
        // 	.catch((err) => {
        // 		onFail(err);
        // 		return {success:false, data:err};
        // 	});
    }

    getAudioStream(onSuccess, onFail): Promise<any> {
        return this.getMediaStream({video: false,
            audio: true
                // {
                //     autoGainControl: false,
                //     echoCancellation: false,
                //     noiseSuppression: false
                // }
            }, onSuccess, onFail);
    }

    getVideoStream(onSuccess, onFail): Promise<any> {
        return this.getMediaStream({video: true, audio: false}, onSuccess, onFail);
    }

    getAudioVideoStream(onSuccess, onFail): Promise<any> {
        return this.getMediaStream({video: true,
            audio: true
                // {
                //     autoGainControl: false,
                //     echoCancellation: false,
                //     noiseSuppression: false
                // }
            }, onSuccess, onFail);
    }

    stopMediaStream(stream) {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
    }
}
