import {Component, ElementRef, Input, OnInit, ViewChild,AfterViewInit} from '@angular/core';

@Component({
    selector: 'app-base-scale-thumb-score',
    templateUrl: './base-scale-thumb-score.component.html',
    styleUrls: ['./base-scale-thumb-score.component.scss']
})
export class BaseScaleThumbScoreComponent implements AfterViewInit {

    @ViewChild('textElement') textElement: ElementRef;
    @Input() fillColor: string;
    @Input() value: number;

    constructor() {
    }

    ngAfterViewInit() {
        this.updateThumb(this.fillColor);
    }

    updateThumb(fillColor){
        if (this.textElement) {
            this.textElement.nativeElement.setAttribute('style', 'color:' + fillColor);
        }
    }

}
