import { Injectable } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {BehaviorSubject} from "rxjs";

@Injectable()



export class ViewSDKClient {

    previewEvents: BehaviorSubject<any> = new BehaviorSubject(false);

    previewFilePromise: any;

    adobeDCView: any;

    readyPromise: Promise<void> = new Promise((resolve) => {
        if (window.AdobeDC) {
            console.log('hi')
            resolve();
        } else {
            /* Wait for Adobe Document Cloud View SDK to be ready */
            console.log('yobro')
            document.addEventListener('adobe_dc_view_sdk.ready', () => {
                console.log('hello')
                resolve();
            });
        }
    });


    ready() {

        return this.readyPromise;

    }

    viewFile(divId: string, content: any, viewerConfig: any) {
        const config: any = {
            /* Pass your registered client id */
            //clientId: '83211fc1505245e88e8bf44f99f1bd51',
            clientId: environment.viewSDKKey,
        };

        if (divId) { /* Optional only for Light Box embed mode */
            /* Pass the div id in which PDF should be rendered */
            config.divId = divId;
        }
        /* Initialize the AdobeDC View object */
        this.adobeDCView = new window.AdobeDC.View(config);
        /* Invoke the file preview API on Adobe DC View object */
        this.previewFilePromise = this.adobeDCView.previewFile(content,viewerConfig);

        return this.previewFilePromise;
    }

    getPDFCurrentPage(){
        if(this.previewFilePromise) {
            return this.previewFilePromise.then(adobeViewer => {
                console.log('file promise');
                return  adobeViewer.getAPIs().then(apis => {
                    return apis.getCurrentPage()
                        .then(result => {
                            return result;
                        })
                        .catch(error => console.log(error));
                });
            });
        }
    }

    setPDFCurrentPage(pageNum){
        if(this.previewFilePromise) {
            return this.previewFilePromise.then(adobeViewer => {
                return adobeViewer.getAPIs().then(apis => {
                    return apis.gotoLocation(pageNum)
                        .then(() => {
                            return true;
                        })
                        .catch(error => console.log(error));
                });
            }).catch(error => console.log(error));
        }
    }

    previewFileUsingFilePromise(divId: string, filePromise: Promise<string | ArrayBuffer>, fileName: any) {
        /* Initialize the AdobeDC View object */
        this.adobeDCView = new window.AdobeDC.View({
            /* Pass your registered client id */
            //clientId: '83211fc1505245e88e8bf44f99f1bd51',
            clientId: environment.viewSDKKey,
            /* Pass the div id in which PDF should be rendered */
            divId,
        });

        /* Invoke the file preview API on Adobe DC View object */
        this.adobeDCView.previewFile({
            /* Pass information on how to access the file */
            content: {
                /* pass file promise which resolve to arrayBuffer */
                promise: filePromise,
            },
            /* Pass meta data of file */
            metaData: {
                /* file name */
                fileName
            }
        }, {});
    }

    registerSaveApiHandler() {
        /* Define Save API Handler */
        const saveApiHandler = (metaData: any, content: any, options: any) => {
            return new Promise((resolve) => {
                /* Dummy implementation of Save API, replace with your business logic */
                setTimeout(() => {
                    const response = {
                        code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                        data: {
                            metaData: Object.assign(metaData, { updatedAt: new Date().getTime() })
                        },
                    };
                    resolve(response);
                }, 2000);
            });
        };

        this.adobeDCView.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.SAVE_API,
            saveApiHandler,
            {}
        );
    }

    registerEventsHandler() {

        /* Register the callback to receive the events */
       this.adobeDCView.registerCallback(
            /* Type of call back */
            window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
            /* call back function */
            (event: any) => {
                this.previewEvents.next(event);
            },
            /* options to control the callback execution */
            {
                /* Enable PDF analytics events on user interaction. */
                //enablePDFAnalytics: true,
                enableFilePreviewEvents: true
            }
        );
    }
}

