<div *ngIf="isCoachVisible && !isCoachOpen && messages.length > 0" class="fixed bottom-0 left-0 right-0 mb-16 h-auto flex flex-col pointer-events-none">
  <div *ngFor="let message of messages" class='container mx-auto'>
    <!-- <h6>Title 1</h6> -->
    <app-base-chat-bubble [message]="message.message" class="flex ml-32 max-w-1/2"></app-base-chat-bubble>
    <!--          <p>{{message.message}}</p>-->
  </div>
</div>

<div *ngIf="isCoachVisible" class="fixed h-full w-full bottom-0 left-0 transition-slow transition-property-all {{isActive ? 'coach-drop-shadow max-h-3/4' : 'max-h-16 cursor-pointer' }}"
      (click)="openCoach()" (mouseenter)="hovered()" (mouseleave)="hoveredFinished()" id="coach-component">

  <div class="flex flex-col w-full h-full  {{isActive ? 'bg-blue' :'bg-gray-darker '}} transition transition-property-bg">
    <div class="relative flex w-full h-auto justify-center" data-tutorial="coach-icon">
      <div class="absolute container z-20">
        <div class="absolute left-0 top-0 w-16 h-16 md:w-24 md:h-24" >

          <span *ngIf="badgeNumber > 0" class="absolute -top-1/2 right-0 md:right-1/4 z-30 rounded-full w-5 h-5 text-center items-center bg-error text-white text-sm">{{badgeNumber}}</span>
          <app-svg-component type="abbie" class="absolute left-0 -top-3/4 w-full h-full" ></app-svg-component>
        </div>

        <div class="absolute right-0 top-0 h-10"  >
            <div class="absolute h-full w-32 right-0 -top-1 cursor-pointer items-center hover:font-bold group" *ngIf="isCoachOpen" (click)="$event.stopPropagation(); closeCoach()">
                <span class="text-white text-sm mr-2">close panel</span>
                <button class="bg-blue text-white font-bold h-8 w-8 group-hover:border group-hover:bg-blue-darker">x</button>
            </div>
        </div>
      </div>
      <div class="relative {{isActive ? 'bg-blue-lighter border-blue' :' bg-gray border-gray-darker '}}  w-full border-t-2 transition transition-property-bg" >
        <div class="container mx-auto flex flex-grow flex-row w-full h-full text-center text-white">
          <div class="flex-1 border-r-2 p-2 {{isActive ? 'border-blue' :' border-gray-darker'}} cursor-pointer
          hover:font-bold {{selectedTab == 'chat' ? 'font-bold ' + (isActive ? 'bg-blue': '') :''}}" (click)="selectedTab = 'chat'">Abbie</div>
          <div class="flex-1 p-2 cursor-pointer hover:font-bold {{selectedTab == 'sessions' ? 'font-bold ' + (isActive ? 'bg-blue': '') :''}}"
               (click)="selectedTab = 'sessions'" >Scheduled Sessions</div>
          <div class="flex-1 border-l-2 p-2 {{isActive ? 'border-blue' :' border-gray-darker'}} cursor-pointer
          hover:font-bold {{selectedTab == 'tasks' ? 'font-bold ' + (isActive ? 'bg-blue': '') :''}}" (click)="selectedTab = 'tasks'">Task List</div>
        </div>
      </div>
    </div>
    <div class="flex mx-auto w-full h-full {{isActive ? 'visible' :'invisible'}} transition-property-all transition-delay-longest overflow-hidden" *ngIf="isCoachOpen">
      <app-coach-chat class="w-full h-full bg-gray-lighter" *ngIf="selectedTab == 'chat'"></app-coach-chat>
      <app-coach-scheduler class="w-full h-full" *ngIf="selectedTab == 'sessions'"></app-coach-scheduler>
      <app-coach-tasks class="w-full h-full" *ngIf="selectedTab == 'tasks'"></app-coach-tasks>
    </div>
  </div>
</div>
