import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-free-trial-badge',
  templateUrl: './free-trial-badge.component.html',
  styleUrls: ['./free-trial-badge.component.scss']
})
export class FreeTrialBadgeComponent implements OnInit {
  @Input() freeTrialDays: any;
  @Input() canManageCustomerBilling: any;

  constructor() { }

  ngOnInit() {
  }

}
