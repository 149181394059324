
export enum FeedbackEventTypeEnums {
    NotWordsReceived,
    VolumeToLow,
    VolumeToHigh,
    VolumePerfect,
    VolumeGood,

    FillerMidSentenceToHigh,
    FillerBehaviourToHigh,
    FillerTooHigh,
    FillerPerfect,
    FillerGood,

    PaceToHigh,
    PaceToLow,
    PaceNoPauses,
    PaceGood,
    PacePerfect
}