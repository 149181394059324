import {ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import {
    StripeCardElementOptions,
    StripeElementsOptions
  } from '@stripe/stripe-js';
import {environment} from '../../../environments/environment';
import {ApiService, CustomerService, AuthService, LoadingService, PingsService} from '../../classes/services';
import {Router} from '@angular/router';
import {PingsPageEnum, PingsActionEnum} from '../../classes/enums/pings-page.enum';
import {PopupNotificationService} from '../../classes/services/core/popup-notification.service';
import {ConfirmationPopupModalComponent} from '../popup-manager/confirmation-popup-modal/confirmation-popup-modal.component';
import {SubscriptionTrialFormComponent} from './subscription-trial-form/subscription-trial-form.component';


@Component({
  selector: 'app-base-customer-create',
  templateUrl: './base-customer-create.component.html',
  styleUrls: ['./base-customer-create.component.scss']
})
export class BaseCustomerCreateComponent implements OnInit {
    paymentStep = false;
    startTrialStep = false;
    freeTrialDays = 14;
    selectedPlan = null;
    @ViewChild('subscriptionTrialForm') form : SubscriptionTrialFormComponent; //reference to child form
    @Input() showFreemium = false;
    constructor(private customerService: CustomerService,
                private authService: AuthService,
                private router: Router,
                private ngCh: ChangeDetectorRef,
                private pingsService: PingsService) {
    }

    ngOnInit() {
        this.pingsService.sendPing(PingsPageEnum.CustomerCreate, PingsActionEnum.viewed);
        this.determineCurrentPlan();
        this.customerService.getAppInfo().subscribe(response => {
            this.freeTrialDays = response.response.trial_period_days;
        });
    }

    get planOptions(){
        return SubscriptionTrialFormComponent.PlanOptions.filter(x => x.type > 0 || (this.showFreemium && x.type ==0 ));
    }

    subscribeClicked(){
        //this.paymentStep = true;
        this.startTrialStep = true;
        this.ngCh.detectChanges();
        if(this.form)
            this.form.updatePurchasingFor(this.selectedPlan.purchasing_for) //updates model.seats & model.name & mounts stripe card/recalculates
    }

    planChanged(plan){
        this.selectedPlan = plan;
        this.ngCh.detectChanges();
    }

    private determineCurrentPlan(){
        this.selectedPlan = SubscriptionTrialFormComponent.PlanOptions[SubscriptionTrialFormComponent.PlanOptions.length - 1];
    }

    get selectedPlanType(){
        return this.selectedPlan ? this.selectedPlan.type : 0;
    }

    completedSubscription(){
        this.authService.licenses().subscribe(licResp => {
            if (licResp.success) {
                let license = this.authService.getLicense();
                if (license && license.customer && license.customer.type == 'team')
                    this.router.navigate(['/customer/manager/team-wizard']);
                else
                    this.router.navigate(['/customer/create/success']);
            } else
                this.router.navigate(['/customer/create/success']);
        });
    }

}
