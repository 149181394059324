<div class="card no-border">
  <div class="card-body align-middle">
    <div class="row" *ngIf="recording.video_path">
      <div class="container">
        <video id="video" controls class="container"autoplay>
          <source [src]="securePath()" />
        </video>
        <div *ngIf="!isFastForwarding">
          <span class="fa-stack fa-2x" (click)="forward()">
            <i class="fa fa-circle fa-stack-2x" style="color: #137ad7"></i>
            <i class="fa fa-forward fa-stack-1x fa-inverse" style="padding-left: 5px"></i>
          </span>
        </div>
        <div *ngIf="isFastForwarding">
          <span class="fa-stack fa-2x" (click)="resume()">
            <i class="fa fa-circle fa-stack-2x" style="color: #137ad7"></i>
            <i class="fa fa-play fa-stack-1x fa-inverse" style="padding-left: 5px"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="recording.audio_path">
      <div class="col-12">
        <audio controls class="full-width" autoplay>
          <source [src]="securePath()" />
        </audio>
      </div>
    </div>
  </div>
</div>