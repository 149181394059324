import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-svg-component',
  templateUrl: './svg-component.component.html',
  styleUrls: ['./svg-component.component.scss']
})
export class SvgComponentComponent implements OnInit {

  @Input() type:string;
  // @Input() fillColor:string;
  // @Input() svgClass:string;
  constructor() { }

  ngOnInit() {
  }

}
