<app-popup-base>
    <ng-container>
        <div class="modal-body container">
            <div class="p-4 text-center">
                <h2 class="text-error">{{title}}</h2>
                <p [innerHTML]="message"></p>
            </div>
        </div>
    </ng-container>
</app-popup-base>
