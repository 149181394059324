import {ElementRef, Injectable, TemplateRef} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

@Injectable()
export class SidebarTemplateProviderService {
    public templateReferences: TemplateRef<any>[] = [];
    public underTemplateReferences: TemplateRef<any>[] = [];
    public topTemplateReferences: TemplateRef<any>[] = [];


    constructor(private router: Router) {
        router.events.subscribe((val) => {
          if(val instanceof NavigationStart)
          {
              this.clearSidebarTemplates();
              this.clearTopBarTemplates();
          }
        });
    }
    public addSidebarTemplate(inBoxRefs: TemplateRef<any>, underBoxRefs:TemplateRef<any>, clear = false){
        if(clear){
            this.clearSidebarTemplates();
        }
        if(inBoxRefs)
            this.templateReferences.push(inBoxRefs);
        if(underBoxRefs)
            this.underTemplateReferences.push(underBoxRefs);
    }
    public removeSidebarTemplate(inBoxRefs: TemplateRef<any>, underBoxRefs:TemplateRef<any>){
        if(inBoxRefs)
            this.templateReferences = this.templateReferences.filter( x => x !== inBoxRefs);
        if(underBoxRefs)
            this.templateReferences = this.templateReferences.filter( x => x !== underBoxRefs);
    }
    public clearSidebarTemplates(){
        this.templateReferences = [];
        this.underTemplateReferences = [];
    }
    public clearTopBarTemplates(){
        this.topTemplateReferences = [];
    }

    public addTopBarTemplate(inBoxRefs: TemplateRef<any>, clear = false){
        if(clear){
            this.clearTopBarTemplates();
        }
        if(inBoxRefs)
            this.topTemplateReferences.push(inBoxRefs);
    }

}
