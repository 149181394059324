import {Component, ElementRef, NgZone, OnInit, Renderer2} from '@angular/core';
import {TutorialService} from '../../classes/services/core/tutorial.service';
import {EventSubscriptionService} from '../../classes/services';

@Component({
    selector: 'app-tutorial-manager',
    templateUrl: './tutorial-manager.component.html',
    styleUrls: ['./tutorial-manager.component.scss']
})
export class TutorialManagerComponent implements OnInit {

    // private tutorialElementsId = [];
    // private currTutorialIndex = 0;
    // public tutorialSteps = 1;
    // public tutorialCurrentSteps = 1;

    inTutorial: boolean = false;
    activeTutorial:any;
    tutorialKey: string = '';

    static RUN_TUTORIAL = 'RUN_TUTORIAL';

    constructor(
        private notificationService: EventSubscriptionService,
        private _ngZone: NgZone,
        private renderer: Renderer2) {
    }

    ngOnInit() {
        window.onresize = e => {
            this._ngZone.run(() => {
                if (this.inTutorial){
                    // this.runTutorial(this.tutorialElementsId);
                    this.initTutorial(this.activeTutorial);
                }
            });
        };

        window.onscroll = e => {
            this._ngZone.run(() => {
                if (this.inTutorial)
                {
                    this.initTutorial(this.activeTutorial);
                    // this.runTutorial(this.tutorialElementsId);
                }
            });
        };

        this.notificationService.subscribeToNotificationEvent(
            TutorialManagerComponent.RUN_TUTORIAL,
            this,
            (scope, data) => {
                scope.initTutorial(data.data);
            }
        );

    }

    static isElementInView(el, fullyInView , moveInView) {
        let rect = el.getBoundingClientRect();
        let elemTop = rect.top;
        let elemBottom = rect.bottom;

        let isVisible = fullyInView
            ? elemTop >= 0 && elemBottom <= window.innerHeight
            : !(elemBottom < 0 || elemTop > window.innerHeight);
        // DebugConsole.log(elemBottom, elemTop, window.innerHeight);

        if (!isVisible && moveInView) {
            el.scrollIntoView();
        }
        // Partially visible elements return true:
        //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return isVisible;
    }

    initTutorial(data){
        this.inTutorial = true;
        this.tutorialKey = data.tutorialKey;
        this.activeTutorial = data;
        this.findElements(data.elements);
    }

    private attachElement(containerElement, sourceElement, element){
        let sourceBoundingRect = sourceElement.getBoundingClientRect();

        let divElement = document.createElement('div');
        let divElementRef = new ElementRef(divElement);

        let newTop: number;


        let titleElement = document.createElement('h4');
        let titleElementRef = new ElementRef(titleElement);
        titleElement.innerText = element.description.title;
        titleElementRef.nativeElement.className = 'flex justify-center pt-2 text-blue bg-white';
        divElement.appendChild(titleElement);

        let textElement = document.createElement('p');
        let textElementRef = new ElementRef(textElement);
        textElement.innerText = element.description.text;
        textElementRef.nativeElement.className = 'flex justify-center pb-2 bg-white';
        divElement.appendChild(textElement);

        divElementRef.nativeElement.className = 'absolute z-inherit justify-center text-center bg-white';

        /*let textStyle = `top:${sourceBoundingRect.top}px; bottom:${sourceBoundingRect.bottom}px;
                    width:${sourceBoundingRect.width}px; height:${sourceBoundingRect.height}px;
                    left:${sourceBoundingRect.left}px; right:${sourceBoundingRect.right}px;`;*/

        if(element.description.title.toLowerCase() === 'need help?'){
            newTop = sourceBoundingRect.top + 116;
        }
        else newTop = sourceBoundingRect.top;

        let textStyle = `top:${newTop}px; bottom:${sourceBoundingRect.bottom}px;
                    width:${sourceBoundingRect.width}px;
                    left:${sourceBoundingRect.left}px; right:${sourceBoundingRect.right}px;`;

        this.renderer.setAttribute(divElementRef.nativeElement, 'style', textStyle);

        // Append the cloned <li> element to <ul> with id="myList1"
        containerElement.appendChild(divElement);
    }

    private static clearContainer(containerElement){
        while (containerElement.firstChild) {
            containerElement.removeChild(containerElement.firstChild);
        }
    }

    findElements(data){
        let container = document.getElementById('tutorialContainer');
        if(!container)
            return;

        TutorialManagerComponent.clearContainer(container);
        data.forEach(el => {
            let tutItem = document.querySelector('[data-tutorial="'+el.element+'"]');
            if(tutItem){
                let inView = TutorialManagerComponent.isElementInView(tutItem, false,false);
                if(inView){
                    this.attachElement(container, tutItem, el);
                }
            }
        });
    }

    closeTutorial(){
        this.inTutorial = false;
        this.activeTutorial = null;
    }
}
