import {Component, OnInit} from '@angular/core';
import {EventSubscriptionService, ResultService} from '../../../classes/services';
import {ResultType} from '../../../classes/models/result';
import {DebugConsole} from '../../../classes/helpers/debug-console';
import {NotificationEvent} from '../../../classes/models';

@Component({
  selector: 'app-result-type-filter',
  templateUrl: './result-type-filter.component.html',
  styleUrls: ['./result-type-filter.component.scss']
})
export class ResultTypeFilterComponent implements OnInit {

  public static RESULT_FILTER_CHANGED_EVENT = 'result-type-filter-changed';
  buttons = [
    // {
    //     label : "Kinect",
    //     selected : false,
    //     image: "../../../../assets/analytics/core/score-card/icon-kinect.png",
    //     type: ResultType.kinect
    // },
    //
    // {
    //   label : "Mobile",
    //   selected : false,
    //   image: "../../../../assets/analytics/core/score-card/icon-mobile.png",
    //    type: ResultType.mobile
    // },
    //
    // {
    //   label : "Web",
    //   selected : false,
    //   image: "../../../../assets/analytics/core/score-card/icon-web.png",
    //   type: ResultType.web
    // }
  ];

  constructor(private resultService: ResultService, private notificationService: EventSubscriptionService) {
  }

  ngOnInit() {
    this.resultService.getSelectedResultFilter().subscribe(resp => {
      // DebugConsole.log("RESULT TYPES", resp);
      this.buttons.forEach(value => {
        // DebugConsole.log(value, resp.findIndex(x => x == value.type));
        if (resp.findIndex(x => x == value.type) >= 0) {
          value.selected = true;
        }
      });
    });

  }

  toggleSelection(button) {
    button.selected = !button.selected;
    let sel = this.buttons.filter(x => x.selected).map(y => y.type);
    if (sel.length == 0) {
      this.buttons.map(y => {
        y.selected = true;
      });
      sel = this.buttons.filter(x => x.selected).map(y => y.type);
    }
    let observable = this.resultService.setSelectedResultFilter(sel);
    if (observable) {
      observable.subscribe(resp => {
        this.notificationService.sendNotificationEvent(new NotificationEvent(ResultTypeFilterComponent.RESULT_FILTER_CHANGED_EVENT, sel));
      });
    }
  }

}
