import { Component, OnInit } from '@angular/core';
import {PopupSetPayload} from '../PopupSetPayload';
import {PopupManagerComponent} from '../popup-manager.component';

@Component({
  selector: 'app-input-popup-modal',
  templateUrl: './input-popup-modal.component.html',
  styleUrls: ['./input-popup-modal.component.scss']
})
export class InputPopupModalComponent implements OnInit , PopupSetPayload {
    title = "Generic";
    message = "Generic Message";
    value:string;

    constructor(private popupManager: PopupManagerComponent) { }

    ngOnInit() {
    }

    setPayload(payload): void {
        this.title = payload.title;
        this.message = payload.message;
    }

    submit(){
        this.popupManager.closeOpenModal({label:this.value});
    }

    cancel() {
        this.popupManager.closeOpenModal(null);
    }
}
