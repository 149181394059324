import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import {DebugConsole} from '../../helpers/debug-console';
import {SettingsService} from '../core/settings.service';
import { flatMap, map } from 'rxjs/operators';
import { format } from 'd3-format';

@Injectable()
export class TextStoreService {
    textStorePullDateCookie = 'text-store-pull-date-';

    constructor(private apiService: ApiService, private settingsService: SettingsService) {
    }

    public getTextByKey(key){
        let cached = this.getCachedTextByKey(key);
        if(cached && cached[key]){
            return this.hasTextStoreChangedForKey(key).pipe( flatMap(r => {
                if (r) {
                    return this.cacheText(key);
                }
                else{
                    return new Observable<any>((observer) => {
                        observer.next(cached);
                        observer.complete();
                    });
                }
            })
            )
        }
        else{
            return this.cacheText(key).pipe ( map(r => {
                return r;
            })
            )
        }
    }

    private hasTextStoreChangedForKey(key) {
        return this.apiService.get('/content/date-check').pipe (map(response => {

            let resp = JSON.parse(JSON.stringify(response));
            if (resp.success) {
                // DebugConsole.log("ContentDateCheck", resp);
                if (this.settingsService.hasLocalSetting(this.textStorePullDateCookie + key)) {
                    // DebugConsole.log("Cookie Exists for key", key);
                    let textStorePullDate = this.settingsService.getLocalSetting(this.textStorePullDateCookie + key);
                    let newDate = new Date(textStorePullDate);
                    // DebugConsole.log("DATE ON COOKIE", newDate, textStorePullDate);
                    let updatedText = new Date(resp.response.find(x => x.name == 'content-date-text-store').date);
                    // DebugConsole.log("hasTextChanged" , newDate, updatedText);
                    let needsUpdate = newDate < updatedText;
                    return needsUpdate;
                }
                else {
                    // DebugConsole.log("Cookie Does Exists", key);
                    return true;
                }
            }

        })
        )
    }

    private cacheText(key) {
        let content = {
            result_type: 'web',
            key: key
        };
        return this.apiService.post('/content/text-store', content).pipe( map(response => {
            let resp = JSON.parse(JSON.stringify(response));
            if (resp.success) {
                let texts = [];
                let val = resp.response.map(x => {
                    let v = x.web ? x.web : x.default;
                    texts[x.key] = v;
                    this.settingsService.setLocalSetting(x.key, false, v);
                    return {key: x.key, text: v};
                });

                // DebugConsole.log(val);
                this.settingsService.setLocalSetting(this.textStorePullDateCookie + key, false, (new Date()).toString());
                return texts;
            }
        })
        )
    }

    private getCachedTextByKey(key) {
        return this.settingsService.getAllLocalSettings(key);
    }
}
