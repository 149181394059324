<section class="container">
    <div class="flex flex-col">
        <h2>{{title}}</h2>

        <app-base-card class="flex w-full mt-4">
            <ng-container slot="header">
                <div class="flex p-2 w-full justify-between">
                    <div class="flex flex-row align-middle">
                        <span class="text-sm my-auto pr-2">Default Topics</span>
                        <app-toggle-slider [value]="hidingDefaults" [trueText]="'Hidden'" [falseText]="'Shown'" (changed)="defaultsClicked($event)"></app-toggle-slider>
                    </div>

                    <button [routerLink]="[createEditRoute]" class="button-blue">Create Topic</button>
                </div>
            </ng-container>
            <ng-container slot="body">

                <app-base-list class="flex w-full p-4">
                    <ng-container slot="header_row">
                        <tr class="h-16 text-left">
                            <th>Title</th>
                            <th>Prompt #</th>
                            <th>For</th>
                            <th>Actions</th>
                        </tr>
                    </ng-container>
                    <ng-container *ngFor="let topic of topics" >
                        <tr class="h-16  {{(topic.user_id == userId && topic.customer_id == customerId) ? '' : 'bg-gray-light'}}">
                            <td>{{ topic.topic }}</td>
                            <td>{{ topic.prompts ? topic.prompts.length : 'Empty' }}</td>
                            <td>{{  topic.customer_id ? 'Groups' : topic.user_id ? 'User': 'Generic' }}</td>
                            <td>
                                <a class="button-white" [routerLink]="[createEditRoute+'/'+topic.id]" *ngIf="topic.user_id == userId && topic.customer_id == customerId">Edit</a>
                                <a class="button-white" (click)="deleteTopic(topic)" *ngIf="topic.user_id == userId && topic.customer_id == customerId">Delete</a>
                            </td>
                        </tr>
                    </ng-container>
                </app-base-list>
            </ng-container>
        </app-base-card>
    </div>
</section>

