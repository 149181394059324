import {Injectable} from '@angular/core';
import {observable, Observable, Observer, Subscription} from 'rxjs';
import {LearnCardData} from '../../models/learn-card-data';
import {Blog} from '../../models/blog';
import {VideoTip} from '../../models/video-tip';
import {Topic} from '../../models/topic';
import {TopicPrompt} from '../../models/topic-prompt';
import {environment} from '../../../../environments/environment';
import {AuthService} from './auth.service';
import {ApiService} from './api.service';
import { map } from 'rxjs/operators';
import { Response } from 'aws-sdk';


@Injectable()
export class ContentService {
    response: any;

    constructor(private apiService: ApiService, private authService: AuthService) {
    }

    getReleaseNotes() {
        return this.apiService.get('/content/release-info').pipe (map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    getSignedContentPath(type: string, typeId: number) {
        return environment.ketchupUrl + '/content/signed?type=' + type + '&id=' + typeId + '&token=' + this.authService.getToken();
    }

    getSignedContentUrl(type: string, typeId: number) {
        return this.apiService.post('/content/signed',{id:typeId, type:type, token:this.authService.getToken()}).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    getPresentationUrl(file: string) {
        return this.apiService.post('/content/presentation',{file:file}).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    getVideoBlogs(id: number = null) {
        let queryParams = '';
        if (id) {
            queryParams = '?id=' + id;
        }

        return this.apiService.get('/content/video-tips' + queryParams).pipe( map(
            (response) => {
                let resp = JSON.parse(JSON.stringify(response));
                let translatedResp = [];
                if (resp.success) {
                    resp.response.forEach((val) => {

                        let b = <VideoTip>val;
                        translatedResp.push(b);
                    });

                    resp.response = translatedResp;
                }
                return resp;
            }
        )
        )
    }

    getVideoBlogsLearnCards(id: number = null) {
        return this.getVideoBlogs(id).pipe( map(resp => {
            let translatedResp = [];

            if (resp.success) {
                resp.response.forEach((val) => {
                    let b = new LearnCardData();
                    b.initWithVideoTip(val);
                    translatedResp.push(b);
                });
                resp.response = translatedResp;
            }

            return resp;
        })
        )
    }

    getBlogs(id: number = null) {
        let queryParams = '';
        if (id) {
            queryParams = '?id=' + id;
        }
        return this.apiService.get('/content/blogs' + queryParams).pipe( map(
            (response) => {
                let resp = JSON.parse(JSON.stringify(response));
                let translatedResp = [];
                if (resp.success) {
                    resp.response.forEach((val) => {
                        let b = <Blog>val;
                        translatedResp.push(b);
                    });
                    resp.response = translatedResp;
                }

                return resp;
            }
        )
        )
    }

    getBlogsLearnCards(id: number = null) {
        return this.getBlogs(id).pipe( map(resp => {
            let translatedResp = [];

            if (resp.success) {
                resp.response.forEach((val) => {
                    let b = new LearnCardData();
                    b.initWithBlog(val);
                    translatedResp.push(b);
                });
                resp.response = translatedResp;
            }

            return resp;
        })
        )
    }

    getAllTopics(get_users = true, get_customers = true){

        let queryParams = '';
        if (!get_users) {
            queryParams += '?get_users=' + get_users;
        }
        if (!get_customers) {
            queryParams += (queryParams ? '&' : '?') + 'get_customers=' + get_customers;
        }

        return this.apiService.get('/content/all-topics'+queryParams).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }


    getRotatingTopics(){
        return new Observable((observer) => {
            this.apiService.get('/content/topics').pipe( map(
                (response) => {
                    return JSON.parse(JSON.stringify(response));
                }
                ),this.response.subscribe(
                response => {
                    if (response.success) {

                        let ts = [];
                        response.response.forEach(element => {
                            let prompts = [];
                            if (element.prompts != null && element.prompts.length > 0) {

                                element.prompts.forEach(prompt => {
                                    prompts.push({text: prompt.prompt, subtext: ''});
                                });
                            }

                            ts.push({title: element.topic, entries: prompts});
                        });

                        ts.push({title: 'No Topic', entries: []});


                        let topics: any = {
                            success: true,
                            response: ts
                        };

                        observer.next(topics);
                        observer.complete();

                    }
                })
            )
        });

    }
    getVolumeExerciseTopic(){
        return new Observable((observer) => {
            let ts = [
                {
                    title: 'Volume Exercise', entries: [
                        {text: 'Introduce yourself', subtext: '("Hello, my name is...")'},
                        {text: 'Where are you from', subtext: '("I live in…” or “I was born in...")'},
                        {text: 'Your favorite pastime', subtext: '("I love to play soccer" or "I’m an avid reader" etc.)'},
                        {
                            text: 'Tell us someone dead or alive you would like to have dinner with',
                            subtext: '("I would like to have dinner with Bruce Springsteen")'
                        },
                    ]
                }
            ];

            let topics: any = {
                success: true,
                response: ts
            };

            observer.next(topics);
            observer.complete();

        });
    }

    storeTopic(topicModel){
        return this.apiService.post('/content/topic/add', topicModel).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }
    updateTopic(topicModel){
        return this.apiService.post('/content/topic/edit' , topicModel).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    removeTopic(topicId){
        return this.apiService.post('/content/topic/remove/'+topicId , null).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    removePresentation(presentationId){
        return this.apiService.post('/content/presentation/remove/'+presentationId , null).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    checkPresentation(presentationId){
        return this.apiService.get('/content/presentation/check/'+presentationId , null).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    storePresentation(presentationModel){
        return this.apiService.post('/content/presentation/add', presentationModel).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    updatePresentation(presentationModel){
        return this.apiService.post('/content/presentation/edit' , presentationModel).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }
    getAllPresentations(user = false, customer = false){

        let queryParam = '';

        if(user && customer){
            queryParam = 'all';
        }
        else if (user) {
            queryParam = 'user';
        }
        else if (customer) {
            queryParam = 'customer';
        }

        return this.apiService.get('/content/presentation/'+queryParam).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }
    private tutorialsData = [
        {
            title: 'Learn Games',
            key: 'T_LEARN_GAMES',
            elements: [
                {
                    element: 'games-library',
                    description: {
                        title: 'Games',
                        text: 'You can play games to help you learn. There are different games for the different voice skills that allow you learn in a fun way. Each game will challenge you to level up by sustaining the skill for longer periods of time.'
                    }
                },
                {
                    element: 'filters',
                    description: {
                        title: 'Filters',
                        text: 'Control the games you see by topics.'
                    }
                }
            ]
        },
        {
            title: 'Learn Library',
            key: 'T_LEARN_LIBRARY',
            elements: [
                {
                    element: 'learn-library',
                    description: {
                        title: 'Library',
                        text: 'Presentr offers a full library of content that is continually updated to provide microlearning through reads and videos. You can scroll through the content to find the topic you want to learn about. Reads and Watchs typically take no more than 1-2 minutes to read and watch.'
                    }
                },
                {
                    element: 'filters',
                    description: {
                        title: 'Filters',
                        text: 'Control the content you want to see by type and topic.'
                    }
                }
            ]
        },
        {
            title: 'Result List Filter / Sort',
            key: 'T_RESULT_LIST',
            elements: [
                {
                    element: 'filter-sort',
                    description: {
                        title: 'Sort and Filter',
                        text: ''//'You can filter your recordings by time ranges to easily find the recordings you are looking for.'
                    }
                },
                {
                    element: 'list-results',
                    description: {
                        title: 'Recordings and Practices',
                        text: 'You can see the list of all your recordings and practices. By clicking on a recording, you can see the score card of that result.'
                    }
                }
            ]
        },
        {
            title: 'Analytics',
            key: 'T_USER_ANALYTICS',
            description: 'Your analytics provides you an average of your overall performance, your most commonly used words, and trending data to show how you are performing over time.',
            elements: [
                {
                    element: 'side-menu',
                    description: {
                        title: 'Analytics or Trending',
                        text: 'Here you can navigate between your results and your trending graph to review averages and long term performance.'
                    }
                },
                {
                    element: 'avg-overall',
                    description: {
                        title: 'Overall Average',
                        text: 'Your analytics provides you an average of your overall performance, your most commonly used words, and trending data to show how you are performing over time.'
                        // text: 'Here you can look at the averages of your recordings.'
                    }
                },
                {
                    element: 'word-count',
                    description: {
                        title: 'Word Count',
                        text: 'Your word count show your most commonly used words over all your recordings and allows you to select words you want to track as filler or power words'
                    }
                }
            ]
        },
        {
            title: 'Record Card',
            key: 'T_RECORD_CARD',
            elements: [
                {
                    element: 'present-card',
                    description: {
                        title: 'Record Your Presentation',
                        text: 'When you record your presentation, you will have the opportunity to speak as long as you want on any topic. You can select \'Present with content\' where you can choose from pre-written topics and prompts or create your own. You can also upload a presentation to use while you are speaking. Choose whether you are sitting or standing to receive an accurate score. Once selecting Start, Presentr will count you down and your content will appear, ready for you to begin your presentation.\n' +
                            ' \n' +
                            '\'Present without content\' allows you to speak without topics or presentations displaying on the screen. The screen will be blank with buttons to Pause, Resume, and Stop the recording. You will choose whether you are sitting or standing to receive an accurate score. After selecting Start, Presentr will count you down and when the screen says go you should begin speaking.'
                    }
                },
                {
                    element: 'practice-card',
                    description: {
                        title: 'Practice Mode',
                        text: 'If you want to practice and get real-time feedback without impacting your performance score, you can use the Practice mode. You can select \'Practice with content\' where you can choose from pre-written topics and prompts or create your own. You can also upload a presentation to display while speaking. Choose whether you are sitting or standing to receive an accurate score. Once selecting Start, Presentr will count you down and your content will appear, ready for you to begin your presentation.\n' +
                            ' \n' +
                            '\'Practice without content\' allows you to speak without topics or presentations displaying on the screen. The screen will not have any content for you to work with while presenting. You will choose whether you are sitting or standing to receive an accurate score. After selecting Start, Presentr will count you down and when the screen says go you should begin speaking.\n' +
                            'Remember, Practice Mode will not affect your overall average score in the analytics tab.'
                    }
                },
                {
                    element: 'coach-card',
                    description: {
                        title: 'Need Help?',
                        text: 'Abbie can guide you through your experience with the app.'
                    }
                },
                {
                    element: 'coach-icon',
                    description: {
                        title: 'Abbie',
                        text: 'This is Abbie, your Presentr Coach. If you want to access Abbie, click on the icon in the lower right corner. She is always waiting behind the scenes to help you. If you are using Abbie and don\'t want to be coached, you can close it down and go back at any time.'
                    }
                },
                {
                    element: 'present-header',
                    description: {
                        title: 'Record or Practice?',
                        text: 'Here you can record yourself or practice and get real-time feedback.'
                    }
                },
                {
                    element: 'score-header',
                    description: {
                        title: 'Scores',
                        text: 'Here you can look at all of your scores and access your recordings. You can also look at your performance over time.'
                    }
                },
                {
                    element: 'learn-header',
                    description: {
                        title: 'Learn',
                        text: 'Here we help you learn how to improve with our content library, games and activities.'
                    }
                },
                {
                    element: 'analytics-header',
                    description: {
                        title: 'Team and Analytics',
                        text: 'Find out how your team is engaging and performing with the app.'
                    }
                }
            ]
        },
        {
            title: 'Score Card',
            key: 'T_SCORE_CARD_SCREEN',
            description: 'At the end of each recording, you will receive a score based on your voice and/or body language. You can also see if you have made improvement from your last recording. The score helps you identify where you might need some additional practice or learning and helps you track your progress over time.',

            elements: [
                {
                    element: 'recording-specs',
                    description: {
                        title: 'Recording Details',
                        // text: 'At the top of your scorecard you can see how long you spoke during your recording and how your current score compares to your last score.'
                        text: ''
                    }
                },
                {
                    element: 'presentr-score',
                    description: {
                        title: 'Your Presentr Score',
                        text: 'Your Presentr score reflects how you did with your voice and/or body language. Nobody is perfect and your goal is to try to make incremental improvements.'
                    }
                },
                {
                    element: 'result-type',
                    description: {
                        title: 'Recording Platforms',
                        // text: 'Presentr is offered on multiple platforms including mobile, web and with the use of the Kinect sensor. You will see an icon that indicates which platform you used for each recording.'
                        text: 'Presentr is offered on multiple platforms including mobile, web and with the use of the Kinect sensor.'
                    }
                },
                {
                    element: 'voice-detail',
                    description: {
                        title: 'Voice Score',
                        text: 'Your Voice score reflects how you did with your volume, your pace and your use of filler words.'
                    }
                },
                {
                    element: 'gesture-detail',
                    description: {
                        title: 'Gesture Score',
                        text: 'Your Gesture score provides insight about your body language and whether or not you were using open or closed gestures.'
                    }
                },
                {
                    element: 'posture-detail',
                    description: {
                        title: 'Posture Score',
                        text: 'Your Posture score provides insight about your body language and whether or not your stance and posture was open or closed.'
                    }
                },
                {
                    element: 'eye-detail',
                    description: {
                        title: 'Eye Contact Score',
                        text: 'Your Eye Contact score reflects whether or not you were able to sustain engaged eye contact with your audience.'
                    }
                },
                {
                    element: 'volume-detail',
                    description: {
                        title: 'Volume Score',
                        text: 'Your Volume  score is an important indicator of how passionate you sound. If your volume is too low, your audience may not be able to hear you and the animation and passion in your voice may be diminished. If you volume is too high, you may sound like you are screaming. If you want to track where your volume is, you can practice with real-time feedback to determine if you are too loud or too soft. You can also play games to help optimize your volume.'
                    }
                },
                {
                    element: 'pace-detail',
                    description: {
                        title: 'Pace Score',
                        text: 'Your Pace  score  indicates how quickly or slowly you are speaking. If you are talking too fast, it may be hard for your audience to follow along with what you’re saying. It may also give the impression that you are nervous. If you want to track where your pace is, you can practice with real-time feedback to determine if you are too fast or too slow. You can also play games to help optimize your pace.'
                    }
                },
                {
                    element: 'filler-detail',
                    description: {
                        title: 'Filler Words',
                        text: 'Everyone uses filler words and your score reflects how many you used. Presentr allows you to set your own filler words based on the ones you commonly use and add more along way. This score indicates how many of your filler words you used during your recording. The fewer the filler words, the better the impression on the audience. If you want to see how you’re managing your use of filler words,  you can practice with real-time feedback. You can also play games to help reduce your use of filler words.'
                    }
                },
                {
                    element: 'tip',
                    description: {
                        title: 'View Tips',
                        text: 'You have the option to view tips under each score, that are customized based on your score. The tips provide you with quick ideas on how you can improve each skill. The tips will change based on your scores.'
                    }
                },
                {
                    element: 'word-breakdown',
                    description: {
                        title: 'View Filler Words',
                        text: 'You can take a look at the actual filler words used during your recording and the frequency in which you used them to help give you greater insights on how you’re doing.'
                    }
                },
                // {
                //     element: 'question-mark',
                //     description: {
                //         title: 'Question Marks',
                //         text: 'Question marks can be found throughout the app and indicate there is more information about that section. Click on the question mark to get help on the area you are working in.'
                //     }
                // },
                {
                    element: 'transcript',
                    description: {
                        title: 'Transcript',
                        text: ''
                        // text: 'If you want to review the actual words spoken during your presentation, you can access your transcripts. You can also go back and look at older recordings and review those transcripts. (Note: accuracy of transcripts will be impacted by your volume and clarity as well as the microphone and internet quality during the recording).'
                    }
                },
                {
                    element: 'replay',
                    description: {
                        title: 'Replay',
                        text: 'You can replay your recording to hear what your audience would experience'
                    }
                },
            ]
        },
    ];

    static typeOptions = [
        {name: 'Videos', initial: 'V', value: 'video', bgClass: 'bg-white border border-gray-darker', textClass:'text-black'},
        {name: 'Reads', initial: 'R', value: 'read', bgClass: 'bg-white border border-gray-darker', textClass:'text-black' }
    ];

    static topicOptions = [
        {name: 'Volume', initial: 'V', value: 1, bgClass: 'bg-purple'},
        {name: 'Pace', initial: 'P', value: 2, bgClass: 'bg-purple'},
        {name: 'Filler Words', initial: 'F', value: 3, bgClass: 'bg-purple'},
        {name: 'Eye Contact', initial: 'E', value: 4, bgClass: 'bg-green'},
        {name: 'Gesture', initial: 'G', value: 5, bgClass: 'bg-mustard'},
        {name: 'Posture', initial: 'P', value: 6, bgClass: 'bg-mustard'},
        {name: 'Body Language', initial: 'B', value: 11, bgClass: 'bg-mustard'},
        {name: 'Practice', initial: 'P', value: 7, bgClass: 'bg-blue'},
        {name: 'Content', initial: 'C', value: 8, bgClass: 'bg-blue'},
        {name: 'Confidence', initial: 'C', value: 9, bgClass: 'bg-blue'},
        {name: 'Audience', initial: 'A', value: 10, bgClass: 'bg-blue'},
        {name: 'Other', initial: 'O', value: 12, bgClass: 'bg-blue'},
    ];

    getTutorial(key) {
        return new Observable((observer) => {

            let topics: any = {
                success: true,
                response: this.tutorialsData.find(x => x.key == key)
            };
            // observable execution
            observer.next(topics);
            observer.complete();
        });
    }

    getLanguageModels() {
        return new Observable((observer) => {

            let models: any = {
                success: true,
                response: [
                    {name: 'US English', model: 'en-US_BroadbandModel'},
                    {name: 'Brazilian Portuguese', model: 'pt-BR_BroadbandModel'},
                    {name: 'French', model: 'fr-FR_BroadbandModel'},
                    {name: 'German', model: 'de-DE_BroadbandModel'},
                    {name: 'Japanese', model: 'ja-JP_BroadbandModel'},
                    {name: 'Korean', model: 'ko-KR_BroadbandModel'},
                    {name: 'Mandarin Chinese', model: 'zh-CN_BroadbandModel'},
                    {name: 'Modern Standard Arabic', model: 'ar-AR_BroadbandModel'},
                    {name: 'Spanish', model: 'es-ES_BroadbandModel'},
                    {name: 'UK English', model: 'en-GB_BroadbandModel'}
                ]
            };
            // observable execution
            observer.next(models);
            observer.complete();
        });
    }

    getTutorials() {
        return new Observable((observer) => {

            let topics: any = {
                success: true,
                response: this.tutorialsData
            };
            // observable execution
            observer.next(topics);
            observer.complete();
        });
    }
}
