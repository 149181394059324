import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {MediaStreamService} from '../../classes/services';
import {TextStoreService} from '../../classes/services/data/text-store.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SettingsService} from '../../classes/services/core/settings.service';
import {MediaTestComponent} from './media-test.component';

@Component({
    selector: 'app-mic-test',
    templateUrl: './media-test.component.html',
    styleUrls: ['./media-test.component.scss']
})

export class MicTestComponent extends MediaTestComponent {


    static MIC_CHECKED_SETTINGS = 'micCheckComplete';
    constructor(protected mediaService: MediaStreamService,
                protected textStoreService: TextStoreService,
                protected zone: NgZone,
                protected ngCh: ChangeDetectorRef,
                protected route: ActivatedRoute,
                protected router: Router,
                protected settingsService: SettingsService) {

        super(mediaService, textStoreService, zone, ngCh, route, router, settingsService);

        this.audioOnly = true;
        this.mediaCheckedSetting = MicTestComponent.MIC_CHECKED_SETTINGS;

    }
}