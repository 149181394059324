import {FeedbackEventTypeEnums} from '../enums/feedback-event-type.enums';

export class FeedbackEvent{
    event:FeedbackEventTypeEnums;
    data: any;

    public constructor(event : FeedbackEventTypeEnums, data) {
        this.event = event;
        this.data = data;
    }
}
