import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CoachPopupComponent} from '../coach-popup/coach-popup.component';
import {EventSubscriptionService} from '../../classes/services';
import {NgModel} from '@angular/forms';
import {NotificationEvent} from 'aws-sdk/clients/ssm';
import {CoachService} from '../../classes/services/core/coach.service';

@Component({
    selector: 'app-coach-bubble',
    templateUrl: './coach-bubble.component.html',
    styleUrls: ['./coach-bubble.component.scss']
})
export class CoachBubbleComponent implements OnInit, OnDestroy {

    constructor(
        private notificationService: EventSubscriptionService,
        private coachService: CoachService
    ) {
    }
    messages: any = [];
    badgeNumber: number = 0;
    isCoachVisible: Boolean;
    isCoachOpen: Boolean = false;

    isActive:Boolean = false;
    selectedTab: string = 'chat'; // chat, sessions, task_list
    readonly hoverDelayTime = 500;

    ngOnDestroy(): void {
        this.notificationService.unsubscribeToNotificationEvent('coach_chat_ws', this);
        this.notificationService.unsubscribeToNotificationEvent('coach_chat_bubble_badge', this);
        this.notificationService.unsubscribeToNotificationEvent('coach_is_visible', this);
        this.notificationService.unsubscribeToNotificationEvent('coach_open', this);
    }

    ngOnInit() {

        this.notificationService.subscribeToNotificationEvent(
            'coach_open',
            this,
            this.handleCoachOpen
        );

        this.notificationService.subscribeToNotificationEvent(
            'coach_chat_ws',
            this,
            this.handleChatWS
        );

        this.notificationService.subscribeToNotificationEvent(
            'coach_chat_bubble_badge',
            this,
            this.handleBadgeUpdate
        );

        this.notificationService.subscribeToNotificationEvent(
            'coach_is_visible',
            this,
            this.handleCoachIsVisibleUpdate
        );

        this.isCoachVisible = this.coachService.isCoachVisible;
        // if(this.isCoachVisible)
        //     this.openCoach();
    }

    openCoach() {
        this.isActive = true;
        this.isCoachOpen = true;
        clearTimeout(this.hoverHandler);
    }

    closeCoach() {
        this.isActive = false;
        this.isCoachOpen = false;
        clearTimeout(this.hoverHandler);
    }

    private hoverHandler;
    hovered(){
        clearTimeout(this.hoverHandler);
        this.hoverHandler = setTimeout(() => {
            this.openCoach();
        }, this.hoverDelayTime);
    }
    hoveredFinished(){
        clearTimeout(this.hoverHandler);
        this.hoverHandler = setTimeout(() => {
            this.closeCoach();
        }, this.hoverDelayTime);
    }

    handleCoachOpen(scope, data){
        if(data.data)
            scope.openCoach();
        else
            scope.closeCoach();
    }

    handleBadgeUpdate(scope, data: any) {
        scope.badgeNumber = data.data;
    }

    //================================================================================
    // UI Functions
    //================================================================================

    closePopup(message: any) {
        this.messages.pop(message);
    }

    handleCoachIsVisibleUpdate(scope, data: any) {
        scope.isCoachVisible = data.data;
    }


    //================================================================================
    // Web socket functions
    //================================================================================

    handleChatWS(scope, data: any) {
        // if(!this.isCoachOpen){
        switch (data.data.type) {
            case 'message':
                if (
                    (!data.data.settings.fullScreenOnly
                        || data.data.settings.fullScreenOnly == false)
                    && data.data.message.type == 'bot'
                ) {
                    scope.messages.push(data.data.message);
                    setTimeout(function () {
                        scope.closePopup(data.data.message);
                    }, 5000);
                }
                break;
            default:
                break;
        }
        // }
    }
}
