import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import * as DetectRTC from 'detectrtc';
import {SettingsService} from '../../../../classes/services/core/settings.service';
import {AuthService} from '../../../../classes/services';
import {TextStoreService} from '../../../../classes/services/data/text-store.service';

@Component({
    selector: 'app-browser-recommended',
    templateUrl: './browser-recommended.component.html',
    styleUrls: ['./browser-recommended.component.scss']
})
export class BrowserRecommendedComponent implements OnInit {

    pageKey = 'browser-recommended::';
    textCached = [];
    static ALLOW_NON_RECOMMENDED_CHROME = 'allow-non-recommended-chrome-web';
    dontShowAgain: boolean = false;
    returnUrl: string;

    constructor(private router: Router, private route: ActivatedRoute, private settingsService: SettingsService, private authService: AuthService,
                private textStoreService: TextStoreService) {
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

        this.textStoreService.getTextByKey(this.pageKey).subscribe(texts => {
            this.textCached = texts;
        });

        if (DetectRTC.browser.isChrome) {
            this.router.navigate([this.returnUrl ? this.returnUrl : '']);
        } else if (this.settingsService.hasLocalSetting(BrowserRecommendedComponent.ALLOW_NON_RECOMMENDED_CHROME)) {
            this.router.navigate([this.returnUrl ? this.returnUrl : '']);
        }
    }

    openDownload() {
        window.open('https://www.google.com/chrome/', '_blank');
    }

    continueToUrl() {
        this.settingsService.setLocalSetting(BrowserRecommendedComponent.ALLOW_NON_RECOMMENDED_CHROME, this.dontShowAgain);
        this.router.navigate([this.returnUrl ? this.returnUrl : '']);
    }
}
