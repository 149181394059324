import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-coach-chat-bubble',
    templateUrl: './coach-chat-bubble.component.html',
    styleUrls: ['./coach-chat-bubble.component.scss']
})
export class CoachChatBubbleComponent implements OnInit {

    @Input() message: any;
    @Output() feedbackClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() bot:boolean = true;
    constructor() {
    }

    ngOnInit() {
    }

    handleClickOfDateArea(message: any, shouldShow: boolean) {
        message.hide_date_time = shouldShow;
    }
    sendFeedback(message){
        this.feedbackClicked.emit(message);
    }
}
