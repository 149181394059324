import {Component, OnInit, AfterViewChecked, OnDestroy} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SettingsService} from '../../classes/services/core/settings.service';
import {NotificationEvent} from '../../classes/models';
import {EventSubscriptionService} from '../../classes/services';
import {Integer} from 'aws-sdk/clients/gamelift';
import {AppComponent} from '../../app.component';
import {PopupNotificationService} from '../../classes/services/core/popup-notification.service';

@Component({
    selector: 'app-coach-popup',
    templateUrl: './coach-popup.component.html',
    styleUrls: ['./coach-popup.component.scss']
})
export class CoachPopupComponent implements OnInit, AfterViewChecked, OnDestroy {


    overrideUserId: Integer = 0;

    constructor(public activeModal: NgbActiveModal,
                private settingService: SettingsService,
                // private popupService: PopupNotificationService,
                private notificationService: EventSubscriptionService) {
    }

    ngAfterViewChecked() {
        // const parentHeight: any = (document.getElementsByClassName('modal-content')[0]);
        // const historyDiv = document.getElementById('chat-history');
        // historyDiv.style.height = (parentHeight.offsetHeight - 140) + 'px';
    }

    ngOnInit() {



    }

    closePopup() {
        // this.popupService.closeActivePopup();
        this.activeModal.dismiss();
    }

    ngOnDestroy() {
        if (!this.settingService.hasLocalSetting('coach-remember-me-message-this-session')) {
            this.settingService.setLocalSetting('coach-remember-me-message-this-session', false, 'true');
            // send message

            const message = {
                'type': 'message',
                'message': {
                    'type': 'bot',
                    'message': 'If you need me, I am here for you anytime!'
                },
                'settings': {
                    'fullScreenOnly': false
                }
            };

            this.notificationService.sendNotificationEvent(
                new NotificationEvent('coach_chat_bubble', message)
            );

            this.notificationService.sendNotificationEvent(
                new NotificationEvent(PopupNotificationService.COACH_CLOSED_AFTER_FIRST_VIEW, true)
            );

        }
    }

}
