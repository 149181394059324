import { Component, OnInit } from '@angular/core';

@Component({
  host: {
    class:'flex'
  },

  selector: 'app-base-static-card',
  templateUrl: './base-static-card.component.html',
  styleUrls: ['./base-static-card.component.scss']
})
export class BaseStaticCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
