import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupNotificationService} from '../../classes/services/core/popup-notification.service';
import {PopupNotificationComponentRef, PopupNotificationType} from '../../classes/models/popup-notification';
import {HelpPopupByKeyComponent} from './help-popup-by-key/help-popup-by-key.component';
import {WelcomePopupModalComponent} from './welcome-popup-modal/welcome-popup-modal.component';
import {SuccessPopupModalComponent} from './success-popup-modal/success-popup-modal.component';
import {FailurePopupModalComponent} from './failure-popup-modal/failure-popup-modal.component';
import {GenericPopupModalComponent} from './generic-popup-modal/generic-popup-modal.component';

@Component({
    selector: 'app-popup-manager',
    templateUrl: './popup-manager.component.html',
    styleUrls: ['./popup-manager.component.scss']
})
export class PopupManagerComponent implements OnInit, AfterViewInit {
    openModalStack: PopupNotificationComponentRef[] = [];

    constructor(private modalService: NgbModal,
                private activeModal: NgbActiveModal,
                private popupService: PopupNotificationService) {
    }

    ngOnInit() {
        this.popupService.emitter.subscribe((value: PopupNotificationType) => {
            switch (value.type) {
                case 'welcome':
                    this.openWelcomeModal(value.callback);
                    break;
                case 'help':
                    this.openHelpKeyModal(value.data, value.callback);
                    break;
                case 'success':
                    this.openSuccessModal(value.data, value.callback);
                    break;
                case 'failure':
                    this.openFailureModal(value.data, value.callback);
                    break;
                default:
                    this.openGenericModal(value.data.title, value.data.message, value.callback);
                    break;
            }
        });

        this.popupService.emitterGeneric.subscribe((value: PopupNotificationComponentRef) => {
            this.openModal(value.component, value.payload, value.callback);
        });
    }

    ngAfterViewInit(): void {
    }

    private openWelcomeModal(callback) {
        this.openModal(WelcomePopupModalComponent, null, callback);
    }

    private openHelpKeyModal(key, callback) {
        this.openModal(HelpPopupByKeyComponent, key, callback);
    }

    private openSuccessModal(message, callback) {
        this.openModal(SuccessPopupModalComponent, message, callback);
    }

    private openFailureModal(message, callback) {
        this.openModal(FailurePopupModalComponent, message, callback);
    }

    private openGenericModal(title, message, callback) {
        this.openModal(GenericPopupModalComponent, {title: title, message: message}, callback);
    }

    private openModalFromStack() {
        setTimeout(() => {
            if (this.modalService.hasOpenModals()) {
                this.modalService.dismissAll('auto-close');
            }
            const ref = this.openModalStack.pop();
            if (ref) {
                const instance = this.modalService.open(ref.component, {size: 'xl',});
                if ('setPayload' in instance.componentInstance)
                    instance.componentInstance.setPayload(ref.payload);

                instance.result.then(
                    result => {
                        if (ref.callback)
                            ref.callback(result);
                        this.openModalFromStack();
                    },
                    reason => {
                        if (reason === 'auto-close') {
                            this.openModalStack.push(ref);
                        } else {
                            this.openModalFromStack();
                            if (ref.callback)
                                ref.callback(reason);
                        }
                    }
                );
            }
        });
    }

    private openModal(component, payload, callback) {
        this.openModalStack.push({component: component, payload: payload, callback: callback});
        this.openModalFromStack();
    }

    closeOpenModal(result) {
        if (this.modalService.hasOpenModals())
            this.modalService.dismissAll(result);
    }

}
