import { Component, OnInit } from '@angular/core';
import {SidebarTemplateProviderService} from '../../classes/services/core/sidebar-template-provider.service';

@Component({
  selector: 'app-side-menu',
  host: {
    class:'w-full'
  },
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  constructor(public sidebarTemplateProvider:SidebarTemplateProviderService) { }

  ngOnInit() {
    console.log(this.sidebarTemplateProvider.templateReferences)
  }


}
