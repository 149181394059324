

<app-popup-base>
    <ng-container>
        <div class="modal-body container">
            <div class="p-4 text-center">
                <h2 class="text-blue">{{title}}</h2>
                <p *ngIf="message" [innerHTML]="message"></p>

                <input type="text" class="form-input" [(ngModel)]="value" placeholder="" />
            </div>
            <div class="flex justify-end w-full">
                <button class="button-blue float-right" (click)="submit()"> Submit</button>
                <button class="button-white float-right" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </ng-container>
</app-popup-base>
