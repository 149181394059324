import {Pipe} from "@angular/core";
@Pipe({
	name: 'scoreToCategory'
})
export class ScoreToCategoryPipe {
	transform(score, arg1, arg2 = [[20, 'Poor'], [40, 'Fair'], [60, 'Average'], [80, 'Great'], [100, 'Pro']]){

		let index = 0;
		while(index < arg2.length){
			if(score < arg2[index][0]){
				break;
			}
			index++;
		}

		if(index >= arg2.length)
			index = arg2.length - 1;

		let cat = arg2[index][1] as string;
		return arg1 ? cat.toLowerCase() : cat;
	}
}