<div class="flex rounded-large px-6 py-4 m-2 w-auto  shadow pointer-events-auto relative {{ colorClass }}">

    <div *ngIf="tailLocation=='left'" class="absolute w-0 h-full left-0 bottom-0">
        <svg class="absolute h-24 w-auto tail-left" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="60 40 75 170" style="enable-background:new 75 50 50 100;" xml:space="preserve">
             <filter id="dropshadow" x="-50%" y="30%" width="140%" height="100%">
                  <feOffset dx="-1" dy="5" result="offsetblur"/>
                <feGaussianBlur result="blurOut" in="offOut" stdDeviation="6"/>
            </filter>
            <path style="fill:#cccccc; filter: url(#dropshadow);" d="M125.3,51v64.7c0,0-3.8,33.2-37.7,31.7l-13,0c0,0,14.7-17.7,15.2-44.3c2.6-36.9,15.2-52,35.1-52.2
                C125.1,51,125.2,51,125.3,51z"/>
            <path d="M125.3,51v64.7c0,0-3.8,33.2-37.7,31.7l-13,0c0,0,14.7-17.7,15.2-44.3c2.6-36.9,15.2-52,35.1-52.2
                C125.1,51,125.2,51,125.3,51z"/>
    </svg>
    </div>

    <div *ngIf="tailLocation=='right'" class="absolute w-0 h-full right-0 bottom-0">
        <svg class="absolute h-24 w-auto tail-right" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="60 40 75 170" style="enable-background:new 75 50 50 100;" xml:space="preserve">
             <filter id="bubble_dropshadow" x="-50%" y="30%" width="140%" height="100%">
                  <feOffset dx="-1" dy="5" result="offsetblur"/>
                 <feGaussianBlur result="blurOut" in="offOut" stdDeviation="6"/>
            </filter>
            <path style="fill:#cccccc; filter: url(#bubble_dropshadow);" d="M125.3,51v64.7c0,0-3.8,33.2-37.7,31.7l-13,0c0,0,14.7-17.7,15.2-44.3c2.6-36.9,15.2-52,35.1-52.2
                C125.1,51,125.2,51,125.3,51z"/>
            <path d="M125.3,51v64.7c0,0-3.8,33.2-37.7,31.7l-13,0c0,0,14.7-17.7,15.2-44.3c2.6-36.9,15.2-52,35.1-52.2
                C125.1,51,125.2,51,125.3,51z"/>
    </svg>
    </div>
    <p *ngIf="message" [innerHTML]="message"></p>
    <ng-content></ng-content>
</div>
