import {Injectable} from '@angular/core';

import {User} from '../../models';
import {ApiService} from './api.service';
import {AuthService} from './auth.service';
import {Observable, forkJoin} from 'rxjs';
import {EnvironmentAPIs} from '../../enums/environment-apis';
import { map } from "rxjs/operators";
import { ajax } from 'rxjs/ajax';

@Injectable()
export class UserService {
    constructor(private apiService: ApiService,
                private auth: AuthService) {
    }

    getAll() {
        return this.apiService.get('/user/all').pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    filterAll(email) {
        return this.apiService.post('/user/all', {email: email}).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    allUserCount() {
        return this.apiService.get('/user/count').pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    create(user: User, logIn: boolean) {
        return this.auth.register(user, logIn);
    }

    update(user: User) {
        return this.apiService.put('/user/update/' + user.id, user).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            })
        )
    }

    updatePassword(password: string) {

        return this.apiService.post('/user/update-password', {password: password}).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            },
            (error) => {
                console.error(error);
            })
        )
    }

    pendingCustomerInvites() {
        return this.apiService.get("/user/licenses/pending").pipe( map(response => {
          let responseObj = JSON.parse(JSON.stringify(response));

          return responseObj.response;
        })
        )
      }


      // customer user row id
      // accept, decline
    manageCustomerInvite(id, action) {
        return this.apiService.post("/user/licenses/pending/manage", {id, action}).pipe( map(response => {
            let responseObj = JSON.parse(JSON.stringify(response));

            return responseObj;
          })
        )
      }

    batchCreate(license_id: number, users: any[]) {
        let create_users: any[] = [];
        for (let _i = 0; _i < users.length; _i++) {
            let user = users[_i];
            user.username = user.email;
            create_users.push(user);
        }

        return this.apiService.post('/auth/batch-register', {users: create_users}, EnvironmentAPIs.Ketchup,
          // this.licenseService.getLicenseHeader(license_id)
        )
         .pipe(
         map(
            resp => JSON.parse(JSON.stringify(resp))
        )
        )
        //
        // return Observable.forkJoin(
        //    this.apiService.post('/auth/batch-register', {users:create_users}, this.licenseService.getLicenseHeader(license_id)).map(resp => JSON.parse(JSON.stringify(resp))),
        //     this.apiService.post('/license/attach-users',{users:attach_users}, this.licenseService.getLicenseHeader(license_id)).map(resp => JSON.parse(JSON.stringify(resp)))
        // ).map(
        // 	resp => resp
        // )
    }

    attachUserToLicense(license_code) {
        return this.apiService.post('/user/attach-to-license', {license_code: license_code}).pipe (map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    detachUserFromCustomer(customer_id) {
        return this.apiService.post('/user/detach-from-customer', {customer_id}).pipe( map(
            (response) => {
                return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    static isValidEmailFormat(email) {
        let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

        return email != '' && email.length >= 5 && EMAIL_REGEXP.test(email);
    }

    validateBatchData(license, data) {

        let valid = false;
        for (let _i = 0; _i < data.length; _i++) {
            data[_i].valid = false;
            data[_i].validation_error = '';
            data[_i].validation_message = '';
        }
        throw new Error("Validate Data does not work");


        // return this.customer.getUsers(license.id).map(resp => {
        //         if (resp.success) {
        //             let licenseUserList = resp.response;
        //
        //             let allValid = true;
        //             // check the license list
        //             for (let _i = 0; _i < data.length; _i++) {
        //                 data[_i].valid = true;
        //                 if(data[_i].import_error){
        //                     data[_i].valid = false;
        //                     data[_i].validation_error = 'I';
        //                     data[_i].validation_message = data[_i].import_error;
        //                 }
        //                 else if (!data[_i].email || data[_i].email == '' ||
        //                     !data[_i].first_name || data[_i].first_name == '' ||
        //                     !data[_i].last_name || data[_i].last_name == '') {
        //                     data[_i].valid = false;
        //                     data[_i].validation_error = 'E';
        //                     data[_i].validation_message = 'EMPTY';
        //                 } else if (!UserService.isValidEmailFormat(data[_i].email)) {
        //                     data[_i].valid = false;
        //                     data[_i].validation_error = 'IE';
        //                     data[_i].validation_message = 'INVALID EMAIL';
        //                 } else if (licenseUserList.find(ul => ul.email.toLowerCase() == data[_i].email.toLowerCase())) {
        //                     data[_i].valid = false;
        //                     data[_i].validation_error = 'AL';
        //                     data[_i].validation_message = 'ALREADY IN LICENSE';
        //                 } else {
        //                     data[_i].valid = true;
        //                     data[_i].validation_error = '';
        //                     data[_i].validation_message = 'VALID';
        //                 }
        //
        //                 allValid = allValid && data[_i].valid;
        //             }
        //
        //             valid = allValid;
        //         } else {
        //             valid = false;
        //         }
        //         return valid;
        //     },
        //     error => {
        //         return false;
        //     });
    }
}
