export const environment = {
    // production: false,
    // ketchupUrl: "https://api.t3interactive.com",
    // bbqUrl: "https://dev.bbq.t3interactive.com",
    // adminUrl: "https://admin.t3interactive.com",
    // awsBucketSecureName: "presentrsecuretesting",
    // awsBucketPublicName: "t3interactive.com",
    // awsCognitoIdentity: "us-east-1:0e344ecc-189e-4cf5-aebb-fb41c2596d5f",
    // awsAudioFolder: "audio-testing/",
    // cdnUrl: "https://s3.amazonaws.com/t3interactive.com/",
    // secureCdnUrl: "https://test.secure.cdn.presentr.me/",
    // hesitationWord: "um/uh",
    // webSocketUrl: "https://dev.mustard.t3interactive.com",
    // stripePublicKey: "pk_test_sWIxCM2ruXTw8n1MPwBXr6hV00WlBJggVm"

    name: 'dev',
    production: false,
    ketchupUrl: 'https://test-api.t3interactive.com',
    bbqUrl: 'https://dev.bbq.t3interactive.com',
    adminUrl: 'https://admin.t3interactive.com',
    awsBucketSecureName: 'presentrsecuretesting',
    awsBucketPublicName: 'mlw-devtest',
    awsCognitoIdentity: 'us-east-1:0e344ecc-189e-4cf5-aebb-fb41c2596d5f',
    awsAudioFolder: 'audio-testing/',
    awsPresentationFolder: "docs/",
    cdnUrl: 'https://s3.amazonaws.com/mlw-devtest/',
    secureCdnUrl: 'https://test.secure.cdn.presentr.me/',
    hesitationWord: 'um/uh',
    webSocketUrl: 'https://dev.mustard.t3interactive.com',
    stripePublicKey: 'pk_test_sWIxCM2ruXTw8n1MPwBXr6hV00WlBJggVm',
    googleAnalyticsUA_ID: '',
    FacebookPixelTracking_ID: '',
    adrollTrackingEnabled: false,
    firebase: {
        apiKey: "AIzaSyDrOjLrB8sWe38atAKgd0yIOkBGSsicpAo",
        authDomain: "presentr-web.firebaseapp.com",
        databaseURL: "https://presentr-web.firebaseio.com",
        projectId: "presentr-web",
        storageBucket: "presentr-web.appspot.com",
        messagingSenderId: "262626440154",
        appId: "1:262626440154:web:0868cddbfa2df17b75c6e3",
        measurementId: "G-MX4ZSYTZ3Z"
    },
    pdfMakerUrl: 'https://em28hujzma.execute-api.us-east-1.amazonaws.com/dev/makepdf',
    viewSDKKey: 'ad46396b05ee494295d1d3330f704e05',
    pdfCdnUrl: 'https://s3.amazonaws.com/mlw-devtest/docs/',
    notificationsUrl: 'https://lca2cfl7z0.execute-api.us-east-1.amazonaws.com/dev',
    watsonTextToSpeechUrl: 'https://api.us-south.speech-to-text.watson.cloud.ibm.com/instances/a50b6054-da17-4c47-9cbd-f4a6b64cb607'

};
