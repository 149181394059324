<div class='flex flex-col w-full h-full items-center'>
    <div (domChange)="onDomChange()" class="flex flex-col container justify-end w-full h-full overflow-auto">
        <!--id='chat-history'-->

        <a *ngIf="!hasReachedBeginningOfHistory" id='chat-history-load-more'
           class="text-blue text-center cursor-pointer pt-2" (click)="loadMore()">Load More...</a>

        <div class="p-4 h-full overflow-auto">
            <div *ngFor="let message of chatHistory; let i = index">
                <div *ngIf="message.hidden == false || message.hidden == null"
                     class="flex m-1 {{message.type == 'user' ? 'justify-end': ''}} {{message.type == 'system' || (message.type == 'bot' && !message.message) ? 'justify-center': ''}}">

                    <!--          {{ message.type }} - {{ chatHistory[i + 1] ? chatHistory[i + 1].type : ''}} -> {{ message.message }}-->
                    <span *ngIf=" message.type =='system' " class='text-center text-gray'>{{message.message}}</span>

                    <div *ngIf=" message.type =='user'" class="relative flex">
                        <div class="absolute right-0 top-3/4 text-center text-blue max-w-16 text-xs">{{userName.toLocaleUpperCase()}}</div>
                        <div class="mr-16 my-1 flex flex-col">
                            <app-coach-chat-bubble [bot]="false"  [message]="message"></app-coach-chat-bubble>
                        </div>
                    </div>

                    <div *ngIf=" message.type =='bot' && message.message != NULL" class="relative flex">
                        <div class="absolute left-0 top-3/4 text-center text-white"
                             *ngIf="message.message && (!chatHistory[i + 1] || chatHistory[i + 1].type != message.type || (chatHistory[i + 1].type == 'bot' && !chatHistory[i + 1].message))">
                            <app-svg-component type="abbie" class="flex svg w-16 h-16"></app-svg-component>
                        </div>
                        <div class="message-parent incoming ml-16 my-1">
                            <app-coach-chat-bubble [bot]="true"  (feedbackClicked)="sendFeedback($event)" [message]="message"></app-coach-chat-bubble>
                        </div>
                    </div>
                </div>
            </div>

            <div id='chat-history-bottom'></div>
        </div>
        <span class='text-center text-gray pb-2' *ngIf="botIsTyping">Abbie is typing <span
            id="dotdotdot">.</span> </span>


        <div *ngIf="!isQuickRepliesHidden && quickReplies.length > 0 && !isReadOnly" id='chat-quick-reply'
             class="border border-gray rounded-t p-2 border-b-0">
            <div class="text-xs text-gray" id='quick-reply-title'>Quick Reply</div>
            <button *ngFor="let button of quickReplies" type="button" (click)="sendMessage(button.value)"
                    class="button-white m-1">{{button.label}}</button>
        </div>


    </div>

    <div *ngIf="!isChatReplyHidden && !isReadOnly" class="w-50 h-16 bg-white">  <!--id='chat-reply-outter'-->
        <form class='flex container mx-auto items-center w-full h-full bg-gray-lighter' (submit)="sendMessageButton()"
              autocomplete="off">
            <input id='message' name='message' type="text" placeholder="Type a message"
                   class="w-full h-full outline-none">
            <div (click)="sendMessageButton()" class="flex w-8 h-full bg-white justify-center cursor-pointer">
                <app-svg-component class="svg w-full h-auto fill-gray" type="icon_send"></app-svg-component>
            </div>
        </form>
    </div>
</div>
