import { Component, OnInit } from "@angular/core";
import { element } from "protractor";
import { ApiService } from "../../../../classes/services";
import { EnvironmentAPIs } from "../../../../classes/enums/environment-apis";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CoachChatService } from "../../../../classes/services/data/coach-chat.service";

@Component({
  selector: "app-coach-chat-feedback-popup",
  templateUrl: "./coach-chat-feedback-popup.component.html",
  styleUrls: ["./coach-chat-feedback-popup.component.scss"]
})
export class CoachChatFeedbackPopupComponent implements OnInit {
  id: number;
  starRating: number;
  message: String;

  couldBeBetterArray: Array<String> = [];

  constructor(
    private coachChatService: CoachChatService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit() {}

  couldBeBetter(event: Event, title: String) {
    const target: HTMLElement =
      (event.target || event.srcElement || event.currentTarget) as HTMLElement;
    if (target.classList.contains("active")) {
      target.classList.remove("active");
      this.couldBeBetterArray = this.couldBeBetterArray.filter(function(ele) {
        return ele !== title;
      });
    } else {
      target.classList.add("active");
      this.couldBeBetterArray.push(title);
    }
  }

  sendFeedback() {
    this.coachChatService
      .sendFeedback({
        message_id: this.id,
        message: this.message,
        rating: this.starRating,
        improve_types: this.couldBeBetterArray.toString()
      })
      .subscribe(response => {
        this.activeModal.dismiss();
        alert("Thank you for providing feedback!");
      });
  }
}
