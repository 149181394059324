<!--<div *ngIf="type=='abbie-circle'">-->
<svg *ngIf="type=='abbie'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="12 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FFFFFF;stroke:#3274BB;stroke-width:8.6881;stroke-miterlimit:10;}
  .st1{fill:#EDDBC3;}
  .st2{fill:#F79226;}
  .st3{fill:#3274BB;}
  .st4{fill:#F7B74E;}
  .st5{fill:#F9C58C;}
  .st6{fill:#DA3850;}
  .st7{fill:#FFFFFF;}
  .st8{fill:#A77D52;}
  .st9{fill:#333333;}
  .st10{fill:#E8A77C;}
  .st11{fill:#454444;}
  .st12{fill:#4E4E4E;}
  .st13{fill:#575757;}
</style>
    <ellipse transform="matrix(1 -2.968971e-03 2.968971e-03 1 -0.7763 0.777)" class="st0" cx="261.32" cy="261.87" rx="219.81" ry="219.89"/>
    <path class="st1" d="M290.76,383.86c0.12,0.05,0.23,0.11,0.35,0.16c-1.28-0.83-3.03-1.47-5.17-1.97c1.02,3.48,2.22,6.84,3.59,10.01
	C291.04,387.48,290.76,383.86,290.76,383.86z"/>
    <path class="st2" d="M232.11,423.96l28.67-13.74c-10.61-1.05-17.3-4.55-21.52-8.67c-3.17-3.09-4.95-6.52-5.94-9.54
	c-1.43-4.34-1.24-7.79-1.22-8.11c0-0.02,0-0.04,0-0.04c-3.88,1.75-6.88,3.62-9.16,5.55c-0.21,0.18-0.42,0.35-0.61,0.53
	C205.59,405.06,232.11,423.96,232.11,423.96z"/>
    <path class="st1" d="M233.33,392.01c1.36-3.13,2.55-6.46,3.56-9.9c-1.96,0.46-3.58,1.05-4.78,1.79
	C232.09,384.22,231.9,387.68,233.33,392.01z"/>
    <path class="st2" d="M302.36,391.84c-0.18-0.2-0.33-0.41-0.52-0.61c-2.36-2.52-5.83-4.96-10.73-7.21c-0.12-0.05-0.23-0.11-0.35-0.16
	c0,0,0.28,3.62-1.23,8.2c-2.31,6.99-8.84,16.19-27.05,18.12h0c-0.13,0.01-0.26,0.03-0.39,0.04l28.67,13.74
	C290.76,423.96,315.09,406.6,302.36,391.84z"/>
    <path class="st3" d="M232.11,423.96c0,0-26.52-18.89-9.77-34.02c0.2-0.18,0.41-0.35,0.61-0.53l0,0
	c-9.78,7.35-27.31,19.45-39.64,34.07c-9.44,11.22-10.85,27.94-10.58,39.64c27.13,11.98,57.13,18.64,88.69,18.64
	c31.06,0,60.62-6.45,87.41-18.08c0.32-11.71-0.96-28.8-10.57-40.2c-11.27-13.38-25.76-24.26-35.9-31.64
	c12.73,14.76-11.6,32.12-11.6,32.12l-28.67-13.74c-0.06-0.01-0.11-0.02-0.17-0.03c-0.38,0.01-0.76,0.03-1.13,0.03L232.11,423.96z"/>
    <path class="st4" d="M258.95,410.01c-0.2-0.03-0.39-0.06-0.58-0.08C258.57,409.95,258.76,409.98,258.95,410.01z"/>
    <path class="st5" d="M346.4,231.16c-0.14-1.37-0.32-2.73-0.52-4.08c-9.53-8.3-30.43-19.75-34.7-23.83
	c-6.19-5.92-14.32-12.08-19.05-19.13c-1.98,1.42-4.01,2.77-6.06,4.07c-13.79,8.71-28.99,15.07-43.2,23.94
	c-17.28,10.79-35.66,21.59-49.83,36.46c-4.58,4.81-8.56,10.62-12.27,16.78c-1.37,2.27-2.7,4.58-4.01,6.92l0.12,1.41
	c0.03,0.22,0.04,0.45,0.07,0.67c4.36,31.56,42.57,55.93,66.08,68.1c-0.45,8.68-1.83,25.01-6.15,39.64c-1.01,3.44-2.2,6.77-3.56,9.9
	c1.32,4.01,4.03,8.77,9.55,12.46c0.04,0.03,0.09,0.06,0.14,0.09c0.29,0.19,0.58,0.38,0.88,0.56c0.08,0.05,0.15,0.09,0.23,0.14
	c0.28,0.17,0.57,0.33,0.86,0.49c0.09,0.05,0.18,0.1,0.28,0.15c0.29,0.16,0.59,0.31,0.89,0.46c0.1,0.05,0.19,0.09,0.29,0.14
	c0.33,0.16,0.68,0.32,1.03,0.47c0.07,0.03,0.14,0.06,0.21,0.09c0.85,0.37,1.75,0.71,2.69,1.03c0.11,0.04,0.24,0.07,0.35,0.11
	c0.35,0.11,0.71,0.23,1.07,0.33c0.17,0.05,0.35,0.1,0.52,0.15c0.32,0.09,0.65,0.18,0.98,0.26c0.2,0.05,0.4,0.1,0.6,0.15
	c0.32,0.08,0.65,0.15,0.98,0.22c0.21,0.05,0.43,0.09,0.64,0.14c0.34,0.07,0.69,0.13,1.04,0.2c0.21,0.04,0.43,0.08,0.65,0.11
	c0.39,0.06,0.78,0.12,1.18,0.18c0.19,0.03,0.38,0.06,0.58,0.08c0.6,0.08,1.21,0.15,1.83,0.21c0.38,0,0.76-0.02,1.13-0.03
	c0.18-0.01,0.37-0.01,0.55-0.01h0c18.22-1.93,24.74-11.13,27.05-18.12c-1.38-3.16-2.57-6.53-3.59-10.01
	c-4.3-14.62-5.69-30.91-6.13-39.58c23.34-12.08,61.18-36.18,65.99-67.41c0.07-0.45,0.13-0.91,0.18-1.36l0.14-5.84
	c9.22-1.04,16.39-8.87,16.39-18.37C362.51,240.08,355.48,232.33,346.4,231.16z"/>
    <path class="st6" d="M288.98,296.31c-0.09-0.05-0.21-0.03-0.27,0.04c0,0-0.26,0.32-0.8,0.67c-0.53,0.36-1.24,0.84-1.95,1.32
	c-0.31,0.21-0.62,0.42-0.91,0.62c-3.4,0.35-16.17,1.58-18.86,0.5c-3.33-1.35-4.85,0.01-6.06,0.34c-1.21,0.34-3.84-1.7-6.3-0.52
	c-2.56,1.22-15.28,0-18.51-0.33c-0.29-0.2-0.6-0.4-0.9-0.61c-0.71-0.48-1.42-0.96-1.95-1.32c-0.54-0.35-0.81-0.67-0.81-0.67
	c-0.01-0.02-0.03-0.03-0.06-0.05c-0.09-0.04-0.21-0.02-0.26,0.06c-0.05,0.07-0.02,0.17,0.07,0.22c0,0,0.4,0.2,0.87,0.62
	c0.47,0.42,1.1,0.97,1.73,1.52c0.31,0.27,0.61,0.53,0.89,0.79c1.98,3.74,12.77,22.91,25.54,22.91c12.95,0,23.52-19.71,25.22-23.05
	c0.24-0.21,0.48-0.43,0.73-0.65c0.63-0.55,1.26-1.11,1.73-1.52c0.46-0.42,0.86-0.62,0.86-0.62c0.02-0.01,0.04-0.03,0.06-0.05
	C289.09,296.46,289.07,296.36,288.98,296.31z"/>
    <path class="st7" d="M269.81,304.53c-3.25,0.32-1.47,0.59-9.59,0.59s-6.35-0.27-9.59-0.59c-3.25-0.32-12.33-3.38-12.33-3.38
	s7.05,8.35,10.96,10.72c6.52,3.93,15.36,3.96,21.93,0c3.92-2.36,10.97-10.72,10.97-10.72S273.06,304.2,269.81,304.53z"/>
    <path class="st7" d="M303.67,238.53c-10.64,2.12-18.24,13.01-18.24,13.01c5.55,4.07,16.98,6.18,26.78,4.39
	c9.8-1.79,12.93-10.9,12.93-10.9S314.3,236.42,303.67,238.53z"/>
    <path class="st8" d="M316.86,247.77c0,5.04-4.42,9.14-9.88,9.14c-5.46,0-9.88-4.09-9.88-9.14c0-5.04,4.42-9.14,9.88-9.14
	C312.44,238.64,316.86,242.73,316.86,247.77z"/>
    <path class="st9" d="M302.84,247.1c-0.09,2.34,1.69,4.32,3.99,4.41c2.29,0.09,4.22-1.74,4.31-4.09c0.09-2.34-1.69-4.32-3.98-4.41
	C304.86,242.93,302.93,244.76,302.84,247.1z"/>
    <path class="st10" d="M258.14,257.64c0,0,0.03,0.65,0.01,1.77c-0.02,1.12-0.06,2.71-0.23,4.59c-0.17,1.9-0.46,4.11-0.85,6.47
	c-0.17,1.16-0.41,2.44-0.71,3.56c-0.26,1.13-0.48,2.22-0.6,3.3c-0.09,1.06-0.09,2.07,0.08,2.94c0.24,0.82,0.46,1.61,1.04,2.17
	c0.21,0.35,0.57,0.56,0.88,0.82c0.29,0.3,0.7,0.44,1.06,0.64c0.19,0.1,0.37,0.2,0.55,0.29c0.19,0.08,0.39,0.12,0.58,0.2
	c0.39,0.14,0.77,0.27,1.13,0.4c0.77,0.18,1.48,0.34,2.11,0.48c0.64,0.14,1.22,0.18,1.69,0.27c0.95,0.15,1.51,0.28,1.51,0.28
	s-0.52,0.22-1.46,0.52c-0.47,0.13-1.05,0.37-1.72,0.47c-0.68,0.1-1.45,0.25-2.32,0.28c-0.87-0.07-1.83,0.05-2.86-0.3
	c-0.52-0.14-1.06-0.25-1.59-0.58c-0.54-0.28-1.1-0.56-1.59-1.08c-0.25-0.24-0.52-0.46-0.75-0.74l-0.63-0.94
	c-0.23-0.31-0.37-0.67-0.51-1.03c-0.13-0.37-0.3-0.73-0.38-1.11c-0.4-1.52-0.39-3.04-0.28-4.47c0.17-1.41,0.46-2.75,0.83-3.98
	c0.17-0.6,0.39-1.19,0.53-1.73c0.15-0.57,0.3-1.12,0.45-1.67c0.61-2.19,1.24-4.23,1.86-5.97c0.56-1.77,1.1-3.24,1.5-4.26
	C257.88,258.22,258.14,257.64,258.14,257.64z"/>
    <path class="st11" d="M195.77,227.48c0,0,0.55-0.44,1.59-1.09c0.52-0.33,1.17-0.7,1.92-1.12c0.76-0.39,1.62-0.85,2.59-1.26
	c0.95-0.46,2.04-0.83,3.18-1.25c0.57-0.21,1.18-0.36,1.79-0.54c0.61-0.18,1.23-0.38,1.89-0.5c0.65-0.14,1.31-0.28,1.98-0.42
	c0.67-0.15,1.36-0.19,2.05-0.28c1.39-0.22,2.81-0.24,4.23-0.28c0.71-0.05,1.42,0,2.13,0.06c0.71,0.05,1.42,0.1,2.12,0.15
	c1.39,0.15,2.75,0.45,4.07,0.66c1.31,0.29,2.54,0.7,3.73,1.02c0.3,0.09,0.59,0.17,0.87,0.26c0.28,0.11,0.55,0.22,0.82,0.33
	c0.54,0.22,1.06,0.42,1.55,0.62c0.99,0.41,1.93,0.73,2.68,1.17c0.77,0.41,1.44,0.76,2,1.05c1.11,0.59,1.74,0.92,1.74,0.92
	l-1.14,1.83c0,0-0.59-0.35-1.63-0.95c-0.52-0.3-1.15-0.67-1.87-1.1c-0.7-0.46-1.59-0.8-2.52-1.24c-0.47-0.21-0.96-0.44-1.46-0.67
	c-0.25-0.11-0.51-0.23-0.77-0.35c-0.27-0.09-0.54-0.19-0.82-0.28c-1.12-0.35-2.29-0.8-3.54-1.14c-1.26-0.26-2.55-0.61-3.89-0.81
	c-0.67-0.08-1.35-0.16-2.03-0.23c-0.68-0.09-1.37-0.16-2.06-0.15c-1.38-0.03-2.76-0.09-4.12,0.06c-0.68,0.06-1.36,0.06-2.02,0.17
	c-0.66,0.1-1.32,0.21-1.96,0.31c-0.65,0.08-1.27,0.25-1.88,0.39c-0.61,0.15-1.21,0.27-1.78,0.44c-1.14,0.36-2.23,0.67-3.2,1.07
	c-0.99,0.35-1.86,0.76-2.63,1.11c-0.77,0.37-1.43,0.71-1.96,1.01C196.34,227.07,195.77,227.48,195.77,227.48z"/>
    <path class="st11" d="M324.51,227.48c0,0-0.57-0.41-1.64-1c-0.53-0.3-1.19-0.64-1.96-1.01c-0.78-0.35-1.64-0.76-2.63-1.11
	c-0.97-0.4-2.06-0.71-3.2-1.07c-0.57-0.18-1.18-0.29-1.79-0.44c-0.61-0.14-1.23-0.31-1.88-0.39c-0.64-0.1-1.3-0.2-1.96-0.31
	c-0.66-0.11-1.34-0.11-2.02-0.17c-1.36-0.15-2.74-0.09-4.12-0.06c-0.69-0.01-1.38,0.06-2.06,0.15c-0.68,0.08-1.36,0.16-2.03,0.23
	c-1.34,0.2-2.63,0.56-3.89,0.81c-1.24,0.33-2.41,0.78-3.54,1.14c-0.28,0.09-0.55,0.19-0.82,0.28c-0.26,0.12-0.52,0.24-0.77,0.35
	c-0.51,0.23-0.99,0.45-1.46,0.67c-0.93,0.44-1.81,0.77-2.52,1.24c-0.72,0.42-1.35,0.79-1.87,1.1c-1.03,0.61-1.63,0.95-1.63,0.95
	l-1.13-1.83c0,0,0.63-0.33,1.74-0.92c0.55-0.29,1.23-0.65,2-1.05c0.75-0.44,1.69-0.76,2.68-1.17c0.5-0.2,1.02-0.41,1.55-0.62
	c0.27-0.11,0.54-0.22,0.82-0.33c0.29-0.08,0.58-0.17,0.87-0.26c1.19-0.32,2.42-0.73,3.73-1.02c1.33-0.21,2.68-0.51,4.07-0.66
	c0.7-0.05,1.41-0.1,2.12-0.15c0.71-0.06,1.42-0.11,2.13-0.06c1.43,0.04,2.85,0.05,4.23,0.28c0.69,0.1,1.38,0.14,2.05,0.29
	c0.67,0.14,1.33,0.28,1.98,0.42c0.65,0.12,1.28,0.32,1.89,0.5c0.61,0.19,1.22,0.33,1.79,0.54c1.14,0.43,2.22,0.79,3.18,1.25
	c0.98,0.41,1.83,0.87,2.59,1.26c0.75,0.41,1.4,0.79,1.92,1.12C323.97,227.04,324.51,227.48,324.51,227.48z"/>
    <path class="st12" d="M320.16,237.81c-0.46-0.21-0.8-0.35-0.94-0.39c-3.48-1.92-8.47-3.56-14.93-2.95
	c-13.76,1.3-18.86,17.08-18.86,17.08s8.58-9.42,19.22-11.53c10.64-2.11,20.5,5.03,20.5,5.03l4.59-5.36
	C326.21,239.94,321.95,238.5,320.16,237.81z"/>
    <path class="st7" d="M218.38,238.53c10.64,2.12,18.24,13.01,18.24,13.01c-5.55,4.07-16.98,6.18-26.78,4.39
	c-9.8-1.79-12.93-10.9-12.93-10.9S207.74,236.42,218.38,238.53z"/>
    <path class="st8" d="M205.18,247.52c0,5.04,4.42,9.14,9.88,9.14c5.46,0,9.88-4.09,9.88-9.14c0-5.04-4.42-9.14-9.88-9.14
	C209.6,238.38,205.18,242.47,205.18,247.52z"/>
    <path class="st9" d="M219.21,247.36c0.09,2.34-1.69,4.32-3.99,4.41c-2.29,0.09-4.22-1.74-4.31-4.09c-0.09-2.34,1.69-4.31,3.98-4.4
	C217.18,243.18,219.12,245.01,219.21,247.36z"/>
    <path class="st12" d="M201.89,237.81c0.46-0.21,0.8-0.35,0.94-0.39c3.48-1.92,8.47-3.56,14.93-2.95
	c13.76,1.3,18.86,17.08,18.86,17.08s-8.58-9.42-19.22-11.53c-10.64-2.11-20.5,5.03-20.5,5.03l-4.59-5.36
	C195.84,239.94,200.1,238.5,201.89,237.81z"/>
    <path class="st13" d="M347.01,82.9c-26.83-10.72-56.6,0.71-66.51,25.52c-1,2.5-1.74,5.04-2.27,7.6c-5.46-0.96-11.07-1.48-16.81-1.48
	c-53.55,0-96.96,43.42-96.96,96.99c0,7.83,0.94,15.44,2.69,22.73c-3.68,13.33-6.08,26.87-6.52,39.17
	c-0.28,7.69,0.77,15.33,2.26,22.86c4.87-7.29,9.24-15.75,13.87-24c1.31-2.34,2.64-4.65,4.01-6.92l0,0c0.74-1.23,1.5-2.45,2.26-3.64
	c0.29-0.46,0.59-0.92,0.88-1.37c0.28-0.42,0.55-0.84,0.84-1.26c0.38-0.57,0.77-1.13,1.16-1.68c0.21-0.29,0.41-0.6,0.62-0.89
	c0.59-0.82,1.19-1.62,1.79-2.41c0.2-0.26,0.4-0.51,0.6-0.76c0.47-0.6,0.95-1.19,1.44-1.76c0.2-0.24,0.41-0.49,0.61-0.73
	c0.67-0.78,1.36-1.55,2.06-2.28c14.17-14.87,32.55-25.67,49.83-36.46c14.21-8.87,29.41-15.23,43.2-23.94
	c2.05-1.3,4.08-2.65,6.06-4.07c4.73,7.05,12.86,13.21,19.05,19.13c4.27,4.08,25.17,15.53,34.7,23.83c0.44,2.82,0.74,5.69,0.88,8.59
	h0.11l-0.53,22.68c7.67-13.88,12.04-29.84,12.04-46.82c0-13.55-2.79-26.44-7.8-38.16c12.11-4.6,22.11-13.68,27.08-26.15
	C387.56,122.42,373.84,93.61,347.01,82.9z"/>
</svg>
<!--</div>-->
<svg *ngIf="type=='logo'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 832.46 199.49" style="enable-background:new 0 0 832.46 199.49;" xml:space="preserve">
<g>
	<path d="M230.94,83.56c0,6.05-1.34,11.74-4.01,17.08c-2.67,5.34-6.54,8.01-11.6,8.01c-2.53,0-4.96-0.56-7.27-1.69
		c-2.32-1.12-4.68-1.69-7.06-1.69c-5.2,0-9.46,1.34-12.76,4.01c-3.3,2.67-4.95,5.9-4.95,9.7v44.28c0,3.24-0.11,5.66-0.32,7.27
		c-0.21,1.62-0.88,3.45-2,5.48c-1.13,2.04-3.02,3.48-5.69,4.32c-2.67,0.84-6.26,1.26-10.75,1.26c-3.52,0-6.5-0.35-8.96-1.05
		c-2.46-0.7-4.29-1.54-5.48-2.53c-1.2-0.98-2.11-2.42-2.74-4.32c-0.63-1.9-0.98-3.51-1.05-4.85c-0.07-1.33-0.11-3.34-0.11-6.01
		V88.83c0-3.23,0.11-5.66,0.32-7.27c0.21-1.61,0.84-3.44,1.9-5.48c1.05-2.04,2.92-3.48,5.59-4.32c2.67-0.84,6.25-1.27,10.75-1.27
		c4.22,0,7.66,0.42,10.33,1.27c2.67,0.84,4.5,2,5.48,3.48c0.98,1.48,1.61,2.71,1.9,3.69c0.28,0.99,0.42,2.11,0.42,3.37
		c0.7-0.98,1.72-2.18,3.06-3.58c1.33-1.4,4.18-3.3,8.54-5.69c4.36-2.39,8.78-3.58,13.28-3.58c2.11,0,4.29,0.18,6.54,0.53
		c2.25,0.35,4.71,0.95,7.38,1.79c2.67,0.84,4.88,2.29,6.64,4.32C230.06,78.11,230.94,80.6,230.94,83.56z"/>
  <path d="M334.73,111.81c0,9.28-2.15,15.99-6.43,20.14c-4.29,4.15-9.03,6.22-14.23,6.22h-49.97
		c0,3.94,2.32,7.24,6.96,9.91c4.64,2.67,9.63,4.01,14.97,4.01c3.8,0,7.38-0.28,10.75-0.84c3.37-0.56,5.83-1.12,7.38-1.69l2.32-0.84
		c3.37-1.54,6.18-2.32,8.43-2.32c4.5,0,8.36,3.16,11.6,9.49c1.83,3.8,2.74,7.03,2.74,9.7c0,12.51-14.97,18.77-44.91,18.77
		c-10.4,0-19.65-1.79-27.73-5.38c-8.09-3.58-14.37-8.29-18.87-14.13c-4.5-5.83-7.84-11.98-10.02-18.45
		c-2.18-6.46-3.27-13.07-3.27-19.82c0-17.71,5.73-31.87,17.18-42.49c11.46-10.61,25.76-15.92,42.91-15.92
		c6.89,0,13.21,0.95,18.98,2.85c5.76,1.9,10.54,4.36,14.34,7.38c3.8,3.02,6.99,6.5,9.59,10.44c2.6,3.94,4.46,7.84,5.59,11.7
		C334.16,104.4,334.73,108.16,334.73,111.81z M290.24,118.98c5.34,0,8.01-2.25,8.01-6.75c0-3.23-1.23-5.83-3.69-7.8
		c-2.46-1.97-6.01-2.95-10.65-2.95c-4.5,0-9,1.86-13.49,5.59c-4.5,3.73-6.75,7.7-6.75,11.91H290.24z"/>
  <path d="M377.62,183.5c-14.2,0-27.55-3.93-40.06-11.81c-2.67-1.69-4.01-4.14-4.01-7.38c0-4.08,2.67-9.84,8.01-17.29
		c1.54-2.39,3.93-3.58,7.17-3.58c3.65,0,7.94,1.48,12.86,4.43c6.89,3.94,12.72,5.9,17.5,5.9c8.57,0,12.86-1.69,12.86-5.06
		c0-1.4-1.34-2.6-4.01-3.58c-2.67-0.98-6.05-1.76-10.12-2.32c-4.08-0.56-8.47-1.72-13.18-3.48c-4.71-1.76-9.1-3.9-13.18-6.43
		c-4.08-2.53-7.45-6.5-10.12-11.91c-2.67-5.41-4.01-11.84-4.01-19.29c0-22.21,15.25-33.31,45.76-33.31c9.98,0,20.24,2.39,30.78,7.17
		c4.92,2.25,7.38,5.34,7.38,9.28c0,2.81-1.48,6.61-4.43,11.39c-3.23,5.06-6.47,7.59-9.7,7.59c-1.69,0-4.08-0.7-7.17-2.11
		c-6.05-2.81-11.74-4.22-17.08-4.22c-8.3,0-12.44,1.55-12.44,4.64c0,2.25,1.37,4.04,4.11,5.38c2.74,1.34,6.18,2.25,10.33,2.74
		c4.14,0.49,8.61,1.44,13.39,2.85c4.78,1.41,9.24,3.13,13.39,5.17c4.15,2.04,7.59,5.48,10.33,10.33c2.74,4.85,4.11,10.79,4.11,17.82
		c0,7.59-1.37,13.95-4.11,19.08c-2.74,5.13-6.61,8.93-11.6,11.39c-4.99,2.46-10.05,4.18-15.18,5.17
		C390.1,183,384.23,183.5,377.62,183.5z"/>
  <path d="M536.32,111.81c0,9.28-2.15,15.99-6.43,20.14c-4.29,4.15-9.03,6.22-14.23,6.22h-49.97
		c0,3.94,2.32,7.24,6.96,9.91c4.64,2.67,9.63,4.01,14.97,4.01c3.8,0,7.38-0.28,10.75-0.84c3.37-0.56,5.83-1.12,7.38-1.69l2.32-0.84
		c3.37-1.54,6.18-2.32,8.43-2.32c4.5,0,8.36,3.16,11.6,9.49c1.83,3.8,2.74,7.03,2.74,9.7c0,12.51-14.97,18.77-44.91,18.77
		c-10.4,0-19.65-1.79-27.73-5.38c-8.09-3.58-14.37-8.29-18.87-14.13c-4.5-5.83-7.84-11.98-10.02-18.45
		c-2.18-6.46-3.27-13.07-3.27-19.82c0-17.71,5.73-31.87,17.18-42.49c11.46-10.61,25.76-15.92,42.91-15.92
		c6.89,0,13.21,0.95,18.98,2.85c5.76,1.9,10.54,4.36,14.34,7.38c3.8,3.02,6.99,6.5,9.59,10.44c2.6,3.94,4.46,7.84,5.59,11.7
		C535.76,104.4,536.32,108.16,536.32,111.81z M491.83,118.98c5.34,0,8.01-2.25,8.01-6.75c0-3.23-1.23-5.83-3.69-7.8
		c-2.46-1.97-6.01-2.95-10.65-2.95c-4.5,0-9,1.86-13.49,5.59c-4.5,3.73-6.75,7.7-6.75,11.91H491.83z"/>
  <path d="M601.09,70.48c14.05,0,25.76,5.38,35.11,16.13c9.35,10.75,14.02,23.86,14.02,39.32v37.11
		c0,3.24-0.11,5.66-0.32,7.27c-0.21,1.62-0.84,3.45-1.9,5.48c-1.05,2.04-2.92,3.48-5.59,4.32c-2.67,0.84-6.26,1.27-10.75,1.27
		c-3.66,0-6.68-0.35-9.07-1.05c-2.39-0.7-4.22-1.51-5.48-2.43c-1.27-0.91-2.21-2.35-2.85-4.32c-0.63-1.97-0.98-3.58-1.05-4.85
		c-0.07-1.27-0.11-3.23-0.11-5.9v-37.11c0-5.76-1.65-10.26-4.95-13.5c-3.3-3.23-7.77-4.85-13.39-4.85c-5.48,0-9.95,1.69-13.39,5.06
		c-3.45,3.37-5.16,7.8-5.16,13.28v37.32c0,3.24-0.11,5.66-0.32,7.27c-0.21,1.62-0.84,3.45-1.9,5.48c-1.05,2.04-2.92,3.48-5.59,4.32
		c-2.67,0.84-6.26,1.27-10.75,1.27c-4.36,0-7.87-0.42-10.54-1.27c-2.67-0.84-4.53-2.32-5.59-4.43c-1.05-2.11-1.69-3.97-1.9-5.59
		c-0.21-1.61-0.32-4.04-0.32-7.27V88.19c0-3.09,0.11-5.45,0.32-7.06c0.21-1.61,0.88-3.41,2-5.38c1.12-1.97,3.02-3.34,5.69-4.11
		c2.67-0.77,6.18-1.16,10.54-1.16c4.22,0,7.66,0.42,10.33,1.27c2.67,0.84,4.5,2.08,5.48,3.69c0.98,1.62,1.58,2.99,1.79,4.11
		c0.21,1.13,0.32,2.6,0.32,4.43c0.28-0.42,0.67-0.91,1.16-1.48c0.49-0.56,1.54-1.61,3.16-3.16c1.61-1.54,3.34-2.92,5.17-4.11
		c1.83-1.19,4.18-2.28,7.06-3.27C595.22,70.98,598.14,70.48,601.09,70.48z"/>
  <path d="M715.51,107.8l-13.71-0.84v29.1c0,3.8,0.6,6.54,1.79,8.22c1.19,1.69,3.34,2.53,6.43,2.53
		c3.51,0,6.15,0.11,7.91,0.32c1.76,0.21,3.58,0.84,5.48,1.9c1.9,1.05,3.2,2.71,3.9,4.95c0.7,2.25,1.05,5.34,1.05,9.28
		c0,4.36-0.46,7.87-1.37,10.54c-0.92,2.67-2.42,4.53-4.53,5.59s-3.97,1.69-5.59,1.9c-1.62,0.21-3.97,0.32-7.06,0.32
		c-14.62,0-25.72-3.3-33.31-9.91c-7.59-6.61-11.39-17.78-11.39-33.53v-31.21c-2.67,0.42-5.2,0.63-7.59,0.63
		c-3.8,0-6.71-1.19-8.75-3.58c-2.04-2.39-3.06-7.38-3.06-14.97c0-3.93,0.35-7.2,1.05-9.8c0.7-2.6,1.72-4.46,3.06-5.59
		c1.33-1.12,2.64-1.86,3.9-2.21c1.26-0.35,2.74-0.53,4.43-0.53l6.96,0.84V50.03c0-3.23,0.11-5.62,0.32-7.17
		c0.21-1.54,0.84-3.27,1.9-5.17c1.05-1.9,2.92-3.23,5.59-4.01c2.67-0.77,6.25-1.16,10.75-1.16c3.51,0,6.43,0.35,8.75,1.05
		c2.32,0.7,4.11,1.51,5.38,2.42c1.27,0.92,2.21,2.32,2.85,4.22c0.63,1.9,0.98,3.55,1.05,4.96c0.07,1.41,0.11,3.37,0.11,5.9v20.66
		c6.18-0.56,10.82-0.84,13.92-0.84c3.23,0,5.66,0.11,7.27,0.32c1.61,0.21,3.44,0.84,5.48,1.9c2.04,1.05,3.48,2.92,4.32,5.59
		c0.84,2.67,1.27,6.26,1.27,10.75c0,4.36-0.42,7.87-1.27,10.54c-0.84,2.67-2.32,4.53-4.43,5.59c-2.11,1.05-3.97,1.69-5.59,1.9
		C721.16,107.7,718.74,107.8,715.51,107.8z"/>
  <path d="M821.72,83.56c0,6.05-1.34,11.74-4.01,17.08c-2.67,5.34-6.54,8.01-11.6,8.01c-2.53,0-4.96-0.56-7.27-1.69
		c-2.32-1.12-4.68-1.69-7.06-1.69c-5.2,0-9.46,1.34-12.76,4.01c-3.3,2.67-4.95,5.9-4.95,9.7v44.28c0,3.24-0.11,5.66-0.32,7.27
		c-0.21,1.62-0.88,3.45-2,5.48c-1.13,2.04-3.02,3.48-5.69,4.32c-2.67,0.84-6.26,1.26-10.75,1.26c-3.52,0-6.5-0.35-8.96-1.05
		c-2.46-0.7-4.29-1.54-5.48-2.53c-1.2-0.98-2.11-2.42-2.74-4.32c-0.63-1.9-0.98-3.51-1.05-4.85c-0.07-1.33-0.11-3.34-0.11-6.01
		V88.83c0-3.23,0.11-5.66,0.32-7.27c0.21-1.61,0.84-3.44,1.9-5.48c1.05-2.04,2.92-3.48,5.59-4.32c2.67-0.84,6.25-1.27,10.75-1.27
		c4.22,0,7.66,0.42,10.33,1.27c2.67,0.84,4.5,2,5.48,3.48c0.98,1.48,1.61,2.71,1.9,3.69c0.28,0.99,0.42,2.11,0.42,3.37
		c0.7-0.98,1.72-2.18,3.06-3.58c1.33-1.4,4.18-3.3,8.54-5.69c4.36-2.39,8.78-3.58,13.28-3.58c2.11,0,4.29,0.18,6.54,0.53
		c2.25,0.35,4.71,0.95,7.38,1.79c2.67,0.84,4.88,2.29,6.64,4.32C820.84,78.11,821.72,80.6,821.72,83.56z"/>
  <path d="M138.84,49.06c-2.48-5.06-5.4-9.45-8.76-13.18c-1.95-2.16-4.04-4.1-6.28-5.82c-1.11-0.85-2.23-1.65-3.36-2.43
		c-0.18-0.12-0.36-0.25-0.54-0.37c-0.38-0.26-0.76-0.51-1.14-0.76c-0.44-0.29-0.89-0.57-1.34-0.85c-4.16-2.59-8.44-4.71-12.86-6.34
		c-6.71-2.49-13.23-3.73-19.57-3.73h-3.6c-35.87,0-64.95,26.89-64.95,60.06v85.4c0,2.87,0.04,5.02,0.11,6.45
		c0.07,1.44,0.45,3.17,1.13,5.2c0.68,2.04,1.7,3.58,3.05,4.64c1.36,1.06,3.32,1.96,5.88,2.71c2.56,0.76,5.8,1.13,9.73,1.13
		c4.83,0,8.67-0.45,11.54-1.36c2.86-0.9,4.9-2.49,6.11-4.75c1.2-2.26,1.92-4.26,2.15-5.99c0.23-1.73,0.34-4.33,0.34-7.8v-25.56h0.02
		v-4.57V95.66v-8.35v-11.9c0-0.27,0.03-0.53,0.04-0.8c0.26-7.85,4.24-14.75,10.25-18.99c3.94-2.77,8.72-4.41,13.9-4.41
		c7.18,0,13.6,3.14,18.03,8.1c2.92,3.27,4.97,7.32,5.77,11.82c0.06,0.32,0.09,0.65,0.14,0.97c0.05,0.32,0.1,0.64,0.13,0.96
		c0.04,0.46,0.05,0.94,0.07,1.41c0.01,0.33,0.04,0.66,0.04,1c-0.01,3.89-0.96,7.55-2.6,10.8c-3.98,7.9-12.15,13.33-21.6,13.33
		c-2.66,0-5.2-0.44-7.59-1.23v36.85c2.71,0.32,5.48,0.48,8.28,0.48c12.6,0,24.36-3.32,34.31-9.06c0.26-0.15,0.52-0.3,0.77-0.45
		c0.22-0.13,0.43-0.26,0.64-0.39c0.64-0.39,1.27-0.79,1.9-1.2c0.22-0.14,0.43-0.29,0.64-0.43c0.27-0.18,0.55-0.37,0.82-0.56
		c1.11-0.76,2.21-1.55,3.3-2.38c0.74-0.57,1.42-1,2.21-1.62c-0.7-5.09-4.44-11.91-9.71-14.91c-1.11,1.22-2.96,1.56-4.44,0.7
		l-6.01-3.47c-1.7-0.98-2.29-3.18-1.31-4.89l0.54-0.93c0.98-1.7,3.18-2.29,4.89-1.31l6.01,3.47c1.65,0.95,2.24,3.05,1.38,4.73
		c5.39,3.1,9.23,9.62,10.4,15.06c1.31-1.29,3.01-3.23,5.47-6.51c5.17-8.26,3.95-5.85,5.62-9.22c3.92-7.92,5.88-16.78,5.88-26.58
		C144.72,65.96,142.76,57.06,138.84,49.06z"/>
</g>
</svg>

<svg *ngIf="type=='bubble_tail'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="25 50 100 100" style="enable-background:new 25 50 100 100;" xml:space="preserve">
<path d="M125.3,51v64.7c0,0-3.8,33.2-37.7,31.7l-13,0c0,0,14.7-17.7,15.2-44.3c2.6-36.9,15.2-52,35.1-52.2
	C125.1,51,125.2,51,125.3,51z"/>
</svg>

<svg *ngIf="type=='icon_facebook'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.8 163.8" style="enable-background:new 0 0 163.8 163.8;" xml:space="preserve">

    <g>
	<path class="fb_st5" d="M141.5,0.3H22.3c-12.1,0-22,9.8-22,22v119.2c0,12.1,9.8,22,22,22h58.8l0.1-58.3H66.1c-2,0-3.6-1.6-3.6-3.6
		l-0.1-18.8c0-2,1.6-3.6,3.6-3.6h15.1V61.1c0-21.1,12.9-32.5,31.7-32.5h15.4c2,0,3.6,1.6,3.6,3.6V48c0,2-1.6,3.6-3.6,3.6l-9.5,0
		c-10.2,0-12.2,4.9-12.2,12v15.7H129c2.1,0,3.8,1.9,3.5,4l-2.2,18.8c-0.2,1.8-1.7,3.2-3.5,3.2h-20.1l-0.1,58.3h35
		c12.1,0,22-9.8,22-22V22.3C163.5,10.2,153.6,0.3,141.5,0.3L141.5,0.3z M141.5,0.3"/>
</g>
</svg>

<svg *ngIf="type=='icon_twitter'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.8 163.8" style="enable-background:new 0 0 163.8 163.8;" xml:space="preserve">
    <g>
	<path class="twit_st8" d="M56,138.7c52.7,0,81.5-43.7,81.5-81.5c0-1.2,0-2.5-0.1-3.7c5.6-4,10.5-9.1,14.3-14.8
		c-5.1,2.3-10.7,3.8-16.5,4.5c5.9-3.5,10.5-9.2,12.6-15.9c-5.5,3.3-11.7,5.7-18.2,7c-5.2-5.6-12.7-9.1-20.9-9.1
		C93,25.1,80.1,38,80.1,53.8c0,2.2,0.3,4.4,0.7,6.5C57,59.1,35.9,47.7,21.8,30.4c-2.5,4.2-3.9,9.2-3.9,14.4
		c0,9.9,5.1,18.7,12.8,23.9c-4.7-0.1-9.1-1.4-13-3.6c0,0.1,0,0.2,0,0.4c0,13.9,9.9,25.5,23,28.1c-2.4,0.7-4.9,1-7.6,1
		c-1.8,0-3.6-0.2-5.4-0.5c3.6,11.4,14.2,19.7,26.8,19.9c-9.8,7.7-22.2,12.3-35.6,12.3c-2.3,0-4.6-0.1-6.8-0.4
		C24.8,133.9,39.8,138.7,56,138.7"/>
</g>
</svg>

<svg *ngIf="type=='icon_instagram'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.8 163.8" style="enable-background:new 0 0 163.8 163.8;" xml:space="preserve">
    <g>
	<path class="ins_st5" d="M82.2,15.4c21.8,0,24.4,0.1,33,0.5c8,0.4,12.3,1.7,15.1,2.8c3.8,1.5,6.5,3.2,9.4,6.1s4.6,5.6,6.1,9.4
		c1.1,2.9,2.4,7.2,2.8,15.1c0.4,8.6,0.5,11.2,0.5,33s-0.1,24.4-0.5,33c-0.4,8-1.7,12.3-2.8,15.1c-1.5,3.8-3.2,6.5-6.1,9.4
		s-5.6,4.6-9.4,6.1c-2.9,1.1-7.2,2.4-15.1,2.8c-8.6,0.4-11.2,0.5-33,0.5s-24.4-0.1-33-0.5c-8-0.4-12.3-1.7-15.1-2.8
		c-3.8-1.5-6.5-3.2-9.4-6.1c-2.9-2.9-4.6-5.6-6.1-9.4c-1.1-2.9-2.4-7.2-2.8-15.1c-0.4-8.6-0.5-11.2-0.5-33s0.1-24.4,0.5-33
		c0.4-8,1.7-12.3,2.8-15.1c1.5-3.8,3.2-6.5,6.1-9.4c2.9-2.9,5.6-4.6,9.4-6.1c2.9-1.1,7.2-2.4,15.1-2.8
		C57.9,15.5,60.5,15.4,82.2,15.4 M82.2,0.7c-22.2,0-24.9,0.1-33.6,0.5C39.9,1.6,34,2.9,28.8,5c-5.4,2.1-9.9,4.9-14.4,9.4
		S7,23.5,5,28.8c-2,5.2-3.4,11.1-3.8,19.8c-0.4,8.7-0.5,11.5-0.5,33.6s0.1,24.9,0.5,33.6c0.4,8.7,1.8,14.6,3.8,19.8
		c2.1,5.4,4.9,9.9,9.4,14.4s9.1,7.3,14.4,9.4c5.2,2,11.1,3.4,19.8,3.8c8.7,0.4,11.5,0.5,33.6,0.5s24.9-0.1,33.6-0.5
		c8.7-0.4,14.6-1.8,19.8-3.8c5.4-2.1,9.9-4.9,14.4-9.4s7.3-9.1,9.4-14.4c2-5.2,3.4-11.1,3.8-19.8c0.4-8.7,0.5-11.5,0.5-33.6
		s-0.1-24.9-0.5-33.6c-0.4-8.7-1.8-14.6-3.8-19.8c-2.1-5.4-4.9-9.9-9.4-14.4S141,7,135.7,5c-5.2-2-11.1-3.4-19.8-3.8
		C107.2,0.8,104.4,0.7,82.2,0.7z M82.2,40.4c-23.1,0-41.9,18.8-41.9,41.9s18.8,41.9,41.9,41.9s41.9-18.8,41.9-41.9
		S105.4,40.4,82.2,40.4z M82.2,109.4c-15,0-27.2-12.2-27.2-27.2c0-15,12.2-27.2,27.2-27.2s27.2,12.2,27.2,27.2
		C109.4,97.3,97.3,109.4,82.2,109.4z M135.6,38.7c0,5.4-4.4,9.8-9.8,9.8c-5.4,0-9.8-4.4-9.8-9.8s4.4-9.8,9.8-9.8
		C131.2,28.9,135.6,33.3,135.6,38.7z"/>
</g>
</svg>

<svg *ngIf="type=='icon_linkedin'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.8 163.8" style="enable-background:new 0 0 163.8 163.8;" xml:space="preserve">

<path  d="M129.4,0H33.8C15,0-0.3,15.3-0.3,34.1v95.6c0,18.8,15.3,34.1,34.1,34.1h95.6c18.9,0,34.1-15.3,34.1-34.1V34.1
	C163.5,15.3,148.2,0,129.4,0z M54.3,129.7H33.8V54.6h20.5V129.7z M44.1,45.9c-6.6,0-11.9-5.4-11.9-12s5.4-12,11.9-12
	S56,27.3,56,33.9S50.7,45.9,44.1,45.9z M136.2,129.7h-20.5V91.4c0-23-27.3-21.2-27.3,0v38.2H67.9V54.6h20.5v12
	c9.5-17.6,47.8-19,47.8,16.9V129.7z"/>
</svg>

<svg *ngIf="type=='icon_mail'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.8 163.8" style="enable-background:new 0 0 163.8 163.8;" xml:space="preserve">

<g>
	<path class="em_st5" d="M112.2,79.3l39.3-31.1c0.2-0.2,0.4-0.3,0.6-0.4c-0.8-0.2-1.6-0.4-2.4-0.4H74c-0.8,0-1.7,0.1-2.4,0.4
		c0.2,0.1,0.4,0.3,0.6,0.4L112.2,79.3z"/>
    <path class="em_st5" d="M114.6,91.5c-0.7,0.5-1.5,0.8-2.3,0.8c-0.8,0-1.6-0.3-2.3-0.8L65.7,57.1v51c0,4.6,3.7,8.3,8.3,8.3h75.7
		c4.6,0,8.3-3.7,8.3-8.3v-51L114.6,91.5z"/>
    <path class="em_st5" d="M56.2,78l-27.7,0c-1.1,0-2,0.4-2.8,1.2c-0.7,0.7-1.2,1.7-1.2,2.8c0,2.2,1.8,4,4,4l27.6,0c0,0,0,0,0,0
		c1.4,0,2.5-1.1,2.5-2.5l0-2.8c0-0.7-0.3-1.3-0.7-1.8C57.5,78.2,56.9,78,56.2,78z"/>
    <path class="em_st5" d="M56.2,63.8l-42.8,0c-1.1,0-2,0.4-2.8,1.2c-0.7,0.7-1.2,1.7-1.2,2.8c0,2.2,1.8,4,4,4h42.7
		c1.4,0,2.5-1.1,2.5-2.5l0-2.8c0-0.7-0.3-1.3-0.7-1.8C57.5,64.1,56.9,63.8,56.2,63.8z"/>
    <path class="em_st5" d="M56.2,92.1l-13.8,0c-2.2,0-3.9,1.7-4,3.8c0,1.1,0.4,2.1,1.1,2.9c0.7,0.8,1.8,1.2,2.8,1.2c0,0,0,0,0,0l13.8,0
		c0,0,0,0,0,0c1.4,0,2.5-1.1,2.5-2.5l0-2.8c0-0.7-0.3-1.3-0.7-1.8C57.5,92.3,56.9,92.1,56.2,92.1z"/>
</g>
</svg>

<svg *ngIf="type=='icon_highest_usage'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.8 163.8" style="enable-background:new 0 0 163.8 163.8;" xml:space="preserve">

<path class="highest_usage_st5" d="M15.5,119.3v30.5h34.8V93l-2.6-2.6L15.5,119.3z M60.4,103.1v46.7h34.8V99.6L75.3,118L60.4,103.1z M147.8,17.2
	l-20.1,5.5c-5.3,1.4-7.5,4.8-4.8,7.4l4.8,4.8L75.5,83.5L47.9,56L7.2,92.4l8.2,9.2l32-28.6l27.8,27.7l61.3-57c0,0,2.4,2.3,5.3,5.2
	c2.9,2.9,6.4,0.9,7.8-4.4l5.3-20.2C156.3,19,153.1,15.8,147.8,17.2z M105.7,89.8v60h34.8v-88l-2.1-2.5L105.7,89.8z"/>
</svg>

<svg *ngIf="type=='icon_time'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.8 163.8" style="enable-background:new 0 0 163.8 163.8;" xml:space="preserve">

<g>
	<path class="time_st5" d="M138.1,51.8c-6.5-10.3-15.8-18.9-26.7-24.3c-7.9-4-16.6-6.4-25.4-7c0-1.6,0-3.2,0-4.8h5c1.4,0,2.6-1.2,2.6-2.6
		V7.6c0-1.4-1.2-2.6-2.6-2.6H71.3c-1.4,0-2.6,1.2-2.6,2.6V13c0,1.4,1.2,2.6,2.6,2.6h5c0,1.6,0,3.2,0,4.8c-0.6,0-1.1,0.1-1.7,0.1
		c-6.3,0.6-12.4,2.1-18.3,4.4c-3,1.2-6,2.6-8.8,4.2c-2.8,1.6-5.4,3.5-8,5.5c-9.7,7.5-17,17.8-21.3,29.2c-4.6,11.9-5.3,25.2-2.8,37.6
		c2.5,12,8.6,23.3,17,32.2c8.4,8.9,19.4,15.4,31.2,18.6c12.3,3.3,25.6,3.1,37.8-0.8c11.7-3.7,22.3-10.6,30.3-19.8
		c8.1-9.3,13.7-20.8,15.7-33c0.6-3.7,1-7.5,1-11.3C148.4,74.9,144.8,62.4,138.1,51.8z M138.1,95.2c-0.7,5-2.1,9.9-4,14.6
		c-2,4.5-4.5,8.8-7.5,12.8c-3,3.9-6.5,7.3-10.4,10.4c-3.9,3-8.2,5.5-12.8,7.5c-4.7,1.9-9.6,3.3-14.6,4c-5.1,0.6-10.3,0.6-15.5,0
		c-5-0.7-9.9-2.1-14.6-4c-4.5-2-8.8-4.5-12.8-7.5c-3.9-3-7.3-6.5-10.4-10.4c-3-3.9-5.5-8.2-7.5-12.8c-1.9-4.7-3.3-9.6-4-14.6
		c-0.6-5.1-0.6-10.3,0-15.5c0.7-5,2.1-9.9,4-14.6c2-4.5,4.5-8.8,7.5-12.8c3-3.9,6.5-7.3,10.4-10.4c3.9-3,8.2-5.5,12.8-7.5
		c4.7-1.9,9.6-3.3,14.6-4c5.1-0.6,10.3-0.6,15.4,0c5,0.7,9.9,2.1,14.6,4c4.5,2,8.8,4.5,12.8,7.5c3.9,3,7.3,6.5,10.4,10.4
		c3,3.9,5.5,8.2,7.5,12.8c1.9,4.7,3.3,9.6,4,14.6c0.3,2.6,0.5,5.1,0.5,7.7C138.6,90.1,138.4,92.7,138.1,95.2z"/>
    <path class="time_st5" d="M114.8,53.8c-8.5-8.4-20.2-13.5-33.1-13.5v47.2l-33.4,33.4c0.1,0.1,0.2,0.2,0.3,0.3
		c8.5,8.4,20.2,13.5,33.1,13.5v0c12.9,0,24.6-5.2,33.1-13.5c8.7-8.6,14.1-20.5,14.1-33.7C128.9,74.3,123.5,62.4,114.8,53.8z"/>
</g>
</svg>

<svg *ngIf="type=='icon_video'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
<style type="text/css">
	.st25_icon_video{fill:#3A7BBF;}
</style>
  <g id="Layer_6">
	<g>
		<path class="st25_icon_video" d="M460.82,140.24H324.78l158.69,158.69c3.13-15.4,4.78-31.36,4.78-47.69
			C488.25,211.15,478.32,173.38,460.82,140.24z"/>
    <path class="st25_icon_video" d="M307.56,379.47L202.58,484.44c15.41,3.13,31.36,4.78,47.69,4.78c40.1,0,77.86-9.93,111.01-27.44V325.74
			l-53.71,53.71C307.56,379.46,307.56,379.46,307.56,379.47z"/>
    <path class="st25_icon_video" d="M250.27,13.26c-40.08,0-77.85,9.93-111,27.44v136.04L297.97,18.05C282.56,14.91,266.61,13.26,250.27,13.26z"
    />
    <path class="st25_icon_video" d="M381.44,301.39c0,0.01,0,0.01,0,0.01v148.42c45.35-30.01,79.81-75.13,96.22-128.18l-96.22-96.22V301.39z"/>
    <path class="st25_icon_video" d="M17.08,203.55c-3.14,15.41-4.79,31.36-4.79,47.7c0,40.09,9.93,77.86,27.44,111h136.04L17.08,203.55z"/>
    <path class="st25_icon_video" d="M51.7,382.42c30.01,45.34,75.12,79.8,128.17,96.21l96.22-96.21H51.7z"/>
    <path class="st25_icon_video" d="M300.42,120.07c0,0,0.01,0,0.02,0h148.41c-30.01-45.34-75.12-79.8-128.17-96.21l-96.22,96.21H300.42z"/>
    <path class="st25_icon_video" d="M119.1,52.66c-45.34,30.01-79.8,75.12-96.22,128.17l96.22,96.22V52.66z"/>
	</g>
</g>
</svg>

<svg *ngIf="type=='icon_audio'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">
<style type="text/css">
	.st21_mic_icon{fill:#FFFFFF;}
  .st25_mic_icon{fill:#3A7BBF;}
  .st26_mic_icon{fill:#818181;}
</style>
  <g id="Layer_7">
	<path class="st26_mic_icon" d="M250.01,12.05c-54,0-97.77,43.77-97.77,97.76v125.34c0,53.99,43.77,97.76,97.77,97.76
		c53.99,0,97.76-43.78,97.76-97.76V109.81C347.76,55.82,304,12.05,250.01,12.05z"/>
    <path class="st21_mic_icon" d="M273.1,309.91c-5.52,1.13-11.22,1.72-17.05,1.72c-46.76-0.01-84.65-37.9-84.66-84.65V193.5
		c0-5.01,4.07-9.08,9.08-9.08c5.01,0,9.07,4.06,9.07,9.08l-0.01,33.48c0.01,18.39,7.43,34.96,19.48,47.02
		c12.07,12.05,28.63,19.48,47.03,19.48c4.6,0,9.08-0.46,13.41-1.35c4.92-1,9.71,2.16,10.7,7.07
		C281.18,304.11,278.01,308.91,273.1,309.91z"/>
    <path class="st25_mic_icon" d="M390.44,161.41c-12.7,0-23,10.29-23,23v17.08c0,7.94,4.03,14.95,10.16,19.08v17.87
		c-0.01,35.28-14.26,67.09-37.37,90.22c-23.13,23.11-54.95,37.37-90.22,37.37c-35.29,0-67.09-14.26-90.23-37.37
		c-23.12-23.13-37.36-54.94-37.37-90.22v-17.87c6.12-4.13,10.15-11.13,10.15-19.07v-17.08c0-12.7-10.29-23-22.99-23
		c-12.7,0-23,10.29-23,23v17.08c0,6.93,3.08,13.13,7.93,17.34v19.61c0.01,75.96,54.47,139.15,126.47,152.76v36.55l-66.33,0.01
		c-28.95,0-52.42,23.46-52.42,52.42c0,1.02,0.02,2.03,0.09,3c0.15,2.69,2.38,4.78,5.07,4.78h285.29c2.69,0,4.92-2.11,5.06-4.79
		c0.06-0.97,0.09-1.96,0.09-2.99c-0.01-28.96-23.47-52.42-52.42-52.43l-66.34,0V391.2c72.01-13.61,126.46-76.8,126.47-152.76v-19.6
		c4.85-4.22,7.93-10.42,7.93-17.34v-17.08C413.43,171.7,403.14,161.41,390.44,161.41z"/>
</g>
</svg>

<svg *ngIf="type=='icon_stop'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.82 163.82" style="enable-background:new 0 0 163.82 163.82;" xml:space="preserve">
<g id="Layer_5_copy">
	<g>
		<rect x="54.72" y="55.12" class="st7" width="54.39" height="54.39"/>
	</g>
</g>
</svg>

<svg *ngIf="type=='icon_play'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.82 163.82" style="enable-background:new 0 0 163.82 163.82;" xml:space="preserve">
<g id="Layer_4">
	<path d="M56.91,45.89c0-5.32,3.73-7.48,8.34-4.82L96.8,59.28l31.54,18.21c4.61,2.66,4.61,6.97,0,9.63L96.8,105.34
		l-31.54,18.21c-4.61,2.66-8.34,0.5-8.34-4.82V82.31V45.89z"/>
</g>
</svg>

<svg *ngIf="type=='icon_pause'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 163.82 163.82" style="enable-background:new 0 0 163.82 163.82;" xml:space="preserve">
<g id="Layer_5">
	<g>
		<rect x="51.35" y="40.04" class="st7" width="25.63" height="84.54"/>
    <rect x="86.84" y="40.04" class="st7" width="25.63" height="84.54"/>
	</g>
</g>
</svg>

<svg *ngIf="type=='icon_kinect'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 274.35 275.23" style="enable-background:new 0 0 274.35 275.23;" xml:space="preserve">

<g id="Layer_2">
	<g>
		<g>
			<g>
				<path d="M163.73,159.84c0.01,0.15,0.05,0.29,0.05,0.44v7.6c0,2.83,5.76,7.72,7.45,10.22
					c0.51,0.39,0.86,0.97,0.86,1.66v0.79c0,1.2-0.97,2.16-2.17,2.16H105.6c-1.2,0-2.16-0.96-2.16-2.16v-0.79
					c0-0.67,0.34-1.26,0.84-1.65c1.7-2.5,7.46-7.4,7.46-10.23v-7.6c0-0.15,0.03-0.3,0.05-0.44H163.73z M140.15,14.67"/>
			</g>
      <g>
				<path d="M44.3,113.34c-3.32,0-6.03,2.71-6.03,6.03v34.97c0,3.32,2.71,6.03,6.03,6.03h186.93
					c3.32,0,6.03-2.71,6.03-6.03v-34.97c0-3.32-2.71-6.03-6.03-6.03H44.3z M69.43,155.94c-4.41,0-7.99-3.58-7.99-7.99
					c0-4.41,3.58-7.99,7.99-7.99c4.41,0,7.99,3.58,7.99,7.99C77.42,152.36,73.84,155.94,69.43,155.94z M69.43,134.64
					c-4.41,0-7.99-3.58-7.99-7.99c0-4.41,3.58-7.99,7.99-7.99c4.41,0,7.99,3.58,7.99,7.99C77.42,131.07,73.84,134.64,69.43,134.64z
					 M137.76,152.39c-8.58,0-15.53-6.95-15.53-15.53s6.95-15.53,15.53-15.53c8.58,0,15.53,6.95,15.53,15.53
					S146.34,152.39,137.76,152.39z M206.09,155.05c-4.41,0-7.99-3.58-7.99-7.99c0-4.41,3.58-7.99,7.99-7.99
					c4.41,0,7.99,3.58,7.99,7.99C214.08,151.48,210.5,155.05,206.09,155.05z M206.09,133.75c-4.41,0-7.99-3.58-7.99-7.99
					c0-4.41,3.58-7.99,7.99-7.99c4.41,0,7.99,3.58,7.99,7.99C214.08,130.18,210.5,133.75,206.09,133.75z"/>
			</g>
		</g>
	</g>
</g>
</svg>

<svg *ngIf="type=='icon_web'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 274.35 275.23" style="enable-background:new 0 0 274.35 275.23;" xml:space="preserve">
<g id="Layer_3">
	<path d="M222.59,182.44c0,5.25-4.22,9.48-9.44,9.48H56.01c-5.23,0-9.46-4.24-9.46-9.48H222.59z M118.6,185.72
		c0,1.61,1.29,2.92,2.9,2.92h26.16c1.6,0,2.89-1.31,2.89-2.92H118.6z"/>
  <path d="M202.64,76.82H66.49c-4.52,0-8.17,3.66-8.17,8.21v86.39c0,4.54,3.65,8.2,8.17,8.21h136.15
		c4.52-0.01,8.19-3.67,8.19-8.21V85.03C210.83,80.49,207.17,76.82,202.64,76.82z M134.57,79.37c0.98,0,1.79,0.8,1.79,1.79
		c0,0.99-0.81,1.79-1.79,1.79c-0.99,0-1.79-0.8-1.79-1.79C132.78,80.17,133.58,79.37,134.57,79.37z M202.07,171.02H67.07V85.45
		h135.01V171.02z"/>
</g>
</svg>

<svg *ngIf="type=='icon_phone'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 274.35 275.23" style="enable-background:new 0 0 274.35 275.23;" xml:space="preserve">

<path d="M174.84,49.59h-74.16c-5.57,0-10.09,4.51-10.09,10.09v155.99c0,5.57,4.52,10.09,10.09,10.09h74.16
	c5.57,0,10.1-4.51,10.1-10.09V59.68C184.94,54.1,180.4,49.59,174.84,49.59z M130.58,64.47h14.36c0.94,0,1.71,0.76,1.71,1.69
	c0,0.93-0.77,1.69-1.71,1.69h-14.36c-0.94,0-1.7-0.76-1.7-1.69C128.88,65.23,129.64,64.47,130.58,64.47z M138.05,220.19
	c-3.18,0-5.75-2.57-5.75-5.74c0-3.16,2.57-5.74,5.75-5.74c3.16,0,5.73,2.57,5.73,5.74C143.77,217.62,141.21,220.19,138.05,220.19z
	 M180.98,201.76H95.1V73.61h85.88V201.76z"/>
</svg>

<svg *ngIf="type=='icon_help'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
<path d="M29.04,61.53c-5.21-9.73-3.31-22.11,5.29-29.78c10.08-9,25.55-8.13,34.56,1.95
	c9,10.08,8.13,25.55-1.95,34.56c-9.04,8.08-22.43,8.2-31.58,0.86l-10.45,2.84L29.04,61.53L29.04,61.53z M53.22,57.47
	c-0.03-1.57,0.1-2.65,0.39-3.26c0.29-0.61,1.04-1.42,2.24-2.41c2.33-1.93,3.84-3.46,4.55-4.58c0.71-1.12,1.06-2.31,1.06-3.56
	c0-2.27-0.97-4.26-2.9-5.96c-1.93-1.71-4.54-2.56-7.81-2.56c-3.11,0-5.62,0.84-7.54,2.53c-1.91,1.69-2.94,3.74-3.09,6.17l5.22,0.66
	c0.37-1.69,1.04-2.95,2.01-3.78c0.98-0.82,2.19-1.24,3.64-1.24c1.51,0,2.7,0.39,3.58,1.18c0.89,0.78,1.33,1.73,1.33,2.82
	c0,0.79-0.25,1.53-0.75,2.19c-0.32,0.42-1.32,1.31-2.98,2.65c-1.66,1.35-2.77,2.57-3.32,3.65c-0.56,1.08-0.84,2.46-0.84,4.13
	c0,0.17,0.01,0.61,0.02,1.36H53.22L53.22,57.47z M50.63,64.87c1.45,0,2.62-1.17,2.62-2.62c0-1.45-1.17-2.62-2.62-2.62
	c-1.45,0-2.62,1.17-2.62,2.62C48.01,63.7,49.18,64.87,50.63,64.87z"/>
</svg>

<svg *ngIf="type=='icon_send'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 500 500" style="enable-background:new 0 0 500 500;" xml:space="preserve">

<g id="Layer_5">
	<g>
		<path d="M35.82,259.51c0,0-71.46-24.4,12.2-57.51c83.66-33.11,374.63-169.76,374.63-169.76s64.49-36.6,50.54,43.57
			c-13.94,80.17-61,329.4-61,329.4s-4.47,46.62-76.69,8.71c-27.02-14.18-81.12-41.32-148.12-74.7
			c-9.19-4.58,241.63-256.87,232.6-261.36c-11.76-5.85-273.02,236.74-284.07,231.25C79.78,281.24,35.82,259.51,35.82,259.51z"/>
    <path d="M168.73,379.94c0,0-51.26,116.01,36.32,37.58l33.66-31.37l-61.28-28.04L168.73,379.94z"/>
	</g>
</g>
</svg>

<svg *ngIf="type == 'icon_zoom_out'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
<metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
    <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M3600.4,4988C2612,4885.9,1662.3,4379.4,1021,3611.5C-627.1,1644.8,14.2-1326.6,2326-2443.7c1088.5-524.9,2322-516.7,3426.9,24.5l251.2,122.5l1137.5-1131.4c624.9-622.9,1178.4-1162,1233.5-1198.8c130.7-87.8,300.2-145,473.8-159.3c653.5-51.1,1184.5,575.9,1021.1,1209c-73.5,277.7-77.6,283.9-1313.2,1525.6L7405.1-895.7l122.5,253.2c271.6,551.4,412.5,1147.7,412.5,1725.7c-2,1162-516.7,2264.9-1399,3004.2C5722.3,4773.6,4662.4,5098.3,3600.4,4988z M4570.5,3644.2c1347.9-306.3,2230.2-1576.6,2034.1-2922.5c-259.4-1768.6-2166.8-2765.2-3759.8-1964.6c-263.4,130.7-428.9,253.3-680.1,500.4C1813.4-395.4,1564.3,66.2,1454,578.8c-53.1,243-53.1,778.1,0,1021.1c112.3,518.7,351.3,959.9,718.9,1331.6c418.7,420.7,957.8,682.1,1560.3,759.7C3894.5,3711.6,4401,3683,4570.5,3644.2z"/><path d="M2058.5,1089.4V435.8h1960.6h1960.6v653.5v653.5H4019.1H2058.5V1089.4z"/></g></g>
</svg>

<svg *ngIf="type == 'icon_zoom_in'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
<metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
    <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M3556.1,5012.2c-779.9-64.8-1582.1-411.2-2181.7-942C341.2,3154.6-108.5,1764.9,191.3,417.8c463.9-2084.5,2568.6-3368.8,4647-2838c218.8,56.7,512.5,166.1,713.1,265.4l156,79l441.6-451.7c243.1-247.1,808.3-824.5,1255.9-1280.3c573.3-587.5,842.7-848.8,911.6-887.3c277.5-149.9,605.7-149.9,881.2,0c105.3,56.7,455.8,405.1,557.1,555c109.4,160,154,322.1,143.8,544.9c-6.1,162.1-16.2,212.7-68.9,324.1c-34.4,72.9-93.2,168.1-129.7,208.6c-34.4,40.5-638.1,563.2-1339,1162.8C7660.2-1302,7086.9-805.7,7086.9-795.6c0,8.1,50.6,103.3,111.4,212.7c745.5,1339,589.5,3028.5-393,4227.7c-542.9,662.4-1351.2,1148.6-2177.6,1306.6C4238.7,5026.4,3938.9,5044.6,3556.1,5012.2z M4210.4,3879.8C5531.1,3717.7,6540,2577.3,6540,1246.4C6540-40,5604.1-1156.2,4342-1375c-235-42.5-680.6-42.5-913.6-2c-546.9,95.2-1053.4,366.7-1442.3,767.7c-216.8,224.9-340.3,397.1-472,654.3c-210.7,413.2-293.7,800.2-275.5,1300.5c8.1,265.4,20.3,354.5,70.9,555.1c68.9,261.3,224.9,613.8,366.7,820.4C2241.3,3553.6,3219.8,4001.3,4210.4,3879.8z"/><path d="M3722.2,3144.5c-131.7-50.6-237-151.9-291.7-285.6c-24.3-56.7-30.4-180.3-30.4-597.6v-526.7l-553-6.1l-555.1-6.1l-99.3-60.8c-137.7-87.1-216.7-218.8-226.9-384.9c-10.1-158,20.3-255.3,113.4-358.6c147.9-162.1,168.1-168.1,779.9-174.2l540.9-6.1V229.4c0-561.1,10.1-619.9,115.5-743.5c188.4-220.8,544.9-226.9,733.3-12.1c127.6,145.9,131.7,170.1,139.8,743.5l8.1,520.6l528.7,6.1c498.3,6.1,534.8,8.1,611.8,50.6c218.8,117.5,318,356.5,251.2,603.7c-28.3,107.4-200.5,275.5-314,309.9c-56.7,16.2-247.1,24.3-583.4,24.3h-498.3v441.6c0,241.1-6.1,488.2-14.2,546.9c-16.2,147.9-111.4,301.8-233,374.8C4032.1,3166.7,3839.7,3189,3722.2,3144.5z"/></g></g>
</svg>

<svg *ngIf="type == 'score_card_gesture'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 377 250" style="enable-background:new 0 0 377 250;" xml:space="preserve">
<style type="text/css">
	.st1_scg{opacity:0.18;}
  .st2_scg{fill:#FFFFFF;}
</style>
  <g id="_x31_0" class="st1_scg">
	<path class="st2_scg" d="M360.97,118.9c0,4.33-3.51,7.83-7.83,7.83c-4.33,0-7.83-3.51-7.83-7.83c0-4.33,3.51-7.83,7.83-7.83
		C357.46,111.06,360.97,114.57,360.97,118.9z"/>
    <g>
		<path class="st2_scg" d="M352.38,190.6c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C350.68,194.23,352.38,192.58,352.38,190.6L352.38,190.6z"/>
      <path class="st2_scg" d="M361.18,190.6c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C359.49,194.23,361.18,192.58,361.18,190.6L361.18,190.6z"/>
	</g>
    <path class="st2_scg" d="M360.4,132.14h-14.53c-0.43,0-0.78,0.42-0.78,0.93v27.25c0,0.52,0.35,0.93,0.78,0.93h6.5c0,0,0,0,0,0
		c0-0.42,0.34-0.76,0.76-0.76c0.42,0,0.76,0.34,0.76,0.76c0,0,0,0,0,0h6.5c0.43,0,0.78-0.42,0.78-0.93v-27.25
		C361.18,132.55,360.83,132.14,360.4,132.14z"/>
    <g>
		<path class="st2_scg" d="M361.18,147.19c-0.93,0.91-1.86,1.82-2.78,2.73c-1.24,1.21-1.28,3.19-0.02,4.41
			c0.74,0.72,1.81,1.02,2.81,0.88V147.19z"/>
      <path class="st2_scg" d="M372.11,142.13c-0.51-0.68-1.04-1.34-1.57-2.01c-2.15-2.71-4.4-5.5-7.1-7.73c-2.07-1.72-4.51-3.14-7.21-3.72
			c-1.03-0.22-2.06-0.33-3.09-0.35v0c0,0,0,0,0,0c0,0,0,0,0,0v0c-1.03,0.02-2.06,0.13-3.09,0.35c-2.7,0.57-5.13,2-7.21,3.72
			c-2.69,2.23-4.95,5.02-7.1,7.73c-0.53,0.67-1.06,1.33-1.57,2.01c-0.85,1.13-0.42,2.86,0.53,3.78c2.87,2.81,5.74,5.62,8.61,8.43
			c0.01,0.01,0.01,0.01,0.02,0.02c0.12,0.12,0.26,0.23,0.39,0.32v-8.83c-0.83-0.81-1.66-1.63-2.49-2.44
			c0.83-1.01,1.67-2.01,2.55-2.99c1.22-1.36,2.48-2.71,3.92-3.86c0.78-0.55,1.61-1.03,2.49-1.42c0.7-0.25,1.42-0.44,2.15-0.56
			c0.27-0.02,0.53-0.03,0.8-0.04c0.27,0,0.53,0.02,0.8,0.04c0.74,0.12,1.45,0.31,2.15,0.56c0.88,0.39,1.71,0.87,2.49,1.42
			c1.44,1.14,2.71,2.5,3.92,3.86c0.87,0.98,1.72,1.97,2.55,2.99c-0.83,0.81-1.66,1.63-2.49,2.44v8.83c0.14-0.1,0.27-0.2,0.39-0.32
			c0.01-0.01,0.01-0.01,0.02-0.02c2.87-2.81,5.74-5.62,8.61-8.43C372.52,144.98,372.96,143.26,372.11,142.13z"/>
	</g>
</g>
  <g id="_x39_" class="st1_scg">
	<path class="st2_scg" d="M278.73,118.9c0,4.33-3.51,7.83-7.83,7.83c-4.33,0-7.83-3.51-7.83-7.83c0-4.33,3.51-7.83,7.83-7.83
		C275.23,111.07,278.73,114.58,278.73,118.9z"/>
    <g>
		<path class="st2_scg" d="M270.14,190.61c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C268.45,194.24,270.14,192.59,270.14,190.61L270.14,190.61z"/>
      <path class="st2_scg" d="M278.94,190.61c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C277.25,194.24,278.94,192.59,278.94,190.61L278.94,190.61z"/>
	</g>
    <path class="st2_scg" d="M278.16,132.14h-14.53c-0.43,0-0.78,0.42-0.78,0.93v27.25c0,0.52,0.35,0.93,0.78,0.93h6.5c0,0,0,0,0,0
		c0-0.42,0.34-0.76,0.76-0.76c0.42,0,0.76,0.34,0.76,0.76c0,0,0,0,0,0h6.5c0.43,0,0.78-0.42,0.78-0.93v-27.25
		C278.94,132.56,278.59,132.14,278.16,132.14z"/>
    <g>
		<path class="st2_scg" d="M262.86,147.19c0.9,0.91,1.8,1.83,2.7,2.74c1.19,1.21,1.23,3.19,0.02,4.41c-0.72,0.73-1.75,1.03-2.72,0.88
			V147.19z"/>
      <path class="st2_scg" d="M252.34,142.13c0.49-0.68,1-1.34,1.51-2.01c2.07-2.71,4.24-5.5,6.84-7.73c2-1.72,4.35-3.14,6.95-3.72
			c2.24-0.5,4.48-0.46,6.72,0.06c2.54,0.59,4.92,1.79,6.89,3.5c1.11,0.95,2.12,2.03,3.12,3.09c1.18,1.24,2.33,2.51,3.47,3.78
			c3.73-1.54,7.45-3.08,11.18-4.62c0.72-0.3,1.73-0.06,2.37,0.31c0.71,0.41,1.24,1.1,1.45,1.89c0.21,0.8,0.1,1.65-0.31,2.37
			c-0.46,0.79-1.09,1.12-1.89,1.45c-0.33,0.14-0.66,0.27-0.99,0.41c-3.96,1.64-7.91,3.27-11.87,4.91c-0.93,0.38-2.36-0.06-3-0.8
			c-1.09-1.25-2.2-2.48-3.33-3.7c-1.51-1.64-3.02-3.39-4.76-4.8c-0.66-0.46-1.36-0.86-2.09-1.2c-0.85-0.32-1.73-0.56-2.62-0.72
			c-0.71-0.07-1.42-0.08-2.13-0.02c-0.71,0.12-1.4,0.31-2.08,0.56c-0.84,0.39-1.64,0.87-2.39,1.42c-1.39,1.14-2.61,2.5-3.79,3.86
			c-0.84,0.98-1.66,1.97-2.46,2.99c0.78,0.79,1.56,1.59,2.34,2.38v8.85c-0.11-0.08-0.22-0.18-0.32-0.28
			c-0.01-0.01-0.01-0.01-0.02-0.02c-2.77-2.81-5.53-5.62-8.3-8.43C251.94,144.99,251.52,143.26,252.34,142.13z"/>
	</g>
</g>
  <g id="_x38_" class="st1_scg">
	<path class="st2_scg" d="M196.94,118.8c0,4.33-3.51,7.83-7.83,7.83c-4.33,0-7.83-3.51-7.83-7.83c0-4.33,3.51-7.83,7.83-7.83
		C193.43,110.97,196.94,114.48,196.94,118.8z"/>
    <g>
		<path class="st2_scg" d="M188.35,190.51c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C186.65,194.14,188.35,192.49,188.35,190.51L188.35,190.51z"/>
      <path class="st2_scg" d="M197.15,190.51c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C195.45,194.14,197.15,192.49,197.15,190.51L197.15,190.51z"/>
	</g>
    <path class="st2_scg" d="M196.37,132.04h-14.53c-0.43,0-0.78,0.42-0.78,0.93v27.25c0,0.52,0.35,0.93,0.78,0.93h6.5c0,0,0,0,0,0
		c0-0.42,0.34-0.76,0.76-0.76c0.42,0,0.76,0.34,0.76,0.76c0,0,0,0,0,0h6.5c0.43,0,0.78-0.42,0.78-0.93v-27.25
		C197.15,132.46,196.8,132.04,196.37,132.04z"/>
    <g>
		<path class="st2_scg" d="M180.07,131.91v0.24c0.6,0.43,0.99,1.13,1,1.92h0.77v-2.16H180.07z"/>
      <path class="st2_scg" d="M198.15,131.91v0.24c-0.6,0.43-0.99,1.13-1,1.92h-0.77v-2.16H198.15z"/>
	</g>
    <path class="st2_scg" d="M206.93,118.08c-0.03-0.12-0.06-0.24-0.09-0.36c-0.22-0.81-0.43-1.63-0.65-2.44c-0.7-2.64-1.4-5.29-2.11-7.93
		c-0.41-1.54-0.82-3.07-1.22-4.61c-0.44-1.64-2.18-2.65-3.82-2.21c-1.65,0.44-2.64,2.18-2.21,3.82c1.11,4.18,2.22,8.36,3.33,12.54
		c0.15,0.55,0.29,1.11,0.44,1.66c-1.17,2.24-2.55,4.4-4.06,6.42c-0.53,0.68-1.09,1.35-1.74,1.92c-0.48,0.33-0.98,0.62-1.51,0.87
		c-0.78,0.29-1.56,0.47-2.37,0.6c-0.42,0.04-0.86,0.06-1.28,0.04c-0.1,0-0.19,0-0.28,0v-0.01c-0.07,0-0.15,0-0.22,0l0,0
		c-0.3,0-0.65,0-0.8,0c-1.11-0.02-2.3,0.06-3.38,0.26c-2.46,0.47-4.64,1.65-6.47,3.2c-2.66,2.25-4.07,5.49-4.85,8.67
		c-0.64,2.57-0.88,5.25-1.14,7.88c-0.39,4.04-0.65,8.1-0.83,12.15c0,0.01,0,0.01,0,0.02c-0.07,1.7,1.31,3.13,3.13,3.14
		c1.9,0.01,3.36-1.4,3.44-3.1c0.17-3.89,0.42-7.78,0.78-11.66c0.15-1.57,0.31-3.14,0.54-4.7c0.27-1.64,0.64-3.25,1.3-4.79
		c0.38-0.77,0.83-1.5,1.35-2.19c0.44-0.48,0.92-0.93,1.44-1.34c0.51-0.33,1.06-0.62,1.63-0.87c0.61-0.2,1.23-0.4,1.87-0.5
		c0.28-0.05,0.85-0.09,1.46-0.09c0.31,0.11,0.65,0.18,0.99,0.2c1.07,0.05,2.19-0.13,3.24-0.31c1.95-0.35,3.94-1.14,5.51-2.36
		c0.58-0.45,1.14-0.89,1.64-1.43c0.44-0.47,0.83-0.99,1.22-1.49c0.88-1.14,1.72-2.31,2.51-3.52c0.94-1.46,1.82-2.96,2.61-4.51
		C206.8,120.11,207.22,119.18,206.93,118.08z"/>
</g>
  <g id="_x37_" class="st1_scg">
	<g>
		<path class="st2_scg" d="M98.33,134.28l0,0.2c0.59,0.37,0.98,0.96,1,1.63l0.77,0l0-1.83L98.33,134.28z"/>
    <path class="st2_scg" d="M116.41,134.31l0,0.2c-0.6,0.37-0.99,0.96-1,1.62l-0.77,0l0-1.83L116.41,134.31z"/>
	</g>

    <ellipse transform="matrix(0.9812 -0.1929 0.1929 0.9812 -20.9046 22.9491)" class="st2_scg" cx="107.37" cy="118.8" rx="7.83" ry="7.83"/>
    <g>
		<path class="st2_scg" d="M106.61,190.51c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C104.92,194.14,106.61,192.49,106.61,190.51L106.61,190.51z"/>
      <path class="st2_scg" d="M115.41,190.51c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C113.72,194.14,115.41,192.49,115.41,190.51L115.41,190.51z"/>
	</g>
    <path class="st2_scg" d="M114.64,132.04h-14.53c-0.43,0-0.78,0.42-0.78,0.93v27.25c0,0.52,0.35,0.93,0.78,0.93h6.5c0,0,0,0,0,0
		c0-0.42,0.34-0.76,0.76-0.76c0.42,0,0.76,0.34,0.76,0.76c0,0,0,0,0,0h6.5c0.43,0,0.78-0.42,0.78-0.93v-27.25
		C115.41,132.46,115.07,132.04,114.64,132.04z"/>
    <path class="st2_scg" d="M78.59,131.48c1.2,0.35,2.44,0.62,3.66,0.88c3.55,0.76,7.15,1.29,10.76,1.71c9.88,1.14,19.92,1.08,29.79-0.15
		c4.46-0.55,9-1.19,13.34-2.44c1.63-0.47,2.65-2.16,2.21-3.82c-0.43-1.62-2.19-2.68-3.82-2.21c-3.9,1.13-8,1.75-12.01,2.27
		c0.28-0.04,0.55-0.07,0.83-0.11c-9.86,1.27-19.88,1.36-29.76,0.27c-1.07-0.12-2.14-0.25-3.2-0.4c0.27,0.04,0.53,0.07,0.8,0.11
		c-3.66-0.51-7.38-1.1-10.93-2.14c-1.63-0.48-3.39,0.57-3.83,2.19C75.95,129.31,76.96,131,78.59,131.48L78.59,131.48z"/>
</g>
  <g id="_x36_" class="st1_scg">
	<g>

			<ellipse transform="matrix(0.5284 -0.849 0.849 0.5284 -89.2059 77.7415)" class="st2_scg" cx="25.37" cy="119.17" rx="7.83" ry="7.83"/>
    <g>
			<path class="st2_scg" d="M16.34,134.82v0.2c0.6,0.37,0.99,0.96,1,1.63h0.77v-1.83H16.34z"/>
      <path class="st2_scg" d="M34.41,134.82v0.2c-0.6,0.37-0.99,0.96-1,1.63h-0.77v-1.83H34.41z"/>
		</g>
    <g>
			<path class="st2_scg" d="M27.87,128.78c-4.08,0-8.17,0-12.25,0c-2.68,0-5.36,0-8.03,0c1.03,1.05,2.07,2.1,3.1,3.14
				c0-3.73,0-9.88,0-13.61c0-1.7-1.39-3.13-3.1-3.14c-1.69-0.01-3.14,1.4-3.14,3.1c0,3.73,0,9.88,0,13.61c0,1.69,1.39,3.14,3.1,3.14
				c4.08,0,8.17,0,12.25,0c2.68,0,5.36,0,8.03,0c1.7,0,3.13-1.39,3.14-3.1C30.98,130.24,29.57,128.78,27.87,128.78L27.87,128.78z"/>
      <path class="st2_scg" d="M22.88,128.78c4.08,0,8.17,0,12.25,0c2.68,0,5.36,0,8.03,0c-1.03,1.05-2.07,2.1-3.1,3.14
				c0-3.73,0-9.88,0-13.61c0-1.7,1.39-3.13,3.1-3.14c1.69-0.01,3.14,1.4,3.14,3.1c0,3.73,0,9.88,0,13.61c0,1.69-1.39,3.14-3.1,3.14
				c-4.08,0-8.17,0-12.25,0c-2.68,0-5.36,0-8.03,0c-1.7,0-3.13-1.39-3.14-3.1C19.77,130.24,21.18,128.78,22.88,128.78L22.88,128.78z
				"/>
		</g>
	</g>
    <g>
		<path class="st2_scg" d="M24.61,190.51c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C22.92,194.14,24.61,192.49,24.61,190.51L24.61,190.51z"/>
      <path class="st2_scg" d="M33.42,190.51c0-2.22,0-4.44,0-6.66c0-4.44,0-8.87,0-13.31c0-3.32,0-6.64,0-9.96c0-0.28,0-0.56,0-0.85
			c0-1.98-1.62-3.65-3.61-3.67c-1.97-0.01-3.67,1.63-3.67,3.61c0,2.22,0,4.44,0,6.66c0,4.44,0,8.87,0,13.31c0,3.32,0,6.64,0,9.96
			c0,0.28,0,0.56,0,0.85c0,1.98,1.62,3.65,3.61,3.67C31.72,194.14,33.42,192.49,33.42,190.51L33.42,190.51z"/>
	</g>
    <path class="st2_scg" d="M32.64,132.04H18.11c-0.43,0-0.78,0.42-0.78,0.93v27.25c0,0.52,0.35,0.93,0.78,0.93h6.5c0,0,0,0,0,0
		c0-0.42,0.34-0.76,0.76-0.76c0.42,0,0.76,0.34,0.76,0.76c0,0,0,0,0,0h6.5c0.43,0,0.78-0.42,0.78-0.93v-27.25
		C33.42,132.46,33.07,132.04,32.64,132.04z"/>
    <g>
		<path class="st2_scg" d="M16.34,131.91v0.24c0.6,0.43,0.99,1.13,1,1.92h0.77v-2.16H16.34z"/>
      <path class="st2_scg" d="M34.41,131.91v0.24c-0.6,0.43-0.99,1.13-1,1.92h-0.77v-2.16H34.41z"/>
	</g>
</g>
</svg>

<svg *ngIf="type == 'score_card_posture'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 377 250" style="enable-background:new 0 0 377 250;" xml:space="preserve">
<style type="text/css">
	.scp_st0{display:none;}
  .scp_st1{display:inline;opacity:0.18;}
  .scp_st2{fill:#FFFFFF;}
  .scp_st3{display:none;opacity:0.18;}
  .scp_st4{display:inline;fill:url(#SVGID_1_);}
  .scp_st5{display:inline;fill:url(#SVGID_2_);}
  .scp_st6{display:inline;fill:url(#SVGID_3_);}
  .scp_st7{display:inline;fill:url(#SVGID_4_);}
  .scp_st8{display:inline;fill:url(#SVGID_5_);}
  .scp_st9{display:inline;fill:url(#SVGID_6_);}
  .scp_st10{display:inline;fill:url(#SVGID_7_);}
  .scp_st11{display:inline;fill:url(#SVGID_8_);}
  .scp_st12{display:inline;fill:url(#SVGID_9_);}
  .scp_st13{display:inline;fill:url(#SVGID_10_);}
  .scp_st14{display:inline;fill:url(#SVGID_11_);}
  .scp_st15{display:inline;fill:url(#SVGID_12_);}
  .scp_st16{display:inline;fill:url(#SVGID_13_);}
  .scp_st17{display:inline;fill:url(#SVGID_14_);}
  .scp_st18{display:inline;fill:url(#SVGID_15_);}
  .scp_st19{display:inline;fill:url(#SVGID_16_);}
  .scp_st20{display:inline;fill:url(#SVGID_17_);}
  .scp_st21{display:inline;fill:url(#SVGID_18_);}
  .scp_st22{display:inline;fill:url(#SVGID_19_);}
  .scp_st23{display:inline;fill:url(#SVGID_20_);}
  .scp_st24{display:inline;fill:url(#SVGID_21_);}
  .scp_st25{display:inline;fill:url(#SVGID_22_);}
  .scp_st26{display:inline;fill:url(#SVGID_23_);}
  .scp_st27{display:inline;fill:url(#SVGID_24_);}
  .scp_st28{display:inline;fill:url(#SVGID_25_);}
  .scp_st29{display:inline;fill:url(#SVGID_26_);}
  .scp_st30{display:inline;fill:url(#SVGID_27_);}
  .scp_st31{display:inline;fill:url(#SVGID_28_);}
  .scp_st32{display:inline;fill:url(#SVGID_29_);}
  .scp_st33{display:inline;fill:url(#SVGID_30_);}
  .scp_st34{display:inline;fill:url(#SVGID_31_);}
  .scp_st35{display:inline;fill:url(#SVGID_32_);}
  .scp_st36{display:inline;fill:url(#SVGID_33_);}
  .scp_st37{display:inline;fill:url(#SVGID_34_);}
  .scp_st38{display:inline;fill:url(#SVGID_35_);}
  .scp_st39{display:inline;fill:url(#SVGID_36_);}
  .scp_st40{display:inline;fill:url(#SVGID_37_);}
  .scp_st41{display:inline;fill:url(#SVGID_38_);}
  .scp_st42{display:inline;fill:url(#SVGID_39_);}
  .scp_st43{display:inline;fill:url(#SVGID_40_);}
  .scp_st44{display:inline;fill:url(#SVGID_41_);}
  .scp_st45{display:inline;fill:url(#SVGID_42_);}
  .scp_st46{display:inline;fill:url(#SVGID_43_);}
  .scp_st47{display:inline;fill:url(#SVGID_44_);}
  .scp_st48{display:inline;fill:url(#SVGID_45_);}
  .scp_st49{display:inline;fill:url(#SVGID_46_);}
  .scp_st50{display:inline;fill:url(#SVGID_47_);}
  .scp_st51{display:inline;fill:url(#SVGID_48_);}
  .scp_st52{display:inline;fill:url(#SVGID_49_);}
  .scp_st53{display:inline;fill:url(#SVGID_50_);}
  .scp_st54{display:inline;fill:url(#SVGID_51_);}
  .scp_st55{display:inline;fill:url(#SVGID_52_);}
  .scp_st56{display:inline;fill:url(#SVGID_53_);}
  .scp_st57{display:inline;fill:url(#SVGID_54_);}
  .scp_st58{display:inline;fill:url(#SVGID_55_);}
  .scp_st59{display:inline;fill:url(#SVGID_56_);}
  .scp_st60{display:none;opacity:0.18;fill:url(#SVGID_57_);}
  .scp_st61{opacity:0.18;}
  .scp_st62{opacity:0.35;}
  .scp_st63{fill:#F1F2F2;}
</style>
  <g class="scp_st3">
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="31.3144" y1="147.4615" x2="9.1246" y2="147.4615">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st4" d="M21.81,137.65c0.9-2.07,2.56-5.94,5.95-5.94c1.1,0,2.08,0.28,3.09,0.89l0.46-0.77
		c-1.14-0.68-2.3-1.02-3.55-1.02c-3.98,0-5.8,4.21-6.78,6.48c-0.1,0.23-0.19,0.44-0.27,0.61L9.12,163.73l0.82,0.37l11.59-25.82
		C21.62,138.1,21.71,137.89,21.81,137.65z"/>
    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="43.4119" y1="158.6739" x2="35.708" y2="158.6739">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st5" d="M42.33,154.15c0.24,0,0.52,0.05,0.83,0.14l0.25-0.86c-0.4-0.11-0.75-0.17-1.08-0.17
		c-1.95,0-2.84,1.98-3.32,3.04c-0.06,0.14-0.12,0.27-0.18,0.4l-3.13,7.06l0.82,0.36l3.12-7.04c0.06-0.11,0.12-0.25,0.19-0.41
		C40.26,155.72,40.97,154.15,42.33,154.15z"/>
    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="45.8769" y1="160.926" x2="41.0109" y2="160.926">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st6" d="M42.55,160.33l-1.54,3.41l0.82,0.37l1.49-3.29l0.06-0.15c0.26-0.65,0.81-2.01,1.89-2.01
		c0.14,0,0.25,0.03,0.39,0.06l0.22-0.87c-0.16-0.04-0.36-0.09-0.61-0.09C43.58,157.75,42.86,159.56,42.55,160.33z"/>
    <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="41.0101" y1="156.4437" x2="30.369" y2="156.4437">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st7" d="M36.2,152.92c0.53-1.21,1.41-3.24,3.23-3.24c0.47,0,0.89,0.08,1.17,0.22l0.4-0.8
		c-0.52-0.26-1.14-0.31-1.57-0.31c-2.41,0-3.48,2.46-4.06,3.78c-0.06,0.14-0.12,0.27-0.17,0.38l-4.84,10.79l0.82,0.37l4.84-10.79
		C36.08,153.2,36.14,153.07,36.2,152.92z"/>
    <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="38.5691" y1="154.1932" x2="25.0672" y2="154.1932">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st8" d="M32.62,149.07c0.59-1.36,1.69-3.88,3.88-3.88c0.62,0,1.17,0.12,1.67,0.37l0.4-0.8
		c-0.63-0.31-1.31-0.47-2.07-0.47c-2.78,0-4.03,2.88-4.71,4.42c-0.07,0.17-0.14,0.32-0.2,0.46l-6.52,14.57l0.82,0.37l6.52-14.56
		C32.47,149.4,32.54,149.24,32.62,149.07z"/>
    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="33.6789" y1="149.6927" x2="14.4261" y2="149.6927">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st9" d="M25.37,141.52c0.79-1.87,2.25-5.34,5.33-5.34c0.9,0,1.74,0.24,2.52,0.7l0.46-0.77
		c-0.91-0.55-1.92-0.83-2.98-0.83c-3.67,0-5.35,3.98-6.15,5.89c-0.08,0.18-0.14,0.34-0.21,0.48l-9.92,22.08l0.82,0.37l9.92-22.09
		C25.23,141.87,25.3,141.7,25.37,141.52z"/>
    <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="36.1281" y1="151.9433" x2="19.7277" y2="151.9433">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st10" d="M28.99,145.32c0.69-1.62,1.97-4.64,4.61-4.64c0.72,0,1.42,0.17,2.13,0.52l0.4-0.8
		c-0.83-0.42-1.66-0.62-2.53-0.62c-3.24,0-4.72,3.51-5.44,5.19c-0.07,0.17-0.14,0.32-0.2,0.45l-8.24,18.31l0.82,0.37l8.24-18.31
		C28.85,145.66,28.91,145.5,28.99,145.32z"/>
</g>
  <g class="scp_st3">
	<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="58.9925" y1="173.2807" x2="72.0365" y2="173.2807">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st11" d="M65.59,178.46c0.06-0.13,0.11-0.25,0.16-0.36l6.29-14l-0.82-0.37l-6.29,14c-0.05,0.11-0.1,0.23-0.16,0.37
		c-0.57,1.34-1.64,3.82-3.78,3.82c-0.58,0-1.08-0.12-1.59-0.37l-0.4,0.8c0.63,0.31,1.28,0.47,1.99,0.47
		C63.71,182.83,64.98,179.88,65.59,178.46z"/>
    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="61.3479" y1="175.4355" x2="77.1473" y2="175.4355">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st12" d="M69.04,182.13c0.06-0.15,0.12-0.29,0.18-0.41l7.93-17.62l-0.82-0.37l-7.93,17.62
		c-0.05,0.12-0.12,0.27-0.18,0.43c-0.66,1.56-1.89,4.46-4.4,4.46c-0.76,0-1.43-0.16-2.04-0.48l-0.42,0.79
		c0.75,0.39,1.55,0.59,2.46,0.59C66.92,187.14,68.35,183.76,69.04,182.13z"/>
    <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="63.7065" y1="177.6095" x2="82.296" y2="177.6095">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st13" d="M72.54,185.78c0.07-0.16,0.13-0.31,0.18-0.43l9.57-21.24l-0.82-0.37l-9.58,21.25
		c-0.06,0.13-0.12,0.28-0.19,0.45c-0.76,1.8-2.17,5.15-5.12,5.15c-0.83,0-1.63-0.22-2.45-0.67l-0.43,0.79
		c0.95,0.53,1.9,0.78,2.89,0.78C70.14,191.49,71.76,187.63,72.54,185.78z"/>
    <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="66.004" y1="179.7646" x2="87.4071" y2="179.7646">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st14" d="M75.41,188.56c-0.07,0.17-0.16,0.36-0.25,0.57c-0.85,2.01-2.45,5.76-5.75,5.76c-1.1,0-2.05-0.28-2.92-0.84
		L66,194.81c1.02,0.66,2.14,0.99,3.41,0.99c3.9,0,5.71-4.26,6.58-6.31c0.09-0.21,0.17-0.39,0.24-0.55l11.18-24.83l-0.82-0.37
		L75.41,188.56z"/>
    <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="51.9552" y1="166.7965" x2="56.6654" y2="166.7965">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st15" d="M56.67,164.1l-0.82-0.37l-1.42,3.14L54.38,167c-0.26,0.64-0.79,1.97-1.82,1.97c-0.13,0-0.24-0.03-0.39-0.06
		l-0.21,0.87c0.17,0.04,0.36,0.09,0.6,0.09c1.63,0,2.35-1.77,2.66-2.53L56.67,164.1z"/>
    <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="54.287" y1="168.9523" x2="61.7771" y2="168.9523">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st16" d="M58.65,171.12c0.04-0.1,0.08-0.19,0.12-0.27l3.01-6.75l-0.82-0.37l-3.01,6.75c-0.04,0.08-0.08,0.18-0.12,0.28
		c-0.52,1.21-1.19,2.51-2.44,2.51c-0.32,0-0.55-0.04-0.74-0.12l-0.35,0.83c0.3,0.13,0.65,0.19,1.09,0.19
		C57.33,174.17,58.22,172.11,58.65,171.12z"/>
    <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="56.6429" y1="171.1062" x2="66.9252" y2="171.1062">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st17" d="M62.08,174.83c0.06-0.13,0.11-0.25,0.16-0.35l4.69-10.37l-0.82-0.37l-4.69,10.37
		c-0.05,0.11-0.1,0.23-0.16,0.37c-0.47,1.08-1.34,3.1-3.09,3.1c-0.45,0-0.8-0.07-1.15-0.23l-0.37,0.82
		c0.46,0.21,0.94,0.31,1.52,0.31C60.51,178.48,61.57,176.02,62.08,174.83z"/>
</g>
  <g class="scp_st3">
	<linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="117.8673" y1="162.0888" x2="114.8894" y2="162.0888">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st18" d="M117.55,160.07c-1.11,0-1.59,1.22-1.77,1.68l-0.89,1.98l0.82,0.37l0.88-1.96l0.02-0.06
		c0.13-0.33,0.44-1.11,0.93-1.11c0.04,0,0.07,0.01,0.1,0.01c0.08,0.01,0.15,0.02,0.2,0.02l0.01-0.9c-0.02,0-0.04-0.01-0.05-0.01
		C117.74,160.09,117.65,160.07,117.55,160.07z"/>
    <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="113.7064" y1="158.141" x2="105.5063" y2="158.141">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st19" d="M110.25,155.34c0.32-0.79,0.92-2.26,2.15-2.26c0.36,0,0.67,0.06,0.91,0.18l0.4-0.8
		c-0.36-0.18-0.81-0.28-1.31-0.28c-1.83,0-2.63,1.97-2.98,2.82l-3.92,8.74l0.82,0.37l3.86-8.59L110.25,155.34z"/>
    <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="116.6049" y1="160.7728" x2="111.7615" y2="160.7728">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st20" d="M115.83,158.34c0.16,0,0.28,0.02,0.37,0.07l0.4-0.8c-0.22-0.11-0.48-0.16-0.77-0.16
		c-1.28,0-1.85,1.32-2.12,1.95c-0.03,0.07-0.05,0.13-0.08,0.18l-1.87,4.16l0.82,0.37l1.87-4.15c0.03-0.06,0.05-0.12,0.09-0.2
		C114.83,159.07,115.19,158.34,115.83,158.34z"/>
    <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="115.0757" y1="159.4567" x2="108.6339" y2="159.4567">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st21" d="M112.39,157.54c0.27-0.64,0.77-1.83,1.72-1.83c0.24,0,0.46,0.06,0.72,0.14l0.24-0.86
		c-0.28-0.08-0.59-0.17-0.96-0.17c-1.55,0-2.23,1.61-2.55,2.38c-0.03,0.06-0.05,0.12-0.07,0.17l-2.86,6.37l0.82,0.37l2.86-6.37
		C112.34,157.67,112.37,157.61,112.39,157.54z"/>
    <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="110.8558" y1="155.4901" x2="99.2512" y2="155.4901">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st22" d="M106.03,150.82c0.45-1.06,1.28-3.04,2.94-3.04c0.55,0,1.01,0.13,1.39,0.38l0.5-0.75
		c-0.54-0.36-1.16-0.53-1.89-0.53c-2.26,0-3.28,2.42-3.77,3.59c-0.04,0.1-0.08,0.19-0.11,0.26l-5.84,13.01l0.82,0.37l5.84-13.01
		C105.94,151.01,105.98,150.92,106.03,150.82z"/>
    <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="112.257" y1="156.806" x2="102.3788" y2="156.806">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st23" d="M108.17,153.02c0.39-0.91,1.12-2.62,2.52-2.62c0.39,0,0.77,0.1,1.17,0.3l0.4-0.8
		c-0.53-0.26-1.04-0.39-1.57-0.39c-1.99,0-2.9,2.13-3.34,3.16c-0.04,0.1-0.08,0.19-0.12,0.27l-4.84,10.79l0.82,0.37l4.84-10.79
		C108.08,153.23,108.12,153.13,108.17,153.02z"/>
    <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="109.4385" y1="154.1743" x2="96.1236" y2="154.1743">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st24" d="M103.95,148.49c0.51-1.17,1.45-3.34,3.31-3.34c0.65,0,1.23,0.16,1.7,0.46l0.49-0.75
		c-0.62-0.4-1.36-0.61-2.19-0.61c-2.45,0-3.58,2.62-4.13,3.88c-0.06,0.14-0.12,0.27-0.17,0.39l-6.83,15.22l0.82,0.37l6.82-15.21
		C103.82,148.77,103.88,148.64,103.95,148.49z"/>
</g>
  <g class="scp_st3">
	<linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="130.8509" y1="174.3867" x2="145.1521" y2="174.3867">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st25" d="M136.97,180.07l-0.11,0.27c-0.5,1.24-1.55,3.81-3.67,3.81c-0.67,0-1.28-0.18-1.86-0.54l-0.48,0.76
		c0.73,0.46,1.49,0.68,2.34,0.68c2.72,0,3.92-2.95,4.5-4.37l0.1-0.25l7.36-16.32l-0.82-0.37L136.97,180.07z"/>
    <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="127.8227" y1="171.5451" x2="138.4392" y2="171.5451">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st26" d="M133.05,176.05c0.06-0.13,0.11-0.25,0.16-0.36l5.23-11.59l-0.82-0.37l-5.22,11.59
		c-0.05,0.1-0.11,0.23-0.17,0.37c-0.45,1.03-1.21,2.77-2.75,2.77c-0.42,0-0.84-0.11-1.23-0.33l-0.43,0.79
		c0.52,0.29,1.1,0.44,1.66,0.44C131.6,179.36,132.58,177.13,133.05,176.05z"/>
    <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="124.7334" y1="168.7033" x2="131.6879" y2="168.7033">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st27" d="M128.56,171.04l3.13-6.93l-0.82-0.37l-3.1,6.84l-0.05,0.13c-0.27,0.67-0.84,2.08-1.94,2.08
		c-0.22,0-0.46-0.05-0.7-0.15l-0.35,0.83c0.35,0.15,0.71,0.23,1.05,0.23C127.49,173.67,128.24,171.83,128.56,171.04z"/>
    <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="126.2912" y1="170.1153" x2="135.0454" y2="170.1153">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st28" d="M130.79,173.6c0.05-0.11,0.09-0.21,0.14-0.31l4.12-9.19l-0.82-0.37l-4.12,9.18c-0.04,0.09-0.09,0.2-0.15,0.32
		c-0.39,0.88-1.04,2.36-2.31,2.36c-0.34,0-0.64-0.07-1-0.23l-0.36,0.82c0.47,0.21,0.9,0.31,1.36,0.31
		C129.51,176.5,130.34,174.61,130.79,173.6z"/>
    <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="123.1794" y1="167.2723" x2="128.331" y2="167.2723">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st29" d="M126.25,168.67l2.08-4.56l-0.82-0.37l-2.03,4.43l-0.07,0.16c-0.23,0.56-0.65,1.59-1.47,1.59
		c-0.16,0-0.3-0.03-0.44-0.08l-0.33,0.83c0.25,0.1,0.5,0.15,0.78,0.15C125.38,170.81,125.99,169.31,126.25,168.67z"/>
    <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="121.6412" y1="165.8441" x2="124.9385" y2="165.8441">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st30" d="M123.97,166.29l0.97-2.19l-0.82-0.36l-0.91,2.06c-0.02,0.04-0.04,0.09-0.06,0.14
		c-0.23,0.54-0.52,1.12-1.02,1.12c-0.14,0-0.16,0-0.2-0.02l-0.28,0.85c0.18,0.06,0.32,0.06,0.48,0.06
		C123.25,167.95,123.73,166.83,123.97,166.29z"/>
    <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="129.3884" y1="172.9564" x2="141.7955" y2="172.9564">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st31" d="M135.37,178.38c0.05-0.12,0.09-0.23,0.13-0.32l6.29-13.96l-0.82-0.37l-6.29,13.96
		c-0.04,0.1-0.09,0.21-0.14,0.33c-0.48,1.14-1.37,3.25-3.19,3.25c-0.53,0-1.04-0.13-1.54-0.4l-0.43,0.79
		c0.64,0.34,1.28,0.51,1.97,0.51C133.77,182.18,134.85,179.61,135.37,178.38z"/>
</g>
  <g class="scp_st3">
	<linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="194.7547" y1="136.7439" x2="158.4077" y2="136.7439">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st32" d="M178.9,120.29c1.5-3.5,4.3-10,10.17-10c1.9,0,3.61,0.49,5.22,1.5l0.48-0.76c-1.73-1.08-3.65-1.63-5.69-1.63
		c-6.46,0-9.41,6.86-10.99,10.55c-0.16,0.38-0.31,0.71-0.44,1.01l-19.22,42.79l0.82,0.37l19.22-42.79
		C178.58,121.02,178.73,120.67,178.9,120.29z"/>
    <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="210.8708" y1="151.6187" x2="193.6495" y2="151.6187">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st33" d="M202.76,145.58c0.84-1.94,2.41-5.54,5.6-5.54c0.81,0,1.51,0.14,2.14,0.42l0.36-0.82
		c-0.75-0.33-1.57-0.5-2.51-0.5c-3.78,0-5.5,3.96-6.42,6.08c-0.1,0.23-0.19,0.44-0.28,0.63l-8.01,17.89l0.82,0.37l8.01-17.88
		C202.56,146.04,202.66,145.82,202.76,145.58z"/>
    <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="214.9421" y1="155.3374" x2="202.4601" y2="155.3374">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st34" d="M214.6,147.7l0.35-0.83c-0.49-0.2-1.05-0.3-1.74-0.3c-3.15,0-4.55,3.26-5.31,5.01
		c-0.08,0.18-0.15,0.34-0.21,0.48l-5.23,11.67l0.82,0.37l5.22-11.67c0.07-0.14,0.14-0.31,0.22-0.5c0.67-1.56,1.93-4.47,4.48-4.47
		C213.76,147.47,214.23,147.55,214.6,147.7z"/>
    <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="206.801" y1="147.9002" x2="184.8391" y2="147.9002">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st35" d="M196.75,139.38c1.01-2.37,2.88-6.79,6.8-6.79c1,0,1.97,0.22,2.86,0.64l0.39-0.81
		c-1.01-0.48-2.11-0.73-3.25-0.73c-4.51,0-6.62,4.95-7.63,7.33c-0.09,0.22-0.18,0.42-0.26,0.6l-10.83,24.1l0.82,0.37L196.49,140
		C196.57,139.82,196.66,139.61,196.75,139.38z"/>
    <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="218.9525" y1="159.056" x2="211.2705" y2="159.056">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st36" d="M213.82,158.03c-0.04,0.09-0.07,0.18-0.1,0.25l-2.44,5.45l0.82,0.37l2.44-5.46c0.03-0.08,0.07-0.17,0.11-0.27
		c0.47-1.13,1.44-3.47,3.37-3.47c0.28,0,0.55,0.03,0.81,0.07l0.13-0.89c-0.28-0.04-0.61-0.08-0.94-0.08
		C215.48,154.01,214.36,156.73,213.82,158.03z"/>
    <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="202.7432" y1="144.1814" x2="176.0287" y2="144.1814">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st37" d="M190.77,133.07c1.17-2.76,3.36-7.91,7.94-7.91c1.33,0,2.5,0.29,3.6,0.9l0.43-0.79
		c-1.21-0.67-2.57-1.01-4.03-1.01c-5.18,0-7.6,5.71-8.77,8.45c-0.11,0.26-0.21,0.49-0.3,0.7l-13.62,30.32l0.82,0.37l13.62-30.32
		C190.56,133.57,190.66,133.33,190.77,133.07z"/>
    <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="198.7112" y1="140.4628" x2="167.2181" y2="140.4628">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st38" d="M184.84,126.71c1.33-3.14,3.8-8.99,9.03-8.99c1.6,0,3.08,0.39,4.39,1.16l0.46-0.77
		c-1.45-0.85-3.08-1.28-4.84-1.28c-5.82,0-8.54,6.44-9.85,9.54c-0.13,0.31-0.25,0.59-0.36,0.83l-16.44,36.54l0.82,0.37l16.44-36.54
		C184.59,127.31,184.71,127.03,184.84,126.71z"/>
</g>
  <g class="scp_st3">
	<linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="241.7305" y1="180.165" x2="263.6936" y2="180.165">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st39" d="M252.09,189.86c0.09-0.2,0.16-0.39,0.23-0.55l11.37-25.21l-0.82-0.37l-11.37,25.21
		c-0.07,0.17-0.15,0.36-0.24,0.56c-0.91,2.16-2.6,6.19-6.14,6.19c-1.06,0-2.05-0.27-2.93-0.81l-0.47,0.77
		c1.01,0.62,2.19,0.94,3.4,0.94C249.26,196.6,251.18,192.05,252.09,189.86z"/>
    <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="244.4709" y1="182.7205" x2="269.7582" y2="182.7205">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st40" d="M255.67,193.25c-0.09,0.21-0.2,0.45-0.31,0.72c-1.02,2.39-2.93,6.84-6.87,6.84c-1.29,0-2.48-0.34-3.54-1
		l-0.48,0.76c1.2,0.75,2.55,1.14,4.02,1.14c4.53,0,6.59-4.8,7.69-7.38c0.11-0.26,0.21-0.5,0.31-0.7l13.27-29.52l-0.82-0.37
		L255.67,193.25z"/>
    <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="236.1564" y1="175.0158" x2="251.5266" y2="175.0158">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st41" d="M243.86,181.11c0.06-0.14,0.11-0.27,0.16-0.37l7.51-16.63l-0.82-0.37l-7.52,16.64
		c-0.05,0.12-0.1,0.25-0.16,0.39c-0.67,1.62-1.93,4.64-4.57,4.64c-0.67,0-1.29-0.15-1.9-0.45l-0.4,0.8c0.73,0.37,1.48,0.54,2.3,0.54
		C241.7,186.3,243.16,182.79,243.86,181.11z"/>
    <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="238.9252" y1="177.5906" x2="257.5915" y2="177.5906">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st42" d="M247.97,185.51c0.07-0.17,0.14-0.33,0.2-0.46l9.42-20.94l-0.82-0.37l-9.42,20.94
		c-0.06,0.14-0.13,0.3-0.21,0.48c-0.8,1.88-2.28,5.39-5.33,5.39c-0.88,0-1.68-0.21-2.45-0.63l-0.43,0.79
		c0.9,0.49,1.87,0.74,2.89,0.74C245.46,191.45,247.16,187.43,247.97,185.51z"/>
    <linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse" x1="227.7635" y1="167.3117" x2="233.2576" y2="167.3117">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st43" d="M231.58,167.84l1.68-3.74l-0.82-0.37l-1.67,3.73c-0.04,0.08-0.09,0.19-0.14,0.3
		c-0.47,1.08-1.06,2.23-2.17,2.23c-0.19,0-0.35-0.03-0.52-0.07l-0.18,0.88c0.2,0.04,0.42,0.09,0.7,0.09c1.78,0,2.6-1.87,2.99-2.76
		C231.5,168.02,231.54,167.93,231.58,167.84z"/>
    <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="233.3622" y1="172.4418" x2="245.4245" y2="172.4418">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st44" d="M239.7,176.87c0.07-0.16,0.14-0.31,0.2-0.44l5.53-12.32l-0.82-0.37l-5.53,12.31
		c-0.06,0.13-0.13,0.29-0.2,0.46c-0.61,1.4-1.64,3.75-3.74,3.75c-0.52,0-1-0.09-1.43-0.27l-0.35,0.83c0.54,0.23,1.14,0.34,1.77,0.34
		C237.82,181.15,239.04,178.36,239.7,176.87z"/>
    <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="230.5551" y1="169.8868" x2="239.3225" y2="169.8868">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st45" d="M235.57,172.52c0.06-0.14,0.12-0.26,0.17-0.37l3.59-8.05l-0.82-0.37l-3.58,8.04
		c-0.05,0.11-0.11,0.24-0.18,0.39c-0.5,1.12-1.33,2.98-2.97,2.98c-0.3,0-0.64-0.06-0.91-0.16l-0.31,0.84
		c0.38,0.14,0.81,0.22,1.23,0.22C234,176.04,235.06,173.66,235.57,172.52z"/>
</g>
  <g class="scp_st3">
	<linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="329.0541" y1="157.073" x2="319.0567" y2="157.073">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st46" d="M328.8,151.11l0.26-0.86c-0.47-0.14-0.92-0.21-1.39-0.21c-2.58,0-3.74,2.75-4.3,4.07
		c-0.06,0.13-0.11,0.25-0.15,0.35l-4.16,9.27l0.82,0.37l4.16-9.27c0.05-0.11,0.1-0.23,0.16-0.37c0.52-1.23,1.49-3.52,3.47-3.52
		C328.04,150.94,328.41,151,328.8,151.11z"/>
    <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="332.347" y1="160.0293" x2="326.0367" y2="160.0293">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st47" d="M328.08,159.2c-0.04,0.09-0.07,0.16-0.1,0.23l-1.95,4.31l0.82,0.37l1.95-4.32c0.03-0.07,0.07-0.16,0.1-0.25
		c0.39-0.94,1.12-2.69,2.61-2.69c0.21,0,0.39,0,0.54,0.05l0.28-0.85c-0.3-0.1-0.58-0.1-0.83-0.1
		C329.43,155.96,328.52,158.14,328.08,159.2z"/>
    <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="325.8836" y1="154.1174" x2="312.039" y2="154.1174">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st48" d="M319.44,149.46c0.66-1.55,1.88-4.43,4.41-4.43c0.65,0,1.18,0.11,1.63,0.33l0.4-0.8
		c-0.58-0.29-1.24-0.43-2.03-0.43c-3.12,0-4.55,3.36-5.24,4.98c-0.06,0.15-0.12,0.28-0.17,0.39l-6.41,14.23l0.82,0.37l6.41-14.23
		C319.32,149.75,319.38,149.62,319.44,149.46z"/>
    <linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse" x1="319.4064" y1="148.2054" x2="298.041" y2="148.2054">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st49" d="M309.98,139.33c0.93-2.14,2.65-6.13,6.2-6.13c0.97,0,1.89,0.23,2.81,0.71l0.41-0.8
		c-1.05-0.55-2.1-0.81-3.22-0.81c-4.14,0-6.02,4.34-7.03,6.67c-0.1,0.24-0.2,0.46-0.29,0.65l-10.83,24.1l0.82,0.37l10.83-24.1
		C309.78,139.81,309.88,139.58,309.98,139.33z"/>
    <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="313.0674" y1="142.2934" x2="284.0816" y2="142.2934">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st50" d="M300.47,129.4c1.19-2.8,3.4-8.02,8.01-8.02c1.48,0,2.83,0.39,4.11,1.19l0.48-0.76
		c-1.43-0.89-2.93-1.33-4.59-1.33c-5.21,0-7.66,5.78-8.84,8.56c-0.11,0.26-0.21,0.5-0.3,0.7l-15.26,33.98l0.82,0.37l15.26-33.98
		C300.25,129.91,300.35,129.66,300.47,129.4z"/>
    <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="316.2177" y1="145.2494" x2="291.0614" y2="145.2494">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st51" d="M305.26,134.32c1.05-2.46,3.01-7.03,7.08-7.03c1.22,0,2.34,0.31,3.44,0.93l0.45-0.78
		c-1.24-0.71-2.51-1.05-3.88-1.05c-4.66,0-6.77,4.92-7.9,7.57c-0.12,0.28-0.23,0.53-0.32,0.74l-13.04,29.03l0.82,0.37l13.04-29.02
		C305.02,134.86,305.13,134.6,305.26,134.32z"/>
    <linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="322.6499" y1="151.1614" x2="305.059" y2="151.1614">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st52" d="M314.71,144.45c0.78-1.86,2.25-5.33,5.29-5.33c0.84,0,1.57,0.17,2.23,0.52l0.42-0.79
		c-0.8-0.42-1.66-0.62-2.65-0.62c-3.64,0-5.31,3.97-6.11,5.88c-0.08,0.18-0.14,0.34-0.21,0.48l-8.62,19.15l0.82,0.37l8.62-19.15
		C314.56,144.81,314.63,144.64,314.71,144.45z"/>
</g>
  <g class="scp_st3">
	<linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse" x1="348.9882" y1="175.6643" x2="364.9956" y2="175.6643">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st53" d="M356.6,182.75c0.07-0.17,0.14-0.32,0.2-0.45l8.2-18.19l-0.82-0.37l-8.2,18.19c-0.06,0.13-0.13,0.29-0.21,0.47
		c-0.65,1.5-1.85,4.3-4.27,4.3c-0.71,0-1.4-0.2-2.06-0.59l-0.46,0.77c0.8,0.47,1.65,0.71,2.52,0.71
		C354.51,187.59,355.92,184.32,356.6,182.75z"/>
    <linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse" x1="346.9716" y1="173.7955" x2="360.6094" y2="173.7955">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st54" d="M353.68,179.5c0.05-0.12,0.1-0.23,0.14-0.33l6.79-15.07l-0.82-0.37L353,178.8c-0.04,0.1-0.09,0.22-0.15,0.34
		c-0.56,1.33-1.6,3.81-3.75,3.81c-0.65,0-1.22-0.13-1.68-0.4l-0.45,0.78c0.6,0.34,1.32,0.52,2.13,0.52
		C351.85,183.86,353.09,180.91,353.68,179.5z"/>
    <linearGradient id="SVGID_52_" gradientUnits="userSpaceOnUse" x1="350.9547" y1="177.5142" x2="369.382" y2="177.5142">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st55" d="M368.56,163.73l-9.57,21.28c-0.06,0.14-0.14,0.31-0.21,0.49c-0.73,1.71-2.09,4.89-4.87,4.89
		c-0.9,0-1.71-0.24-2.46-0.73l-0.49,0.75c0.9,0.59,1.87,0.87,2.95,0.87c3.38,0,4.94-3.67,5.69-5.43c0.08-0.18,0.14-0.34,0.21-0.48
		l9.57-21.28L368.56,163.73z"/>
    <linearGradient id="SVGID_53_" gradientUnits="userSpaceOnUse" x1="342.9311" y1="170.0957" x2="351.837" y2="170.0957">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st56" d="M347.75,173.18l4.09-9.08l-0.82-0.37l-4.01,8.89l-0.09,0.21c-0.4,0.95-1.15,2.72-2.63,2.72
		c-0.39,0-0.71-0.06-1-0.19l-0.36,0.82c0.41,0.18,0.85,0.27,1.36,0.27C346.37,176.46,347.3,174.25,347.75,173.18z"/>
    <linearGradient id="SVGID_54_" gradientUnits="userSpaceOnUse" x1="344.9581" y1="171.9454" x2="356.2232" y2="171.9454">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st57" d="M350.68,176.38c0.05-0.11,0.09-0.22,0.13-0.3l5.42-11.98l-0.82-0.37l-5.42,11.98
		c-0.04,0.09-0.09,0.2-0.14,0.32c-0.48,1.13-1.36,3.23-3.15,3.23c-0.51,0-0.96-0.1-1.31-0.29l-0.43,0.79
		c0.48,0.26,1.08,0.4,1.74,0.4C349.08,180.16,350.16,177.61,350.68,176.38z"/>
    <linearGradient id="SVGID_55_" gradientUnits="userSpaceOnUse" x1="338.9238" y1="166.3949" x2="343.0638" y2="166.3949">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st58" d="M341.84,166.77l1.22-2.67l-0.82-0.37l-1.22,2.67c-0.03,0.06-0.06,0.14-0.1,0.22
		c-0.33,0.75-0.74,1.55-1.44,1.55c-0.13,0-0.25,0-0.35-0.02l-0.22,0.87c0.21,0.05,0.4,0.05,0.57,0.05c1.35,0,1.97-1.41,2.26-2.08
		C341.79,166.9,341.82,166.84,341.84,166.77z"/>
    <linearGradient id="SVGID_56_" gradientUnits="userSpaceOnUse" x1="340.95" y1="168.2453" x2="347.4505" y2="168.2453">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scp_st59" d="M344.73,170.1c0.03-0.08,0.06-0.15,0.09-0.2l2.63-5.8l-0.82-0.37l-2.63,5.8c-0.03,0.06-0.06,0.14-0.09,0.22
		c-0.31,0.74-0.89,2.1-2.01,2.1c-0.26,0-0.48-0.03-0.66-0.09l-0.28,0.85c0.27,0.09,0.59,0.14,0.94,0.14
		C343.61,172.76,344.37,170.97,344.73,170.1z"/>
</g>
  <linearGradient id="SVGID_57_" gradientUnits="userSpaceOnUse" x1="2.4382" y1="154.5356" x2="375" y2="154.5356">
	<stop  offset="0" style="stop-color:#FFFFFF"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
</linearGradient>
  <path class="scp_st60" d="M373.09,163.49l-10.76,23.84c-0.07,0.16-0.15,0.35-0.24,0.56c-0.76,1.8-2.17,5.15-4.95,5.15
	c-3.24,0-4.92-3.17-5.93-5.06c-0.15-0.28-0.29-0.54-0.4-0.74l-13.5-23.84h0l-21.55-38.11c-0.17-0.29-0.35-0.64-0.56-1.03
	c-1.77-3.34-5.08-9.57-11.87-9.57c-6.27,0-9.1,6.73-10.45,9.97c-0.11,0.27-0.21,0.51-0.3,0.72l-17.12,38.1h0l-14.91,33.11
	c-0.11,0.23-0.22,0.5-0.35,0.8c-1.1,2.57-3.14,7.35-7.2,7.35c-4.8,0-7.31-4.79-8.65-7.37c-0.17-0.33-0.32-0.62-0.47-0.87
	L225.1,163.4l0,0l-27.15-47.94c-0.2-0.35-0.42-0.77-0.67-1.26c-2.19-4.18-6.27-11.97-14.73-11.97c-7.62,0-11.16,8.29-12.86,12.27
	c-0.17,0.39-0.32,0.74-0.45,1.05l-21.55,47.94h0l-8.24,18.31c-0.05,0.12-0.11,0.25-0.17,0.39c-0.56,1.34-1.61,3.83-3.6,3.83
	c-2.36,0-3.6-2.38-4.34-3.8c-0.1-0.19-0.19-0.37-0.28-0.52l-10.37-18.31h0l-9.65-17.05c-0.07-0.12-0.14-0.26-0.23-0.42
	c-0.79-1.5-2.44-4.62-5.84-4.62c-3.19,0-4.61,3.37-5.21,4.81c-0.05,0.12-0.09,0.22-0.13,0.31l-7.67,17.05l0,0L79.4,191.34
	c-0.08,0.18-0.16,0.38-0.26,0.6c-0.91,2.16-2.61,6.17-5.95,6.17c-3.93,0-5.92-3.82-7.11-6.1c-0.15-0.29-0.29-0.55-0.41-0.77
	L49.88,163.4h0l-16.37-28.95c-0.13-0.23-0.28-0.5-0.44-0.81c-1.37-2.58-3.92-7.39-9.24-7.39c-4.89,0-7.08,5.18-8.13,7.66
	c-0.1,0.23-0.19,0.44-0.27,0.62L2.44,163.49l1.91,0.86l13.01-28.95c0.09-0.19,0.18-0.41,0.28-0.66c0.94-2.23,2.7-6.39,6.2-6.39
	c4.06,0,6.15,3.93,7.39,6.27c0.17,0.33,0.33,0.62,0.47,0.86l16.36,28.95l0,0l15.79,27.84c0.11,0.2,0.24,0.45,0.38,0.71
	c1.22,2.34,3.76,7.22,8.96,7.22c4.74,0,6.86-5.03,7.88-7.45c0.09-0.21,0.17-0.4,0.24-0.56l12.55-27.84l0,0l7.67-17.06
	c0.04-0.1,0.09-0.22,0.15-0.34c0.52-1.23,1.48-3.53,3.28-3.53c1.97,0,3.09,1.81,3.98,3.5c0.09,0.17,0.18,0.33,0.25,0.47l9.65,17.05
	l0,0l10.37,18.3c0.07,0.13,0.15,0.29,0.24,0.46c0.83,1.6,2.57,4.92,6.2,4.92c3.38,0,4.83-3.45,5.53-5.11
	c0.06-0.13,0.11-0.26,0.15-0.35l8.24-18.31l0,0l21.55-47.94c0.14-0.32,0.3-0.68,0.47-1.09c1.64-3.84,4.69-11,10.93-11
	c7.19,0,10.89,7.06,12.88,10.85c0.26,0.5,0.49,0.93,0.7,1.32l27.16,47.94v0l18.76,33.1c0.13,0.23,0.27,0.51,0.44,0.82
	c1.44,2.76,4.44,8.49,10.51,8.49c5.45,0,7.93-5.83,9.13-8.62c0.12-0.28,0.23-0.54,0.33-0.76l14.91-33.11l0,0l17.13-38.11
	c0.1-0.22,0.2-0.48,0.32-0.75c1.27-3.04,3.64-8.69,8.52-8.69c5.54,0,8.34,5.29,10.02,8.46c0.22,0.41,0.41,0.78,0.59,1.08l21.55,38.1
	l0,0L349,188.28c0.11,0.19,0.23,0.43,0.37,0.68c1.15,2.16,3.28,6.17,7.78,6.17c4.16,0,5.99-4.35,6.88-6.43
	c0.08-0.19,0.16-0.37,0.22-0.51L375,164.35L373.09,163.49z"/>
  <g>
	<g class="scp_st61">
		<path class="scp_st2" d="M284.39,62.01l-0.14-0.01c-1.63-0.96-3.66-0.85-5.55-0.68c-2.32,0.22-4.78,0.57-6.53,2.11
			c-1.47,1.3-2.25,3.23-2.66,5.14c-0.05,0.25-0.17,2.68,0.03,2.96c-0.61,0.56-2.38,3.03-2.38,3.43c0,0.97,0.76,0.83,1.65,1.04
			c-0.16,0.46,0.04,1.56,0.04,1.56c0.47,0.04,1.04,0.62,0.08,0.84c-0.1,0.03,0.4,0.71,0.55,1.48c0.05,0.26-0.41,1.16,0,1.77
			c0.23,0.34,1,0.21,1.41,0.25c1.05,0.11,2.02,0,3.07,0.11c0.35,0.04,0.69,0.07,1.02,0.18c0.82,0.27,1.47,1.03,1.61,1.88
			c0.19,1.15-0.45,2.4,0.05,3.45l0.09,0.12c-1.24,2.19-2.85,4.57-4.43,6.69l0.01,0c0.96,0.13,2.4-1.3,3.59-2.91c0,0,0,0,0,0
			c0.33-0.75,0.83-1.43,1.49-1.92c0.58-0.43,1.28-0.66,1.97-0.88c2.08-0.67,4.19-1.7,6.28-2.37l0.15-0.03
			c0-0.01-0.01-0.01-0.01-0.02c-0.52-0.72-1.29-6.45-1-7.96c-0.06-1.07,2.31-2.41,2.6-3.5c0.28-1.08,0.93-2.36,1.13-3.71
			c0.06-0.39,0.09-0.79,0.06-1.18c-0.02-0.25-0.22-3.03-0.64-4.07c-0.29-0.7-0.94-1.65-1.6-2.02l0.02-0.05
			c-0.03-0.01-0.07-0.03-0.1-0.05c-0.54-0.28-1.01-0.69-1.35-1.18c-0.08-0.11-0.15-0.23-0.26-0.31c-0.07-0.05-0.16-0.11-0.14-0.2
			L284.39,62.01z"/>
    <path class="scp_st2" d="M280.14,119.8c0.35-0.6,0.23-1.34,0.13-2.03c-0.54-3.45-0.44-6.96-0.64-10.45c-0.06-1-0.14-2.01-0.27-3.02
			l-0.08,0.01c-1.22-1.49-3.3-2.35-4.54-3.48c-0.49-0.45-1.04-1.22-1.33-1.83c-0.36-0.76,0.22-2.2,0.5-2.99
			c0.41-1.15,1.25-2.51,1.79-4.06c0.06-0.18,0.13-0.35,0.21-0.51c-1.19,1.61-2.64,3.04-3.59,2.91l-0.01,0
			c-0.35,0.46-0.69,0.92-1.03,1.35c-1.23,1.58-2.54,3.1-3.64,4.77c-1.11,1.67-2,3.51-2.31,5.49c-0.04,0.27,0.33,1.48,0.43,1.83
			c0.63,2.14,1.08,3.24,1.57,4.93c0.95,3.26,2.51,6.98,3.42,14.19c0.56,4.46,0.51,8.57,0.64,13.42c1.68,0.57,3.49,0.74,5.24,0.49
			l0.63,0.03c0.53-2.16,1.09-4.88,1.04-5.41c-0.21-2.35,1.53-14.09,1.9-15.59L280.14,119.8z"/>
    <path class="scp_st2" d="M288,98.84l0.08,0.01c0.17-1.4,0.62-2.97,0.58-4.31c-0.05-1.34-0.3-2.67-0.59-3.98
			c-0.18-0.81-0.38-1.63-0.71-2.39c-0.34-0.76-0.83-1.47-1.52-1.94l-0.05,0.03c0,0.01,0.01,0.01,0.01,0.02l-0.15,0.03
			c-2.09,0.67-4.19,1.7-6.28,2.37c-0.69,0.22-1.39,0.45-1.97,0.88c-0.66,0.49-1.15,1.17-1.49,1.92c0,0,0,0,0,0
			c-0.08,0.17-0.15,0.34-0.21,0.51c-0.53,1.55-1.37,2.91-1.79,4.06c-0.28,0.79-0.86,2.23-0.5,2.99c0.29,0.6,0.84,1.37,1.33,1.83
			c1.23,1.14,3.32,1.99,4.54,3.48l0.08-0.01c0.13,1,0.22,2.01,0.27,3.02c0.2,3.48,0.1,7,0.64,10.45c0.11,0.68,0.23,1.43-0.13,2.03
			l0.05,0.06c-0.38,1.49-2.11,13.24-1.9,15.59c0.05,0.53-0.51,3.25-1.04,5.41c-0.33,1.33-0.64,2.46-0.79,2.73
			c-0.26,0.47-1.27,7.86-1.27,8.19c0,0.49,0.77-0.37,0.83-0.5c0.61-1.44,1.1-1.93,1.27-2.88l0.05,0c-0.09,1.6-0.4,2.21-0.38,3.65
			c0.02,1.21,0.16,3.61,0.44,3.54c1.03,0.24,1.27-3.37,1.33-4.1h0.08c-0.01,1.5-0.21,2.85-0.1,4.44c0.01,0.19,0.66,0.75,0.94,0.72
			c0.11-0.01,0.46-0.37,0.47-0.53c0.04-0.62,0.23-2.12,0.27-2.75l0.04,0c0.01,0.06,0.02,0.13,0.03,0.2c0.12,0.9,0.13,1.89,0.33,2.76
			c0.05,0.22,0.17,0.48,0.39,0.46c0.17-0.02,0.44-0.43,0.5-0.59c0.37-1.08,0.39-3.5,0.38-4.64l0.03,0c0.02,0.31,0.05,0.61,0.08,0.91
			c0.05,0.55,0.1,0.69,0.22,1.16l-0.01,0c0,0.73,1.12,1.1,1.37-0.29c0,0,0.09-3.87,0-5.06c-0.17-2.08-0.27-3.45-0.96-6.47
			l-0.01-0.35c0-0.2,0-0.4,0.01-0.62c0.23-4.18,3.12-12.44,3.75-18.25c0.08-0.75,0.13-1.46,0.12-2.12
			c-0.26-2.84-0.01-1.71,0.09-4.51c0.04-1.24,0.13-2.49,0.22-3.73c0.18-2.51,0.37-5.02,0.55-7.54
			C287.66,102.75,287.79,100.79,288,98.84z"/>
    <path class="scp_st2" d="M292.72,138.86c-3.22,1.18-6.04,2.09-9.47,2.01l-0.47-0.03c-0.01,0.22-0.02,0.43-0.01,0.62l0.01,0.35
			c0.69,3.02,0.79,4.39,0.96,6.47c0.1,1.19,0,5.06,0,5.06c-0.25,1.39-1.37,1.02-1.37,0.29l0.01,0c-0.12-0.47-0.17-0.61-0.22-1.16
			c-0.03-0.3-0.06-0.6-0.08-0.91l-0.03,0c0,1.14-0.01,3.56-0.38,4.64c-0.06,0.16-0.34,0.57-0.5,0.59c-0.22,0.02-0.34-0.24-0.39-0.46
			c-0.2-0.88-0.21-1.87-0.33-2.76c-0.01-0.07-0.02-0.14-0.03-0.2l-0.04,0c-0.04,0.62-0.23,2.12-0.27,2.75
			c-0.01,0.16-0.37,0.52-0.47,0.53c-0.28,0.03-0.93-0.53-0.94-0.72c-0.1-1.59,0.09-2.94,0.1-4.44h-0.08
			c-0.06,0.73-0.3,4.34-1.33,4.1c-0.28,0.07-0.42-2.33-0.44-3.54c-0.02-1.43,0.29-2.05,0.38-3.65l-0.05,0
			c-0.17,0.94-0.66,1.44-1.27,2.88c-0.06,0.13-0.83,0.99-0.83,0.5c0-0.33,1.01-7.71,1.27-8.19c0.15-0.28,0.47-1.4,0.79-2.73
			l-0.63-0.03c-1.75,0.25-3.56,0.08-5.24-0.49c0.13,4.54,0.41,9.75,1.51,16.53c0.16,1.87,0.35,3.92,0.59,6.07l0.38-0.04
			c5.02-0.71,9.34-0.58,14.38-0.02l0.06,0c-0.1-1.5-0.27-3.01-0.49-4.51c-0.06-4.36,1.2-8.73,3.59-12.38
			c0.91-1.4,1.04-3.97,1.32-5.63c0.03-0.53,0.04-1.03,0.03-1.49H292.72z"/>
    <path class="scp_st2" d="M286.24,61.71c-0.17-0.18-0.4-0.29-0.63-0.39c-0.06-0.03-0.12-0.06-0.18-0.05c-0.07,0.01-0.13,0.05-0.18,0.09
			c-0.25,0.18-0.5,0.37-0.7,0.6c-0.02,0.02-0.04,0.04-0.04,0.07c-0.02,0.08,0.07,0.15,0.14,0.2c0.11,0.08,0.18,0.2,0.26,0.31
			c0.35,0.5,0.81,0.91,1.35,1.18l0.15-0.31c-0.07-0.08-0.02-0.21,0.06-0.27c0.09-0.06,0.19-0.07,0.29-0.1
			c0.04-0.01,0.08-0.02,0.11-0.06c0.01-0.02,0.01-0.03,0.01-0.05c0-0.04-0.02-0.08-0.04-0.11c-0.11-0.22-0.22-0.44-0.33-0.66
			C286.44,62,286.36,61.84,286.24,61.71z"/>
    <path class="scp_st2" d="M284.15,187.86c1.04-4.06,2.08-8.12,3.13-12.19c1.07-4.16,1.32-8.46,1.03-12.8l-0.06,0
			c-5.04-0.56-9.36-0.68-14.38,0.02l-0.38,0.04c0.76,6.81,1.98,14.56,3.98,20.15l0.05-0.01c0.2,1.75,0.09,3.53,0.06,5.3
			c-0.19,8.73,1.29,17.48,4.32,25.67l-0.34,0.32c0.39,2.35-1.92,4.59-4.25,5.56c-4.81,1.99-4.36,3.15-4.47,3.76
			c-0.08,0.43,7.73,0.54,11.21-2.88c0.4-0.4,3.94-1.98,4.13-2.51c0.43-1.26-0.33-2.58-0.87-3.8c-1.78-4.03-1.15-8.65-0.66-13.03
			C287.13,197.1,286.5,191.59,284.15,187.86z"/>
    <path class="scp_st2" d="M287.93,65.82c0.42,1.04,0.62,3.82,0.64,4.07c0.03,0.4,0.01,0.79-0.06,1.18l0.44,0.07
			c0.07-0.41,0.16-0.82,0.27-1.22c0.16-0.58,0.38-1.15,0.49-1.74c0.27-1.42-0.07-2.89-0.63-4.22c-0.18-0.41-0.39-0.83-0.75-1.09
			c-0.37-0.26-0.92-0.3-1.23,0.02l-0.21,0.03c0,0.02,0,0.03-0.01,0.05c-0.02,0.04-0.07,0.05-0.11,0.06c-0.1,0.03-0.21,0.04-0.29,0.1
			c-0.08,0.06-0.13,0.19-0.06,0.27l-0.15,0.31c0.03,0.02,0.07,0.04,0.1,0.05l-0.02,0.05C287,64.17,287.65,65.12,287.93,65.82z"/>
    <path class="scp_st2" d="M283.25,140.88c3.43,0.07,6.26-0.83,9.47-2.01h0.03c-0.05-2.31-0.65-3.94-2-6.21
			c-0.44-0.73-0.9-1.46-1.31-2.2c-1.35-2.42-2.26-5.08-2.67-7.83l-0.24-0.02c-0.64,5.8-3.52,14.07-3.75,18.25L283.25,140.88z"/>
    <path class="scp_st2" d="M288.65,78.72c0.34,1.43,4.17,4.58,4.62,5.14l0.08-0.1c-0.41-3.58-0.39-7.21,0.07-10.78
			c0.15-1.17,0.34-2.34,0.26-3.52c-0.08-1.26-0.47-2.49-0.62-3.75c-0.1-0.88-0.09-1.8-0.4-2.63c-0.24-0.64-0.67-1.19-1.13-1.7
			c-0.4-0.44-0.84-0.86-1.36-1.14c-0.96-0.51-2.15-0.48-3.17-0.1c-1.02,0.38-1.88,1.08-2.62,1.87l0.13,0.01
			c0.01-0.03,0.02-0.05,0.04-0.07c0.2-0.23,0.45-0.41,0.7-0.6c0.05-0.04,0.11-0.08,0.18-0.09c0.06-0.01,0.12,0.02,0.18,0.05
			c0.23,0.1,0.46,0.21,0.63,0.39c0.12,0.13,0.2,0.29,0.28,0.45c0.11,0.22,0.22,0.44,0.33,0.66c0.02,0.03,0.04,0.07,0.04,0.11
			l0.21-0.03c0.31-0.33,0.87-0.28,1.23-0.02c0.36,0.26,0.57,0.68,0.75,1.09c0.56,1.33,0.9,2.8,0.63,4.22
			c-0.11,0.59-0.33,1.16-0.49,1.74c-0.11,0.4-0.2,0.81-0.27,1.22c-0.07,0.41-0.13,0.83-0.19,1.24
			C288.46,74.48,288.16,76.65,288.65,78.72z"/>
	</g>
    <g class="scp_st62">
		<g>
			<g>
				<path class="scp_st63" d="M280.91,71.3c0.11,4.83,0.22,9.67,0.33,14.5c0.27,11.67,0.53,23.33,0.8,35
					c0.32,14.07,0.64,28.13,0.97,42.2c0.28,12.21,0.56,24.42,0.84,36.62c0.09,4.01,0.18,8.01,0.27,12.02
					c0.03,1.26,0.06,2.51,0.09,3.77c0.01,0.57,0.19,1.39,0.04,1.94c-0.02,0.07,0,0.18,0.01,0.25c0.01,0.27-0.41,0.27-0.41,0
					c-0.11-4.83-0.22-9.67-0.33-14.5c-0.27-11.67-0.53-23.33-0.8-35c-0.32-14.07-0.64-28.13-0.97-42.2
					c-0.28-12.21-0.56-24.42-0.84-36.62c-0.09-4.01-0.18-8.01-0.27-12.02c-0.03-1.26-0.06-2.51-0.09-3.77
					c-0.01-0.57-0.19-1.39-0.04-1.94c0.02-0.07,0-0.18-0.01-0.25C280.49,71.03,280.91,71.03,280.91,71.3L280.91,71.3z"/>
			</g>
		</g>
      <g>
			<g>
				<path class="scp_st63" d="M280.93,73.45c-3.2,0-3.21-4.98,0-4.98C284.13,68.48,284.14,73.45,280.93,73.45L280.93,73.45z"/>
			</g>
		</g>
      <g>
			<g>
				<path class="scp_st63" d="M281.31,94.17c-3.2,0-3.21-4.98,0-4.98C284.52,89.19,284.52,94.17,281.31,94.17L281.31,94.17z"/>
			</g>
		</g>
      <g>
			<g>
				<path class="scp_st63" d="M281.98,123.68c-3.2,0-3.21-4.98,0-4.98C285.18,118.7,285.19,123.68,281.98,123.68L281.98,123.68z"/>
			</g>
		</g>
      <g>
			<g>
				<path class="scp_st63" d="M282.84,156.95c-3.2,0-3.21-4.98,0-4.98C286.04,151.97,286.04,156.95,282.84,156.95L282.84,156.95z"/>
			</g>
		</g>
      <g>
			<g>
				<path class="scp_st63" d="M283.55,189.39c-3.2,0-3.21-4.98,0-4.98C286.76,184.42,286.76,189.39,283.55,189.39L283.55,189.39z"/>
			</g>
		</g>
      <g>
			<g>
				<path class="scp_st63" d="M284.63,218.68c-3.2,0-3.21-4.98,0-4.98C287.84,213.71,287.84,218.68,284.63,218.68L284.63,218.68z"/>
			</g>
		</g>
	</g>
</g>
  <g class="scp_st61">
	<path class="scp_st2" d="M82.02,137.79c-0.38-3.64-1.45-13.58-2.36-16.49l0.21-0.33c-0.35-0.6-0.23-1.34-0.13-2.03
		c0.54-3.45,1.95-5.99,2.15-9.47c0.17-3.04,0.68-5.37,1.9-7.95l0.21,0.09c0.33,0.05,0.66-0.11,0.89-0.35
		c0.23-0.24,0.38-0.54,0.52-0.84c0.53-1.13,0.69-1.4,0.97-2.62l0.1-0.06c0.12,0.22,0.25,0.42,0.4,0.61c1.23,1.58,2.05,4.3,3.15,5.97
		c1.1,1.67,1.93,4.79,1.83,5.14c-0.63,2.14-2.83,2.13-3.32,3.82c-0.95,3.26,3.7,10.29,3.98,13.99c0.28,3.78,0.76,7.24,1.03,11.05
		l-0.26,0.02c-3.76,2.21-6.37,2.77-10.19,2.52l-0.08,0.03C82.61,139.74,82.08,138.34,82.02,137.79z"/>
    <path class="scp_st2" d="M83.01,61.65c0.21-0.14,0.46-0.19,0.7-0.23c0.06-0.01,0.13-0.02,0.19,0c0.06,0.02,0.11,0.08,0.15,0.13
		c0.15,0.18,0.29,0.36,0.42,0.55c0.04,0.07,0.08,0.13,0.11,0.2c0.01,0.03,0.02,0.05,0.02,0.08c0,0.09-0.11,0.12-0.18,0.16
		c-0.12,0.05-0.22,0.15-0.32,0.23c-0.45,0.4-1.01,0.68-1.6,0.82l-0.07-0.34c0.08-0.06,0.07-0.2,0-0.28
		c-0.07-0.08-0.17-0.11-0.26-0.16c-0.04-0.02-0.08-0.04-0.09-0.08c0-0.02,0-0.04,0-0.06c0.01-0.03,0.03-0.07,0.06-0.09
		c0.16-0.19,0.32-0.37,0.48-0.55C82.74,61.89,82.86,61.75,83.01,61.65z"/>
    <path class="scp_st2" d="M72.14,139.22c1.92,1.17,3.26,1.88,5.31,1.8l0.25-0.06c0.26,1.08,0.48,2,0.65,2.71
		c0.25,3.67-0.54,4.97-0.33,7.28c0.18,1.9,0.59,4.8,0.59,4.8c0.15,0.7,0.35,1.32,0.69,1.21l0.42-0.04c0.04,0.43,0.11,0.73,0.2,0.81
		c0.14,0.13,0.27,0.41,0.44,0.43c0.22,0.02,0.37-0.04,0.45-0.28c0.07-0.21,0.17-1.53,0.26-2.57l0.21,0.02
		c0.04,0.72,0.2,2.74,0.27,3.17c0.03,0.19,0.37,0.6,0.47,0.62c0.28,0.03,0.94-0.61,0.94-0.83c0-0.34,0.03-2,0.08-3.78
		c0.14,1.41,0.52,3.9,1.34,3.67c0.29,0.08,0.48-2.71,0.5-4.11c0.02-1.71-0.22-1.02-0.3-3.02l0.13-0.02
		c0.22,1.01,0.87,2.89,1.33,2.89c0.31,0,0.11-3.43-0.32-5.42c-0.11-0.53-0.45-1.24-0.72-1.71c-0.4-0.73-0.6-0.49-0.89-1.27
		c-0.25-0.68-0.45-2.49-0.62-3.2c-0.07-0.3-0.26-0.84-0.48-1.45l0.08-0.03c3.83,0.26,6.43-0.31,10.19-2.52l0.26-0.02
		c0.36,5.11,0.36,10.85-0.95,18.88c-0.65,7.78-1.94,18.86-4.56,26.22l-0.05-0.01c-0.2,1.75-0.09,3.53-0.06,5.3
		c0.19,8.73-1.29,17.48-4.32,25.67l0.05,0.4c-0.39,2.35,2.21,4.51,4.54,5.48c4.81,1.99,4.36,3.15,4.47,3.76
		c0.08,0.43-7.73,0.54-11.21-2.88c-0.4-0.4-3.94-1.98-4.13-2.51c-0.43-1.26,0.33-2.58,0.87-3.8c1.78-4.03,1.15-8.65,0.66-13.03
		c-0.49-4.37,0.13-9.89,2.49-13.61c-1.04-4.06-2.08-8.12-3.13-12.19c-1.43-5.59-1.4-11.45-0.54-17.32c0.06-4.36-1.2-8.73-3.59-12.38
		c-0.91-1.4-1.54-4-1.82-5.65C72.13,140.39,72.09,139.88,72.14,139.22z"/>
    <path class="scp_st2" d="M78.75,69.02c-0.12,0.37-0.2,0.75-0.24,1.14l-0.44-0.03c0.03-0.41,0.04-0.83,0.03-1.24
		c-0.01-0.6-0.08-1.2-0.05-1.8c0.09-1.44,0.77-2.79,1.64-3.94c0.27-0.36,0.58-0.71,1-0.88c0.41-0.16,0.97-0.07,1.18,0.32l0.2,0.08
		c-0.01,0.02-0.01,0.04,0,0.06c0.01,0.04,0.05,0.06,0.09,0.08c0.09,0.05,0.19,0.09,0.26,0.16c0.07,0.08,0.08,0.22,0,0.28l0.07,0.34
		c-0.04,0.01-0.07,0.02-0.11,0.02l0.01,0.05c-0.73,0.19-1.59,0.96-2.04,1.57C79.7,66.13,78.83,68.78,78.75,69.02z"/>
    <path class="scp_st2" d="M73.99,87.61c0.66-0.5,6.1-7.8,6.39-9.49c0.37-1.02-1.65-2.92-1.66-4.07c-0.01-1.12-0.33-2.53-0.2-3.9
		c0.04-0.39,0.11-0.77,0.24-1.14c0.08-0.24,0.95-2.89,1.61-3.79c0.45-0.61,1.31-1.38,2.04-1.57l-0.01-0.05
		c0.04-0.01,0.07-0.01,0.11-0.02c0.59-0.14,1.14-0.42,1.6-0.82c0.1-0.09,0.2-0.18,0.32-0.23c0.08-0.03,0.18-0.07,0.18-0.16
		c0-0.03-0.01-0.05-0.02-0.08c-0.03-0.07-0.07-0.13-0.11-0.2l0.06-0.04c0.07,0.12,0.14,0.24,0.21,0.36l0.12,0.01
		c1.82-0.53,3.76,0.06,5.55,0.69c2.19,0.78,4.5,1.72,5.82,3.63c1.11,1.62,1.4,3.68,1.33,5.64c-0.01,0.26-0.49,2.64-0.75,2.86
		c0.46,0.69,1.57,3.52,1.47,3.91c-0.24,0.94-0.94,0.62-1.85,0.6v0.12c0.04,0.48-0.42,1.51-0.42,1.51c-0.84-0.18-1.38,0.56-0.29,0.8
		c0.11,0.02-0.56,0.59-0.9,1.3c-0.11,0.24,0.12,1.23-0.43,1.72c-0.31,0.27-1.03-0.04-1.43-0.1c-1.04-0.15-1.96-0.49-3.01-0.63
		c-0.34-0.05-0.69-0.1-1.03-0.07c-0.87,0.07-1.67,0.64-2.02,1.44c-0.45,1.05-1.85,2.18-2.6,3.07c-0.01,0.02-0.03,0.03-0.04,0.05
		l-0.04,0.02c-1.1-1.66-3.45-1.64-4.88-2.08c-1.15-0.36-2.43-0.43-3.58-0.1c-0.37,0.1-0.72,0.25-1.06,0.44
		c-0.24,0.13-0.46,0.29-0.66,0.45L73.99,87.61z"/>
    <path class="scp_st2" d="M74.1,82.25c1.26-3.38-1.23-7.84-0.8-11.41c0.14-1.17,0.24-2.35,0.6-3.47c0.38-1.21,1.06-2.3,1.51-3.48
		c0.31-0.83,0.52-1.72,1.03-2.45c0.39-0.56,0.94-1,1.51-1.38c0.49-0.33,1.02-0.63,1.6-0.77c1.06-0.26,2.2,0.05,3.1,0.67
		c0.78,0.54,1.4,1.29,1.89,2.1l-0.06,0.04c-0.13-0.19-0.27-0.37-0.42-0.55c-0.04-0.05-0.09-0.11-0.15-0.13
		c-0.06-0.02-0.13-0.01-0.19,0c-0.25,0.05-0.5,0.1-0.7,0.23c-0.15,0.1-0.27,0.23-0.38,0.37c-0.16,0.18-0.32,0.37-0.48,0.55
		c-0.03,0.03-0.05,0.06-0.06,0.09l-0.2-0.08c-0.22-0.39-0.77-0.48-1.18-0.32c-0.42,0.16-0.72,0.52-1,0.88
		c-0.87,1.15-1.55,2.5-1.64,3.94c-0.04,0.6,0.03,1.2,0.05,1.8c0.01,0.41-0.01,0.83-0.03,1.24c-0.03,0.42-0.07,0.84-0.12,1.26
		c-0.22,2.11-0.45,4.29-1.43,6.18C75.85,78.87,74.67,81.82,74.1,82.25z"/>
    <path class="scp_st2" d="M73.35,117.95c0.29,1.78,1.24,1.17,1.01,3.7c-0.02,4.98,2.07,13.99,3.35,19.31l-0.25,0.06
		c-2.05,0.07-3.39-0.63-5.31-1.8c0.14-2.01,1.06-5.38,2.34-7.41c0.46-0.72-0.01-2.25-0.11-3.1c-0.44-3.54-1.11-5.75-1.44-9.41h0.01
		c-0.06-0.4-0.13-0.82-0.21-1.24L73.35,117.95z"/>
    <path class="scp_st2" d="M72.73,118.07c-0.69-3.79-2-8.05-3.12-11.26c-0.58-1.67-0.2-4.79-0.15-6.56c0.02-0.8,0.19-1.58,0.39-2.36
		l0.03,0.01c0.27,1.88,1.51,3.64,3.27,4.37l0.07-0.01c0.1,1.2,0.19,2.4,0.28,3.6c0.18,2.51-0.63,5.02-0.45,7.54
		c0.09,1.24,0.18,2.49,0.22,3.73c0.01,0.32,0.04,0.58,0.07,0.81L72.73,118.07z"/>
    <path class="scp_st2" d="M75.8,86.9c-0.69,0.47-1.8,1.66-2.14,2.42c-0.34,0.76-0.53,1.58-0.71,2.39c-0.29,1.31-0.54,2.64-0.59,3.98
		c-0.04,1.34,0.41,2.91,0.58,4.31l0.08-0.01c0.08,0.75,0.15,1.51,0.21,2.27l-0.07,0.01c-1.75-0.73-3-2.49-3.27-4.37l-0.03-0.01
		c0.06-0.22,0.12-0.43,0.18-0.65c0.35-1.26,0.69-2.52,1.04-3.78c0.58-2.12,1.3-4.43,2.97-5.77c0.21-0.17,0.43-0.32,0.66-0.45
		c0.34-0.19,0.69-0.34,1.06-0.44L75.8,86.9z"/>
    <path class="scp_st2" d="M73.5,105.87c-0.09-1.2-0.17-2.4-0.28-3.6c-0.06-0.76-0.13-1.51-0.21-2.27l-0.08,0.01
		c-0.17-1.4-0.62-2.97-0.58-4.31c0.05-1.34,0.3-2.67,0.59-3.98c0.18-0.81,0.38-1.63,0.71-2.39c0.34-0.76,1.45-1.94,2.14-2.42
		l-0.03-0.1c1.15-0.33,2.43-0.26,3.58,0.1c1.44,0.44,3.79,0.42,4.88,2.08l0.04-0.02c0.01-0.02,0.03-0.03,0.04-0.05
		c1.34,2.47,0.8,6.36,2.16,8.83l-0.1,0.06c-0.28,1.22-0.44,1.49-0.97,2.62c-0.14,0.3-0.29,0.6-0.52,0.84
		c-0.23,0.24-0.56,0.4-0.89,0.35l-0.21-0.09c-1.21,2.58-1.73,4.92-1.9,7.95c-0.2,3.48-1.61,6.02-2.15,9.47
		c-0.11,0.68-0.23,1.43,0.13,2.03l-0.21,0.33c0.91,2.9,1.97,12.85,2.36,16.49c0.06,0.56,0.58,1.95,1,3.1
		c0.23,0.61,0.42,1.15,0.48,1.45c0.16,0.71,0.37,2.51,0.62,3.2c0.28,0.78,0.48,0.55,0.89,1.27c0.26,0.47,0.6,1.18,0.72,1.71
		c0.43,1.99,0.62,5.42,0.32,5.42c-0.46,0-1.11-1.88-1.33-2.89l-0.13,0.02c0.09,1.99,0.32,1.31,0.3,3.02
		c-0.02,1.4-0.22,4.19-0.5,4.11c-0.82,0.23-1.2-2.26-1.34-3.67c-0.05,1.78-0.08,3.44-0.08,3.78c0,0.22-0.66,0.86-0.94,0.83
		c-0.11-0.01-0.44-0.43-0.47-0.62c-0.07-0.43-0.23-2.45-0.27-3.17l-0.21-0.02c-0.1,1.04-0.19,2.35-0.26,2.57
		c-0.08,0.24-0.24,0.31-0.45,0.28c-0.17-0.02-0.3-0.3-0.44-0.43c-0.09-0.08-0.15-0.38-0.2-0.81l-0.42,0.04
		c-0.35,0.11-0.54-0.51-0.69-1.21c0,0-0.41-2.9-0.59-4.8c-0.21-2.31,0.58-3.61,0.33-7.28c-0.17-0.71-0.39-1.64-0.65-2.71
		c-1.28-5.32-3.37-14.33-3.35-19.31c0.23-2.52-0.72-1.92-1.01-3.7c-0.04-0.23-0.06-0.49-0.07-0.81c-0.04-1.24-0.13-2.49-0.22-3.73
		C72.87,110.89,73.68,108.38,73.5,105.87z"/>
</g>
  <g class="scp_st62">
	<g>
		<g>
			<path class="scp_st2" d="M86.69,72.52c-2.31,4.77-4.61,9.55-6.92,14.32c-0.81,1.69-2.09,3.54-2.59,5.36
				c-0.32,1.16-0.12,2.67-0.16,3.86c-0.21,6.49-0.42,12.98-0.63,19.47c-0.05,1.4-0.23,2.88-0.13,4.28c0.09,1.18,0.39,2.39,0.58,3.55
				c1.03,6.34,2.06,12.68,3.09,19.02c0.75,4.64,1.8,9.32,2.28,14c0.37,3.62,0.02,7.97,0.02,11.77c0,6.18,0,12.35,0,18.53
				c0,9.36-0.82,18.76-1.27,28.11c-0.02,0.41-0.04,0.82-0.06,1.22c-0.01,0.27,0.4,0.27,0.41,0c0.44-9.22,1.12-18.47,1.33-27.7
				c0.23-10.4,0.11-20.84,0-31.24c-0.03-2.95-0.88-6.02-1.36-8.93c-1.08-6.67-2.17-13.34-3.25-20.01c-0.48-2.95-1.43-6.35-1.34-9.21
				c0.19-5.96,0.38-11.93,0.58-17.89c0.08-2.54,0.16-5.09,0.25-7.63c0.04-1.22,0.27-1.53,0.88-2.79c2.66-5.5,5.32-11.01,7.98-16.51
				c0.22-0.46,0.44-0.92,0.67-1.38C87.17,72.49,86.81,72.28,86.69,72.52L86.69,72.52z"/>
		</g>
	</g>
    <g>
		<g>
			<path class="scp_st2" d="M86.65,75.34c3.2,0,3.21-4.98,0-4.98C83.45,70.36,83.44,75.34,86.65,75.34L86.65,75.34z"/>
		</g>
	</g>
    <g>
		<g>
			<path class="scp_st2" d="M78.02,94.81c3.2,0,3.21-4.98,0-4.98C74.82,89.83,74.81,94.81,78.02,94.81L78.02,94.81z"/>
		</g>
	</g>
    <g>
		<g>
			<path class="scp_st2" d="M76.25,121.58c3.2,0,3.21-4.98,0-4.98C73.05,116.6,73.04,121.58,76.25,121.58L76.25,121.58z"/>
		</g>
	</g>
    <g>
		<g>
			<path class="scp_st2" d="M82.45,158.09c3.2,0,3.21-4.98,0-4.98C79.24,153.11,79.24,158.09,82.45,158.09L82.45,158.09z"/>
		</g>
	</g>
    <g>
		<g>
			<path class="scp_st2" d="M82.89,189.73c3.2,0,3.21-4.98,0-4.98C79.69,184.75,79.68,189.73,82.89,189.73L82.89,189.73z"/>
		</g>
	</g>
    <g>
		<g>
			<path class="scp_st2" d="M81.12,218.49c3.2,0,3.21-4.98,0-4.98C77.92,213.51,77.91,218.49,81.12,218.49L81.12,218.49z"/>
		</g>
	</g>
</g>
</svg>

<svg *ngIf="type == 'score_card_voice'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 377 250" style="enable-background:new 0 0 377 250;" xml:space="preserve">
<style type="text/css">
	.scv_st0{display:none;}
  .scv_st1{display:inline;opacity:0.18;}
  .scv_st2{fill:#FFFFFF;}
  .scv_st3{opacity:0.18;}
  .scv_st4{fill:url(#SVGID_1_);}
  .scv_st5{fill:url(#SVGID_2_);}
  .scv_st6{fill:url(#SVGID_3_);}
  .scv_st7{fill:url(#SVGID_4_);}
  .scv_st8{fill:url(#SVGID_5_);}
  .scv_st9{fill:url(#SVGID_6_);}
  .scv_st10{fill:url(#SVGID_7_);}
  .scv_st11{fill:url(#SVGID_8_);}
  .scv_st12{fill:url(#SVGID_9_);}
  .scv_st13{fill:url(#SVGID_10_);}
  .scv_st14{fill:url(#SVGID_11_);}
  .scv_st15{fill:url(#SVGID_12_);}
  .scv_st16{fill:url(#SVGID_13_);}
  .scv_st17{fill:url(#SVGID_14_);}
  .scv_st18{fill:url(#SVGID_15_);}
  .scv_st19{fill:url(#SVGID_16_);}
  .scv_st20{fill:url(#SVGID_17_);}
  .scv_st21{fill:url(#SVGID_18_);}
  .scv_st22{fill:url(#SVGID_19_);}
  .scv_st23{fill:url(#SVGID_20_);}
  .scv_st24{fill:url(#SVGID_21_);}
  .scv_st25{fill:url(#SVGID_22_);}
  .scv_st26{fill:url(#SVGID_23_);}
  .scv_st27{fill:url(#SVGID_24_);}
  .scv_st28{fill:url(#SVGID_25_);}
  .scv_st29{fill:url(#SVGID_26_);}
  .scv_st30{fill:url(#SVGID_27_);}
  .scv_st31{fill:url(#SVGID_28_);}
  .scv_st32{fill:url(#SVGID_29_);}
  .scv_st33{fill:url(#SVGID_30_);}
  .scv_st34{fill:url(#SVGID_31_);}
  .scv_st35{fill:url(#SVGID_32_);}
  .scv_st36{fill:url(#SVGID_33_);}
  .scv_st37{fill:url(#SVGID_34_);}
  .scv_st38{fill:url(#SVGID_35_);}
  .scv_st39{fill:url(#SVGID_36_);}
  .scv_st40{fill:url(#SVGID_37_);}
  .scv_st41{fill:url(#SVGID_38_);}
  .scv_st42{fill:url(#SVGID_39_);}
  .scv_st43{fill:url(#SVGID_40_);}
  .scv_st44{fill:url(#SVGID_41_);}
  .scv_st45{fill:url(#SVGID_42_);}
  .scv_st46{fill:url(#SVGID_43_);}
  .scv_st47{fill:url(#SVGID_44_);}
  .scv_st48{fill:url(#SVGID_45_);}
  .scv_st49{fill:url(#SVGID_46_);}
  .scv_st50{fill:url(#SVGID_47_);}
  .scv_st51{fill:url(#SVGID_48_);}
  .scv_st52{fill:url(#SVGID_49_);}
  .scv_st53{fill:url(#SVGID_50_);}
  .scv_st54{fill:url(#SVGID_51_);}
  .scv_st55{fill:url(#SVGID_52_);}
  .scv_st56{fill:url(#SVGID_53_);}
  .scv_st57{fill:url(#SVGID_54_);}
  .scv_st58{fill:url(#SVGID_55_);}
  .scv_st59{fill:url(#SVGID_56_);}
  .scv_st60{opacity:0.18;fill:url(#SVGID_57_);}
</style>
  <g class="scv_st3">
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="31.3144" y1="147.4615" x2="9.1246" y2="147.4615">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st4" d="M21.81,137.65c0.9-2.07,2.56-5.94,5.95-5.94c1.1,0,2.08,0.28,3.09,0.89l0.46-0.77
		c-1.14-0.68-2.3-1.02-3.55-1.02c-3.98,0-5.8,4.21-6.78,6.48c-0.1,0.23-0.19,0.44-0.27,0.61L9.12,163.73l0.82,0.37l11.59-25.82
		C21.62,138.1,21.71,137.89,21.81,137.65z"/>
    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="43.4119" y1="158.6739" x2="35.708" y2="158.6739">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st5" d="M42.33,154.15c0.24,0,0.52,0.05,0.83,0.14l0.25-0.86c-0.4-0.11-0.75-0.17-1.08-0.17
		c-1.95,0-2.84,1.98-3.32,3.04c-0.06,0.14-0.12,0.27-0.18,0.4l-3.13,7.06l0.82,0.36l3.12-7.04c0.06-0.11,0.12-0.25,0.19-0.41
		C40.26,155.72,40.97,154.15,42.33,154.15z"/>
    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="45.8769" y1="160.926" x2="41.0109" y2="160.926">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st6" d="M42.55,160.33l-1.54,3.41l0.82,0.37l1.49-3.29l0.06-0.15c0.26-0.65,0.81-2.01,1.89-2.01
		c0.14,0,0.25,0.03,0.39,0.06l0.22-0.87c-0.16-0.04-0.36-0.09-0.61-0.09C43.58,157.75,42.86,159.56,42.55,160.33z"/>
    <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="41.0101" y1="156.4437" x2="30.369" y2="156.4437">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st7" d="M36.2,152.92c0.53-1.21,1.41-3.24,3.23-3.24c0.47,0,0.89,0.08,1.17,0.22l0.4-0.8
		c-0.52-0.26-1.14-0.31-1.57-0.31c-2.41,0-3.48,2.46-4.06,3.78c-0.06,0.14-0.12,0.27-0.17,0.38l-4.84,10.79l0.82,0.37l4.84-10.79
		C36.08,153.2,36.14,153.07,36.2,152.92z"/>
    <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="38.5691" y1="154.1932" x2="25.0672" y2="154.1932">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st8" d="M32.62,149.07c0.59-1.36,1.69-3.88,3.88-3.88c0.62,0,1.17,0.12,1.67,0.37l0.4-0.8
		c-0.63-0.31-1.31-0.47-2.07-0.47c-2.78,0-4.03,2.88-4.71,4.42c-0.07,0.17-0.14,0.32-0.2,0.46l-6.52,14.57l0.82,0.37l6.52-14.56
		C32.47,149.4,32.54,149.24,32.62,149.07z"/>
    <linearGradient id="SVGID_6_" gradientUnits="userSpaceOnUse" x1="33.6789" y1="149.6927" x2="14.4261" y2="149.6927">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st9" d="M25.37,141.52c0.79-1.87,2.25-5.34,5.33-5.34c0.9,0,1.74,0.24,2.52,0.7l0.46-0.77
		c-0.91-0.55-1.92-0.83-2.98-0.83c-3.67,0-5.35,3.98-6.15,5.89c-0.08,0.18-0.14,0.34-0.21,0.48l-9.92,22.08l0.82,0.37l9.92-22.09
		C25.23,141.87,25.3,141.7,25.37,141.52z"/>
    <linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="36.1281" y1="151.9433" x2="19.7277" y2="151.9433">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st10" d="M28.99,145.32c0.69-1.62,1.97-4.64,4.61-4.64c0.72,0,1.42,0.17,2.13,0.52l0.4-0.8
		c-0.83-0.42-1.66-0.62-2.53-0.62c-3.24,0-4.72,3.51-5.44,5.19c-0.07,0.17-0.14,0.32-0.2,0.45l-8.24,18.31l0.82,0.37l8.24-18.31
		C28.85,145.66,28.91,145.5,28.99,145.32z"/>
</g>
  <g class="scv_st3">
	<linearGradient id="SVGID_8_" gradientUnits="userSpaceOnUse" x1="58.9925" y1="173.2807" x2="72.0365" y2="173.2807">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st11" d="M65.59,178.46c0.06-0.13,0.11-0.25,0.16-0.36l6.29-14l-0.82-0.37l-6.29,14c-0.05,0.11-0.1,0.23-0.16,0.37
		c-0.57,1.34-1.64,3.82-3.78,3.82c-0.58,0-1.08-0.12-1.59-0.37l-0.4,0.8c0.63,0.31,1.28,0.47,1.99,0.47
		C63.71,182.83,64.98,179.88,65.59,178.46z"/>
    <linearGradient id="SVGID_9_" gradientUnits="userSpaceOnUse" x1="61.3479" y1="175.4355" x2="77.1473" y2="175.4355">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st12" d="M69.04,182.13c0.06-0.15,0.12-0.29,0.18-0.41l7.93-17.62l-0.82-0.37l-7.93,17.62
		c-0.05,0.12-0.12,0.27-0.18,0.43c-0.66,1.56-1.89,4.46-4.4,4.46c-0.76,0-1.43-0.16-2.04-0.48l-0.42,0.79
		c0.75,0.39,1.55,0.59,2.46,0.59C66.92,187.14,68.35,183.76,69.04,182.13z"/>
    <linearGradient id="SVGID_10_" gradientUnits="userSpaceOnUse" x1="63.7065" y1="177.6095" x2="82.296" y2="177.6095">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st13" d="M72.54,185.78c0.07-0.16,0.13-0.31,0.18-0.43l9.57-21.24l-0.82-0.37l-9.58,21.25
		c-0.06,0.13-0.12,0.28-0.19,0.45c-0.76,1.8-2.17,5.15-5.12,5.15c-0.83,0-1.63-0.22-2.45-0.67l-0.43,0.79
		c0.95,0.53,1.9,0.78,2.89,0.78C70.14,191.49,71.76,187.63,72.54,185.78z"/>
    <linearGradient id="SVGID_11_" gradientUnits="userSpaceOnUse" x1="66.004" y1="179.7646" x2="87.4071" y2="179.7646">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st14" d="M75.41,188.56c-0.07,0.17-0.16,0.36-0.25,0.57c-0.85,2.01-2.45,5.76-5.75,5.76c-1.1,0-2.05-0.28-2.92-0.84
		L66,194.81c1.02,0.66,2.14,0.99,3.41,0.99c3.9,0,5.71-4.26,6.58-6.31c0.09-0.21,0.17-0.39,0.24-0.55l11.18-24.83l-0.82-0.37
		L75.41,188.56z"/>
    <linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="51.9552" y1="166.7965" x2="56.6654" y2="166.7965">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st15" d="M56.67,164.1l-0.82-0.37l-1.42,3.14L54.38,167c-0.26,0.64-0.79,1.97-1.82,1.97c-0.13,0-0.24-0.03-0.39-0.06
		l-0.21,0.87c0.17,0.04,0.36,0.09,0.6,0.09c1.63,0,2.35-1.77,2.66-2.53L56.67,164.1z"/>
    <linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="54.287" y1="168.9523" x2="61.7771" y2="168.9523">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st16" d="M58.65,171.12c0.04-0.1,0.08-0.19,0.12-0.27l3.01-6.75l-0.82-0.37l-3.01,6.75c-0.04,0.08-0.08,0.18-0.12,0.28
		c-0.52,1.21-1.19,2.51-2.44,2.51c-0.32,0-0.55-0.04-0.74-0.12l-0.35,0.83c0.3,0.13,0.65,0.19,1.09,0.19
		C57.33,174.17,58.22,172.11,58.65,171.12z"/>
    <linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="56.6429" y1="171.1062" x2="66.9252" y2="171.1062">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st17" d="M62.08,174.83c0.06-0.13,0.11-0.25,0.16-0.35l4.69-10.37l-0.82-0.37l-4.69,10.37
		c-0.05,0.11-0.1,0.23-0.16,0.37c-0.47,1.08-1.34,3.1-3.09,3.1c-0.45,0-0.8-0.07-1.15-0.23l-0.37,0.82
		c0.46,0.21,0.94,0.31,1.52,0.31C60.51,178.48,61.57,176.02,62.08,174.83z"/>
</g>
  <g class="scv_st3">
	<linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="117.8673" y1="162.0888" x2="114.8894" y2="162.0888">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st18" d="M117.55,160.07c-1.11,0-1.59,1.22-1.77,1.68l-0.89,1.98l0.82,0.37l0.88-1.96l0.02-0.06
		c0.13-0.33,0.44-1.11,0.93-1.11c0.04,0,0.07,0.01,0.1,0.01c0.08,0.01,0.15,0.02,0.2,0.02l0.01-0.9c-0.02,0-0.04-0.01-0.05-0.01
		C117.74,160.09,117.65,160.07,117.55,160.07z"/>
    <linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="113.7064" y1="158.141" x2="105.5063" y2="158.141">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st19" d="M110.25,155.34c0.32-0.79,0.92-2.26,2.15-2.26c0.36,0,0.67,0.06,0.91,0.18l0.4-0.8
		c-0.36-0.18-0.81-0.28-1.31-0.28c-1.83,0-2.63,1.97-2.98,2.82l-3.92,8.74l0.82,0.37l3.86-8.59L110.25,155.34z"/>
    <linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="116.6049" y1="160.7728" x2="111.7615" y2="160.7728">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st20" d="M115.83,158.34c0.16,0,0.28,0.02,0.37,0.07l0.4-0.8c-0.22-0.11-0.48-0.16-0.77-0.16
		c-1.28,0-1.85,1.32-2.12,1.95c-0.03,0.07-0.05,0.13-0.08,0.18l-1.87,4.16l0.82,0.37l1.87-4.15c0.03-0.06,0.05-0.12,0.09-0.2
		C114.83,159.07,115.19,158.34,115.83,158.34z"/>
    <linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="115.0757" y1="159.4567" x2="108.6339" y2="159.4567">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st21" d="M112.39,157.54c0.27-0.64,0.77-1.83,1.72-1.83c0.24,0,0.46,0.06,0.72,0.14l0.24-0.86
		c-0.28-0.08-0.59-0.17-0.96-0.17c-1.55,0-2.23,1.61-2.55,2.38c-0.03,0.06-0.05,0.12-0.07,0.17l-2.86,6.37l0.82,0.37l2.86-6.37
		C112.34,157.67,112.37,157.61,112.39,157.54z"/>
    <linearGradient id="SVGID_19_" gradientUnits="userSpaceOnUse" x1="110.8558" y1="155.4901" x2="99.2512" y2="155.4901">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st22" d="M106.03,150.82c0.45-1.06,1.28-3.04,2.94-3.04c0.55,0,1.01,0.13,1.39,0.38l0.5-0.75
		c-0.54-0.36-1.16-0.53-1.89-0.53c-2.26,0-3.28,2.42-3.77,3.59c-0.04,0.1-0.08,0.19-0.11,0.26l-5.84,13.01l0.82,0.37l5.84-13.01
		C105.94,151.01,105.98,150.92,106.03,150.82z"/>
    <linearGradient id="SVGID_20_" gradientUnits="userSpaceOnUse" x1="112.257" y1="156.806" x2="102.3788" y2="156.806">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st23" d="M108.17,153.02c0.39-0.91,1.12-2.62,2.52-2.62c0.39,0,0.77,0.1,1.17,0.3l0.4-0.8
		c-0.53-0.26-1.04-0.39-1.57-0.39c-1.99,0-2.9,2.13-3.34,3.16c-0.04,0.1-0.08,0.19-0.12,0.27l-4.84,10.79l0.82,0.37l4.84-10.79
		C108.08,153.23,108.12,153.13,108.17,153.02z"/>
    <linearGradient id="SVGID_21_" gradientUnits="userSpaceOnUse" x1="109.4385" y1="154.1743" x2="96.1236" y2="154.1743">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st24" d="M103.95,148.49c0.51-1.17,1.45-3.34,3.31-3.34c0.65,0,1.23,0.16,1.7,0.46l0.49-0.75
		c-0.62-0.4-1.36-0.61-2.19-0.61c-2.45,0-3.58,2.62-4.13,3.88c-0.06,0.14-0.12,0.27-0.17,0.39l-6.83,15.22l0.82,0.37l6.82-15.21
		C103.82,148.77,103.88,148.64,103.95,148.49z"/>
</g>
  <g class="scv_st3">
	<linearGradient id="SVGID_22_" gradientUnits="userSpaceOnUse" x1="130.8509" y1="174.3867" x2="145.1521" y2="174.3867">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st25" d="M136.97,180.07l-0.11,0.27c-0.5,1.24-1.55,3.81-3.67,3.81c-0.67,0-1.28-0.18-1.86-0.54l-0.48,0.76
		c0.73,0.46,1.49,0.68,2.34,0.68c2.72,0,3.92-2.95,4.5-4.37l0.1-0.25l7.36-16.32l-0.82-0.37L136.97,180.07z"/>
    <linearGradient id="SVGID_23_" gradientUnits="userSpaceOnUse" x1="127.8227" y1="171.5451" x2="138.4392" y2="171.5451">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st26" d="M133.05,176.05c0.06-0.13,0.11-0.25,0.16-0.36l5.23-11.59l-0.82-0.37l-5.22,11.59
		c-0.05,0.1-0.11,0.23-0.17,0.37c-0.45,1.03-1.21,2.77-2.75,2.77c-0.42,0-0.84-0.11-1.23-0.33l-0.43,0.79
		c0.52,0.29,1.1,0.44,1.66,0.44C131.6,179.36,132.58,177.13,133.05,176.05z"/>
    <linearGradient id="SVGID_24_" gradientUnits="userSpaceOnUse" x1="124.7334" y1="168.7033" x2="131.6879" y2="168.7033">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st27" d="M128.56,171.04l3.13-6.93l-0.82-0.37l-3.1,6.84l-0.05,0.13c-0.27,0.67-0.84,2.08-1.94,2.08
		c-0.22,0-0.46-0.05-0.7-0.15l-0.35,0.83c0.35,0.15,0.71,0.23,1.05,0.23C127.49,173.67,128.24,171.83,128.56,171.04z"/>
    <linearGradient id="SVGID_25_" gradientUnits="userSpaceOnUse" x1="126.2912" y1="170.1153" x2="135.0454" y2="170.1153">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st28" d="M130.79,173.6c0.05-0.11,0.09-0.21,0.14-0.31l4.12-9.19l-0.82-0.37l-4.12,9.18c-0.04,0.09-0.09,0.2-0.15,0.32
		c-0.39,0.88-1.04,2.36-2.31,2.36c-0.34,0-0.64-0.07-1-0.23l-0.36,0.82c0.47,0.21,0.9,0.31,1.36,0.31
		C129.51,176.5,130.34,174.61,130.79,173.6z"/>
    <linearGradient id="SVGID_26_" gradientUnits="userSpaceOnUse" x1="123.1794" y1="167.2723" x2="128.331" y2="167.2723">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st29" d="M126.25,168.67l2.08-4.56l-0.82-0.37l-2.03,4.43l-0.07,0.16c-0.23,0.56-0.65,1.59-1.47,1.59
		c-0.16,0-0.3-0.03-0.44-0.08l-0.33,0.83c0.25,0.1,0.5,0.15,0.78,0.15C125.38,170.81,125.99,169.31,126.25,168.67z"/>
    <linearGradient id="SVGID_27_" gradientUnits="userSpaceOnUse" x1="121.6412" y1="165.8441" x2="124.9385" y2="165.8441">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st30" d="M123.97,166.29l0.97-2.19l-0.82-0.36l-0.91,2.06c-0.02,0.04-0.04,0.09-0.06,0.14
		c-0.23,0.54-0.52,1.12-1.02,1.12c-0.14,0-0.16,0-0.2-0.02l-0.28,0.85c0.18,0.06,0.32,0.06,0.48,0.06
		C123.25,167.95,123.73,166.83,123.97,166.29z"/>
    <linearGradient id="SVGID_28_" gradientUnits="userSpaceOnUse" x1="129.3884" y1="172.9564" x2="141.7955" y2="172.9564">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st31" d="M135.37,178.38c0.05-0.12,0.09-0.23,0.13-0.32l6.29-13.96l-0.82-0.37l-6.29,13.96
		c-0.04,0.1-0.09,0.21-0.14,0.33c-0.48,1.14-1.37,3.25-3.19,3.25c-0.53,0-1.04-0.13-1.54-0.4l-0.43,0.79
		c0.64,0.34,1.28,0.51,1.97,0.51C133.77,182.18,134.85,179.61,135.37,178.38z"/>
</g>
  <g class="scv_st3">
	<linearGradient id="SVGID_29_" gradientUnits="userSpaceOnUse" x1="194.7547" y1="136.7439" x2="158.4077" y2="136.7439">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st32" d="M178.9,120.29c1.5-3.5,4.3-10,10.17-10c1.9,0,3.61,0.49,5.22,1.5l0.48-0.76c-1.73-1.08-3.65-1.63-5.69-1.63
		c-6.46,0-9.41,6.86-10.99,10.55c-0.16,0.38-0.31,0.71-0.44,1.01l-19.22,42.79l0.82,0.37l19.22-42.79
		C178.58,121.02,178.73,120.67,178.9,120.29z"/>
    <linearGradient id="SVGID_30_" gradientUnits="userSpaceOnUse" x1="210.8708" y1="151.6187" x2="193.6495" y2="151.6187">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st33" d="M202.76,145.58c0.84-1.94,2.41-5.54,5.6-5.54c0.81,0,1.51,0.14,2.14,0.42l0.36-0.82
		c-0.75-0.33-1.57-0.5-2.51-0.5c-3.78,0-5.5,3.96-6.42,6.08c-0.1,0.23-0.19,0.44-0.28,0.63l-8.01,17.89l0.82,0.37l8.01-17.88
		C202.56,146.04,202.66,145.82,202.76,145.58z"/>
    <linearGradient id="SVGID_31_" gradientUnits="userSpaceOnUse" x1="214.9421" y1="155.3374" x2="202.4601" y2="155.3374">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st34" d="M214.6,147.7l0.35-0.83c-0.49-0.2-1.05-0.3-1.74-0.3c-3.15,0-4.55,3.26-5.31,5.01
		c-0.08,0.18-0.15,0.34-0.21,0.48l-5.23,11.67l0.82,0.37l5.22-11.67c0.07-0.14,0.14-0.31,0.22-0.5c0.67-1.56,1.93-4.47,4.48-4.47
		C213.76,147.47,214.23,147.55,214.6,147.7z"/>
    <linearGradient id="SVGID_32_" gradientUnits="userSpaceOnUse" x1="206.801" y1="147.9002" x2="184.8391" y2="147.9002">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st35" d="M196.75,139.38c1.01-2.37,2.88-6.79,6.8-6.79c1,0,1.97,0.22,2.86,0.64l0.39-0.81
		c-1.01-0.48-2.11-0.73-3.25-0.73c-4.51,0-6.62,4.95-7.63,7.33c-0.09,0.22-0.18,0.42-0.26,0.6l-10.83,24.1l0.82,0.37L196.49,140
		C196.57,139.82,196.66,139.61,196.75,139.38z"/>
    <linearGradient id="SVGID_33_" gradientUnits="userSpaceOnUse" x1="218.9525" y1="159.056" x2="211.2705" y2="159.056">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st36" d="M213.82,158.03c-0.04,0.09-0.07,0.18-0.1,0.25l-2.44,5.45l0.82,0.37l2.44-5.46c0.03-0.08,0.07-0.17,0.11-0.27
		c0.47-1.13,1.44-3.47,3.37-3.47c0.28,0,0.55,0.03,0.81,0.07l0.13-0.89c-0.28-0.04-0.61-0.08-0.94-0.08
		C215.48,154.01,214.36,156.73,213.82,158.03z"/>
    <linearGradient id="SVGID_34_" gradientUnits="userSpaceOnUse" x1="202.7432" y1="144.1814" x2="176.0287" y2="144.1814">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st37" d="M190.77,133.07c1.17-2.76,3.36-7.91,7.94-7.91c1.33,0,2.5,0.29,3.6,0.9l0.43-0.79
		c-1.21-0.67-2.57-1.01-4.03-1.01c-5.18,0-7.6,5.71-8.77,8.45c-0.11,0.26-0.21,0.49-0.3,0.7l-13.62,30.32l0.82,0.37l13.62-30.32
		C190.56,133.57,190.66,133.33,190.77,133.07z"/>
    <linearGradient id="SVGID_35_" gradientUnits="userSpaceOnUse" x1="198.7112" y1="140.4628" x2="167.2181" y2="140.4628">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st38" d="M184.84,126.71c1.33-3.14,3.8-8.99,9.03-8.99c1.6,0,3.08,0.39,4.39,1.16l0.46-0.77
		c-1.45-0.85-3.08-1.28-4.84-1.28c-5.82,0-8.54,6.44-9.85,9.54c-0.13,0.31-0.25,0.59-0.36,0.83l-16.44,36.54l0.82,0.37l16.44-36.54
		C184.59,127.31,184.71,127.03,184.84,126.71z"/>
</g>
  <g class="scv_st3">
	<linearGradient id="SVGID_36_" gradientUnits="userSpaceOnUse" x1="241.7305" y1="180.165" x2="263.6936" y2="180.165">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st39" d="M252.09,189.86c0.09-0.2,0.16-0.39,0.23-0.55l11.37-25.21l-0.82-0.37l-11.37,25.21
		c-0.07,0.17-0.15,0.36-0.24,0.56c-0.91,2.16-2.6,6.19-6.14,6.19c-1.06,0-2.05-0.27-2.93-0.81l-0.47,0.77
		c1.01,0.62,2.19,0.94,3.4,0.94C249.26,196.6,251.18,192.05,252.09,189.86z"/>
    <linearGradient id="SVGID_37_" gradientUnits="userSpaceOnUse" x1="244.4709" y1="182.7205" x2="269.7582" y2="182.7205">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st40" d="M255.67,193.25c-0.09,0.21-0.2,0.45-0.31,0.72c-1.02,2.39-2.93,6.84-6.87,6.84c-1.29,0-2.48-0.34-3.54-1
		l-0.48,0.76c1.2,0.75,2.55,1.14,4.02,1.14c4.53,0,6.59-4.8,7.69-7.38c0.11-0.26,0.21-0.5,0.31-0.7l13.27-29.52l-0.82-0.37
		L255.67,193.25z"/>
    <linearGradient id="SVGID_38_" gradientUnits="userSpaceOnUse" x1="236.1564" y1="175.0158" x2="251.5266" y2="175.0158">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st41" d="M243.86,181.11c0.06-0.14,0.11-0.27,0.16-0.37l7.51-16.63l-0.82-0.37l-7.52,16.64
		c-0.05,0.12-0.1,0.25-0.16,0.39c-0.67,1.62-1.93,4.64-4.57,4.64c-0.67,0-1.29-0.15-1.9-0.45l-0.4,0.8c0.73,0.37,1.48,0.54,2.3,0.54
		C241.7,186.3,243.16,182.79,243.86,181.11z"/>
    <linearGradient id="SVGID_39_" gradientUnits="userSpaceOnUse" x1="238.9252" y1="177.5906" x2="257.5915" y2="177.5906">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st42" d="M247.97,185.51c0.07-0.17,0.14-0.33,0.2-0.46l9.42-20.94l-0.82-0.37l-9.42,20.94
		c-0.06,0.14-0.13,0.3-0.21,0.48c-0.8,1.88-2.28,5.39-5.33,5.39c-0.88,0-1.68-0.21-2.45-0.63l-0.43,0.79
		c0.9,0.49,1.87,0.74,2.89,0.74C245.46,191.45,247.16,187.43,247.97,185.51z"/>
    <linearGradient id="SVGID_40_" gradientUnits="userSpaceOnUse" x1="227.7635" y1="167.3117" x2="233.2576" y2="167.3117">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st43" d="M231.58,167.84l1.68-3.74l-0.82-0.37l-1.67,3.73c-0.04,0.08-0.09,0.19-0.14,0.3
		c-0.47,1.08-1.06,2.23-2.17,2.23c-0.19,0-0.35-0.03-0.52-0.07l-0.18,0.88c0.2,0.04,0.42,0.09,0.7,0.09c1.78,0,2.6-1.87,2.99-2.76
		C231.5,168.02,231.54,167.93,231.58,167.84z"/>
    <linearGradient id="SVGID_41_" gradientUnits="userSpaceOnUse" x1="233.3622" y1="172.4418" x2="245.4245" y2="172.4418">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st44" d="M239.7,176.87c0.07-0.16,0.14-0.31,0.2-0.44l5.53-12.32l-0.82-0.37l-5.53,12.31
		c-0.06,0.13-0.13,0.29-0.2,0.46c-0.61,1.4-1.64,3.75-3.74,3.75c-0.52,0-1-0.09-1.43-0.27l-0.35,0.83c0.54,0.23,1.14,0.34,1.77,0.34
		C237.82,181.15,239.04,178.36,239.7,176.87z"/>
    <linearGradient id="SVGID_42_" gradientUnits="userSpaceOnUse" x1="230.5551" y1="169.8868" x2="239.3225" y2="169.8868">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st45" d="M235.57,172.52c0.06-0.14,0.12-0.26,0.17-0.37l3.59-8.05l-0.82-0.37l-3.58,8.04
		c-0.05,0.11-0.11,0.24-0.18,0.39c-0.5,1.12-1.33,2.98-2.97,2.98c-0.3,0-0.64-0.06-0.91-0.16l-0.31,0.84
		c0.38,0.14,0.81,0.22,1.23,0.22C234,176.04,235.06,173.66,235.57,172.52z"/>
</g>
  <g class="scv_st3">
	<linearGradient id="SVGID_43_" gradientUnits="userSpaceOnUse" x1="329.0541" y1="157.073" x2="319.0567" y2="157.073">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st46" d="M328.8,151.11l0.26-0.86c-0.47-0.14-0.92-0.21-1.39-0.21c-2.58,0-3.74,2.75-4.3,4.07
		c-0.06,0.13-0.11,0.25-0.15,0.35l-4.16,9.27l0.82,0.37l4.16-9.27c0.05-0.11,0.1-0.23,0.16-0.37c0.52-1.23,1.49-3.52,3.47-3.52
		C328.04,150.94,328.41,151,328.8,151.11z"/>
    <linearGradient id="SVGID_44_" gradientUnits="userSpaceOnUse" x1="332.347" y1="160.0293" x2="326.0367" y2="160.0293">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st47" d="M328.08,159.2c-0.04,0.09-0.07,0.16-0.1,0.23l-1.95,4.31l0.82,0.37l1.95-4.32c0.03-0.07,0.07-0.16,0.1-0.25
		c0.39-0.94,1.12-2.69,2.61-2.69c0.21,0,0.39,0,0.54,0.05l0.28-0.85c-0.3-0.1-0.58-0.1-0.83-0.1
		C329.43,155.96,328.52,158.14,328.08,159.2z"/>
    <linearGradient id="SVGID_45_" gradientUnits="userSpaceOnUse" x1="325.8836" y1="154.1174" x2="312.039" y2="154.1174">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st48" d="M319.44,149.46c0.66-1.55,1.88-4.43,4.41-4.43c0.65,0,1.18,0.11,1.63,0.33l0.4-0.8
		c-0.58-0.29-1.24-0.43-2.03-0.43c-3.12,0-4.55,3.36-5.24,4.98c-0.06,0.15-0.12,0.28-0.17,0.39l-6.41,14.23l0.82,0.37l6.41-14.23
		C319.32,149.75,319.38,149.62,319.44,149.46z"/>
    <linearGradient id="SVGID_46_" gradientUnits="userSpaceOnUse" x1="319.4064" y1="148.2054" x2="298.041" y2="148.2054">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st49" d="M309.98,139.33c0.93-2.14,2.65-6.13,6.2-6.13c0.97,0,1.89,0.23,2.81,0.71l0.41-0.8
		c-1.05-0.55-2.1-0.81-3.22-0.81c-4.14,0-6.02,4.34-7.03,6.67c-0.1,0.24-0.2,0.46-0.29,0.65l-10.83,24.1l0.82,0.37l10.83-24.1
		C309.78,139.81,309.88,139.58,309.98,139.33z"/>
    <linearGradient id="SVGID_47_" gradientUnits="userSpaceOnUse" x1="313.0674" y1="142.2934" x2="284.0816" y2="142.2934">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st50" d="M300.47,129.4c1.19-2.8,3.4-8.02,8.01-8.02c1.48,0,2.83,0.39,4.11,1.19l0.48-0.76
		c-1.43-0.89-2.93-1.33-4.59-1.33c-5.21,0-7.66,5.78-8.84,8.56c-0.11,0.26-0.21,0.5-0.3,0.7l-15.26,33.98l0.82,0.37l15.26-33.98
		C300.25,129.91,300.35,129.66,300.47,129.4z"/>
    <linearGradient id="SVGID_48_" gradientUnits="userSpaceOnUse" x1="316.2177" y1="145.2494" x2="291.0614" y2="145.2494">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st51" d="M305.26,134.32c1.05-2.46,3.01-7.03,7.08-7.03c1.22,0,2.34,0.31,3.44,0.93l0.45-0.78
		c-1.24-0.71-2.51-1.05-3.88-1.05c-4.66,0-6.77,4.92-7.9,7.57c-0.12,0.28-0.23,0.53-0.32,0.74l-13.04,29.03l0.82,0.37l13.04-29.02
		C305.02,134.86,305.13,134.6,305.26,134.32z"/>
    <linearGradient id="SVGID_49_" gradientUnits="userSpaceOnUse" x1="322.6499" y1="151.1614" x2="305.059" y2="151.1614">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st52" d="M314.71,144.45c0.78-1.86,2.25-5.33,5.29-5.33c0.84,0,1.57,0.17,2.23,0.52l0.42-0.79
		c-0.8-0.42-1.66-0.62-2.65-0.62c-3.64,0-5.31,3.97-6.11,5.88c-0.08,0.18-0.14,0.34-0.21,0.48l-8.62,19.15l0.82,0.37l8.62-19.15
		C314.56,144.81,314.63,144.64,314.71,144.45z"/>
</g>
  <g class="scv_st3">
	<linearGradient id="SVGID_50_" gradientUnits="userSpaceOnUse" x1="348.9882" y1="175.6643" x2="364.9956" y2="175.6643">
		<stop  offset="0" style="stop-color:#30499C"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st53" d="M356.6,182.75c0.07-0.17,0.14-0.32,0.2-0.45l8.2-18.19l-0.82-0.37l-8.2,18.19c-0.06,0.13-0.13,0.29-0.21,0.47
		c-0.65,1.5-1.85,4.3-4.27,4.3c-0.71,0-1.4-0.2-2.06-0.59l-0.46,0.77c0.8,0.47,1.65,0.71,2.52,0.71
		C354.51,187.59,355.92,184.32,356.6,182.75z"/>
    <linearGradient id="SVGID_51_" gradientUnits="userSpaceOnUse" x1="346.9716" y1="173.7955" x2="360.6094" y2="173.7955">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st54" d="M353.68,179.5c0.05-0.12,0.1-0.23,0.14-0.33l6.79-15.07l-0.82-0.37L353,178.8c-0.04,0.1-0.09,0.22-0.15,0.34
		c-0.56,1.33-1.6,3.81-3.75,3.81c-0.65,0-1.22-0.13-1.68-0.4l-0.45,0.78c0.6,0.34,1.32,0.52,2.13,0.52
		C351.85,183.86,353.09,180.91,353.68,179.5z"/>
    <linearGradient id="SVGID_52_" gradientUnits="userSpaceOnUse" x1="350.9547" y1="177.5142" x2="369.382" y2="177.5142">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st55" d="M368.56,163.73l-9.57,21.28c-0.06,0.14-0.14,0.31-0.21,0.49c-0.73,1.71-2.09,4.89-4.87,4.89
		c-0.9,0-1.71-0.24-2.46-0.73l-0.49,0.75c0.9,0.59,1.87,0.87,2.95,0.87c3.38,0,4.94-3.67,5.69-5.43c0.08-0.18,0.14-0.34,0.21-0.48
		l9.57-21.28L368.56,163.73z"/>
    <linearGradient id="SVGID_53_" gradientUnits="userSpaceOnUse" x1="342.9311" y1="170.0957" x2="351.837" y2="170.0957">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st56" d="M347.75,173.18l4.09-9.08l-0.82-0.37l-4.01,8.89l-0.09,0.21c-0.4,0.95-1.15,2.72-2.63,2.72
		c-0.39,0-0.71-0.06-1-0.19l-0.36,0.82c0.41,0.18,0.85,0.27,1.36,0.27C346.37,176.46,347.3,174.25,347.75,173.18z"/>
    <linearGradient id="SVGID_54_" gradientUnits="userSpaceOnUse" x1="344.9581" y1="171.9454" x2="356.2232" y2="171.9454">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st57" d="M350.68,176.38c0.05-0.11,0.09-0.22,0.13-0.3l5.42-11.98l-0.82-0.37l-5.42,11.98
		c-0.04,0.09-0.09,0.2-0.14,0.32c-0.48,1.13-1.36,3.23-3.15,3.23c-0.51,0-0.96-0.1-1.31-0.29l-0.43,0.79
		c0.48,0.26,1.08,0.4,1.74,0.4C349.08,180.16,350.16,177.61,350.68,176.38z"/>
    <linearGradient id="SVGID_55_" gradientUnits="userSpaceOnUse" x1="338.9238" y1="166.3949" x2="343.0638" y2="166.3949">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st58" d="M341.84,166.77l1.22-2.67l-0.82-0.37l-1.22,2.67c-0.03,0.06-0.06,0.14-0.1,0.22
		c-0.33,0.75-0.74,1.55-1.44,1.55c-0.13,0-0.25,0-0.35-0.02l-0.22,0.87c0.21,0.05,0.4,0.05,0.57,0.05c1.35,0,1.97-1.41,2.26-2.08
		C341.79,166.9,341.82,166.84,341.84,166.77z"/>
    <linearGradient id="SVGID_56_" gradientUnits="userSpaceOnUse" x1="340.95" y1="168.2453" x2="347.4505" y2="168.2453">
		<stop  offset="0" style="stop-color:#30499C"/>
      <stop  offset="1" style="stop-color:#FFFFFF"/>
	</linearGradient>
    <path class="scv_st59" d="M344.73,170.1c0.03-0.08,0.06-0.15,0.09-0.2l2.63-5.8l-0.82-0.37l-2.63,5.8c-0.03,0.06-0.06,0.14-0.09,0.22
		c-0.31,0.74-0.89,2.1-2.01,2.1c-0.26,0-0.48-0.03-0.66-0.09l-0.28,0.85c0.27,0.09,0.59,0.14,0.94,0.14
		C343.61,172.76,344.37,170.97,344.73,170.1z"/>
</g>
  <linearGradient id="SVGID_57_" gradientUnits="userSpaceOnUse" x1="2.4382" y1="154.5356" x2="375" y2="154.5356">
	<stop  offset="0" style="stop-color:#FFFFFF"/>
    <stop  offset="1" style="stop-color:#FFFFFF"/>
</linearGradient>
  <path class="scv_st60" d="M373.09,163.49l-10.76,23.84c-0.07,0.16-0.15,0.35-0.24,0.56c-0.76,1.8-2.17,5.15-4.95,5.15
	c-3.24,0-4.92-3.17-5.93-5.06c-0.15-0.28-0.29-0.54-0.4-0.74l-13.5-23.84h0l-21.55-38.11c-0.17-0.29-0.35-0.64-0.56-1.03
	c-1.77-3.34-5.08-9.57-11.87-9.57c-6.27,0-9.1,6.73-10.45,9.97c-0.11,0.27-0.21,0.51-0.3,0.72l-17.12,38.1h0l-14.91,33.11
	c-0.11,0.23-0.22,0.5-0.35,0.8c-1.1,2.57-3.14,7.35-7.2,7.35c-4.8,0-7.31-4.79-8.65-7.37c-0.17-0.33-0.32-0.62-0.47-0.87
	L225.1,163.4l0,0l-27.15-47.94c-0.2-0.35-0.42-0.77-0.67-1.26c-2.19-4.18-6.27-11.97-14.73-11.97c-7.62,0-11.16,8.29-12.86,12.27
	c-0.17,0.39-0.32,0.74-0.45,1.05l-21.55,47.94h0l-8.24,18.31c-0.05,0.12-0.11,0.25-0.17,0.39c-0.56,1.34-1.61,3.83-3.6,3.83
	c-2.36,0-3.6-2.38-4.34-3.8c-0.1-0.19-0.19-0.37-0.28-0.52l-10.37-18.31h0l-9.65-17.05c-0.07-0.12-0.14-0.26-0.23-0.42
	c-0.79-1.5-2.44-4.62-5.84-4.62c-3.19,0-4.61,3.37-5.21,4.81c-0.05,0.12-0.09,0.22-0.13,0.31l-7.67,17.05l0,0L79.4,191.34
	c-0.08,0.18-0.16,0.38-0.26,0.6c-0.91,2.16-2.61,6.17-5.95,6.17c-3.93,0-5.92-3.82-7.11-6.1c-0.15-0.29-0.29-0.55-0.41-0.77
	L49.88,163.4h0l-16.37-28.95c-0.13-0.23-0.28-0.5-0.44-0.81c-1.37-2.58-3.92-7.39-9.24-7.39c-4.89,0-7.08,5.18-8.13,7.66
	c-0.1,0.23-0.19,0.44-0.27,0.62L2.44,163.49l1.91,0.86l13.01-28.95c0.09-0.19,0.18-0.41,0.28-0.66c0.94-2.23,2.7-6.39,6.2-6.39
	c4.06,0,6.15,3.93,7.39,6.27c0.17,0.33,0.33,0.62,0.47,0.86l16.36,28.95l0,0l15.79,27.84c0.11,0.2,0.24,0.45,0.38,0.71
	c1.22,2.34,3.76,7.22,8.96,7.22c4.74,0,6.86-5.03,7.88-7.45c0.09-0.21,0.17-0.4,0.24-0.56l12.55-27.84l0,0l7.67-17.06
	c0.04-0.1,0.09-0.22,0.15-0.34c0.52-1.23,1.48-3.53,3.28-3.53c1.97,0,3.09,1.81,3.98,3.5c0.09,0.17,0.18,0.33,0.25,0.47l9.65,17.05
	l0,0l10.37,18.3c0.07,0.13,0.15,0.29,0.24,0.46c0.83,1.6,2.57,4.92,6.2,4.92c3.38,0,4.83-3.45,5.53-5.11
	c0.06-0.13,0.11-0.26,0.15-0.35l8.24-18.31l0,0l21.55-47.94c0.14-0.32,0.3-0.68,0.47-1.09c1.64-3.84,4.69-11,10.93-11
	c7.19,0,10.89,7.06,12.88,10.85c0.26,0.5,0.49,0.93,0.7,1.32l27.16,47.94v0l18.76,33.1c0.13,0.23,0.27,0.51,0.44,0.82
	c1.44,2.76,4.44,8.49,10.51,8.49c5.45,0,7.93-5.83,9.13-8.62c0.12-0.28,0.23-0.54,0.33-0.76l14.91-33.11l0,0l17.13-38.11
	c0.1-0.22,0.2-0.48,0.32-0.75c1.27-3.04,3.64-8.69,8.52-8.69c5.54,0,8.34,5.29,10.02,8.46c0.22,0.41,0.41,0.78,0.59,1.08l21.55,38.1
	l0,0L349,188.28c0.11,0.19,0.23,0.43,0.37,0.68c1.15,2.16,3.28,6.17,7.78,6.17c4.16,0,5.99-4.35,6.88-6.43
	c0.08-0.19,0.16-0.37,0.22-0.51L375,164.35L373.09,163.49z"/>
</svg>

<svg *ngIf="type == 'score_card_eye_contact'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 377 250" style="enable-background:new 0 0 377 250;" xml:space="preserve">
<style type="text/css">
	.scec_st1{opacity:0.18;fill:#F1F2F2;}
  .scec_st2{opacity:0.35;fill:#F1F2F2;}
</style>
  <g id="Layer_2">
	<path class="scec_st1" d="M189.13,138.2c-29.67,0-53.73,30.21-53.73,30.21s24.05,30.21,53.73,30.21c29.67,0,53.72-30.21,53.72-30.21
		S218.8,138.2,189.13,138.2z M189.13,189.06c-11.38,0-20.65-9.26-20.65-20.65c0-11.38,9.26-20.65,20.65-20.65
		c11.38,0,20.65,9.26,20.65,20.65C209.78,179.8,200.51,189.06,189.13,189.06z M144.49,168.41c4.7-4.89,13.43-12.95,24.17-18.23
		c-4.32,4.84-6.94,11.23-6.94,18.23c0,7,2.63,13.38,6.95,18.23C157.92,181.37,149.2,173.31,144.49,168.41z M209.6,186.64
		c4.32-4.85,6.95-11.23,6.95-18.23c0-7.02-2.64-13.42-6.99-18.28c10.79,5.27,19.52,13.36,24.22,18.27
		C229.08,173.3,220.35,181.36,209.6,186.64z"/>
    <path class="scec_st2" d="M189.13,179.39c-6.05,0-10.98-4.93-10.98-10.98c0-6.05,4.93-10.98,10.98-10.98c6.05,0,10.98,4.93,10.98,10.98
		C200.11,174.47,195.18,179.39,189.13,179.39z"/>
    <path class="scec_st1" d="M315.96,138.2c-29.67,0-53.73,30.21-53.73,30.21s24.05,30.21,53.73,30.21c29.67,0,53.72-30.21,53.72-30.21
		S345.63,138.2,315.96,138.2z M315.95,189.06c-11.38,0-20.65-9.26-20.65-20.65c0-11.38,9.26-20.65,20.65-20.65
		c11.38,0,20.65,9.26,20.65,20.65C336.6,179.8,327.34,189.06,315.95,189.06z M271.32,168.41c4.7-4.89,13.43-12.95,24.17-18.23
		c-4.32,4.84-6.94,11.23-6.94,18.23c0,7,2.63,13.38,6.95,18.23C284.74,181.37,276.02,173.31,271.32,168.41z M336.42,186.64
		c4.32-4.85,6.95-11.23,6.95-18.23c0-7.02-2.64-13.42-6.99-18.28c10.79,5.27,19.52,13.36,24.22,18.27
		C355.9,173.3,347.17,181.36,336.42,186.64z"/>
    <path class="scec_st2" d="M315.95,169.52c-6.05,0-10.98-4.93-10.98-10.98c0-6.05,4.93-10.98,10.98-10.98c6.05,0,10.98,4.93,10.98,10.98
		C326.94,164.59,322.01,169.52,315.95,169.52z"/>
    <path class="scec_st1" d="M62.31,138.2c-29.67,0-53.73,30.21-53.73,30.21s24.05,30.21,53.73,30.21c29.67,0,53.72-30.21,53.72-30.21
		S91.98,138.2,62.31,138.2z M62.3,189.06c-11.38,0-20.65-9.26-20.65-20.65c0-11.38,9.26-20.65,20.65-20.65
		c11.38,0,20.65,9.26,20.65,20.65C82.95,179.8,73.69,189.06,62.3,189.06z M17.67,168.41c4.7-4.89,13.43-12.95,24.17-18.23
		c-4.32,4.84-6.94,11.23-6.94,18.23c0,7,2.63,13.38,6.95,18.23C31.09,181.37,22.37,173.31,17.67,168.41z M82.77,186.64
		c4.32-4.85,6.95-11.23,6.95-18.23c0-7.02-2.64-13.42-6.99-18.28c10.79,5.27,19.52,13.36,24.22,18.27
		C102.25,173.3,93.52,181.36,82.77,186.64z"/>
    <path class="scec_st2" d="M62.3,188.72c-6.05,0-10.98-4.93-10.98-10.98c0-6.05,4.93-10.98,10.98-10.98c6.05,0,10.98,4.93,10.98,10.98
		C73.29,183.79,68.36,188.72,62.3,188.72z"/>
</g>
</svg>

<svg  *ngIf="type == 'thumbnail'"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
     x="0px" y="0px"
     viewBox="0 0 152.8 94" style="enable-background:new 0 0 152.8 94;" xml:space="preserve">

        <path d="M4.7,41L39.9,5.7c1.3-1.3,3-2.1,4.7-2.4V3.1H82h19.7h47v88.2h-47H82H44.7v-0.1c-1.8-0.3-3.4-1.1-4.7-2.4
            L4.7,53.5c-1.7-1.7-2.6-3.9-2.6-6.2C2.1,44.9,3,42.6,4.7,41z"/>
    </svg>

<svg *ngIf="type == 'volume_gauge_bg'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 90 500 290" style="enable-background:new 0 90 500 290;" xml:space="preserve">
<style type="text/css">
	.vgbg_st0{display:none;}
    .vgbg_st1{display:inline;fill:#FFFFFF;stroke:#3274BB;stroke-width:8.6881;stroke-miterlimit:10;}
    .vgbg_st2{display:inline;fill:#EDDBC3;}
    .vgbg_st3{display:inline;fill:#F79226;}
    .vgbg_st4{display:inline;fill:#3274BB;}
    .vgbg_st5{display:inline;fill:#F7B74E;}
    .vgbg_st6{display:inline;fill:#F9C58C;}
    .vgbg_st7{display:inline;fill:#DA3850;}
    .vgbg_st8{display:inline;fill:#FFFFFF;}
    .vgbg_st9{display:inline;fill:#A77D52;}
    .vgbg_st10{display:inline;fill:#333333;}
    .vgbg_st11{display:inline;fill:#E8A77C;}
    .vgbg_st12{display:inline;fill:#454444;}
    .vgbg_st13{display:inline;fill:#4E4E4E;}
    .vgbg_st14{display:inline;fill:#575757;}
    .vgbg_st15{fill:#3A7BBF;}
    .vgbg_st16{opacity:0.24;fill:#FFFFFF;}
    .vgbg_st17{fill:#941C1D;}
    .vgbg_st18{fill:#FBB03B;}
    .vgbg_st19{fill:#F15B27;}
    .vgbg_st20{fill:#FFFFFF;}
    .vgbg_st21{fill:#3DB54A;}
</style>
    <g id="Layer_2">
	<path class="vgbg_st15" d="M8.49,332.57c0,11.25,0.78,29.95,2.29,40.78h233.86V94.05C114,95.33,8.49,201.62,8.49,332.57z"/>
        <path class="vgbg_st15" d="M485.56,332.57c0-131.74-106.8-238.53-238.53-238.53c-0.8,0-1.59,0.01-2.38,0.02v279.3h238.63
		C484.78,362.51,485.56,343.82,485.56,332.57z"/>
        <path class="vgbg_st16" d="M244.86,332.15L452.18,210.8c0,0-63.36-117.69-207.31-116.8V332.15z"/>
        <path class="vgbg_st17" d="M385.54,331.11L385.54,331.11l68.89,0c0-38.21-10.34-74-28.37-104.74l-59.38,34.29
		C378.67,281.38,385.54,305.44,385.54,331.11z"/>
        <path class="vgbg_st18" d="M244.64,190.22v-66.48c-37.63,0.42-72.84,10.86-103.11,28.78l32.65,56.56
		C194.91,197.08,218.97,190.22,244.64,190.22z"/>
        <path class="vgbg_st17" d="M122.61,260.65l-55.82-32.23C49.5,258.7,39.62,293.75,39.62,331.11h64.13v0
		C103.75,305.44,110.61,281.38,122.61,260.65z"/>
        <path class="vgbg_st19" d="M174.18,209.07l-32.65-56.56c-31.02,18.37-56.85,44.57-74.74,75.91l55.82,32.23
		C134.98,239.27,152.8,221.45,174.18,209.07z"/>
        <g>
		<path class="vgbg_st20" d="M265.53,251.75c0-0.65-0.25-1.19-0.73-1.6c-0.49-0.41-1.34-0.78-2.56-1.1c-1.31-0.33-2.31-0.76-2.98-1.29
			c-0.68-0.53-1.01-1.24-1.01-2.14c0-0.92,0.38-1.67,1.13-2.24c0.75-0.58,1.73-0.87,2.91-0.87c1.24,0,2.23,0.34,2.99,1.03
			c0.76,0.69,1.13,1.53,1.11,2.51l-0.03,0.05h-0.91c0-0.8-0.29-1.46-0.85-1.98c-0.57-0.52-1.34-0.77-2.31-0.77
			c-0.96,0-1.71,0.21-2.25,0.64s-0.81,0.96-0.81,1.6c0,0.6,0.25,1.11,0.75,1.51c0.5,0.4,1.36,0.76,2.59,1.07
			c1.29,0.33,2.27,0.77,2.95,1.34c0.67,0.56,1.01,1.3,1.01,2.22c0,0.96-0.39,1.72-1.17,2.28c-0.78,0.57-1.78,0.85-3,0.85
			c-1.2,0-2.24-0.3-3.14-0.9c-0.89-0.6-1.33-1.48-1.3-2.63l0.02-0.05h0.91c0,0.93,0.35,1.62,1.05,2.07c0.7,0.45,1.52,0.67,2.46,0.67
			c0.95,0,1.72-0.21,2.3-0.62C265.23,252.99,265.53,252.43,265.53,251.75z"/>
            <path class="vgbg_st20" d="M270.46,251.8l0.34,1.59h0.05l0.42-1.59l1.9-6.03h0.85l1.9,6.03l0.43,1.63h0.05l0.37-1.63l1.62-6.03h1.02
			l-2.6,8.92h-0.84l-1.98-6.1l-0.39-1.54h-0.05l-0.4,1.54l-1.95,6.1h-0.85l-2.59-8.92h1.02L270.46,251.8z"/>
            <path class="vgbg_st20" d="M284.66,254.87c-1.12,0-2.04-0.41-2.78-1.24s-1.11-1.87-1.11-3.13v-0.45c0-1.27,0.37-2.33,1.1-3.17
			c0.73-0.84,1.62-1.26,2.67-1.26c1.09,0,1.95,0.35,2.58,1.04c0.63,0.69,0.94,1.62,0.94,2.77v0.84h-6.3v0.24c0,1,0.27,1.84,0.8,2.52
			c0.53,0.67,1.23,1.01,2.1,1.01c0.62,0,1.14-0.08,1.58-0.25c0.43-0.17,0.8-0.42,1.11-0.73l0.42,0.68
			c-0.33,0.35-0.76,0.63-1.27,0.84C285.98,254.76,285.37,254.87,284.66,254.87z M284.54,246.45c-0.73,0-1.34,0.28-1.84,0.83
			c-0.49,0.56-0.78,1.25-0.87,2.09l0.02,0.04h5.23v-0.25c0-0.77-0.23-1.42-0.68-1.94C285.93,246.71,285.32,246.45,284.54,246.45z"/>
            <path class="vgbg_st20" d="M293.36,254.87c-1.12,0-2.04-0.41-2.78-1.24s-1.11-1.87-1.11-3.13v-0.45c0-1.27,0.37-2.33,1.1-3.17
			c0.73-0.84,1.62-1.26,2.67-1.26c1.09,0,1.95,0.35,2.58,1.04c0.63,0.69,0.94,1.62,0.94,2.77v0.84h-6.3v0.24c0,1,0.27,1.84,0.8,2.52
			c0.53,0.67,1.23,1.01,2.1,1.01c0.62,0,1.14-0.08,1.58-0.25c0.43-0.17,0.8-0.42,1.11-0.73l0.42,0.68
			c-0.33,0.35-0.76,0.63-1.27,0.84C294.68,254.76,294.07,254.87,293.36,254.87z M293.23,246.45c-0.73,0-1.34,0.28-1.84,0.83
			c-0.49,0.56-0.78,1.25-0.87,2.09l0.02,0.04h5.23v-0.25c0-0.77-0.23-1.42-0.68-1.94C294.63,246.71,294.01,246.45,293.23,246.45z"/>
            <path class="vgbg_st20" d="M300.26,243.49v2.28h1.96v0.81h-1.96v5.81c0,0.58,0.11,1,0.32,1.24c0.21,0.25,0.49,0.37,0.84,0.37
			c0.16,0,0.31-0.01,0.47-0.02s0.33-0.04,0.52-0.07l0.15,0.73c-0.17,0.07-0.37,0.13-0.6,0.17s-0.47,0.06-0.71,0.06
			c-0.63,0-1.11-0.2-1.46-0.59c-0.35-0.4-0.52-1.02-0.52-1.88v-5.81h-1.54v-0.81h1.54v-2.28H300.26z"/>
            <path class="vgbg_st20" d="M309.08,253.85h7.36v0.84h-8.54v-0.77l7.07-10.4h-6.85v-0.84h8.05v0.72L309.08,253.85z"/>
            <path class="vgbg_st20" d="M317.78,250.1c0-1.31,0.36-2.39,1.08-3.23s1.67-1.26,2.86-1.26c1.19,0,2.15,0.42,2.87,1.26
			s1.08,1.92,1.08,3.23v0.27c0,1.32-0.36,2.4-1.08,3.24c-0.72,0.84-1.67,1.26-2.86,1.26c-1.2,0-2.16-0.42-2.88-1.26
			s-1.08-1.92-1.08-3.24V250.1z M318.77,250.37c0,1.03,0.26,1.9,0.78,2.6c0.52,0.7,1.25,1.05,2.18,1.05c0.92,0,1.64-0.35,2.16-1.05
			c0.53-0.7,0.79-1.57,0.79-2.6v-0.27c0-1.02-0.26-1.88-0.79-2.59c-0.53-0.71-1.25-1.06-2.18-1.06c-0.92,0-1.65,0.35-2.17,1.06
			c-0.52,0.71-0.78,1.57-0.78,2.59V250.37z"/>
            <path class="vgbg_st20" d="M328.61,245.78l0.09,1.56c0.29-0.55,0.67-0.97,1.16-1.27c0.49-0.3,1.06-0.45,1.73-0.45
			c0.97,0,1.7,0.3,2.21,0.89c0.5,0.59,0.76,1.51,0.76,2.77v5.43h-0.99v-5.44c0-1.02-0.19-1.75-0.57-2.17
			c-0.38-0.42-0.92-0.64-1.63-0.64c-0.69,0-1.26,0.18-1.69,0.55c-0.44,0.37-0.75,0.85-0.94,1.46v6.24h-0.99v-8.92H328.61z"/>
            <path class="vgbg_st20" d="M340.48,254.87c-1.12,0-2.04-0.41-2.78-1.24s-1.11-1.87-1.11-3.13v-0.45c0-1.27,0.37-2.33,1.1-3.17
			c0.73-0.84,1.62-1.26,2.67-1.26c1.09,0,1.95,0.35,2.58,1.04c0.63,0.69,0.94,1.62,0.94,2.77v0.84h-6.3v0.24c0,1,0.27,1.84,0.8,2.52
			c0.53,0.67,1.23,1.01,2.1,1.01c0.62,0,1.14-0.08,1.58-0.25c0.43-0.17,0.8-0.42,1.11-0.73l0.42,0.68
			c-0.33,0.35-0.76,0.63-1.27,0.84C341.8,254.76,341.19,254.87,340.48,254.87z M340.36,246.45c-0.73,0-1.34,0.28-1.84,0.83
			c-0.49,0.56-0.78,1.25-0.87,2.09l0.02,0.04h5.23v-0.25c0-0.77-0.23-1.42-0.68-1.94C341.75,246.71,341.14,246.45,340.36,246.45z"/>
	</g>
        <g>
		<path class="vgbg_st20" d="M48.99,344.84h-4.13V356h-0.99v-11.16h-4.13V344h9.25V344.84z"/>
            <path class="vgbg_st20" d="M49.3,351.41c0-1.31,0.36-2.39,1.08-3.23c0.72-0.84,1.67-1.26,2.86-1.26c1.19,0,2.15,0.42,2.87,1.26
			c0.72,0.84,1.08,1.92,1.08,3.23v0.27c0,1.32-0.36,2.4-1.08,3.24s-1.67,1.26-2.86,1.26c-1.2,0-2.16-0.42-2.88-1.26
			c-0.72-0.84-1.08-1.92-1.08-3.24V351.41z M50.29,351.67c0,1.03,0.26,1.9,0.78,2.6c0.52,0.7,1.25,1.05,2.19,1.05
			c0.92,0,1.64-0.35,2.16-1.05c0.52-0.7,0.79-1.57,0.79-2.6v-0.27c0-1.02-0.26-1.88-0.79-2.59c-0.53-0.71-1.25-1.06-2.18-1.06
			s-1.65,0.35-2.17,1.06c-0.52,0.71-0.78,1.57-0.78,2.59V351.67z"/>
            <path class="vgbg_st20" d="M58.7,351.41c0-1.31,0.36-2.39,1.08-3.23c0.72-0.84,1.67-1.26,2.86-1.26c1.19,0,2.15,0.42,2.87,1.26
			c0.72,0.84,1.08,1.92,1.08,3.23v0.27c0,1.32-0.36,2.4-1.08,3.24s-1.67,1.26-2.86,1.26c-1.2,0-2.16-0.42-2.88-1.26
			c-0.72-0.84-1.08-1.92-1.08-3.24V351.41z M59.69,351.67c0,1.03,0.26,1.9,0.78,2.6c0.52,0.7,1.25,1.05,2.19,1.05
			c0.92,0,1.64-0.35,2.16-1.05c0.52-0.7,0.79-1.57,0.79-2.6v-0.27c0-1.02-0.26-1.88-0.79-2.59c-0.53-0.71-1.25-1.06-2.18-1.06
			s-1.65,0.35-2.17,1.06c-0.52,0.71-0.78,1.57-0.78,2.59V351.67z"/>
            <path class="vgbg_st20" d="M74.02,355.16h5.94V356h-6.92v-12h0.99V355.16z"/>
            <path class="vgbg_st20" d="M81.1,351.41c0-1.31,0.36-2.39,1.08-3.23c0.72-0.84,1.67-1.26,2.86-1.26c1.19,0,2.15,0.42,2.87,1.26
			c0.72,0.84,1.08,1.92,1.08,3.23v0.27c0,1.32-0.36,2.4-1.08,3.24s-1.67,1.26-2.86,1.26c-1.2,0-2.16-0.42-2.88-1.26
			c-0.72-0.84-1.08-1.92-1.08-3.24V351.41z M82.09,351.67c0,1.03,0.26,1.9,0.78,2.6c0.52,0.7,1.25,1.05,2.19,1.05
			c0.92,0,1.64-0.35,2.16-1.05c0.52-0.7,0.79-1.57,0.79-2.6v-0.27c0-1.02-0.26-1.88-0.79-2.59c-0.53-0.71-1.25-1.06-2.18-1.06
			s-1.65,0.35-2.17,1.06c-0.52,0.71-0.78,1.57-0.78,2.59V351.67z"/>
            <path class="vgbg_st20" d="M92.97,353.1l0.34,1.59h0.05l0.42-1.59l1.9-6.03h0.85l1.9,6.03l0.43,1.63h0.05l0.37-1.63l1.62-6.03h1.02
			l-2.6,8.92h-0.84l-1.98-6.1l-0.39-1.54h-0.05l-0.4,1.54l-1.95,6.1h-0.85l-2.59-8.92h1.02L92.97,353.1z"/>
	</g>
        <g>
		<path class="vgbg_st20" d="M396.44,344.84h-4.13V356h-0.99v-11.16h-4.13V344h9.25V344.84z"/>
            <path class="vgbg_st20" d="M396.76,351.41c0-1.31,0.36-2.39,1.08-3.23s1.67-1.26,2.86-1.26c1.19,0,2.15,0.42,2.87,1.26
			s1.08,1.92,1.08,3.23v0.27c0,1.32-0.36,2.4-1.08,3.24c-0.72,0.84-1.67,1.26-2.86,1.26c-1.2,0-2.16-0.42-2.88-1.26
			s-1.08-1.92-1.08-3.24V351.41z M397.75,351.67c0,1.03,0.26,1.9,0.78,2.6c0.52,0.7,1.25,1.05,2.18,1.05c0.92,0,1.64-0.35,2.16-1.05
			c0.53-0.7,0.79-1.57,0.79-2.6v-0.27c0-1.02-0.26-1.88-0.79-2.59s-1.25-1.06-2.18-1.06c-0.92,0-1.65,0.35-2.17,1.06
			s-0.78,1.57-0.78,2.59V351.67z"/>
            <path class="vgbg_st20" d="M406.15,351.41c0-1.31,0.36-2.39,1.08-3.23s1.67-1.26,2.86-1.26c1.19,0,2.15,0.42,2.87,1.26
			s1.08,1.92,1.08,3.23v0.27c0,1.32-0.36,2.4-1.08,3.24c-0.72,0.84-1.67,1.26-2.86,1.26c-1.2,0-2.16-0.42-2.88-1.26
			s-1.08-1.92-1.08-3.24V351.41z M407.14,351.67c0,1.03,0.26,1.9,0.78,2.6c0.52,0.7,1.25,1.05,2.18,1.05c0.92,0,1.64-0.35,2.16-1.05
			c0.53-0.7,0.79-1.57,0.79-2.6v-0.27c0-1.02-0.26-1.88-0.79-2.59s-1.25-1.06-2.18-1.06c-0.92,0-1.65,0.35-2.17,1.06
			s-0.78,1.57-0.78,2.59V351.67z"/>
            <path class="vgbg_st20" d="M421.47,355.16h5.94V356h-6.93v-12h0.99V355.16z"/>
            <path class="vgbg_st20" d="M428.55,351.41c0-1.31,0.36-2.39,1.08-3.23s1.67-1.26,2.86-1.26c1.19,0,2.15,0.42,2.87,1.26
			s1.08,1.92,1.08,3.23v0.27c0,1.32-0.36,2.4-1.08,3.24c-0.72,0.84-1.67,1.26-2.86,1.26c-1.2,0-2.16-0.42-2.88-1.26
			s-1.08-1.92-1.08-3.24V351.41z M429.54,351.67c0,1.03,0.26,1.9,0.78,2.6c0.52,0.7,1.25,1.05,2.18,1.05c0.92,0,1.64-0.35,2.16-1.05
			c0.53-0.7,0.79-1.57,0.79-2.6v-0.27c0-1.02-0.26-1.88-0.79-2.59s-1.25-1.06-2.18-1.06c-0.92,0-1.65,0.35-2.17,1.06
			s-0.78,1.57-0.78,2.59V351.67z"/>
            <path class="vgbg_st20" d="M444.35,354.54c-0.29,0.52-0.67,0.92-1.15,1.21c-0.48,0.28-1.05,0.42-1.71,0.42c-0.94,0-1.68-0.32-2.21-0.96
			s-0.8-1.63-0.8-2.97v-5.17h0.99v5.2c0,1.09,0.19,1.87,0.56,2.35s0.9,0.71,1.58,0.71c0.75,0,1.34-0.17,1.79-0.5
			c0.45-0.34,0.76-0.8,0.93-1.39v-6.36h0.99V356h-0.87L444.35,354.54z"/>
            <path class="vgbg_st20" d="M447.51,351.61c0-1.42,0.3-2.56,0.9-3.42c0.6-0.85,1.44-1.28,2.52-1.28c0.62,0,1.16,0.13,1.62,0.4
			s0.83,0.64,1.14,1.12v-5.29h0.98V356h-0.87l-0.08-1.29c-0.3,0.47-0.68,0.83-1.15,1.09s-1.02,0.38-1.65,0.38
			c-1.07,0-1.9-0.4-2.5-1.19c-0.6-0.79-0.9-1.86-0.9-3.19V351.61z M448.5,351.79c0,1.08,0.22,1.94,0.65,2.58
			c0.43,0.64,1.08,0.96,1.95,0.96c0.64,0,1.17-0.14,1.6-0.43s0.76-0.68,0.99-1.19v-4.21c-0.23-0.52-0.54-0.94-0.96-1.26
			s-0.95-0.48-1.62-0.48c-0.87,0-1.52,0.36-1.96,1.07c-0.44,0.71-0.66,1.64-0.66,2.79V351.79z"/>
	</g>
        <path class="vgbg_st21" d="M366.68,260.65l59.38-34.29c-18.53-31.61-45.19-57.87-77.11-75.92l-33.85,58.63
		C336.48,221.45,354.31,239.27,366.68,260.65z"/>
        <path class="vgbg_st21" d="M315.1,209.07l33.85-58.63c-30.1-17.02-64.87-26.74-101.92-26.74c-0.8,0-1.59,0.02-2.38,0.03v66.48
		C270.31,190.22,294.37,197.08,315.1,209.07z"/>
</g>
</svg>

<svg *ngIf="type == 'volume_gauge_dial'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 90 500 280" style="enable-background:new 0 90 500 280;" xml:space="preserve">
<style type="text/css">
    .vgdial_st21{fill:#FFFFFF;}
</style>
    <g id="Layer_3">
	<g>
		<g>
			<path class="vgdial_st21" d="M247.33,311.74L112.65,200.69l109.48,135.05c0.02-0.16,0.03-0.33,0.05-0.49
				C223.78,322.14,234.59,312.41,247.33,311.74z"/>
		</g>
	</g>
        <g>
		<g>

				<ellipse transform="matrix(0.7569 -0.6535 0.6535 0.7569 -158.4794 240.7571)" class="vgdial_st23" cx="244.39" cy="333.41" rx="23.38" ry="23.38"/>
		</g>
	</g>
        <path class="vgdial_st21" d="M252.34,333.53c0.06-4.39-3.44-8-7.83-8.06c-4.39-0.06-8,3.44-8.06,7.83c-0.06,4.39,3.44,8,7.83,8.06
		C248.66,341.42,252.27,337.92,252.34,333.53z"/>
</g>
</svg>
