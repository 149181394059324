<div class="flex flex-wrap">
    <app-base-static-card class="w-full md:w-128 mx-auto m-3">
        <ng-container slot="body">
            <div class="flex flex-col flex-grow h-full">
                <div class="flex flex-grow flex-col text-center p-4" *ngIf="steps && steps[currentStep]">
                    <h2 class="my-2">Mic {{this.audioOnly ? '' : 'And Camera'}} Check</h2>

                    <span class="mt-2" [innerHtml]="steps[currentStep].text"></span>



                </div>
                <div class="flex items-end flex-grow p-4 justify-center">
                    <a href="https://presentr.zendesk.com/hc/en-us" target="_blank" class="text-sm hover:text-blue">
                        Having Issues? Go to our Help Center! </a>
                </div>
            </div>

        </ng-container>
    </app-base-static-card>
    <app-base-dynamic-card class="w-full md:w-128 mx-auto m-3">

        <ng-container slot="header">
            <div class="flex flex-col w-full p-4">

                <div class="flex flex-row justify-center mx-auto {{currentStep != 0 ? 'hidden' : ''}}">
                    <app-svg-component class="w-32 h-32 svg" type="icon_audio"></app-svg-component>
                    <app-svg-component class="w-32 h-32 svg" type="icon_video" *ngIf="!audioOnly"></app-svg-component>
                </div>

                <div class="flex flex-row justify-center w-full {{currentStep != 1 ? 'hidden' : ''}}">
                    <app-voice-check #voiceCheck [stream]="stream" (completed)="voiceCheckComplete()" class="w-full"></app-voice-check>
                </div>

                <div class="flex flex-row justify-center mx-auto {{currentStep != 2 ? 'hidden' : ''}}"
                     *ngIf="!audioOnly">
                    <app-skeletal-check #skeletalCheck [stream]="stream"
                                        (completed)="skeletalCheckComplete()"></app-skeletal-check>
                </div>

                <div class='flex flex-row justify-center mx-auto' *ngIf="currentStep == steps.length - 1">
                    <app-svg-component class="w-32 h-32 svg" type="icon_audio"></app-svg-component>
                    <app-svg-component class="w-32 h-32 svg" type="icon_video" *ngIf="!audioOnly"></app-svg-component>
                </div>
            </div>
        </ng-container>

        <ng-container slot="body">
            <div class="flex flex-col flex-grow justify-end text-center p-4 h-32">
                <span *ngIf="allowNext() && currentStep < steps.length - 1" class="h-full mx-auto text-blue text-lg">Ready To Continue</span>
                <span *ngIf="currentStep == steps.length - 1"
                      class="h-full mx-auto text-blue text-lg">Check Complete</span>

<!--                <a href="https://presentr.zendesk.com/hc/en-us" target="_blank" class="text-xs text-blue"> Having-->
<!--                    Issues? Go to our Help Center! </a>-->
                <app-dot-progress-bar class="mt-1" [count]="steps.length"
                                      [current]="currentStep"></app-dot-progress-bar>
            </div>
        </ng-container>

        <ng-container slot="overlay">
            <div class="flex flex-col pb-4 text-center w-full justify-end">
                <div class="flex-1" *ngIf="currentStep < steps.length - 1">
                    <button class="button-text h-full w-full" *ngIf="allowNext()" (click)="nextStep()">Continue To Next
                        Step
                    </button>
                    <div class="text-white h-full w-full items-center justify-center flex" *ngIf="!allowNext()">
                        <span>Complete the Step to move continue</span>
                    </div>
                </div>
                <button
                    *ngIf="watsonReady && volumeReady && (audioOnly || skeletalReady) && currentStep == steps.length - 1"
                    class="flex-1 button-text"
                    (click)="presentClicked()"> Continue to your Recording
                </button>
<!--                <div class="flex w-full h-px bg-white"></div>-->
                <div class="flex flex-col justify-end text-center">
                    <app-dot-progress-bar [count]="steps.length" [current]="currentStep" [invertedColor]="true"
                                          class="mt-1"></app-dot-progress-bar>
                    <!--        <span class="w-3 h-3 bg-dark-gray inline-block m-1 rounded-full {{i == currentIndex ? 'active-white' :''}}" *ngFor="let ac of getArrayCount(); let i = index;" ></span>-->
                </div>
            </div>

        </ng-container>
    </app-base-dynamic-card>
</div>

