
<app-popup-base>
    <ng-container>

        <div class="modal-body container">
            <div class="p-4 min-w-1/4 text-center flex flex-col" *ngIf="helpPageKey && textCached">
                <img src="../../../../assets/audio/scores/question@2x.png" class="w-32 h-32 mx-auto">
                <h2 class="text-blue">{{textCached[helpPageKey+"title"]}}</h2>
                <p [innerHtml]="textCached[helpPageKey+'description']"></p>
            </div>
        </div>

    </ng-container>
</app-popup-base>
