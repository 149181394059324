<app-popup-base>
    <ng-container>
        <div class="modal-body flex justify-center">
            <div class="p-4 text-center">
                <div class="p-4 min-w-1/2" *ngIf="data">
                    <div *ngIf="!data.is_video">
                        <img class="w-1/2 h-auto mx-auto" src="{{data.large_image ? data.large_image : (data.small_image ? data.large_image : '') }}"/>
                    </div>
                    <div *ngIf="data.is_video && data.video">
                        <video class="w-full h-auto" controls>
                            <source src="{{data.video}}" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <h2 class="text-blue">{{data.title}}</h2>
                    <div *ngIf="data.html_description" [innerHTML]="data.html_description | noSanitize" class="text-justify-center"></div>
                    <div *ngIf="!data.html_description && data.description" [innerText]="data.description" class="text-justify-center"></div>
                </div>
            </div>
        </div>
    </ng-container>
</app-popup-base>
