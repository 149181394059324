<div *ngIf="showSystemNotification" class="l">
    <div class="row" *ngFor="let notification of systemStaticNotifications">
        <div class="col-md-12" [ngClass]="(notification.alertType == 'critical') ? 'alert-danger':'alert-primary'">
            <div class="alert" role="alert">
                <span *ngIf="notification.icon" [ngClass]="'fa ' + notification.icon">&nbsp;</span>
                {{notification.message}}
            </div>
        </div>
    </div>
</div>

<ng-template #userNotificationPopup>
    <div class="flex flex-col flex-grow p-4 vw-50">
        <div *ngFor="let notification of customerNotifications">
            <h2 class="text-blue">{{notification.title}}</h2>
            <p>{{notification.message}}</p>
        </div>
    </div>
</ng-template>
<ng-template #systemNotificationPopup>
    <div class="row">
        <div *ngIf="systemPopupNotification.image" class="col-md-6">
            <img src="../../../assets/home/{{systemPopupNotification.image}}" class="img-inline" />
        </div>
        <div class="col-md-6 notification-text">

            <h2 class="text-blue">{{systemPopupNotification.title}}</h2>
            <p>{{systemPopupNotification.message}}</p>

            <div class="never-show">
                <label for="neverShowAgain" *ngIf="!neverShowClicked">
                    <input type="checkbox" (click)="removePopup($event, systemPopupNotification.title)" id="neverShowAgain" />
                    &nbsp;<span>Do not show again</span>
                </label>
                <label *ngIf="neverShowClicked">(this message will not be displayed again)</label>
            </div>
        </div>
    </div>
</ng-template>