import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Router } from "@angular/router";
import { EnvironmentAPIs } from '../../enums/environment-apis';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CoachTaskService {
    constructor(private apiService:ApiService) { }
    
    getTasks(overrideUserId: number){
        const overrideQuery =
        overrideUserId != 0 && overrideUserId != null
          ? "?userId=" + overrideUserId
          : "";

        return this.apiService.get('/tasks' + overrideQuery, EnvironmentAPIs.BBQ).pipe( map(
            (response) => {
	            return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    removeTask(task: any) {
        return this.apiService.post('/tasks/remove', task, EnvironmentAPIs.BBQ).pipe( map(
            (response) => {
	            return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

}
