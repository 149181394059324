<app-popup-base>
    <ng-container>
        <div class="feedback_container">

            <div class="rating_div">

                <h4 class="title_feedback">How would you rate your coach experience?</h4>

                <ngb-rating [(rate)]="starRating" max="5" class="outline-none">
                    <ng-template let-fill="fill" let-index="index">
                        <span class="star" [class.filled]="fill === 100">&#9733;</span>
                    </ng-template>
                </ngb-rating>

            </div>

            <div *ngIf="starRating" class="qa_div">

                <h4 class="title_feedback">What Could Be Better?</h4>

                <div class="question flex flex-col justify-center">
                    <a class="button-blue m-1" (click)="couldBeBetter($event, 'Navigation Experience')">Navigation Experience</a>
                    <a class="button-blue m-1" (click)="couldBeBetter($event, 'Overall Experience')">Overall Experience</a>

                    <a class="button-blue m-1" (click)="couldBeBetter($event, 'Look & Feel')">Look & Feel</a>

                    <a class="button-blue m-1" (click)="couldBeBetter($event, 'Response Time')">Response Time</a>
                    <a class="button-blue m-1" (click)="couldBeBetter($event, 'Information Availability')">Information Availability</a>
                    <a class="button-blue m-1" (click)="couldBeBetter($event, 'Other')">Other</a>
                </div>

            </div>

            <div *ngIf="starRating" class="comment_div">

                <h4 class="title_feedback">Comments / Suggestions?</h4>

                <textarea [(ngModel)]="message" rows="4"></textarea>

            </div>

            <div *ngIf="starRating" class="submit_btn">
                <a class="btn btn-primary btn-lg pill" (click)="sendFeedback()">Submit</a>
            </div>

        </div>
    </ng-container>
</app-popup-base>
