import {Component, Input, OnInit} from '@angular/core';

@Component({
  host: {
    class:'flex'
  },

  selector: 'app-base-bsstatic-card',
  templateUrl: './base-bsstatic-card.component.html',
  styleUrls: ['./base-bsstatic-card.component.scss']
})
export class BaseBsStaticCardComponent implements OnInit {

    @Input() bStyle: string;

    constructor() { }

  ngOnInit() {
  }

}
