import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Router } from "@angular/router";
import { EnvironmentAPIs } from '../../enums/environment-apis';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class CoachScheduleService {
    constructor(private apiService: ApiService) { }
    
    getSchedule(overrideUserId: number) {
        const overrideQuery =
        overrideUserId != 0 && overrideUserId != null
          ? "?userId=" + overrideUserId
          : "";
  
        return this.apiService.get('/schedule' + overrideQuery, EnvironmentAPIs.BBQ).pipe( map(
            (response) => {
	            return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }

    removeSchedule(schedule: any) {
        return this.apiService.post('/schedule/remove', schedule, EnvironmentAPIs.BBQ).pipe( map(
            (response) => {
	            return JSON.parse(JSON.stringify(response));
            }
        )
        )
    }
}
