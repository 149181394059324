import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LearnCardData} from '../../../classes/models/learn-card-data';
import {LearnModalComponent} from '../../../core/popup-manager/learn-modal/learn-modal.component';
import {Router} from '@angular/router';
import {PopupNotificationService} from '../../../classes/services/core/popup-notification.service';
import {ContentService} from '../../../classes/services';

@Component({
    selector: 'app-learn-card',
    templateUrl: './learn-card.component.html',
    styleUrls: ['./learn-card.component.scss']
})
export class LearnCardComponent implements OnInit {
    @Input() data: LearnCardData;
    topicOptions = [];

    constructor(
        private popupService: PopupNotificationService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.topicOptions = ContentService.topicOptions.filter(x => {
            // console.log(this.data.data.tip_type_ids, x.value);
            return this.data && this.data.data && this.data.data.tip_type_ids && this.data.data.tip_type_ids.indexOf(x.value) >= 0;
        });
    }


    routerLinkClicked() {
        this.router.navigate([this.data.button_settings.router_link]);
    }

    openInModal() {
        if (this.data && this.data.data && this.data.data.id) {
            this.popupService.openPopup(LearnModalComponent, this.data);
        }
    }
}
