<section class="flex flex-wrap">
    <div class="w-full">
        <app-top-bar></app-top-bar>
    </div>
    <!--Side-Menu-removed-->
    <!-- <div class="w-full md:w-64" id="side-menu">
        <app-side-menu></app-side-menu>
    </div> -->
    <div id="app-layout" class="flex-1 flex-1 p-2 pb-8 {{('CanSeeCoach' | hasPermission) ? 'pb-24' : ''}}">
        <router-outlet></router-outlet>
    </div>

    <app-loading></app-loading>
</section>
