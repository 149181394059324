import {NgModule} from '@angular/core';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {LoadingComponent} from '../core/loading/loading.component';
import {AppLayoutComponent} from './app-layout/app-layout.component';
import {AppLayoutWithCoachComponent} from './app-layout-with-coach/app-coach-layout.component';
import {AppLayoutWithBSCoachComponent} from './app-layout-with-bscoach/app-bscoach-layout.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {CoachModule} from '../coach/coach.module';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import { SideMenuComponent } from './side-menu/side-menu.component';
import {CoreModule} from '../core/core.module';
import {CorePipesModule} from '../core/core-pipes.module';
import {UiCoreModule} from '../core/ui/ui-core.module';
import { TopBarComponent } from './top-bar/top-bar.component';
import { AppLayoutBaseComponent } from './app-layout-base/app-layout-base.component';
import { FreeTrialBadgeComponent } from './free-trial-badge/free-trial-badge.component';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    RouterModule,
    CoachModule,
    NgbDropdownModule,
    CorePipesModule,
    UiCoreModule
  ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        AppLayoutComponent,
        AppLayoutWithCoachComponent,
        AppLayoutWithBSCoachComponent,
        SideMenuComponent,
        TopBarComponent,
        AppLayoutBaseComponent,
        FreeTrialBadgeComponent
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        LoadingComponent,
        AppLayoutComponent,
        AppLayoutWithCoachComponent,
        AppLayoutWithBSCoachComponent
    ]
})

export class LayoutsModule {
}
