<div class="relative flex flex-col w-full justify-between t card-bg border border-gray">
  <div class="flex flex-col w-100 t">
    <ng-content select="[slot='header']"></ng-content>
  </div>

  <div class="w-100 h-full t">
    <ng-content select="[slot='body']"></ng-content>
  </div>
</div>

