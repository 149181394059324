import {Injectable} from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import {AuthService} from '../services/data/auth.service';

@Injectable()
export class HasPermissionGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const requiredPermissions = route.data['permissions'] as Array<string>;
        const userPermissions = this.authService.getPermissions();

        let hasPermission = true;
        if (requiredPermissions && requiredPermissions.length > 0) {
            requiredPermissions.forEach(permission => {
                if (!userPermissions.find(x => x === permission)) {
                   console.log(permission)
                    hasPermission = false;
                }
            });
        }
        if(!hasPermission)
            this.router.navigate(["/auth/no-access"]);
        return hasPermission;
    }
}
