import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TextStoreService } from '../../../../classes/services/data/text-store.service';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {LearnCardData} from '../../../../classes/models/learn-card-data';

@Component({
  selector: 'app-coach-present-card',
  templateUrl: './coach-present-card.component.html',
  styleUrls: ['./coach-present-card.component.scss']
})
export class CoachPresentCardComponent implements OnInit {

  constructor(private textStoreService: TextStoreService) {}

    data : LearnCardData = new LearnCardData();
    ngOnInit() {
      this.data.stretch_image = true;
      this.data.title = 'Present';
      this.data.description = '';
      this.data.button_settings = {label:'Click Here To Record', router_link:'/app/record/present'};
      this.data.small_image = '../../../../assets/audio/present/microphone@2x.png';

    }

}
