import {AudioScoreProcessorResult} from './audio-score-processor-result';
import {DebugConsole} from '../helpers/debug-console';
import {isDevMode} from '@angular/core';
import {VideoScoreProcessorResult} from './video-score-processor-result';

export enum ResultType {
    kinect,
    mobile,
    android,
    ios,
    web
}

export class ResultTypeHelper {
    public static TransformForServerTypes(resultTypes: ResultType[]): string[] {
        let arr = [];
        resultTypes.map(x => {
            if (x == ResultType.mobile) {
                arr.push(ResultType[ResultType.ios]);
                arr.push(ResultType[ResultType.android]);
            }
            else {
                arr.push(ResultType[x]);
            }
        });
        return arr;
    }
}

export class Result {
    overall_score: number;
    result_type: string;
    time: number;
    result_position: string;
    audio_path: string;
    video_path: string;
    transcript: string;
    word_data: any;
    voice_overall_score: number;
    pace_score: number;
    volume_score: number;
    filler_word_score: number;
    power_word_score: number;
    filler_power_word_score: number;
    result_mode: string;

    prepApiResult(audioResult: AudioScoreProcessorResult, videoResult: VideoScoreProcessorResult, filePath: string, time: number = null, position = ''): any {
        let r: any = this;

        if(!r.result_type) r.result_type = 'web';
        if(!r.result_position) r.result_position = position.toLowerCase();

        if (audioResult) {
            //r.overall_score = audioResult.overall_score;
            // r.result_type = 'web';

            r.voice_overall_score = audioResult.overall_score;
            r.pace_score = audioResult.pace_score;
            r.volume_score = audioResult.volume_score;
            r.filler_word_score = audioResult.filler_word_score;
            r.power_word_score = audioResult.power_word_score;
            r.filler_power_word_score = audioResult.filler_power_word_score;
            r.audio_path = filePath;
            r.transcript = audioResult.transcript;
            if(!r.result_mode) r.result_mode = 'recording';


            DebugConsole.log('result', audioResult.fillerWords);
            DebugConsole.log(audioResult.powerWords);

            // add filler and power words
            for (let w in audioResult.fillerWords) {
                if (isNaN(audioResult.fillerWords[w])) {
                    if (isDevMode())
                        alert('filler word ' + w + 'is NaN');
                    DebugConsole.log(w, audioResult.fillerWords[w], audioResult.fillerWords);
                    //spResult.fillerWords[w] = 0;
                }
                else {
                    r['filler~' + w] = audioResult.fillerWords[w];
                }
            }
            for (let w in audioResult.powerWords) {
                if (isNaN(audioResult.powerWords[w])) {
                    if (isDevMode())
                        alert('power word ' + w + 'is NaN');
                    DebugConsole.log('power', w, audioResult.powerWords[w], audioResult.powerWords);
                    //spResult.fillerWords[w] = 0;
                }
                else {
                    r['power~' + w] = audioResult.powerWords[w];
                }
            }
            // add all the words
            for (let w in audioResult.words) {
                if (!isNaN(audioResult.words[w]))
                    r['word~' + w] = audioResult.words[w];
            }

            r['voice_detail~sweet_zone_score'] = audioResult.sweet_zone;
            r['voice_detail~avg_volume'] = audioResult.avg_volume;
            r['voice_detail~words_per_minute'] = audioResult.wpm;

        }

        if (videoResult) {
            r.audio_path = null;
            r.video_path = filePath;

            r.gesture_overall_score = videoResult.gesture_overall_score;
            r.posture_overall_score = videoResult.posture_overall_score;

            r.posture_score_details = videoResult.posture_score_details;
            r.gesture_score_details = videoResult.gesture_score_details;

            // r.result_mode = 'video-beta';
        }

        // if audio and video are valid, make sure to set the overall score to take half of each
        // else we will go with the audio result's overall
        if (audioResult && videoResult) {
            r.overall_score = Math.min(100, Math.round(audioResult.overall_score * 0.5 + videoResult.overall_score * 0.5));
            r.time = audioResult.time;
        }
        else if (audioResult) {
            r.overall_score = audioResult.overall_score;
            r.time = audioResult.time;
        }
        else if (videoResult) {
            r.overall_score = videoResult.overall_score;
            r.time = videoResult.time;
        }
        else {
            r.overall_score = 0;
        }

        if(time){
            r.time = time;
        }


        return r;
    }
}
