import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { PopupNotificationComponentRef, PopupNotificationType } from '../../models/popup-notification';
import { AuthService } from '..';
import { SettingsService } from './settings.service';

@Injectable()
export class PopupNotificationService {
    static welcomeMessageShownSettings = 'web-welcome-message-shown';
    static NotificationMessageShownSettings = 'web-notification-ids-shown';

    static WELCOME_MESSAGE_CLOSED = 'WELCOME_MESSAGE_CLOSED';

    static coachShownSettings = 'web-coach-shown-count';
    static COACH_CLOSED_AFTER_FIRST_VIEW = 'COACH_CLOSED_AFTER_FIRST_VIEW';

    emitterGeneric: EventEmitter<PopupNotificationComponentRef> = new EventEmitter<PopupNotificationComponentRef>();
    emitter: EventEmitter<PopupNotificationType> = new EventEmitter<PopupNotificationType>();

    constructor(private authService: AuthService,
        private settingsService: SettingsService) {
    }

    openHelpKeyPopup(key, callback = null) {
      this.openPopupByType('help', key, callback);
    }
    openSuccessPopup(message, callback = null) {
        this.openPopupByType('success', message, callback);
    }
    openFailurePopup(message, callback = null) {
        this.openPopupByType('failure', message, callback);
    }
    openPopup(component, payload, callback = null) {
        this.openPopupComponentRef(component, payload, callback);
    }

    private openPopupComponentRef(component, payload, callback) {
        this.emitterGeneric.emit({ component: component, payload:payload, callback: callback });
    }
    private openPopupByType(type, message, callback) {
        this.emitter.emit({ data: message, callback: callback, type: type });
    }

    openStartupPopups() {
        //this.openWelcomeMessagePopup(null);
    }

    private openWelcomeMessagePopup(callback = null) {
        let user = this.authService.getLoggedInUser();
        if (user) {
            this.settingsService.getSettingForUser(user.id, 'web-welcome-message-shown').subscribe(resp => {
                if (!resp) {
                    this.openPopupByType('welcome', null, callback);
                    this.settingsService.setSettingForUser(user.id, 'web-welcome-message-shown', true);
                }
                else if (callback) {
                    callback();
                }
            });
        }
        else if (callback) {
            callback();
        }
    }

    closeActivePopup() {
    }
}
