import {Blog} from './blog';
import {VideoTip} from './video-tip';

export class LearnCardData{

    public data:any;

    public small_image : string;
    public large_image : string;
    public title: string;
    public description: string;
    public html_description:string;
    public short_description: string;
    public button_settings:any = {
        label : '',
        router_link:''
    };
    public is_video :boolean;
    public video: string;
    public stretch_image: boolean;

    public initWithBlog(blog :Blog){
        this.data = blog;

        this.small_image = blog.small_image;
        this.large_image = blog.large_image;
        this.title = blog.name;
        this.stretch_image = true;
        this.button_settings = {
            router_link : '',
            label: 'Read More'
        };
        this.description = blog.description;
        this.html_description = blog.html_description;
        this.short_description = blog.short_description;

    }
    public initWithVideoTip(videoTip :VideoTip){
        this.data = videoTip;

        this.small_image = videoTip.small_image;
        this.video = videoTip.video;
        this.is_video = true;
        this.title = videoTip.question;
        this.stretch_image = true;
        this.button_settings = {
            router_link : '',
            label: 'Watch'
        };
        this.description = videoTip.description;
        this.html_description = videoTip.html_description;
        this.short_description = videoTip.short_description;
    }
}
