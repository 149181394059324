import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';


export class ITabOption {
    label: string;
    value: any;
    class: string;

    constructor(label, val) {
        this.label = label;
        this.value = val;
    }
}

@Component({
    selector: 'app-tab-set',
    templateUrl: './tab-set.component.html',
    styleUrls: ['./tab-set.component.scss']
})
export class TabSetComponent implements OnInit {

    public active: any;
    @Input() options: ITabOption[] = [];
    @Input() pillMode: boolean = false;
    @Output() changed: EventEmitter<any> = new EventEmitter<any>();
    @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() reClicked: EventEmitter<any> = new EventEmitter<any>();

    constructor(private router: Router) {
    }

    private findActiveTab(){
        let currRoute = this.router.url;
        this.options.forEach((x) => {
            if(currRoute.startsWith(x.value)){
                this.active = x.value;
            }
        });

        if (!this.active && this.options.length > 0) {
            this.active = this.options[0].value;
        }
    }

    ngOnInit() {
        this.findActiveTab();
    }

    valueChanged($event) {
        this.changed.emit($event);
    }

    buttonClicked($event) {
        this.clicked.emit($event.target.value);
        if (this.active == $event.target.value) {
            this.reClicked.emit($event.target.value);
        }
    }
}
