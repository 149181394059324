<section id="main">
    <div class="container-fluid">
        <div class="row h-100">
            <div class="col nav-main">
                <app-header></app-header>
            </div>
            <div class="col shading">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <app-footer></app-footer>
</div>

<app-popup-manager></app-popup-manager>
<app-tutorial-manager></app-tutorial-manager>
