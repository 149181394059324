import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {environment} from "../../../environments/environment";
import { AuthService } from '../services';

@Injectable()
export class ContentSignedAuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.getToken()) {
            window.open(environment.ketchupUrl + decodeURI(state.url) + '&token=' + this.authService.getToken() , '_self');

            return true;
        }

        // not logged in so redirect to login page with the return url
        // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url, id: route.queryParams.id, type: route.queryParams.type }});
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});

        return false;
    }
}
