import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {SkeletalProviderService} from '../../../classes/services/core/skeletal-provider.service';
import {JOINT_KEY_POINT_ENUM} from '../../../classes/enums/joint-key-point.enum';
import {VideoScoreProcessorPoseResult} from '../../../classes/models/video-score-processor-pose-result';
import {MediaTestStep} from '../media-test.component';
import {DebugConsole} from '../../../classes/helpers/debug-console';
import {MediaStreamService} from '../../../classes/services';

@Component({
    selector: 'app-skeletal-check',
    templateUrl: './skeletal-check.component.html',
    styleUrls: ['./skeletal-check.component.scss']
})
export class SkeletalCheckComponent implements OnInit, MediaTestStep, OnDestroy {

    @Input() stream: MediaStream;

    readonly videoResolution = MediaStreamService.videoDimensions;
    success: boolean = false;
    @Output('completed') completed: EventEmitter<any> = new EventEmitter<any>();
    lastKeyPoints:any;
    videoHandle:any;


    constructor(private ngCh: ChangeDetectorRef, private skeletalService: SkeletalProviderService) {
    }

    ngOnInit() {
        this.skeletalService.subscribeForNotification(this, (scope, notification) => scope.skeletalReceived(notification.data));
    }

    ngOnDestroy() {
        this.finish();
    }


    skeletalReceived(reading){
        switch (reading.event){
            case 'recognized':
                // alert("Skeletal Ready");
                this.lastKeyPoints = reading.data.keypoints;

                if(reading.data){
                    let minScore = SkeletalProviderService.MIN_POSE_CONFIDENCE;
                    if(reading.data.keypoints[JOINT_KEY_POINT_ENUM.leftShoulder].score > minScore &&
                        reading.data.keypoints[JOINT_KEY_POINT_ENUM.rightShoulder].score > minScore
                        // &&
                        // reading.data.keypoints[JOINT_KEY_POINT_ENUM.leftWrist].score > minScore &&
                        // reading.data.keypoints[JOINT_KEY_POINT_ENUM.rightWrist].score > minScore
                    ){
                        // this.skeletalReady = true; // facing audience
                        this.completed.emit(true);
                        this.success = true;
                        // this.drawSkeleton();
                    }
                }

                break;
        }
    }

    drawSkeleton() {
        let video = <HTMLVideoElement>document.getElementById('video');
        let canvas = <HTMLCanvasElement>document.getElementById('canvasCheck');

        if(!canvas){ return; }
        let ctx = canvas.getContext('2d');


        ctx.drawImage(video, 0, 0, MediaStreamService.videoDimensions.width, MediaStreamService.videoDimensions.height);

        if(this.lastKeyPoints){
            SkeletalProviderService.drawSkeleton(ctx, this.lastKeyPoints );
        }
    }


    private startVideoElement(stream) {
        let video = <HTMLVideoElement>document.getElementById('video');
        if (stream && video) {

            video.onloadedmetadata = (event) => {
                video.play();
                video.muted = true;
            };
            video.srcObject = this.stream;
        }
        this.videoHandle = setInterval(() => {
            this.drawSkeleton();
        }, 0);

        let canvasElement = <HTMLCanvasElement>document.getElementById('canvas');
        return canvasElement ? canvasElement : null;
    }

    start(){
        let canvasElement = this.startVideoElement(this.stream);
        this.skeletalService.initializeSkeletalProvider();
        this.skeletalService.startSkeletalProvider(this.stream, canvasElement);
    }

    finish(){
        clearInterval(this.videoHandle);
        this.skeletalService.stopSkeletalProcess();
        this.skeletalService.unsubscribeNotification(this);
    }
}
