import {Component, OnInit, AfterViewInit, Input, OnDestroy} from '@angular/core';
import {
  ApiService,
  EventSubscriptionService
} from "../../../classes/services";
import { CoachChatService } from "../../../classes/services/data/coach-chat.service";
import { NotificationEvent } from "../../../classes/models";
import { TextStoreService } from "../../../classes/services/data/text-store.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CoachChatFeedbackPopupComponent } from "./coach-chat-feedback-popup/coach-chat-feedback-popup.component";
import {CoachService} from '../../../classes/services/core/coach.service';
import {bool} from 'aws-sdk/clients/signer';

@Component({
  selector: "app-coach-chat",
  templateUrl: "./coach-chat.component.html",
  styleUrls: ["./coach-chat.component.scss"]
})
export class CoachChatComponent implements OnInit, OnDestroy {

  hasReachedBeginningOfHistory: boolean = false;
  nextPageURL: any;
  currentPage: number = 0;

  quickReplies: Array<any> = [];
  chatHistory: Array<any> = [];

  isQuickRepliesHidden: Boolean = false;
  isChatReplyHidden: Boolean = true;

  shouldScrollToBottom: Boolean = false;
  botIsTyping: Boolean = false;
  botIsTypingInterval: any;

  isReadOnly = false;
  private _overrideUserId: number;

  @Input() set overrideUserId(value: number) {
    this._overrideUserId = value;
    this.isReadOnly = value !== 0 && value !== null;
  }

  get overrideUserId(): number {
    return this._overrideUserId;
  }

  constructor(
    private coachChatService: CoachChatService,
    private coachService: CoachService,
    private notificationService: EventSubscriptionService,
    private textStoreService: TextStoreService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.chatHistory = [];

    this.loadMore();
    this.showReplyBox();
    this.notificationService.subscribeToNotificationEvent(
      "coach_chat_ws",
      this,
      this.handleChatWS
    );

    this.notificationService.subscribeToNotificationEvent(
      "send_coach_message",
      this,
      this.handleSendMessage
    );
  }

  get userName(){
      return this.coachChatService.getUserName(this.overrideUserId);
  }
  ngOnDestroy(): void {
    this.notificationService.unsubscribeToNotificationEvent('coach_chat_ws', this);
    this.notificationService.unsubscribeToNotificationEvent('send_coach_message', this);
  }
  //================================================================================
  // UI Functions
  //================================================================================

  startBotTyping(timeout: number, untilNextMessage: boolean = false) {
    this.stopBotTyping(); // fixes a bug where the bot would type really fast
    var parent = this;
    if (!untilNextMessage) {
      setTimeout(function() {
        parent.stopBotTyping();
      }, timeout);
    }

    this.botIsTyping = true;
    this.botIsTypingInterval = window.setInterval(function() {
      var wait = document.getElementById("dotdotdot");
      if (wait != null) {
        if (wait.innerHTML.length > 3) {
          wait.innerHTML = "";
        } else {
          wait.innerHTML += ".";
        }
      } else {
        parent.stopBotTyping();
      }
    }, 750);
  }

  stopBotTyping() {
    this.botIsTyping = false;
    clearInterval(this.botIsTypingInterval);
  }

  scrollToBottom(): any {
    let el = document.getElementById("chat-history-bottom");
    if (el) {
      el.scrollIntoView(true);
      window.scrollBy(0, -10); // Adjust scrolling with a negative value here

      // el.scrollIntoView();
    }
  }

  onDomChange(): void {
    if (this.shouldScrollToBottom) {
      this.shouldScrollToBottom = false;
      setTimeout(this.scrollToBottom(), 1500);
    }
  }


  showQuickReply() {
    this.isQuickRepliesHidden = false;
  }

  hideQuickReply() {
    this.isQuickRepliesHidden = true;
  }

  showReplyBox() {
    this.isChatReplyHidden = false;
  }

  hideReplyBox() {
    this.isChatReplyHidden = true;
  }

  sendFeedback(id: number) {
    const feedbackModal = this.modalService.open(CoachChatFeedbackPopupComponent, {
      size: "lg",
      backdropClass:
        "modal-custom-backdrop modal-custom-backdrop-overlay-coach",
      windowClass: "modal-window-class modal-window-class-overlay-coach",
      centered: true
    });
    feedbackModal.componentInstance.id = id;
  }

  //================================================================================
  // Chat content functions
  //================================================================================

  handleLoadMore() {
    if (this.hasReachedBeginningOfHistory) {
      this.chatHistory.unshift({
        type: "system",
        message: "You've reached the begining of your chat history!"
      });
    }
  }

  sortAndRemoveDuplicates() {
    this.chatHistory.sort(function(a, b) {
      return a.id - b.id;
    });


    const tempHistory = [];
    this.chatHistory.map(x => tempHistory.filter(a => a.id == x.id).length > 0 ? null : tempHistory.push(x));

    this.chatHistory = tempHistory;

  }

  loadMore() {
    this.hasReachedBeginningOfHistory = true; // Set this to true to hide the load more content button
    const scope = this;
    this.coachChatService
      .getChats((this.currentPage), this.overrideUserId)
      .subscribe(response => {
        if (response.success) {
          this.chatHistory.unshift.apply(
            this.chatHistory,
            response.response.data.reverse()
          );
          scope.sortAndRemoveDuplicates();
          if (this.currentPage <= 1) {

            this.shouldScrollToBottom = true;
            setTimeout(function() {
              scope.scrollToBottom();
            }, 300);

            // Only do this for the first page because if they're loading more pages it means they are at the top clicking load more

            const parsedData = this.chatHistory[this.chatHistory.length - 1];

            if (parsedData != null && parsedData.buttons != null) {
              this.quickReplies = [];
              this.quickReplies = JSON.parse(parsedData.buttons);
            }
          }

            // The api will return the breakpoint content on a request for page zero.
              // - Breakpoint is found: request page 1
              // - Breakpoint is not found: change the page and request page 2

          if (this.currentPage == 0 && response.response.data.length < 5) {
            this.nextPageURL = response.response.first_page_url;
            this.currentPage = 1;
          } else {
            this.nextPageURL = response.response.next_page_url;
            this.currentPage = response.response.current_page + 1;
          }

          this.hasReachedBeginningOfHistory =
            response.response.next_page_url == null;
          this.handleLoadMore();
        }
      });
  }

  sendMessageButton() {
    const input: HTMLInputElement = document.getElementById(
      "message"
    ) as HTMLInputElement;

    if (input.value !== "") {
      this.sendMessage(input.value);
      input.value = "";
    }
  }

  sendMessage(message: String) {
    this.quickReplies = []; // Remove quick reply options and wait for the bot to reply with more
    const input: HTMLInputElement = document.getElementById(
      "message"
    ) as HTMLInputElement;
    input.value = "";

    this.coachChatService.sendMessage(message).subscribe(response => {
      if (response.success) {
        // We get the message bak from Mustard here. Do not add it directly to the stack
        this.shouldScrollToBottom = true;
      }
    });
  }

  markAllRead() {
    this.coachChatService.markAllRead().subscribe(response => {});
  }

  handleSendMessage(scope, data: any) {
    scope.sendMessage(data.data);
  }

  isMessageHintOrTip(message: String): Boolean {
    return message.includes("Hint: ") || message.includes("Tip: ");
  }

  //================================================================================
  // Web socket functions
  //================================================================================

  handleChatWS(scope, data: any) {
    switch (data.data.type) {
      case "typing-indicator":
        scope.startBotTyping(data.data.timeout, data.data.waitUntilNextMessage);
        setTimeout(function() {
          scope.scrollToBottom();
        }, 250);

        break;
      case "reload":
        // delay here to make sure the database has been updated
        setTimeout(function() {
          setTimeout(function() {
            scope.scrollToBottom();
          }, 250);
          scope.shouldScrollToBottom = true;
          scope.quickReplies = [];
          scope.currentPage = 0;
          scope.chatHistory = [];
          scope.loadMore();
        }, 1500);

        break;
      case "buttons":
        setTimeout(function() {
          scope.quickReplies = [];
          scope.quickReplies = data.data.buttons;


          setTimeout(function() {
            scope.scrollToBottom();
          }, 250);
          scope.shouldScrollToBottom = true;

        }, data.data.settings.delay);
        break;
      case "message":
        setTimeout(function() {
          setTimeout(function() {
            scope.scrollToBottom();
          }, 250);
          scope.shouldScrollToBottom = true;
          scope.quickReplies = [];

          if (data.data.message.type == "breakpoint") {
            scope.currentPage = 0;
            scope.hasReachedBeginningOfHistory = false;
            scope.chatHistory = scope.chatHistory.filter(function(element) {
              return element.id >= data.data.message.id;
            });
          } else {
            scope.stopBotTyping();
            scope.chatHistory.push(data.data.message);
            scope.sortAndRemoveDuplicates();
          }

          scope.markAllRead();

        }, data.data.settings.delay);
      default:
        break;
    }
  }
}
