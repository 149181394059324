<section class="container-fluid overflow-hidden">
    <div class="row mx-0">
    <div id="top-bar" class="w-full">
        <app-top-bar></app-top-bar>
    </div>

    <div class="w-full {{('CanSeeCoach' | hasPermission) ? 'pb-24' : ''}}">
        <router-outlet></router-outlet>
    </div>

    <app-loading></app-loading>
    </div>
</section>
