import {Injectable} from '@angular/core';
import {EventSubscriptionService} from './event-subscription.service';
import {NotificationEvent} from '../../models';
import {DebugConsole} from '../../helpers/debug-console';

import * as posenet from '@tensorflow-models/posenet';
import * as imagecapture from 'image-capture';
import Timer = NodeJS.Timer;
import {JOINT_KEY_POINT_ENUM} from '../../enums/joint-key-point.enum';
import {SettingsService} from './settings.service';
import {AuthService} from '../data/auth.service';


@Injectable()
export class SkeletalProviderService {
    static readonly NOTIFY_EVENT = 'SKELETON_RECEIVED_EVENT';

    static FRAME_CAPTURE_TIME_INTERVAL: number = 500;
    static POSE_NET_TIME_INTERVAL: number = 500;
    static readonly MIN_POSE_CONFIDENCE = .60;
    static readonly MAX_POSTURE_SHOULDER_SLOPE = .15;
    static readonly MIN_OVERALL_POSES_CONFIDENCE = .35;

    count = 0;
    videoStream: MediaStream;
    poseNet: posenet.PoseNet;
    imageCaptureDevice: imagecapture.ImageCapture;

    canvasElement: HTMLCanvasElement;

    frameCaptureId: Timer;
    poseNetId: Timer;

    constructor(private notificationService: EventSubscriptionService, private settingsService: SettingsService, private authService: AuthService) {
    }

    subscribeForNotification(scope, callback) {
        this.notificationService.subscribeToNotificationEvent(SkeletalProviderService.NOTIFY_EVENT, scope, callback);
    }

    unsubscribeNotification(scope) {
        this.notificationService.unsubscribeToNotificationEvent(SkeletalProviderService.NOTIFY_EVENT, scope);
    }

    private poseNetLoadSuccess(pNet: posenet.PoseNet) {
        this.poseNet = pNet;
        this.notificationService.sendNotificationEvent(new NotificationEvent(SkeletalProviderService.NOTIFY_EVENT, {event: 'loaded'}));
    }

    private startImageCaptureProcess(imageInterval:number) {

        this.imageCaptureDevice = new imagecapture.ImageCapture(this.videoStream.getVideoTracks()[0]);
        this.frameCaptureId = setInterval(() => {

            this.imageCaptureDevice.grabFrame().then((imageBitmap) => {
                this.canvasElement.width = imageBitmap.width;
                this.canvasElement.height = imageBitmap.height;

                if(!this.canvasElement){ return; }
                const ctx = this.canvasElement.getContext('2d');

                ctx.drawImage(imageBitmap, 0, 0);

            }).catch((err) => {
                console.error(err);
            });

        }, imageInterval ? imageInterval : SkeletalProviderService.FRAME_CAPTURE_TIME_INTERVAL);
    }

    private startPoseNetProcess(poseInterval:number) {
        this.poseNetId = setInterval(() => {
            if(this.poseNet){
                this.poseNet.estimateSinglePose(this.canvasElement).then((pose) => {

                    this.notificationService.sendNotificationEvent(new NotificationEvent(SkeletalProviderService.NOTIFY_EVENT, {
                        event: 'recognized',
                        data: pose
                    }));
                });
            }
        }, poseInterval ? poseInterval : SkeletalProviderService.POSE_NET_TIME_INTERVAL);
    }


    private poseNetLoadFailed(error) {
        console.error('poseNet Failed');
        this.notificationService.sendNotificationEvent(new NotificationEvent(SkeletalProviderService.NOTIFY_EVENT, {
            event: 'error',
            data: error
        }));
    }

    initializeSkeletalProvider() {
        this.settingsService.getSettingForUser(this.authService.getLoggedInUser().id, "web-skeletal-frames-per-second").subscribe((setting) => {
            if (setting) {
                SkeletalProviderService.POSE_NET_TIME_INTERVAL = parseInt(setting.description);
                SkeletalProviderService.FRAME_CAPTURE_TIME_INTERVAL = parseInt(setting.description);
            }
        });
        posenet.load().then((p) => this.poseNetLoadSuccess(p), (e) => this.poseNetLoadFailed(e));
    }

    startSkeletalProvider(stream, canvasElement,  imageInterval = null, poseInterval = null) {
        this.videoStream = stream;
        this.canvasElement = canvasElement ? canvasElement : document.createElement('canvas');
        this.canvasElement.addEventListener("webglcontextlost", function(event) {
            console.error(event);
            event.preventDefault();
        }, false);
        this.canvasElement.addEventListener(
            "webglcontextrestored", function(event) {
                console.error("WEB GL CONTEXT RESTORED", event);
            }, false);

        // Create an AudioNode from the stream.
        DebugConsole.log('start skeletal');
        this.startImageCaptureProcess(imageInterval);
        this.startPoseNetProcess(poseInterval);

    }

    stopSkeletalProcess() {
        if (this.poseNetId)
            clearInterval(this.poseNetId);

        if (this.frameCaptureId)
            clearInterval(this.frameCaptureId);
    }

    static drawSkeleton(context, keyPoints, shoulderColors = '#FFFFFF', leftArmColor = '#FFFF00', rightArmColor = '#FFFF00', keyPointColor = '#0081E6'){

        if(!keyPoints || keyPoints.length == 0)
            return;

        context.lineWidth = 5;

        context.strokeStyle = shoulderColors;
        SkeletalProviderService.drawLine(context, keyPoints,
            [JOINT_KEY_POINT_ENUM.rightShoulder, JOINT_KEY_POINT_ENUM.leftShoulder]);

        context.strokeStyle = rightArmColor;
        SkeletalProviderService.drawLine(context, keyPoints,
            [JOINT_KEY_POINT_ENUM.rightWrist, JOINT_KEY_POINT_ENUM.rightElbow, JOINT_KEY_POINT_ENUM.rightShoulder ]);

        context.strokeStyle = leftArmColor;
        SkeletalProviderService.drawLine(context, keyPoints,
            [JOINT_KEY_POINT_ENUM.leftWrist, JOINT_KEY_POINT_ENUM.leftElbow, JOINT_KEY_POINT_ENUM.leftShoulder ]);

        context.fillStyle = keyPointColor;
        SkeletalProviderService.drawPoint(context, keyPoints, JOINT_KEY_POINT_ENUM.leftShoulder);
        SkeletalProviderService.drawPoint(context, keyPoints, JOINT_KEY_POINT_ENUM.leftElbow);
        SkeletalProviderService.drawPoint(context, keyPoints, JOINT_KEY_POINT_ENUM.leftWrist);
        SkeletalProviderService.drawPoint(context, keyPoints, JOINT_KEY_POINT_ENUM.rightWrist);
        SkeletalProviderService.drawPoint(context, keyPoints, JOINT_KEY_POINT_ENUM.rightElbow);
        SkeletalProviderService.drawPoint(context, keyPoints, JOINT_KEY_POINT_ENUM.rightShoulder);
    }

    private static drawLine(context, keyPoints, keyPointEnumArr){

        if(keyPointEnumArr.length < 2){
            return;
        }
        for(let i = 0; i < keyPointEnumArr.length; i++){
            if (keyPoints[keyPointEnumArr[i]].score < SkeletalProviderService.MIN_POSE_CONFIDENCE) {
                return;
            }
        }

        context.beginPath();
        context.moveTo(keyPoints[keyPointEnumArr[0]].position.x, keyPoints[keyPointEnumArr[0]].position.y);

        for(let i = 1; i < keyPointEnumArr.length; i++){
            context.lineTo(keyPoints[keyPointEnumArr[i]].position.x, keyPoints[keyPointEnumArr[i]].position.y);
        }

        context.stroke();
    }

    private static drawPoint(context, keyPoints, keyPointEnum){
        if(keyPoints[keyPointEnum].score > SkeletalProviderService.MIN_POSE_CONFIDENCE){
            context.fillRect(keyPoints[keyPointEnum].position.x-5, keyPoints[keyPointEnum].position.y -5,10,10);
        }
    }
}
