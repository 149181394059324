import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../../environments/environment";

@Injectable()
export class UserNotificationService {
    
    constructor(private http: HttpClient) { }

    getAllNotificationsForUserId(customerId, userId): any {
        return this.http.get(environment.notificationsUrl + '/notifications/' + customerId + "?userId=" + userId);
    }

}
