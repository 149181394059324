<!--<div class="full-width detail-score-slider" *ngIf="sliderType == 'SINGLE'">-->
  <!--<div class="detail-score-slider score-slider scale-slider " id="slider">-->
    <!--<i class="score-slider-thumb"></i>-->
  <!--</div>-->

  <!--</div>-->

<!--<div class="full-width detail-score-slider" *ngIf="sliderType == 'BALANCE'">-->
  <!--<div  class="detail-score-slider score-slider balance-scale-slider" id="balance-slider">-->
    <!--<i id="thumb" class="score-slider-thumb"></i>-->
  <!--</div>-->
<!--</div>-->
<div class="relative flex h-24 pl-12 pr-8 items-center">
  <div class="slider-linear-background w-full h-4 border border-gray"></div>
  <div #thumb class="absolute top-0 h-24 w-24">
    <app-base-circle-score [percentage]="value" [fillColor]="fillColor"></app-base-circle-score>
  </div>

</div>



<!--<div class="under-text mt-2">-->
<!--  <table class="full-width center" style="table-layout: fixed">-->
<!--    <tr>-->
<!--      <td *ngIf="underText[0]" style="text-align: left">{{underText[0]}}</td>-->
<!--      <td *ngIf="underText[1]" style="text-align: center">{{underText[1]}}</td>-->
<!--      <td *ngIf="underText[2]" style="text-align: right">{{underText[2]}}</td>-->
<!--    </tr>-->
<!--  </table>-->
<!--</div>-->
