import {Injectable, isDevMode} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import * as DetectRTC from 'detectrtc';

@Injectable()
export class ChromeGuard implements CanActivate {

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (DetectRTC.browser.isChrome) {
            return true;
        }
        this.router.navigate(['/app/browser'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
