import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {MediaStreamService} from '../services/core/media-stream.service';

@Injectable()
export class MediaPermissionGuard implements CanActivate {

    constructor(private router: Router, private mediaRecorder: MediaStreamService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let permission = this.mediaRecorder.hasCameraPermissions() && this.mediaRecorder.hasMicPermissions();
        if (!permission) {
            this.router.navigate(['/app/app-settings/media-permission'], {queryParams: {returnUrl: state.url}});
        }

        return permission;
    }
}
