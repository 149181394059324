
export enum PingsPageEnum {
	Dashboard = 1,
	History,
	Learn,
	Blog,
	More,
	ManagePowerWords,
	ManageFillerWords,
	ManageProfile,
	ViewScoreboardTutorial,
	PickATopic,
	PrepareToRecord,
	Recording,
	AreYouHappy,
	QuestionPage,
	PlayGame,
	GameResults,
	IntroVolumeRedo,
	CompleteVolumeExercise,
	VideoTip,
	WordCountDates,
	WordCount,
	IBMProcessingError,
	RealTimePracticePrepare,
	GameIntro,
	GameCountdown,
	HelpScoreBox,
	HelpScoreVolume,
	HelpScorePace,
	HelpScoreFillerWords,
	HelpScoreFillerWordsBreakdown,
	HelpScorePowerWordsBreakdown,
	PausedRecording,
	ResumedRecording,
	CustomerCreate,
	CustomerAccountDetails,
	CustomerUsers,
	CustomerUsersCreate,
	CustomerGroups,
	CustomerGroupsAddEdit,
	CustomerPaymentMethods,
	CustomerPaymentMethodsAdd,
	CustomerInvoices,
	CustomerSubscriptions,
	CustomerSubscriptionsAdd
}



export enum PingsActionEnum {
	viewed = 1,
	edited,
	created,
	deleted
}

// private $pages = array(
// 	'Dashboard',
// 	'History',
// 	'Learn',
// 	'Blog',
// 	'More',
// 	'ManagePowerWords',
// 	'ManageFillerWords',
// 	'ManageProfile',
// 	'ViewScoreboardTutorial',
// 	'PickATopic',
// 	'PrepareToRecord',
// 	'Recording',
// 	'AreYouHappy',
// 	'QuestionPage',
// 	'PlayGame',
// 	'GameResults',
// 	'IntroVolumeRedo',
// 	'CompleteVolumeExercise',
// 	'VideoTip',
// 	'WordCountDates',
// 	'WordCount',
// 	'IBMProcessingError',
// 	'RealTimePracticePrepare',
// 	'GameIntro',
// 	'GameCountdown'
// );
//

// private $actions = array(
// 	'viewed',
// 	'edited',
// 	'created',
// 	'deleted'
// );