import {Injectable, isDevMode} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import * as DetectRTC from 'detectrtc';
import {BrowserRecommendedComponent} from '../../presentr-app/app-settings/browser/browser-recommended/browser-recommended.component';
import {SettingsService} from '../services/core/settings.service';

@Injectable()
export class ChromeRecommendedGuard implements CanActivate {

    constructor(private router: Router, private settingsService: SettingsService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (DetectRTC.browser.isChrome || this.settingsService.hasLocalSetting(BrowserRecommendedComponent.ALLOW_NON_RECOMMENDED_CHROME)) {
            this.settingsService.removeLocalSetting(BrowserRecommendedComponent.ALLOW_NON_RECOMMENDED_CHROME, false);
            return true;
        }

        if (DetectRTC.osName == 'iOS') {
            // for iOS it really doesnt matter if they are in chrome. the experience will not be great. so we are skipping the chrome recommended message
            return true;
        }

        this.router.navigate(['/app/browser/recommended'], {queryParams: {returnUrl: state.url}});
        return false;
    }
}
