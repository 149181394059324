<div class="container flex flex-col">
    <h2>{{title}}</h2>
    <app-base-card class="flex w-half mt-4">
        <ng-container slot="body">
            <div class="flex flex-col-reverse md:flex-row flex-grow p-4">
                <div class="flex flex-col w-full p-4">
                    <form class='mx-4' name="addEditTopicForm" id="addEditTopicForm" novalidate
                          (ngSubmit)="f.form.valid && saveChanges()" #f="ngForm">
                        <div class="mt-4" [ngClass]="{ 'border-error': f.submitted && !topic.valid }">
                            <label>Topic</label>
                            <input type="text" class="form-input w-full" name="topic"
                                   [(ngModel)]="model.topic" #topic="ngModel" required
                                   placeholder="Topic Title" />
                            <div *ngIf="f.submitted && !topic.valid" class="text-error">A valid topic title is required.</div>
                        </div>
                        <div class="mt-4">
                            <label>Enabled</label>
                            <select class="form-input ml-2" name="enabled" [(ngModel)]="model.enabled">
                                <option [ngValue]="0">No</option>
                                <option [ngValue]="1">Yes</option>
                            </select>
                        </div>

                        <h4 class="mt-4">Prompts</h4>

                        <div class="flex flex-row">
                            <app-base-list class="flex w-full px-4 py-2">

                                <ng-container *ngFor="let row of model.prompts; index as i" >
                                    <tr class="h-10">
                                        <td>{{ row.prompt }}</td>
                                        <td><a class="button-white" (click)="removePrompt(i)">remove</a></td>
                                    </tr>
                                </ng-container>
                            </app-base-list>
                        </div>

                        <div class="mt-4 flex flex-row">
                            <input type="text" class="form-input flex-1 mx-1" [(ngModel)]="addPrompt.prompt" placeholder="Prompt" [ngModelOptions]="{standalone: true}" />
                            <button  type="button" class="button-blue" (click)="submitPrompt()">Add Prompt</button>
                        </div>

                        <button type="submit" form="addEditTopicForm" class="button-blue mt-4">{{ model && model.id ? 'Save' : 'Create' }}</button>
                    </form>
                </div>
            </div>
        </ng-container>
    </app-base-card>
</div>
