import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { ResultService } from "./result.service";
import { ResultType, ResultTypeHelper } from "../../models/result";
import { EnvironmentAPIs } from "../../enums/environment-apis";
import { map } from "rxjs/operators";

@Injectable()
export class AnalyticsDataService {
  constructor(
    private resultService: ResultService,
    private apiService: ApiService,
    private authService: AuthService
  ) {}

  teamOverview(userList, resultTypes: ResultType[]) {
    //userList = array of user_id's to include.
    let data:any = {};
    if (resultTypes) {
      data["filter_result_type"] = ResultTypeHelper.TransformForServerTypes(
        resultTypes
      );
    }

    data.userList = userList;
    console.log('team-analytics-overview-data-->', data);
    
    return this.apiService
      .post(
        "/team-analytics/overview",	data,	EnvironmentAPIs.Ketchup
        // this.licenseService.getLicenseHeader(licenseId)
      )
      .pipe(
      map(data => {
        let resp = JSON.parse(JSON.stringify(data));
        console.log('team-analytics-overview-response-->', resp);
        if (resp.success) {
          return resp.response;
        }
      })
      )
  }

  // teamGraph(userId, licenseId){
  //
  // }

  userOverview(userId, customerId, resultTypes: ResultType[]) {
    let data = { id: userId, customer_id:customerId };
    if (resultTypes) {
      data["filter_result_type"] = ResultTypeHelper.TransformForServerTypes(
        resultTypes
      );
    }

    return this.apiService.post("/user-analytics/overview", data).pipe (map(data => {
      let resp = JSON.parse(JSON.stringify(data));
      if (resp.success) {
        return resp.response;
      }
    })
    )
  }

  getUser(id){
    return this.apiService.get('/team-analytics/user/'+id).pipe( map(
        (response) => {
          return JSON.parse(JSON.stringify(response));
        }
    )
    )
  }

  // userGraph(userId, licenseId){
  //
  // }
}
