import {Injectable, isDevMode} from '@angular/core';
import {VolumeProviderService} from './volume-provider.service';
import {WatsonService} from './watson.service';
import {WordManagementService} from '../data/word-management.service';
import {CustomTimer} from '../../helpers/custom-timer';
import {DebugConsole} from '../../helpers/debug-console';
import {EventSubscriptionService} from './event-subscription.service';
import {NotificationEvent} from '../../models';
import {FeedbackEvent} from '../../models/feedback-event';
import {FeedbackEventTypeEnums} from '../../enums/feedback-event-type.enums';
import {VolumeMeterGameComponent} from '../../../presentr-app/learn/games/volume-meter-game/volume-meter-game.component';
import {float} from 'aws-sdk/clients/lightsail';
import {AudioScoreProcessorService} from './audio-score-processor.service';
import {FeedbackMonitorTypeEnums} from '../../enums/feedback-monitor-type.enums';

@Injectable()
export class FeedbackMonitorService {

    static MIN_RANGE = 50;
    static MAX_RANGE = 80;
    static readonly MESSAGE_SENT = 'feedback_monitor_message_sent';

    static BUFFERED_VOLUME_SECONDS = 10;
    static BUFFERED_FILLER_COUNT = 5;
    static FILLER_AGGREGATION_TIME = 5;
    static PACE_AGGREGATION_TIME = 5;
    static BUFFERED_PACE_COUNT = 5;

    // volumeReading: any = [];
    // finalWatsonReadings: any = [];
    // tempWatsonReading:any;

    lastWordReceivedTime: any;

    startTime: any;
    customTimer: CustomTimer;
    paused:boolean;

    secondVolumeAggregation = 0;
    secondVolumeCount = 0;
    bufferAggVol: float[] = [];

    monitorType :FeedbackMonitorTypeEnums;

    fillerWords: any[];
    midSentenceFillerCount:number;
    behavioralFillerCount:number;
    wordCount:number;
    bufferAggFiller : any[];

    bufferAggPace : any[];
    secondPauseCount = 0;


    private fillerAggTimer: number = FeedbackMonitorService.FILLER_AGGREGATION_TIME;
    private paceAggTimer: number = FeedbackMonitorService.FILLER_AGGREGATION_TIME;


    constructor(private watsonService: WatsonService,
                private volumeService: VolumeProviderService,
                private wordService: WordManagementService,
                private notificationService: EventSubscriptionService) {
    }

    private initFeedbackProcessing( type: FeedbackMonitorTypeEnums) {
        this.paused = false;
        this.secondVolumeAggregation = 0;
        this.secondVolumeCount = 0;
        this.bufferAggVol = [];
        this.wordCount = 0;
        this.behavioralFillerCount = 0;
        this.midSentenceFillerCount = 0;
        this.bufferAggFiller = [];
        this.monitorType = type;
        this.fillerAggTimer = FeedbackMonitorService.FILLER_AGGREGATION_TIME;
        this.bufferAggPace = [];
        this.secondPauseCount = 0;
        this.paceAggTimer = FeedbackMonitorService.PACE_AGGREGATION_TIME;
    }

    private subscribeToServices() {
        this.volumeService.subscribeForNotification(this, (scope, notification) => {
            scope.volumeReadingReceived(notification.data);
        });

        this.watsonService.subscribeForNotification(this, (scope, notification) => {
            switch (notification.data.event) {
                case 'recognized':
                    scope.watsonReadingReceived(notification.data.data); // 1 data is the event data, 2 data of the result
                    break;

                case 'error':
                    //DebugConsole.log(notification);
                    // error handling.
                    break;

                case 'close':
                    //DebugConsole.log("closed watson score processor");
                    //DebugConsole.log(notification);
                    scope.watsonService.unsubscribeNotification(scope);
                    break;

                case 'message':
                    // debug purpose
                    // DebugConsole.log("message");
                    //DebugConsole.log(notification);
                    break;

                case 'stop':
                    //DebugConsole.log("stopped watson score processor");
                    //DebugConsole.log(notification);
                    // new data is no longer being processed. the stream stopped
                    break;

                case 'open':
                    // in theory this is where watson connected
                    //DebugConsole.log("watson connection opened");
                    //DebugConsole.log(notification);
                    break;

                case 'listening':
                    // in theory this is where watson connected
                    //DebugConsole.log("watson is listening now");
                    //DebugConsole.log(notification);
                    break;
            }

        });

    }

    public setFillerWords(fillers){
        this.fillerWords = fillers;
    }

    subscribeNotification(scope, callback){
        this.notificationService.subscribeToNotificationEvent(FeedbackMonitorService.MESSAGE_SENT, scope, callback);
    }

    unsubscribeNotification(scope){
        this.notificationService.unsubscribeToNotificationEvent(FeedbackMonitorService.MESSAGE_SENT, scope);
    }

    startFeedbackProcessing(type: FeedbackMonitorTypeEnums) {

        this.initFeedbackProcessing(type);

        this.customTimer = new CustomTimer();
        this.customTimer.tick.subscribe(r => {
            this.onSecondTick(r);
        });
        this.customTimer.start();
        // DebugConsole.log("TIME START", performance.timeOrigin, performance.now());

        this.startTime = performance.now();
        this.lastWordReceivedTime = performance.now();

        this.subscribeToServices();
    }

    private sendFeedbackNotification(type:FeedbackEventTypeEnums, data){

        this.notificationService.sendNotificationEvent(new NotificationEvent(FeedbackMonitorService.MESSAGE_SENT, new FeedbackEvent(type, data)));

    }
    static  NO_WORDS_RECEIVED_NOTIFICATION_TIME = 6000;

    perfectVolumeMessage = [
        "Great Job!",
        "Perfect! Keep it up.",
        "You sounds passionate and enthusiastic",
        "You sounds like a pro."
    ];
    goodVolumeMessage = [
        "Good job. Try to keep your volume up.",
        "Make sure your volume stays in the Sweet Zone.",
        "End a sentence as strong as you begin."
    ];
    lowVolumeMessage = [
        "Don't be Monotone, talk louder.",
        "Imagine talking to the person at the back of the room.",
        "Say each word with the same level of effort."

    ];
    highVolumeMessage = [
        "Are you screaming? talk a little softer.",
        "Bring the volume down a bit.",
        "If you can be this loud, you can hit the sweet zone!"

    ];
    noWordsMessage = [
        "Are you there? We can't hear you.",
        "Not sure what say? Use our prompts to help you.",
        "We can't hear you. Talk a little louder."
    ];

    fillerToHighMessage =[
        "Replace those filler words with silence!",
        "Say the filler word silently in your head.",
        "Take a breath to replace a filler word."
    ];
    behavioralFillerMessage = [
        "Careful, too many fillers.",
        "Think about the first word you want the audience to hear.",
        "Find a set of eyes or object to focus on when talking."
    ];

    midSentenceFillerMessage = [
        "Don't be afraid of silence, pause instead of a filler",
        "Pause to gather your thoughts before speaking",
        "Avoid hesitations in the middle of a sentence."
    ];
    goodFillersMessage =[
        "Good job!",
        "Keep it up. Consistency is key.",
        "Your message sounds better."
    ];
    perfectFillerMessage = [
        "Great Job!",
        "Perfect! Keep it up.",
        "You sound like a pro!",
        "Your message sounds clear and organized!"
    ];



    pacePerfectMessage =[
        "You are doing Great!",
        "Perfect!",
        "You are a pro!",
        "Your pace is on point. Keep it up!"
    ];

    paceGoodMessage = [
        "Good job! Try to stay between 120-145 Words per minute.",
        "You are almost there. Pick up the pace a little bit.",
        "A comma or a period is a good time to pause."
    ];

    paceToFastMessage = [
        "Try to pause more often!",
        "Take a deeper breath at the end of a sentence.",
        "Focus on one point in the room to help slow your pace of speech."
    ];

    paceToSlowMessage =[
        "Try a little faster.",
        "Use longer sentences and fewer pauses.",
        "Take a deep breath and speak a little longer."
    ];

    paceNoPausesMessage = [
        "Stop and take a breath!",
        "Say the word 'Pause' silently in your head at the end of each sentence",
        "Aim for 10-15 pauses per minute"
    ];



    private static GetRandomValueFromArray(array : any[]) : any {
        let randVal = Math.floor(Math.random() * array.length);
        randVal = Math.min(array.length - 1, randVal);
        return array[randVal];
    }

    time = 0;
    private onSecondTick(t){
        let currTime = performance.now();
        this.time = t;
        if(currTime - this.lastWordReceivedTime > FeedbackMonitorService.NO_WORDS_RECEIVED_NOTIFICATION_TIME) { // over 5 seconds
            this.bufferAggVol = [];
            this.bufferAggFiller = [];

            this.lastWordReceivedTime = currTime +  Math.floor(Math.random() * 2000) + 1000;
            this.sendFeedbackNotification(FeedbackEventTypeEnums.NotWordsReceived, FeedbackMonitorService.GetRandomValueFromArray(this.noWordsMessage));
        }
        else{
            switch (this.monitorType){
                case FeedbackMonitorTypeEnums.Volume:
                    this.processVolumeFeedback();
                    break;
                case FeedbackMonitorTypeEnums.FillerWords:
                    this.processFillerFeedback();
                    break;
                case FeedbackMonitorTypeEnums.Pace:
                    this.processPaceFeedback();
                    break;
            }
        }
    }

    private processFillerFeedback(){
        if(this.fillerAggTimer > 0){
            this.fillerAggTimer -= 1;
        }
        else{
            this.bufferAggFiller.push({wordCount:this.wordCount, behavioralCount:this.behavioralFillerCount, midSentenceCount:this.midSentenceFillerCount});
            this.wordCount = 0;
            this.behavioralFillerCount = 0;
            this.midSentenceFillerCount = 0;
            this.fillerAggTimer = FeedbackMonitorService.FILLER_AGGREGATION_TIME;
        }

        // keep up to 5 sets. 5 sec aggregation is up to 25 seconds.
        if(this.bufferAggFiller.length > FeedbackMonitorService.BUFFERED_FILLER_COUNT){
            this.bufferAggFiller.shift();
        }

        if(this.bufferAggFiller.length > FeedbackMonitorService.BUFFERED_FILLER_COUNT * .8) { // at least 80 % of data points (20 secs)

            let handled = false;

            let percentOfMiddleFiller = 0;
            let percentOfBehavioralFiller = 0;
            let percentFiller = 0;
            let wordCountAgg = 0;

            for(let val of this.bufferAggFiller){
                percentOfMiddleFiller += val.midSentenceCount;
                percentOfBehavioralFiller += val.behavioralCount;
                wordCountAgg += val.wordCount;
            }

            if(wordCountAgg > 0){
                percentOfMiddleFiller = 100 * percentOfMiddleFiller / wordCountAgg;
                percentOfBehavioralFiller = 100 * percentOfBehavioralFiller / wordCountAgg;
                percentFiller = 100 * (percentOfMiddleFiller + percentOfBehavioralFiller) / wordCountAgg;

                // 2 % is perfect
                // 8 % is acceptable
                // 10 % + is bad
                // 25% + horrible
                if(percentFiller < 2){
                    this.sendFeedbackNotification(FeedbackEventTypeEnums.FillerPerfect, FeedbackMonitorService.GetRandomValueFromArray(this.perfectFillerMessage));
                    handled = true;
                }
                else if (percentFiller < 8){
                    this.sendFeedbackNotification(FeedbackEventTypeEnums.FillerGood, FeedbackMonitorService.GetRandomValueFromArray(this.goodFillersMessage));
                    handled = true;
                }
                else if(percentOfMiddleFiller > 8){
                    this.sendFeedbackNotification(FeedbackEventTypeEnums.FillerMidSentenceToHigh, FeedbackMonitorService.GetRandomValueFromArray(this.midSentenceFillerMessage));
                    handled = true;
                }
                else if(percentOfBehavioralFiller > 8){
                    this.sendFeedbackNotification(FeedbackEventTypeEnums.FillerBehaviourToHigh, FeedbackMonitorService.GetRandomValueFromArray(this.behavioralFillerMessage));
                    handled = true;
                }
                else if(percentFiller > 20){
                    this.sendFeedbackNotification(FeedbackEventTypeEnums.FillerTooHigh, FeedbackMonitorService.GetRandomValueFromArray(this.fillerToHighMessage));
                    handled = true;
                }
            }
            else{
                handled = true;
            }

            if(handled){ // remove 10 seconds of data
                this.bufferAggFiller.shift();
                this.bufferAggFiller.shift();
            }
        }

    }

    private processPaceFeedback(){
        if(this.paceAggTimer > 0){
            this.paceAggTimer -= 1;
        }
        else{
            this.bufferAggPace.push({wordCount:this.wordCount, time:this.time, pausesCount:this.secondPauseCount});
            this.wordCount = 0;
            this.secondPauseCount = 0;
            this.paceAggTimer = FeedbackMonitorService.PACE_AGGREGATION_TIME;
        }

        // keep up to 5 sets. 5 sec aggregation is up to 25 seconds.
        if(this.bufferAggPace.length > FeedbackMonitorService.BUFFERED_PACE_COUNT){
            this.bufferAggPace.shift();
        }

        if(this.bufferAggPace.length > FeedbackMonitorService.BUFFERED_PACE_COUNT * .8) { // at least 80 % of data points (20 secs)

            let handled = false;
            let wordCountAgg = 0;

            let relativeTime = 1 + this.bufferAggPace[this.bufferAggPace.length - 1] - this.bufferAggPace[0].time;
            let pauseCountAgg = 0;

            for(let val of this.bufferAggPace){
                wordCountAgg += val.wordCount;
                pauseCountAgg += val.pausesCount;
            }

            if(wordCountAgg > 0){

                let pausesPM = 0;
                let wPM = 0;
                if(relativeTime > 0){
                    wPM = Math.round(60 * wordCountAgg / relativeTime);
                    pausesPM = 60 * pauseCountAgg / relativeTime;
                }

                // ideal pausesPM is 5 per minute
                // less than that is too little.

                if(wPM < 80){
                    this.sendFeedbackNotification(FeedbackEventTypeEnums.PaceToLow, FeedbackMonitorService.GetRandomValueFromArray(this.paceToSlowMessage));
                    handled = true;
                }
                else if (wPM > 160){
                    if(pausesPM < 5){
                        this.sendFeedbackNotification(FeedbackEventTypeEnums.PaceNoPauses, FeedbackMonitorService.GetRandomValueFromArray(this.paceNoPausesMessage));
                    }
                    else{
                        this.sendFeedbackNotification(FeedbackEventTypeEnums.PaceToHigh, FeedbackMonitorService.GetRandomValueFromArray(this.paceToFastMessage));
                    }
                    handled = true;
                }
                else if(wPM > 125 && wPM < 145){
                    this.sendFeedbackNotification(FeedbackEventTypeEnums.PacePerfect, FeedbackMonitorService.GetRandomValueFromArray(this.pacePerfectMessage));
                    handled = true;
                }
                else {
                    this.sendFeedbackNotification(FeedbackEventTypeEnums.PaceGood, FeedbackMonitorService.GetRandomValueFromArray(this.paceGoodMessage));
                    handled = true;
                }
            }
            else{
                handled = true;
            }

            if(handled){ // remove 10 seconds of data
                this.bufferAggPace.shift();
                this.bufferAggPace.shift();
            }
        }

    }

    private processVolumeFeedback(){
        this.bufferAggVol.push(this.secondVolumeCount > 20 ? this.secondVolumeAggregation/this.secondVolumeCount: 0);
        this.secondVolumeAggregation = 0;
        this.secondVolumeCount = 0;
        if(this.bufferAggVol.length > FeedbackMonitorService.BUFFERED_VOLUME_SECONDS){
            this.bufferAggVol.shift();
        }

        if(this.bufferAggVol.length > FeedbackMonitorService.BUFFERED_VOLUME_SECONDS * .8){ // we have at least 8 data points (80%)
            let avgDbSpl = 0;
            let belowHardMin = 0;
            let belowMin = 0;
            let overMax = 0;
            let inRange = 0;
            let readingsCount = this.bufferAggVol.length;
            if(readingsCount > 0){
                for(let d of this.bufferAggVol){
                    avgDbSpl += d;

                    if(d < 10){
                        belowHardMin++;
                    }
                    else if(d < AudioScoreProcessorService.MIN_RANGE){
                        belowMin++;
                    }
                    else if(d > AudioScoreProcessorService.MAX_RANGE){
                        overMax++;
                    }
                    else{
                        inRange++;
                    }
                }
                avgDbSpl = avgDbSpl / this.bufferAggVol.length;
            }

            let handled = false;

            // if. the average is over the max, and at least 5 are over 10sbSPL (not really needed)... we can assume they are way to high.
            if(avgDbSpl > AudioScoreProcessorService.MAX_RANGE){
                this.sendFeedbackNotification(FeedbackEventTypeEnums.VolumeToLow, FeedbackMonitorService.GetRandomValueFromArray(this.highVolumeMessage));
                handled = true;
            }
            // if the average is below the min, and at least 50% are over 10dbSPL... then we can assume the volume in general is low.
            else if(avgDbSpl < AudioScoreProcessorService.MIN_RANGE && belowHardMin < readingsCount * .5){
                this.sendFeedbackNotification(FeedbackEventTypeEnums.VolumeToLow, FeedbackMonitorService.GetRandomValueFromArray(this.lowVolumeMessage));
                handled = true;
            }
            // finally. if the average at least 50% are on the sweet zone we consider they are doing a good job.
            else if(inRange > readingsCount * .5){
                this.sendFeedbackNotification(FeedbackEventTypeEnums.VolumeGood, FeedbackMonitorService.GetRandomValueFromArray(this.goodVolumeMessage));
                handled = true;
            }
            // if over 70% is on sweet zone. is perfect.
            else if(inRange > readingsCount * .7){
                this.sendFeedbackNotification(FeedbackEventTypeEnums.VolumePerfect, FeedbackMonitorService.GetRandomValueFromArray(this.perfectVolumeMessage));
                handled = true;
            }

            // now clear the volume, to allow the processing to reset
            if(handled)
                this.bufferAggVol = [];

        }
    }

    private watsonReadingReceived(data) {
        if (data && data.alternatives.length > 0){
            this.lastWordReceivedTime = performance.now();
            if(data.final){
                this.secondPauseCount++;
                data.alternatives.forEach( (alternative, i) => {
                    this.wordCount +=alternative.timestamps.length;

                    alternative.timestamps.forEach( (word, j) => {
                        if( this.fillerWords && this.fillerWords.length > 0){
                            let indexOfFiller = this.fillerWords.indexOf(word[0]);
                            if (indexOfFiller >= 0) {
                                if(alternative.timestamps.length == 1){
                                    this.midSentenceFillerCount++;
                                }
                                else{
                                    this.behavioralFillerCount++;
                                }
                            }
                        }

                    });
                });
            }
        }
    }

    private volumeReadingReceived(data) {
        if (data.dbSPL < 10)
            return;

        this.secondVolumeAggregation += data.dbSPL;
        this.secondVolumeCount++;
    }

    stopFeedbackProcessing() {
        // this.endTime = performance.timeOrigin + performance.now();
        if (this.customTimer != null) {
            this.customTimer.stop();
        }
        this.volumeService.unsubscribeNotification(this);
        this.watsonService.unsubscribeNotification(this);
        // do not unsubscribe to Watson. it will happen when its closed
    }


    pauseFeedbackProcessor() {
        this.paused = true;
        this.customTimer.pause();
    }

    resumeScoreProcessor() {
        this.paused = false;
        this.customTimer.resume();
    }

}
