import {Component, OnInit} from '@angular/core';
import {PopupManagerComponent} from '../popup-manager.component';

@Component({
    selector: 'app-popup-base',
    templateUrl: './popup-base.component.html',
    styleUrls: ['./popup-base.component.scss']
})
export class PopupBaseComponent implements OnInit {

    constructor(private popupManager: PopupManagerComponent) {
    }

    ngOnInit() {
    }

    cancel() {
        this.popupManager.closeOpenModal(null);
    }
}
