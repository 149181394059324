import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
declare var ga: Function;

@Injectable()
export class AdrollService {

    constructor(public router: Router) {
    }

    public appendAdrollTrackingCode(){ 
        if(!environment.adrollTrackingEnabled) {
            return;
        }
        try {               
                const script = document.createElement('script');
                script.innerHTML = `
                adroll_adv_id = "BNDXIEDJARFTRHQOBMDOOV";
    
                adroll_pix_id = "HPMA2C53RVDOXGZYZQHS6L";
   
                (function () {
                    var _onload = function(){
                        if (document.readyState && !/loaded|complete/.test(document.readyState)){setTimeout(_onload, 10);return}
                        if (!window.__adroll_loaded){__adroll_loaded=true;setTimeout(_onload, 50);return}
                        var scr = document.createElement("script");
                        var host = (("https:" == document.location.protocol) ? "https://s.adroll.com" : "http://a.adroll.com");
                        scr.setAttribute('async', 'true');
                        scr.type = "text/javascript";
                        scr.src = host + "/j/roundtrip.js";
                        ((document.getElementsByTagName('head') || [null])[0] ||
                            document.getElementsByTagName('script')[0].parentNode).appendChild(scr);
                    };
                    if (window.addEventListener) {window.addEventListener('load', _onload, false);}
                    else {window.attachEvent('onload', _onload)}
                }());
                      `;
                document.head.appendChild(script);
        } catch (ex) {
            console.error('Error appending adroll tracking code');
            console.error(ex);
        }
    }
}
