<section class="flex flex-col container pb-6">
    <div class="flex flex-grow flex-wrap justify-center mt-4">
        <app-base-card *ngFor="let plan of planOptions" class="flex {{plan.type == selectedPlanType ? 'w-96 ': 'w-48'}}  min-h-32 my-1 mx-4 cursor-pointer transition-property-all transition"
                       (click)="planChosen(plan)">
            <ng-container slot="body">
                <div
                    class="flex flex-col p-4 rounded w-full h-full items-center {{plan.type == selectedPlanType ? 'text-white bg-blue': ''}}">
                    <div class="flex flex-col text-center w-full h-full items-center">
                        <h3 class="{{plan.type != selectedPlanType ? 'text-blue': ''}}">{{plan.name}}</h3>
                        <span *ngIf="plan.price_yearly > 0">${{plan.price_yearly}} / annually</span>
                        <span *ngIf="plan.price_monthly > 0" class="text-xs">(or ${{plan.price_monthly}} billed monthly)</span>
                        <div class="flex mt-2 {{plan.type == selectedPlanType ? 'text-xl': ''}}">{{plan.description}}</div>
                        <ul class="m-4 text-left list-disc text-left {{plan.type == selectedPlanType ? '': 'text-xs'}}">
                            <li *ngFor="let feature of plan.features">{{feature}}</li>
                        </ul>
                    </div>
                    <div>
                        <button class="button-white" *ngIf="plan.type == selectedPlanType && selectedPlanType == 0" routerLink="/">Start Using Presentr</button>
                        <button class="button-white" *ngIf="plan.type == selectedPlanType && selectedPlanType != 0" (click)="selectionClicked(selectedPlan)">{{buttonTitle}}</button>
                    </div>
                </div>
            </ng-container>
        </app-base-card>
    </div>

</section>
